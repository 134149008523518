import React, { Component } from "react";
import { Link, matchPath, Redirect, withRouter } from "react-router-dom";
import { Radio, Checkbox, Row, notification, Upload, Tooltip, Input, Icon, Collapse } from "antd";
import { GlobalContext } from "../../GlobalState";
import { HashLink as HashLink } from 'react-router-hash-link';
import axios from "axios";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ConteudoQuestionario extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        aulaid: 0,
        nrQuestoes: 0,
        questaoAtual: 0,
        questao: {},
        resposta: 0,
        resposta_checkbox: [],
        resposta_livre: "",
        fileList: [],
        fim: false,
        confirmacao: false,
        totalEmBranco: 0,
        //ESTADOS
        apresentacao: true,
        iniciado: false,
        //INFO
        modulo: "",
        aula: "",
        objetivos: "",
        total_questoes: 0,
        estado: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        redirectLinkResolucao: "",
        redirectLinkRepetir: "",
        //
        numPages: null,
        pageNumber: 1,
        scale: 1.5,
        rotate: 0,
        //LOADING
        iconLoadingProximo: false,
        iconLoadingAnterior: false,
        //LOADING
        loading: true,
        //
        nomeTarefaPai: "",
        //FASES
        conclusao: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.setState({
            curso: this.props.match.params.curso,
            aulaid: localStorage.getItem("codigo_aula"),
            redirectLink: `/curso/${this.props.match.params.curso}/${this.props.match.params.aula}/player-questionario-progresso-resumo`,
            redirectLinkResolucao: `/curso/${this.props.match.params.curso}/${this.props.match.params.aula}/player-questionario-progresso-resolucao`,
            redirectLinkRepetir: `/curso/${this.props.match.params.curso}/${this.props.match.params.aula}/player-questionario-progresso?repetir=true`
        });

        if (this.getParameterByName("repetir")) {
            axios({
                method: "put",
                url: "/api/questionario/repetir-questionario",
                params: {
                    aulaid: localStorage.getItem("codigo_aula"),
                    link: true
                }
            })
                .then(response => {
                    this.setState(
                        {
                            total_questoes: response.data,
                            questaoAtual: 0,
                            apresentacao: false,
                            iniciado: true,
                            loading: false
                        },
                        () => this.carregaPergunta()
                    );
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });

                    openNotificationWithIcon("error", "Erro", "Não foi possível repetir o questionario!");
                    this.props.marcarComoNaoDisponivel();
                });
        } else this.carregarQuestionarioInfo();
    }

    //APANHA OS ATRIBUTOS PASSADOS PELO ENDEREÇO
    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    onChangeRadio = e => {
        this.setState({
            resposta: e.target.value
        });
    };

    onChangeCheck = value => {
        var checks = [];

        for (var i = 0; i < value.length; i++) {
            if (value[i] > 0) checks.push(value[i]);
        }

        this.setState({
            resposta_checkbox: checks
        });
    };

    onChangeTextArea = event => {
        this.setState({
            resposta_livre: event.target.value
        });
    };

    //DEVOLVE O TOTAL DE PERGUNTAS
    carregarQuestionarioInfo = () => {
        axios({
            method: "get",
            url: "/api/questionario/carregar-questionario-info",
            params: {
                aulaid: localStorage.getItem("codigo_aula")
            }
        })
            .then(response => {
                var estado = "INICIAR";
                var questaoAtual = 0;

                if (!response.data.tarefaPaiConcluida) 
                    estado = "BLOQUEADO";

                if (response.data.estado && response.data.estado.concluido)
                    estado = "CONCLUIDO";

                localStorage.setItem(
                    "questionario_" + localStorage.getItem("codigo_aula"),
                    JSON.stringify({ modulo: response.data.modulo, aula: response.data.aula })
                );

                localStorage.setItem("curso_pai_nome", response.data.tarefaPaiNome);

                this.setState({
                    modulo: response.data.modulo,
                    aula: response.data.aula,
                    objetivos: response.data.objetivos,
                    total_questoes: response.data.totalQuestoes,
                    estado,
                    questaoAtual,
                    loading: false,
                    nomeTarefaPai: !response.data.tarefaPaiConcluida ? response.data.tarefaPaiNome : ""
                });
                this.props.carregarInfo(response.data.aula, response.data.objetivos, response.data.totalQuestoes, moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"));
            })
            .catch(error => {
                if (error.response.data === "SEM_PERMISSAO")
                    this.setState({
                        redirect: true,
                        redirectLink: "/user"
                    });
                else if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.props.marcarComoNaoDisponivel();
            });
    };

    totalRespostasEmBranco = () => {
        axios({
            method: "get",
            url: "/api/questionario/total-respostas-em-branco",
            params: {
                aulaid: localStorage.getItem("codigo_aula")
            }
        })
            .then(response => {
                this.setState({
                    totalEmBranco: response.data,
                    iniciado: false,
                    conclusao: response.data == 0
                });
            })
            .catch(error => {
                //    this.criarLog(error, "Tentativa de carregar o total de respostas em branco", "/api/questionario/total-respostas-em-branco");
            });
    };

    iniciarQuestionario = event => {
        event.preventDefault();

        axios({
            method: "put",
            url: "/api/player/iniciar-aula",
            params: {
                aulaid: localStorage.getItem("codigo_aula")
            }
        })
            .then(() => {
                this.carregaPergunta();
                this.setState({
                    apresentacao: false,
                    iniciado: true
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível iniciar aula!");
            });
    };

    RepetirQuestionario = event => {
        event.preventDefault();
        axios({
            method: "put",
            url: "/api/questionario/repetir-questionario",
            params: {
                aulaid: localStorage.getItem("codigo_aula")
            }
        })
            .then(() => {
                this.carregaPergunta();
                this.setState({
                    questaoAtual: 0,
                    apresentacao: false,
                    iniciado: true
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível repetir o questionario!");
            });
    };

    carregaPergunta = () => {
        if (this.state.questaoAtual != this.state.total_questoes) {
            this.setState({
                iconLoadingProximo: true
            });

            axios({
                method: "get",
                url: "/api/questionario/carrega-pergunta",
                params: {
                    aulaid: localStorage.getItem("codigo_aula"),
                    pergunta: this.state.questaoAtual
                }
            })
                .then(response => {
                    this.setState({
                        resposta: 0,
                        resposta_checkbox: [],
                        resposta_livre: "",
                        fileList: []
                    });
                    if (response.data.questionario_Utilizadores.length > 0) {
                        if (response.data.tipo_pergunta == "Escolha múltipla") {
                            var resposta_checkbox = [];

                            for (var i = 0; i < response.data.questionario_Utilizadores.length; i++) {
                                if (response.data.questionario_Utilizadores[i].id_opcao)
                                    resposta_checkbox.push(response.data.questionario_Utilizadores[i].id_opcao);
                            }

                            this.setState({
                                resposta_checkbox
                            });
                        } else if (response.data.tipo_pergunta == "Escala 1 a 5" || response.data.tipo_pergunta == "Escala 1 a 10") {
                            if (response.data.questionario_Utilizadores[0].id_opcao)
                                this.setState({
                                    resposta: response.data.questionario_Utilizadores[0].id_opcao.toString()
                                });
                            else
                                this.setState({
                                    resposta: ""
                                });
                        } else if (response.data.tipo_pergunta == "Resposta livre") {
                            if (response.data.questionario_Utilizadores[0].resposta_livre) {
                                var fileList = [];

                                if (response.data.questionario_Utilizadores[0].ficheiro)
                                    fileList.push({
                                        uid: "-1",
                                        name: response.data.questionario_Utilizadores[0].ficheiro_nome,
                                        status: "done",
                                        url: response.data.questionario_Utilizadores[0].ficheiro
                                    });

                                this.setState({
                                    resposta_livre: response.data.questionario_Utilizadores[0].resposta_livre,
                                    fileList
                                });
                            } else
                                this.setState({
                                    resposta_livre: "",
                                    fileList: []
                                });
                        } else {
                            if (response.data.questionario_Utilizadores[0].id_opcao)
                                this.setState({
                                    resposta: response.data.questionario_Utilizadores[0].id_opcao
                                });
                            else
                                this.setState({
                                    resposta: ""
                                });
                        }
                    }

                    this.setState({
                        questao: response.data,
                        questaoAtual: this.state.questaoAtual + 1,
                        iconLoadingProximo: false
                    });
                })
                .catch(() => { });
        } else this.totalRespostasEmBranco();
    };

    carregaPerguntaAnterior = () => {
        this.setState({
            iconLoadingAnterior: true
        });

        axios({
            method: "get",
            url: "/api/questionario/carrega-pergunta",
            params: {
                aulaid: localStorage.getItem("codigo_aula"),
                pergunta: this.state.questaoAtual - 2
            }
        })
            .then(response => {
                this.setState({
                    resposta: 0,
                    resposta_checkbox: [],
                    resposta_livre: "",
                    fileList: []
                });

                if (response.data.questionario_Utilizadores.length > 0) {
                    if (response.data.tipo_pergunta == "Escolha múltipla") {
                        var resposta_checkbox = [];

                        for (var i = 0; i < response.data.questionario_Utilizadores.length; i++) {
                            if (response.data.questionario_Utilizadores[i].id_opcao)
                                resposta_checkbox.push(response.data.questionario_Utilizadores[i].id_opcao);
                        }

                        this.setState({
                            resposta_checkbox
                        });
                    } else if (response.data.tipo_pergunta == "Escala 1 a 5" || response.data.tipo_pergunta == "Escala 1 a 10") {
                        if (response.data.questionario_Utilizadores[0].id_opcao)
                            this.setState({
                                resposta: response.data.questionario_Utilizadores[0].id_opcao.toString()
                            });
                        else
                            this.setState({
                                resposta: ""
                            });
                    } else if (response.data.tipo_pergunta == "Resposta livre") {
                        if (response.data.questionario_Utilizadores[0].resposta_livre) {
                            var fileList = [];

                            if (response.data.questionario_Utilizadores[0].ficheiro)
                                fileList.push({
                                    uid: "-1",
                                    name: response.data.questionario_Utilizadores[0].ficheiro_nome,
                                    status: "done",
                                    url: response.data.questionario_Utilizadores[0].ficheiro
                                });

                            this.setState({
                                resposta_livre: response.data.questionario_Utilizadores[0].resposta_livre,
                                fileList
                            });
                        } else
                            this.setState({
                                resposta_livre: "",
                                fileList: []
                            });
                    } else {
                        if (response.data.questionario_Utilizadores[0].id_opcao)
                            this.setState({
                                resposta: response.data.questionario_Utilizadores[0].id_opcao
                            });
                        else
                            this.setState({
                                resposta: ""
                            });
                    }
                }

                this.setState({
                    questao: response.data,
                    questaoAtual: this.state.questaoAtual - 1,
                    iconLoadingAnterior: false
                    //defaultCheckbox: checkboxs,
                    //checkbox: checkboxs
                });
            })
            .catch(() => { });
    };

    proximo = event => {
        event.preventDefault();

        if (this.state.questao.tipo_pergunta == "Escolha múltipla") {
            axios({
                method: "post",
                url: "/api/questionario/add-resposta-checkbox",
                params: {
                    id_questao: this.state.questao.id,
                    opcoes: JSON.stringify(this.state.resposta_checkbox)
                }
            })
                .then(() => {
                    this.carregaPergunta();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        } else if (this.state.questao.tipo_pergunta == "Resposta livre") {
            let item = new FormData();
            item.append("id_questao", this.state.questao.id);
            item.append("texto", this.state.resposta_livre);
            item.append("ficheiro", this.state.fileList[0]);

            axios({
                method: "post",
                url: "/api/questionario/add-resposta-livre",
                data: item
            })
                .then(() => {
                    this.carregaPergunta();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        } else {
            let item = new FormData();
            item.append("id_questao", this.state.questao.id);
            if (this.state.resposta) item.append("id_opcao", this.state.resposta);

            axios({
                method: "post",
                url: "/api/questionario/add-resposta-radio",
                data: item
            })
                .then(() => {
                    this.carregaPergunta();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        }
    };

    anterior = event => {
        event.preventDefault();

        if (this.state.questao.tipo_pergunta == "Escolha múltipla") {
            axios({
                method: "post",
                url: "/api/questionario/add-resposta-checkbox",
                params: {
                    id_questao: this.state.questao.id,
                    opcoes: JSON.stringify(this.state.resposta_checkbox)
                }
            })
                .then(() => {
                    this.carregaPerguntaAnterior();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        } else if (this.state.questao.tipo_pergunta == "Resposta livre") {
            var item = new FormData();
            item.append("id_questao", this.state.questao.id);
            item.append("texto", this.state.resposta_livre);
            item.append("ficheiro", this.state.fileList[0]);

            axios({
                method: "post",
                url: "/api/questionario/add-resposta-livre",
                data: item
            })
                .then(() => {
                    this.carregaPerguntaAnterior();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        } else {
            let item = new FormData();
            item.append("id_questao", this.state.questao.id);
            if (this.state.resposta) item.append("id_opcao", this.state.resposta);

            axios({
                method: "post",
                url: "/api/questionario/add-resposta-radio",
                data: item
            })
                .then(() => {
                    this.carregaPerguntaAnterior();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        }
    };

    confirmacaoAnterior = event => {
        event.preventDefault();
        this.setState({
            iconLoadingAnterior: true
        });

        axios({
            method: "get",
            url: "/api/questionario/carrega-pergunta",
            params: {
                aulaid: localStorage.getItem("codigo_aula"),
                pergunta: this.state.questaoAtual - 1
            }
        })
            .then(response => {
                this.setState({
                    resposta: 0,
                    resposta_checkbox: [],
                    resposta_livre: "",
                    fileList: []
                });

                if (response.data.questionario_Utilizadores.length > 0) {
                    if (response.data.tipo_pergunta == "Escolha múltipla") {
                        var resposta_checkbox = [];

                        for (var i = 0; i < response.data.questionario_Utilizadores.length; i++) {
                            if (response.data.questionario_Utilizadores[i].id_opcao)
                                resposta_checkbox.push(response.data.questionario_Utilizadores[i].id_opcao);
                        }

                        this.setState({
                            resposta_checkbox
                        });
                    } else if (response.data.tipo_pergunta == "Escala 1 a 5" || response.data.tipo_pergunta == "Escala 1 a 10") {
                        if (response.data.questionario_Utilizadores[0].id_opcao)
                            this.setState({
                                resposta: response.data.questionario_Utilizadores[0].id_opcao.toString()
                            });
                        else
                            this.setState({
                                resposta: ""
                            });
                    } else if (response.data.tipo_pergunta == "Resposta livre") {
                        if (response.data.questionario_Utilizadores[0].resposta_livre) {
                            var fileList = [];

                            if (response.data.questionario_Utilizadores[0].ficheiro)
                                fileList.push({
                                    uid: "-1",
                                    name: response.data.questionario_Utilizadores[0].ficheiro_nome,
                                    status: "done",
                                    url: response.data.questionario_Utilizadores[0].ficheiro
                                });

                            this.setState({
                                resposta_livre: response.data.questionario_Utilizadores[0].resposta_livre,
                                fileList
                            });
                        } else
                            this.setState({
                                resposta_livre: "",
                                fileList: []
                            });
                    } else {
                        if (response.data.questionario_Utilizadores[0].id_opcao)
                            this.setState({
                                resposta: response.data.questionario_Utilizadores[0].id_opcao
                            });
                        else
                            this.setState({
                                resposta: ""
                            });
                    }
                }

                this.setState({
                    questao: response.data,
                    questaoAtual: this.state.questaoAtual,
                    iniciado: true,
                    totalEmBranco: 0,
                    conclusao: false,
                    iconLoadingAnterior: false
                });
            })
            .catch(() => { });
    };

    concluir = () => {
        axios({
            method: "put",
            url: "/api/questionario/concluir",
            params: {
                aulaid: localStorage.getItem("codigo_aula")
            }
        })
            .then(response => {
                this.context.atualizarListaModulos();
                this.setState({
                    redirect: true
                });
            })
            .catch(() => { });
    };

    validarFormatos = (formato, formatos_aceites) => {
        var valido = false

        for (var i = 0; i < formatos_aceites.length; i++) {
            if (formato == formatos_aceites[i]) valido = true
        }

        return valido
    }

    //LEITOR DE PDF
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    scaleDown = () => {
        if (this.state.scale >= 0.8) {
            this.setState({ scale: this.state.scale - 0.3 });
        }
    };

    scaleUp = () => {
        if (this.state.scale <= 1.8) {
            this.setState({ scale: this.state.scale + 0.3 });
        }
    };

    render() {
        const { Panel } = Collapse;
        const Dragger = Upload.Dragger;

        const {
            nrQuestoes,
            questaoAtual,
            questao,
            //REDIRECT
            redirect,
            redirectLink,
            redirectLinkResolucao,
            totalEmBranco,
            resposta_checkbox,
            resposta,
            resposta_livre,
            fileList,
            //INFO
            modulo,
            aula,
            objetivos,
            total_questoes,
            estado,
            //LOADING
            iconLoadingProximo,
            iconLoadingAnterior,
            //LOADING
            loading,
            //FASES
            conclusao
        } = this.state;
        const RadioGroup = Radio.Group;

        const radioStyle = {
            display: "block",
            height: "30px",
            lineHeight: "30px"
        };

        const montar_botao = estado => {
            if (estado === "INICIAR")
                return (
                    <button className="botao-principal apresentacao-controlo" onClick={e => this.iniciarQuestionario(e)}>
                        {" "}
                        Iniciar{" "}
                    </button>
                );
            else
                return (
                    <div className="mensagem-controlos">
                        <h3>Para poder efetuar este questionário de progresso deve concluir a tarefa:</h3>
                        <span>{this.state.nomeTarefaPai}</span>
                    </div>
                    )
        };

        const montarTitulo = (tipo_pergunta) => {
            var titulo = "";

            switch (tipo_pergunta) {
                case "Escolha única":
                    titulo = "Opção";
                    break;
                case "Escolha múltipla":
                    titulo = "Opções";
                    break;
                default:
                    titulo = "Respostas";
                    break;
            }

            return titulo;
        }

        const montar_perguntar = questao => {
            if (questao.tipo_pergunta === "Escolha única") {
                return (
                    <RadioGroup onChange={this.onChangeRadio} value={resposta}>
                        {questao.opcoes.map((opcao, index) => (
                            <Radio key={"opcao" + index} style={radioStyle} value={opcao.id}>
                                {opcao.texto}
                            </Radio>
                        ))}
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Escolha múltipla") {
                return (
                    <Checkbox.Group style={{ width: "100%" }} onChange={this.onChangeCheck} defaultValue={resposta_checkbox}>
                        {questao.opcoes.map((opcao, index) => (
                            <Row key={"opcao" + index}>
                                <Checkbox value={opcao.id}>{opcao.texto}</Checkbox>
                            </Row>
                        ))}
                    </Checkbox.Group>
                );
            } else if (questao.tipo_pergunta === "Escala 1 a 5") {
                return (
                    <RadioGroup className="escala" onChange={this.onChangeRadio} value={resposta}>
                        <span className="minimo">1</span>
                        <Radio style={radioStyle} className="escala" value="1"></Radio>
                        <Radio style={radioStyle} className="escala" value="2"></Radio>
                        <Radio style={radioStyle} className="escala" value="3"></Radio>
                        <Radio style={radioStyle} className="escala" value="4"></Radio>
                        <Radio style={radioStyle} className="escala" value="5"></Radio>
                        <span className="maximo">5</span>
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Escala 1 a 10") {
                return (
                    <RadioGroup className="escala" onChange={this.onChangeRadio} value={resposta}>
                        <span className="minimo">1</span>
                        <Radio style={radioStyle} className="escala" value="1"></Radio>
                        <Radio style={radioStyle} className="escala" value="2"></Radio>
                        <Radio style={radioStyle} className="escala" value="3"></Radio>
                        <Radio style={radioStyle} className="escala" value="4"></Radio>
                        <Radio style={radioStyle} className="escala" value="5"></Radio>
                        <Radio style={radioStyle} className="escala" value="6"></Radio>
                        <Radio style={radioStyle} className="escala" value="7"></Radio>
                        <Radio style={radioStyle} className="escala" value="8"></Radio>
                        <Radio style={radioStyle} className="escala" value="9"></Radio>
                        <Radio style={radioStyle} className="escala" value="10"></Radio>
                        <span className="maximo">10</span>
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Resposta livre") {
                return (
                    <>
                        <TextArea value={resposta_livre} onChange={this.onChangeTextArea} style={{ height: "100%" }} rows={5} />
                        <div className="anexos" style={{ marginTop: 20 }}>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <i className="fas fa-upload" />
                                </p>
                                <p className="ant-upload-text">
                                    Adicionar anexo{" "}
                                    <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </p>
                                <p className="ant-upload-hint">Limite de 25MB por upload</p>
                            </Dragger>
                        </div>
                    </>
                );
            }
        };

        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("ficheiro");
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    return {
                        fileList: newFileList
                    };
                });
            },
            defaultFileList: this.state.fileList,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 26214400) {
                    openNotificationWithIcon("error", "Erro", "Limite de 25 MB por upload")
                    return false;
                }

                this.setState(state => ({
                    fileList: [file]
                }));

                return false;
            },
            fileList
        };

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
                <div className="container">
                    <div className="bloco-questionario bloco-questionarioV2" style={{ background: "transparent", boxShadow: "none" }}>
                        <div className="bloco-info">
                            {this.state.apresentacao && (
                                <>
                                    {/*<div className="apresentacao" style={{ background: "white", boxShadow: "0 4px 6px #eee", height: "100vh", display: "flex", justifyContent: "center" }}>
                                    <div className="bloco-apresentacao">
                                        <span className="nome-modulo">{modulo}</span>
                                        <span className="aula">{aula}</span>
                                        <h2>Questionário {estado === "CONCLUIDO" ? "Concluído" : ""}</h2>
                                        <span>{total_questoes} questões</span>
                                        {estado !== "CONCLUIDO" &&
                                            <div className="apresentacao-controlos">
                                                {montar_botao(estado)}
                                            </div>}
                                    </div>
                                </div>*/}
                                <div className="bloco-presencial">
                                        <div className="bloco-download-link">
                                            <div className="bloco-info-tarefa">
                                                <span className="icon-tarefa">
                                                    <img src={require("../../images/icons/progresso.png")} />
                                                </span>
                                                <span className="titulo-tarefa">{aula}<br /><span className="numero-questoes">{total_questoes} {total_questoes > 1 ? "Questões" : "Questão"}</span></span>
                                            </div>
                                            <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                                            {estado !== "CONCLUIDO" ?
                                                montar_botao(estado)  : null}
                                        </div>
                                    </div>
                                    </>
                            )}
                            {this.state.iniciado && (
                                <div className="prova">
                                    <div className="bloco-questao">
                                        <div className="bloco-enunciado">
                                            <span className="num-pergunta">
                                                Questão {questaoAtual} - {total_questoes}
                                            </span>
                                            <p
                                                className="texto-questao"
                                                dangerouslySetInnerHTML={{
                                                    __html: questao.pergunta ? questao.pergunta.replace(/(?:\r\n|\r|\n)/g, "<br>") : ""
                                                }}
                                            ></p>
                                            {questao.ficheiro_pergunta && (
                                                <div className="primeiro-bloco-links">
                                                    {questao.ficheiro_nome_pergunta.split(".").pop() === "pdf" ?
                                                        <Collapse
                                                            defaultActiveKey={['1']}
                                                            expandIcon={({ isActive }) =>
                                                                <div className="bloco-links-ficheiro">
                                                                    <span className="bloco-links-img-container">
                                                                        <img src={require("../../images/icons/pdf.png")} />
                                                                    </span>
                                                                    <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                                </div>
                                                            }
                                                        >
                                                            <Panel key="1">
                                                                <Document file={questao.ficheiro_pergunta} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                                    <Page
                                                                        pageNumber={this.state.pageNumber}
                                                                        scale={this.state.scale}
                                                                        rotate={this.state.rotate}
                                                                    />
                                                                </Document>
                                                                <div className="controlos-pdf">
                                                                    <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber > this.state.numPages ? prevState.pageNumber - 1 : 1) }))}>
                                                                        <Icon type="caret-left" />
                                                                    </button>
                                                                    <span>
                                                                        {this.state.pageNumber}/{this.state.numPages}
                                                                    </span>
                                                                    <button onClick={() => this.setState(prevState => ({ pageNumber: (prevState.pageNumber < this.state.numPages ? prevState.pageNumber + 1 : this.state.numPages) }))}>
                                                                        <Icon type="caret-right" />
                                                                    </button>
                                                                    <button onClick={this.scaleUp}>
                                                                        <Icon type="zoom-in" />
                                                                    </button>
                                                                    <button onClick={this.scaleDown}>
                                                                        <Icon type="zoom-out" />
                                                                    </button>
                                                                    <button onClick={() => this.setState({ rotate: this.state.rotate === 180 ? 180 : this.state.rotate + 90 })}>
                                                                        <Icon type="reload" />
                                                                    </button>
                                                                    <button onClick={() => this.setState({ rotate: this.state.rotate === -180 ? -180 : this.state.rotate - 90 })}>
                                                                        <Icon type="reload" style={{ transform: "scaleX(-1) " }} />
                                                                    </button>
                                                                </div>
                                                            </Panel>
                                                        </Collapse>
                                                        :
                                                        <Collapse
                                                            defaultActiveKey={['1']}
                                                            expandIcon={({ isActive }) =>
                                                                <div className="bloco-links-ficheiro">
                                                                    <span className="bloco-links-img-container">
                                                                        <img src={require("../../images/icons/images.png")} />
                                                                    </span>
                                                                    <span className="bloco-links-texto">{isActive ? "Fechar" : "Abrir"}</span>
                                                                </div>
                                                            }
                                                        >
                                                            <Panel key="1">
                                                                <img src={questao.ficheiro_pergunta} />
                                                            </Panel>
                                                        </Collapse>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        <div className="bloco-resposta">
                                            <h4 className="bloco-titulo">{montarTitulo(questao.tipo_pergunta)}</h4>
                                            <div className="respostas">{montar_perguntar(questao)}</div>
                                            <div className="controlos-resposta">
                                                {questaoAtual > 1 ? (
                                                    <HashLink
                                                        to="#top"
                                                        className="botao-secundario"
                                                        disabled={iconLoadingProximo || iconLoadingAnterior}
                                                        onClick={e => this.anterior(e)}
                                                    >
                                                        {iconLoadingAnterior ? <Icon type="loading" /> : null}
                                                            Anterior
                                                    </HashLink>
                                                ) : null}
                                                <HashLink
                                                    to="#top"
                                                    className="botao-principal"
                                                    disabled={iconLoadingProximo || iconLoadingAnterior}
                                                    onClick={e => this.proximo(e)}
                                                >
                                                    {iconLoadingProximo ? <Icon type="loading" /> : null}
                                                    Próxima
                                                    </HashLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {totalEmBranco > 0 && (
                                <div
                                    className="confirmacao"
                                    style={{ background: "white", boxShadow: "0 4px 6px #eee", height: "100vh", width: "100%" }}
                                >
                                    <div className="bloco-confirmacao">
                                        <h2>Questionário Concluído!</h2>
                                        <h3>Questões em branco</h3>
                                        <span>{totalEmBranco}</span>
                                        <div className="controlos-resposta">
                                            <button
                                                className="botao-secundario"
                                                disabled={iconLoadingAnterior}
                                                onClick={e => this.confirmacaoAnterior(e)}
                                            >
                                                {iconLoadingAnterior ? <Icon type="loading" /> : null}
                                                Anterior
                                            </button>
                                            <button className="botao-principal" disabled={iconLoadingAnterior} onClick={this.concluir}>
                                                Submeter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {conclusao &&
                                <div
                                className="confirmacao"
                                style={{ background: "white", boxShadow: "0 4px 6px #eee", height: "100vh", width: "100%" }}
                            >
                                <div className="bloco-confirmacao">
                                    <h2>Questionário Concluído!</h2>
                                    <div className="controlos-resposta">
                                        <button
                                            className="botao-secundario"
                                            disabled={iconLoadingAnterior}
                                            onClick={e => this.confirmacaoAnterior(e)}
                                        >
                                            {iconLoadingAnterior ? <Icon type="loading" /> : null}
                                                Anterior
                                            </button>
                                        <button className="botao-principal" disabled={iconLoadingAnterior} onClick={this.concluir}>
                                            Submeter
                                            </button>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            );
    }
}

export default withRouter(ConteudoQuestionario);
