import React, { Component } from "react";
import { Link } from "react-router-dom";
import { notification, Drawer, Form, Input, Icon, DatePicker, Select, Tooltip, Upload } from "antd";
import axios from "axios";
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;
const Dragger = Upload.Dragger;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerInscricao extends Component {
    state = {
        //LOADING
        iconLoading: false,
        inscricao: ""
    };

    guardar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.inscricaoId);
                item.append("ficheiro_inscricao", this.props.ficheiro[0]);

                axios({
                    method: "put",
                    url: "/api/sistema-informacao/guardar-inscricao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Dados da inscrição atualizados!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível atualizar a inscrição");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    downloadFicheiro = () => {
        axios({
            method: "post",
            url: "/api/sistema-informacao/download-inscricao",
            params: {
                inscricaoId: this.props.inscricaoId
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            //LOADING
            iconLoading,
            visible,
            nome,
            email,
            nr_aluno,
            contacto,
            descricao,
            habilitacao,
            paginaPessoal,
            dt_nascimento,
            naturalidade,
            sexo,
            estado_civil,
            cc,
            dt_ccvalidade,
            nif,
            cep,
            localidade,
            morada,
            pais,
            motivo,
            responsavel_1,
            responsavel_2,
            nomeID,
            ccID,
            dt_ccvalidadeID,
            ficheiro,
            ficheiro_inscricao,
            ficheiro_inscricao_nome,
            dt_ficheiro_inscricao,
            curso_ficha_inscricao,
            curso_ficha_inscricao_nome,
            propsInscricao
        } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos"
                    title={<div className="drawer-titulo">
                        Inscrição
                        {localStorage.getItem("nome_curso_pai") ? (
                            <>
                                <h3>
                                    {localStorage.getItem("cod_curso_pai")} - {localStorage.getItem("nome_curso_pai")}
                                </h3>
                                <span>
                                    {localStorage.getItem("cod_subcurso")} - {localStorage.getItem("nome_subcurso")}
                                </span>
                                <span className="escola-nome">
                                    {localStorage.getItem("escola_nome")}
                                </span>
                            </>
                        ) : (
                                <>
                                    <h3>
                                        {localStorage.getItem("cod_curso")} - {localStorage.getItem("nome_curso")}
                                    </h3>
                                    <span className="escola-nome">
                                        {localStorage.getItem("escola_nome")}
                                    </span>
                                </>
                            )}
                    </div>
                    }
                    width={720}
                    onClose={this.props.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <div className="bloco">
                            <div className="campo">
                                <label className="titulo">Nome</label>
                                <span>{nome}</span>
                            </div>
                            <div className="campo">
                                <label className="titulo">E-mail</label>
                                <span>{email}</span>
                            </div>
                            <div className="campo">
                                <label className="titulo">Código</label>
                                <span>{nr_aluno}</span>
                            </div>
                            {contacto ?
                                <div className="campo">
                                    <label className="titulo">Telefone / Telemóvel</label>
                                    <span>{contacto}</span>
                                </div>
                                : null}
                            {descricao ?
                                <div className="campo">
                                    <label className="titulo">Descrição</label>
                                    <span>{descricao}</span>
                                </div>
                                : null}
                            {habilitacao ?
                                <div className="campo">
                                    <label className="titulo">Habilitações</label>
                                    <span>{habilitacao}</span>
                                </div>
                                : null}
                            {paginaPessoal ?
                                <div className="campo">
                                    <label className="titulo">Página Web pessoal</label>
                                    <span>{paginaPessoal}</span>
                                </div>
                                : null}
                            {dt_nascimento ?
                                <div className="campo">
                                    <label className="titulo">Data de nascimento</label>
                                    <span>{moment(dt_nascimento).format("DD/MM/YYYY")}</span>
                                </div>
                                : null}
                            {sexo ?
                                <div className="campo">
                                    <label className="titulo">Sexo</label>
                                    <span>{sexo}</span>
                                </div>
                                : null}
                            {estado_civil ?
                                <div className="campo">
                                    <label className="titulo">Estado Civil</label>
                                    <span>{estado_civil}</span>
                                </div>
                                : null}
                            {cc ?
                                <div className="campo">
                                    <label className="titulo">
                                        Identificação/RG
                                    <Tooltip className="info-icon" title="Documento de Identificação, Passaporte, ...">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </label>
                                    <span>{cc}</span>
                                </div>
                                : null}
                            {dt_ccvalidade ?
                                <div className="campo">
                                    <label className="titulo">
                                        Validade
                                    <Tooltip className="info-icon" title="Validade do documento de identificação">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </label>
                                    <span>{dt_ccvalidade}</span>
                                </div>
                                : null}
                            {nif ?
                                <div className="campo">
                                    <label className="titulo">NIF</label>
                                    <span>{nif}</span>
                                </div>
                                : null}
                            {cep ?
                                <div className="campo">
                                    <label className="titulo">Código Postal</label>
                                <span>{cep}</span>
                                </div>
                                :null}
                            {localidade ?
                                <div className="campo">
                                    <label className="titulo">Localidade</label>
                                    <span>{localidade}</span>
                                </div>
                                : null}
                            {morada ?
                                <div className="campo">
                                    <label className="titulo">Morada</label>
                                    <span>{morada}</span>
                                </div>
                                : null}
                            {pais ?
                                <div className="campo">
                                    <label className="titulo">País</label>
                                <span>{pais}</span>
                                </div>
                                : null}
                        </div>
                        {responsavel_1 || responsavel_2 ?
                            <div className="bloco">
                                <h3 className="titulo-separador">Filiação</h3>
                                {responsavel_1 ?
                                    <div className="campo">
                                        <label className="titulo">Mãe/Responsável</label>
                                        <span>{responsavel_1}</span>
                                    </div>
                                    : null}
                                {responsavel_2 ?
                                    <div className="campo">
                                        <label className="titulo">Pai/Responsável</label>
                                        <span>{responsavel_2}</span>
                                    </div>
                                    : null}
                            </div>
                            : null}
                        {motivo ?
                            <div className="bloco">
                            <div className="campo">
                                <label className="titulo">Motivo</label>
                                <span>{motivo}</span>
                            </div>
                            </div>
                            : null}
                        {curso_ficha_inscricao || ficheiro_inscricao ?
                            <div className="bloco">
                                <h3 className="titulo-separador">Ficha de inscrição</h3>
                                {nomeID ?
                                    <div className="campo">
                                        <label className="titulo">Nome</label>
                                        <span>{nomeID}</span>
                                    </div>
                                    : null}
                                {ccID ?
                                    <div className="campo">
                                        <label className="titulo">Identificação/RG</label>
                                        <span>{ccID}</span>
                                    </div>
                                    : null}
                                {dt_ccvalidadeID ?
                                    <div className="campo">
                                        <label className="titulo">Validade</label>
                                        <span>{dt_ccvalidadeID}</span>
                                    </div>
                                    : null}
                                {curso_ficha_inscricao ? (
                                    <div className="campo">
                                        <label className="titulo">
                                            Modelo para descarregar:
                                            <a href={curso_ficha_inscricao} className="botao-icon-download margin-left" download={curso_ficha_inscricao_nome}>
                                                <Icon type="download" />
                                            </a>
                                        </label>
                                    </div>
                                ) : null}
                                {ficheiro_inscricao ?
                                    <div className="campo">
                                        <label className="titulo">
                                            Submetida a {dt_ficheiro_inscricao}:
                                            <Link to="#" className="botao-icon-download" onClick={() => this.downloadFicheiro()} style={{marginLeft: 10}}>
                                                <Icon type="download" />
                                            </Link>
                                        </label>
                                    </div>
                                    : null}
                            </div>
                            : null}
                    </div>
                    <div className="ant-drawer-footer">
                        {!(curso_ficha_inscricao || ficheiro_inscricao) || dt_ficheiro_inscricao ?
                            <button className="botao-secundario" onClick={this.props.onClose} style={{ display: "inline-block" }}>
                                Voltar
                            </button>
                            :
                            <>
                                <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                                    Voltar
                                </button>
                                <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.guardar}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Guardar
                                </button>
                            </>
                        }
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormDrawerInscricao = Form.create({ name: "drawer-inscricao" })(DrawerInscricao);

export default FormDrawerInscricao;
