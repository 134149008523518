import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "../User/Header";
import Conversa from "./Conversa";
import Conversas from "./Conversas";
import Opcoes from "./Opcoes";
import FromNovoGrupo from "./Adicionar/NovoGrupo";
import Detalhe from "./_Aux/Detalhe";
import axios from "axios";
import { GlobalContext } from "../GlobalState";

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        //FILTROS
        pesquisa: "",
        verConversasResponsaveis: false,
        totalPorLerResponsaveis: 0,
        //LISTAGEM
        iconLoadingGrupos: false,
        grupos: [],
        //GRUPO
        block: 1,
        blockSize: 20,
        todasMensagens: false,
        iconLoadingMais: false,
        iconLoadingGrupo: false,
        grupo: "",
        selecionada: 0,
        mensagens: [],
        //LOADING
        loading: false,
        iconLoadingRecarregar: false,
        //FORM NOVO GRUPO
        grupoId: 0,
        visibleNovoGrupo: false,
        //DETALHE
        visibleDetalhe: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listarGrupos();
        this.carregarTotalPorLerResponsaveis();
    }

    //componentDidMount() {
    //    var objDiv = document.getElementsByClassName("lista-mensagens")[0];
    //    objDiv.addEventListener("scroll", () => { 
    //        if (objDiv.scrollTop === 0)
    //            this.carregarMensagensAnteriores()
    //    }, false);
    //}

    handlerChangeCheckbox = event => {
        this.setState({
            selecionada: 0,
            grupo: "",
            [event.target.name]: event.target.checked
        }, () => this.listarGrupos());
    };

    listarGrupos = () => {
        this.setState(
            {
                iconLoadingGrupos: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/chat/listar-grupos",
                    params: {
                        pesquisa: this.state.pesquisa,
                        verConversasResponsaveis: this.state.verConversasResponsaveis
                    }
                })
                    .then(response => {
                        this.setState({
                            grupos: response.data,
                            iconLoadingGrupos: false
                        }, () => {
                            if (response.data.length > 0) {
                                this.carregarMensagens(this.state.grupo ? this.state.grupo : response.data[0])
                            }
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    listarGruposAtualizados = () => {
        this.setState(
            {
                iconLoadingGrupos: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/chat/listar-grupos",
                    params: {
                        pesquisa: this.state.pesquisa,
                        verConversasResponsaveis: this.state.verConversasResponsaveis
                    }
                })
                    .then(response => {
                        this.setState({
                            grupos: response.data,
                            selecionada: 0,
                            iconLoadingGrupos: false
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    carregarMensagens = grupo => {
        this.setState(
            {
                grupo: "",
                mensagens: [],
                todasMensagens: false,
                iconLoadingGrupo: true,
                block: 1,
                blockSize: 20
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/chat/carregar-mensagens",
                    params: {
                        grupoId: grupo.id,
                        blockSize: this.state.blockSize,
                        block: this.state.block
                    }
                })
                    .then(response => {
                        var grupoIndex = this.state.grupos.findIndex(x => x.id === grupo.id);
                        var grupos = this.state.grupos;
                        grupos[grupoIndex].porLer = false;

                        this.setState({
                            grupos,
                            grupo,
                            mensagens: response.data.grupo,
                            iconLoadingGrupo: false,
                            iconLoadingRecarregar: false,
                            todasMensagens: response.data.grupo.length === response.data.total
                        }, () => {
                            var objDiv = document.getElementsByClassName("lista-mensagens")[0];
                            objDiv.scrollTop = objDiv.scrollHeight;
                            this.carregarTotalConversas();
                            this.carregarTotalPorLerResponsaveis();
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    carregarMensagensAnteriores = () => {
        if (!this.state.todasMensagens)
            this.setState(
                {
                    block: this.state.block + 1,
                    iconLoadingMais: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/chat/carregar-mensagens",
                        params: {
                            grupoId: this.state.grupo.id,
                            blockSize: this.state.blockSize,
                            block: this.state.block
                        }
                    })
                        .then(response => {
                            if (response.data.grupo.length)
                                this.setState({
                                    mensagens: [...response.data.grupo, ...this.state.mensagens],
                                    iconLoadingMais: false
                                })
                            else
                                this.setState({
                                    todasMensagens: true,
                                    iconLoadingMais: false
                                })
                        })
                        .catch(error => {
                            if (error.response.status === 401)
                                this.setState({
                                    redirect: true,
                                    redirectLink: "/login"
                                });
                            this.setState({
                                todasMensagens: true,
                                iconLoadingMais: false
                            })
                        });
                }
            );
    };

    atualizarMensagens = (grupoId, lastId) => {
        this.setState({ iconLoadingRecarregar: true },
            () => {
                axios({
                    method: "get",
                    url: "/api/chat/atualizar-mensagens",
                    params: {
                        grupoId,
                        lastId
                    }
                })
                    .then(response => {
                        if (response.data.length) {
                            this.setState({
                                mensagens: [...this.state.mensagens, ...response.data],
                                iconLoadingRecarregar: false
                            }, () => {
                                var objDiv = document.getElementsByClassName("lista-mensagens")[0];
                                objDiv.scrollTop = objDiv.scrollHeight;
                                this.carregarTotalConversas();
                                this.carregarTotalPorLerResponsaveis();
                            })
                        }
                        else
                            this.setState({
                                iconLoadingRecarregar: false
                            })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    carregarDetalhe = grupoId => {
        this.setState({
            grupoId,
            visibleNovoGrupo: true
        })
    }

    carregarDetalheSimples = grupoId => {
        this.setState({
            grupoId,
            visibleDetalhe: true
        })
    }

    carregarTotalConversas = () => {
        axios({
            method: "get",
            url: "/api/chat/total-porler",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.context.atualizarState({
                    totalConversasPorLer: response.data
                });
            })
            .catch(error => {

            });
    };

    addMensagem = mensagem => {
        var grupos = this.state.grupos.filter(x => x.id !== this.state.grupo.id);
        var grupo = this.state.grupos.find(x => x.id === this.state.grupo.id)
        grupos = [grupo, ...grupos]

        this.setState({
            selecionada: 0,
            grupos,
            mensagens: [...this.state.mensagens, mensagem],
        })
        //this.listarGruposAtualizados();
    }

    atualizarGrupos = grupoId => {
        if (grupoId)
            this.setState({
                visibleNovoGrupo: false,
                grupo: this.state.grupos.find(x => x.id === grupoId),
                selecionada: this.state.grupos.findIndex(x => x.id === grupoId)
            }, () => this.listarGrupos())
        else
            this.setState({
                visibleNovoGrupo: false
            }, () => this.listarGrupos())
    }

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa,
                    block: 1,
                    blockSize: 10,
                },
                () => {
                    this.listarGrupos();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                block: 1,
                blockSize: 10,
            },
            () => {
                this.listarGrupos();
            }
        );
    };

    carregarTotalPorLerResponsaveis = () => {
        axios({
            method: "get",
            url: "/api/chat/total-porler-responsaveis",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.setState({
                    totalPorLerResponsaveis: response.data
                });
            })
            .catch(error => {

            });
    };

    recarregarMensagens = () => {
        if (this.state.mensagens.length)
            this.atualizarMensagens(this.state.grupo.id, this.state.mensagens[this.state.mensagens.length - 1].id)
        else
            this.carregarMensagens(this.state.grupo)

        this.listarGrupos();
        this.carregarTotalPorLerResponsaveis();
        this.carregarTotalConversas();
    }

    render() {
        const {
            //FILTROS
            pesquisa,
            verConversasResponsaveis,
            totalPorLerResponsaveis,
            //LISTAGEM
            iconLoadingGrupos,
            grupos,
            //GRUPO
            iconLoadingMais,
            iconLoadingGrupo,
            grupo,
            selecionada,
            mensagens,
            //LOADING
            iconLoadingRecarregar,
            //FORM NOVO GRUPO
            grupoId,
            visibleNovoGrupo,
            //DETALHE
            visibleDetalhe,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Conversas" />
                <div className="container container-body bloco-chat">
                    <Opcoes
                        totalPorLerResponsaveis={totalPorLerResponsaveis}
                        iconLoadingRecarregar={iconLoadingRecarregar}
                        adicionar={() => this.setState({ visibleNovoGrupo: true, grupoId: 0 })}
                        recarregar={this.recarregarMensagens}
                        HandleKeyPress={this.HandleKeyPress}
                        resetCaixaProcura={this.resetCaixaProcura}
                        verConversasResponsaveis={verConversasResponsaveis}
                        handlerChangeCheckbox={this.handlerChangeCheckbox}
                    />
                    <div className="grupos-chat">
                        <Conversas
                            selecionada={selecionada}
                            selecionarConversa={selecionada => this.setState({ selecionada })}
                            iconLoadingGrupos={iconLoadingGrupos}
                            grupos={grupos}
                            carregarMensagens={this.carregarMensagens}
                        />
                        <Conversa
                            iconLoadingMais={iconLoadingMais}
                            iconLoadingGrupo={iconLoadingGrupo}
                            grupo={grupo}
                            mensagens={mensagens}
                            addMensagem={this.addMensagem}
                            carregarDetalhe={this.carregarDetalhe}
                            carregarDetalheSimples={this.carregarDetalheSimples}
                            atualizarListaGrupos={() => this.setState({ grupo: "", selecionada: 0 }, () => this.listarGrupos())}
                            carregarMensagensAnteriores={this.carregarMensagensAnteriores}
                            todasMensagens={this.state.todasMensagens}
                        />
                    </div>
                </div>
                <FromNovoGrupo
                    grupoId={grupoId}
                    visibleNovoGrupo={visibleNovoGrupo}
                    atualizarGrupos={this.atualizarGrupos}
                    onClose={() => this.setState({ visibleNovoGrupo: false })}
                />
                <Detalhe
                    grupoId={grupoId}
                    visibleDetalhe={visibleDetalhe}
                    atualizarGrupos={this.atualizarGrupos}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                />
            </>
        );
    }
}

export default Main;
