import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Rate, notification, Skeleton, Card, Progress, Tooltip } from "antd";
import noimage from "../images/noimage.png";
import { GlobalContext } from "../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Curso extends Component {
    static contextType = GlobalContext
    onClick = cursoId => {
        localStorage.setItem("codigo_curso", cursoId)
        this.context.atualizarState({
            lista_modulos: []
        });
    }

    render() {
        const { curso } = this.props;

        const desc = ["(1)", "(2)", "(3)", "(4)", "(5)"];

        //var sum = 0;

        //if (curso.avaliacao_Cursos != null)
        //    for (var i = 0; i < curso.avaliacao_Cursos.length; i++) {
        //        sum += curso.avaliacao_Cursos[i].qualificacao;
        //    }

        const balaoLinguas = (
            <div className="balao">
                {curso.curso_Linguas.map((curso_lingua, index) => (
                    <div key={index} className="item">
                        <img src={curso_lingua.lingua.imagem} alt={curso_lingua.lingua.nome} />
                        <span className="desc">{curso_lingua.lingua.nome}</span>
                    </div>
                ))}
            </div>
        );

        const montarInstrutores = (instrutores) => {
            let lista = [];

            instrutores.map(instrutor => {
                const nome = instrutor.split(" ").length > 1 ? `${instrutor.split(" ")[0]} ${instrutor.split(" ")[instrutor.split(" ").length - 1]}` : instrutor
                lista.push(nome);
            });

            return lista.join(", ");
        }

        return (
            <div className="curso">
                <Card>
                    <Link className="header" to={this.props.link} onClick={() => this.onClick(this.props.curso.id)}>
                        <img src={curso.imagem ? curso.imagem : noimage} alt="Imagem do curso" />
                    </Link>
                    <div className="content">
                        <div className="titulo">
                            <span className="curso-codigo">{curso.codigo}</span>
                            <span className="titulo-nome" title={curso.nome}>{curso.nome}</span>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="footer-autor-flag">
                            <span className="autor" title={montarInstrutores(curso.instrutores)}>
                                {curso.instrutores &&
                                    `${curso.instrutores[0].split(" ").length > 1 ? `${curso.instrutores[0].split(" ")[0]} ${curso.instrutores[0].split(" ")[curso.instrutores[0].split(" ").length - 1]}` : curso.instrutores[0]} ${(curso.instrutores.length > 1 ? "..." : "")}`}
                            </span>
                            <div className="container-flag">
                                {curso.curso_Linguas.length > 1 ?
                                    <Tooltip title={balaoLinguas} overlayClassName="tooltip-balao tooltip-balao-linguas">
                                        <img src={require("../images/flags/globe.jpg")} alt="Multilíngua" />
                                    </Tooltip>
                                    :
                                    <img src={curso.curso_Linguas[0].lingua.imagem} alt={curso.curso_Linguas[0].lingua.nome} title={curso.curso_Linguas[0].lingua.nome} />
                                }
                            </div>
                        </div>
                        <div className="bloco-rating-monetizacao">
                            {curso.totalAvaliacoes ?
                                <span className="rating">
                                    <Rate
                                        disabled
                                        tooltips={desc}
                                        onChange={this.handleChange}
                                        value={curso.mediaAvaliacoes ? curso.mediaAvaliacoes : 0}
                                    />
                                    <span>({curso.totalAvaliacoes})</span>
                                </span> : null}
                            {/*{this.context.monetizacao_entidade && (this.context.vendaCursos || this.context.subscricao_entidade) ?
                                curso.preco || curso.subscricao ?
                                    <span className="monetizacao">
                                        {curso.subscricao && this.context.subscricao_entidade ?
                                            <i className="fas fa-shopping-cart"></i>
                                            : null}
                                        {curso.preco && this.context.vendaCursos ?
                                            <span>{this.context.moeda_entidade} {curso.valor}</span>
                                            : null}
                                    </span>
                                    : null
                                : null}*/}
                            {this.context.monetizacao_entidade && this.context.subscricao_entidade || curso.preco ?
                                curso.preco || curso.subscricao ?
                                    <span className="monetizacao">
                                        {curso.subscricao && this.context.subscricao_entidade ?
                                            <i className="fas fa-shopping-cart"></i>
                                            : null}
                                        {curso.preco ?
                                            <span dangerouslySetInnerHTML={{ __html: `${this.context.moeda_entidade} ${curso.valor}` }}></span>
                                            : null}
                                    </span>
                                    : null
                                : null}
                        </div>
                        <div className="info">
                            {curso.sub_Cursos === 0 ? (
                                <span className="aulas">
                                    <i className="fa fa-play" /> {curso.curso_Modulos} atividades
                                </span>
                            ) : (
                                <span className="aulas">
                                    <i className="fa fa-play" /> {curso.sub_Cursos} disciplinas
                                </span>
                            )}
                            <span className="horas" title="Carga horária">
                                <i className="fa fa-clock" />
                                {curso.carga_horaria}
                            </span>
                        </div>
                        {curso.precedencia &&
                            <div>
                                <span>*{curso.precedencia}</span>
                            </div>}
                    </div>
                    <div className="overlay">
                        <i className="fas fa-play-circle" />
                    </div>
                </Card>
            </div>
        );
    }
}

export default Curso;
