import React, { Component } from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
import { Badge, Icon, Dropdown, Modal, Collapse } from "antd";
import logo from "../images/logos/stepforma-01.png";
import noimage from "../images/noimage.png";
import axios from "axios";
import { GlobalContext } from "../GlobalState";
import DrawerChat from "./_Aux/DrawerChat";
import DrawerCarrinho from "./_Aux/DrawerCarrinho";

const { Panel } = Collapse;
class Navbar extends Component {
    static contextType = GlobalContext;

    state = {
        entidade_id: 0,
        entidade_imagem_propria: false,
        entidade_logo: "",
        entidade_nome: "StepForma",
        entidade_nome_abrev: "",
        entidade_afiliado: false,
        entidade_visivel: false,
        entidade_url: "",
        entidade_site: "",
        entidade_chave: "",
        stepmeet_gestao: "",
        entidade_escolas: false,
        categorias: [],
        cursos_stepforma: false,
        nome: "",
        foto: "",
        nr_tickets: 0,
        nr_mensagens: 0,
        //FORMADOR
        formador: false,
        tem_licenca: false,
        //MOBILE
        mobile: false,
        //DRAWER CHAT
        mostrarChat: false,
        //DRAWER CARRINHO
        visibleCart: false,
        //LOADING
        iconLoading: false,
        //MENU MOBILE
        menuAdministracaoMobileEstado: [],
        menuGestaoMobileEstado: [],
        menuInstrutorMobileEstado: [],
        menuUtilizadorMobileEstado: ['1'],
    };

    componentDidMount() {
        if (this.context.carrinhoProdutos) {
            this.setState({
                visibleCart: true
            })
        }

        this.carregarUtilizadorInfo();
        this.carregaDadosEntidade();
        this.carregarTotalConversas();
        this.carregarTotalEventosHoje();
    }

    componentDidUpdate() {
        if (this.context.atualizarDadosGerias) {
            this.carregarUtilizadorInfo();
            this.context.atualizarState({ atualizarDadosGerias: false });
        }

        if (this.context.atualizarEntidade) {
            this.carregaDadosEntidade();
            this.context.atualizarState({ atualizarEntidade: false });
        }
    }

    onVisibleChange = visible => {
        if (visible && this.context.role != "ENTIDADE") {
            this.carregarTicketsPorLer();
        }
    };

    onVisibleChangeEntidade = visible => {
        if (visible) {
            this.carregarTicketsPorLer();
        }
    };

    carregarTotalConversas = () => {
        axios({
            method: "get",
            url: "/api/chat/total-porler",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.context.atualizarState({
                    totalConversasPorLer: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    carregarTotalEventosHoje = () => {
        this.context.atualizarEventosHoje();
    };

    carregarUtilizadorInfo = () => {
        axios({
            method: "get",
            url: "/api/navbar/dados-utilizador",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.context.atualizarState({
                    id_utilizador: response.data.id,
                    foto_utilizador: response.data.foto,
                    avatar_utilizador: response.data.avatar,
                    nome_utilizador: response.data.nome_completo,
                    sexo_utilizador: response.data.sexo,
                    idrole_utilizador: response.data.idrole,
                    role: response.data.role
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    carregarTicketsPorLer = () => {
        axios({
            method: "get",
            url: "/api/tickets/total-tickets-por-ler-menu"
        })
            .then(response => {
                this.setState({
                    nr_tickets: response.data
                });
            })
            .catch(error => {

            });
    };

    decodeHTML = moeda => {
        var map = { "gt": ">" /* , … */ };
        return moeda.replace(/&(#(?:x[0-9a-f]+|\d+)|[a-z]+);?/gi, ($0, $1) => {
            if ($1[0] === "#") {
                return String.fromCharCode($1[1].toLowerCase() === "x" ? parseInt($1.substr(2), 16) : parseInt($1.substr(1), 10));
            } else {
                return map.hasOwnProperty($1) ? map[$1] : $0;
            }
        });
    };

    carregaDadosEntidade = () => {
        axios({
            method: "get",
            url: "/api/navbar/dados-entidade"
        })
            .then(response => {
                this.context.atualizarState({
                    id_entidade: response.data.id,
                    nome_entidade: response.data.nome,
                    responsaveis_entidade: response.data.responsaveis,
                    formacao_entidade: response.data.formacao,
                    projeto_entidade: response.data.projeto,
                    organizacional_entidade: response.data.organizacional,
                    visivel_entidade: response.data.visivel_entidade,
                    albumId_entidade: response.data.albumId,
                    chave_entidade: response.data.chave_integracao,
                    escolas_entidade: response.data.escolas,
                    login2_entidade: response.data.login2,
                    disciplinas_entidade: response.data.disciplinas,
                    conf_total_participantes: response.data.totalParticipantes,
                    conf_total_recorders: response.data.totalRecorders,
                    monetizacao_entidade: response.data.monetizacao_entidade,
                    monetizacao_stepforma: response.data.monetizacao_stepforma,
                    vendaCursos: response.data.vendaCursos,
                    vendaCertificados: response.data.vendaCertificados,
                    valorMensal: response.data.valorMensal,
                    subscricao_entidade: response.data.subscricao,
                    moeda_entidade: this.decodeHTML(response.data.moeda),
                    gamificacao_entidade: response.data.gamificacao,
                    gamificacao_stepforma: response.data.gamificacao_stepforma,
                    conteudos_animados_entidade: response.data.conteudos_animados,
                    stepmeet_gestao: response.data.stepmeet_gestao,
                    whatsapp_entidade: response.data.whatsapp,
                    reclamacoes_entidade: response.data.reclamacoes
                });

                this.setState({
                    entidade_id: response.data.id,
                    entidade_imagem_propria: response.data.imagem_propria,
                    entidade_logo: response.data.logo,
                    entidade_nome: response.data.nome,
                    entidade_nome_abrev: response.data.nome_abrev,
                    entidade_visivel: response.data.visivel_entidade,
                    entidade_url: response.data.url,
                    entidade_site: response.data.site,
                    entidade_chave: response.data.chave_integracao,
                    stepmeet_gestao: response.data.stepmeet_gestao,
                    //entidade_formacao: response.data.formacao,
                    //entidade_projeto: response.data.projeto,
                    //entidade_organizacional: response.data.organizacional,
                    entidade_escolas: response.data.escolas
                });

                localStorage.setItem("entidade_id", response.data.id)

                if (response.data.imagem_propria)
                    this.context.atualizarState({
                        favicon_entidade: response.data.logo,
                        //title_entidade: response.data.nome_abrev ? response.data.nome_abrev : response.data.nome
                        title_entidade: response.data.nome
                    });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    logOut = () => {
        this.setState({
            mobile: false,
            iconLoading: true
        });

        axios({
            method: "post",
            url: "/api/autenticacao/logout"
        })
            .then(() => {
                localStorage.removeItem("accessToken")
                delete axios.defaults.headers.common.Authorization;

                //localStorage.removeItem("participanteNaMeet")
                this.context.atualizarState({
                    favicon_entidade: "",
                    title_entidade: ""
                });

                if (this.state.entidade_chave && this.state.entidade_site) {
                    window.open(this.state.entidade_site, "_self");
                } else {
                    if (this.state.entidade_visivel) localStorage.setItem("entidadeId", this.state.entidade_id);

                    this.setState({
                        redirect: true,
                        redirectLink: this.state.entidade_visivel
                            ? "/" + (this.state.entidade_nome_abrev ? this.state.entidade_nome_abrev : this.state.entidade_nome)
                            : "/"
                    });
                }
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.setState({
                    iconLoading: false
                });
            });
    };

    //DRAWER CHAT
    fecharChat = () => {
        this.setState({ mostrarChat: false });
    };

    voltarCarrinho = () => {
        this.setState({
            visibleCart: false
        }, () => {
                this.context.atualizarState({
                    carrinho: []
                })
        })
    }

    render() {
        const {
            entidade_imagem_propria,
            entidade_logo,
            entidade_nome,
            entidade_afiliado,
            entidade_escolas,
            stepmeet_gestao,
            foto,
            nome,
            nr_tickets,
            //DRAWER CARRINHO
            visibleCart,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            iconLoading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const menuGestao = (
            <>
                {entidade_afiliado && (
                    <li className="nav-item">
                        <NavLink to="/gestao-afiliados" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            StepForma
                        </NavLink>
                    </li>
                )}
                <li className="nav-item">
                    <NavLink to="/gestao-utilizadores-todos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Utilizadores
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/gestao-questionarios" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Questionários
                    </NavLink>
                </li>
                {!this.context.escolas_entidade && (
                    <li className="nav-item">
                        <NavLink to="/gestao-cursos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            <strong>Cursos</strong>
                        </NavLink>
                    </li>
                )}
                {this.context.escolas_entidade && (
                    <li className="nav-item">
                        <NavLink to="/gestao-cursos-organismos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            <strong>Cursos</strong>
                        </NavLink>
                    </li>
                )}
                {this.context.monetizacao_entidade && this.context.monetizacao_stepforma &&
                    <li className="nav-item">
                        <NavLink to="/gestao-pagamentos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Pagamentos
                    </NavLink>
                    </li>}
                {this.context.conf_total_participantes > 0 &&
                    <li className="nav-item">
                    <NavLink to="/gestao-videoconferencias" className="nav-link" onClick={() => { this.setState({ mobile: false }); localStorage.removeItem("menu_mensagens"); }}>
                        Stepmeet
                        </NavLink>
                    </li>}
                {this.context.conf_total_participantes > 0 && this.context.conf_total_recorders > 0 &&
                    <li className="nav-item">
                    <NavLink to="/gestao-webinars" className="nav-link" onClick={() => { this.setState({ mobile: false }); localStorage.removeItem("menu_mensagens"); }}>
                            Webinar
                        </NavLink>
                    </li>}
                <li className="nav-item">
                    <NavLink to="/gestao-estatisticas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Estatísticas
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/gestao-newsletters" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Newsletters
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/gestao-categorias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Categorias
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/gestao-edicoes" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Edições
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/gestao-competencias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Competências
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/gestao-suporte" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Suporte <Badge count={nr_tickets} style={{ backgroundColor: "#ff9933" }} />
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/gestao-conta" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Canal
                    </NavLink>
                </li>
            </>
        );

        const menuGestaoMobile = (
            <div className="bloco-nav-list">
                <Collapse className="collapse-nav-list-gestao" defaultActiveKey={this.state.menuGestaoMobileEstado} onChange={(menuGestaoMobileEstado) => this.setState({ menuGestaoMobileEstado })}>
                    <Panel header={<h3>Gestão</h3>} key="1">
                        <ul className="nav-list-mobile-ul">
                            {entidade_afiliado && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-afiliados" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        StepForma
                        </NavLink>
                                </li>
                            )}
                            <li className="nav-item">
                                <NavLink to="/gestao-utilizadores-todos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Utilizadores
                    </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/gestao-questionarios" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Questionários
                    </NavLink>
                            </li>
                            {!entidade_escolas && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-cursos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        <strong>Cursos</strong>
                                    </NavLink>
                                </li>
                            )}
                            {entidade_escolas && (
                                <li className="nav-item">
                                    <NavLink to="/gestao-cursos-organismos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        <strong>Cursos</strong>
                                    </NavLink>
                                </li>
                            )}
                            {this.context.monetizacao_entidade && this.context.monetizacao_stepforma &&
                                <li className="nav-item">
                                    <NavLink to="/gestao-pagamentos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Pagamentos
                            </NavLink>
                                </li>}
                            {this.context.conf_total_participantes > 0 &&
                                <li className="nav-item">
                                    <NavLink to="/gestao-videoconferencias" className="nav-link" onClick={() => { this.setState({ mobile: false }); localStorage.removeItem("menu_mensagens"); }}>
                                        Stepmeet
                            </NavLink>
                                </li>}
                            {this.context.conf_total_participantes > 0 &&
                                <li className="nav-item">
                                    <NavLink to="/gestao-webinars" className="nav-link" onClick={() => { this.setState({ mobile: false }); localStorage.removeItem("menu_mensagens"); }}>
                                        Webinar
                            </NavLink>
                                </li>}
                            <li className="nav-item">
                                <NavLink to="/gestao-estatisticas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Estatísticas
                    </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/gestao-newsletters" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Newsletters
                    </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/gestao-categorias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Categorias
                    </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/gestao-edicoes" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Edições
                    </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/gestao-competencias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Competências
                    </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/gestao-suporte" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Suporte <Badge count={nr_tickets} style={{ backgroundColor: "#ff9933" }} />
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/gestao-conta" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Canal
                    </NavLink>
                            </li>
                        </ul>
                    </Panel>
                </Collapse>
            </div>
        );

        const menuAdministracao1 = (
            <>
                <li className="nav-item">
                    <NavLink to="/administracao-entidades" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Entidades
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/administracao-newsletters" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Newsletters
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        to="/administracao-sistema-informacao"
                        className="nav-link"
                        title="Sistema de Informação"
                        onClick={() => this.setState({ mobile: false })}
                    >
                        Sis. Info.
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/administracao-suporte" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Suporte <Badge count={nr_tickets} style={{ backgroundColor: "#ff9933" }} />
                    </NavLink>
                </li>
            </>
        );

        const menuAdministracao1Mobile = (
            <div className="bloco-nav-list">
                <h3 className="titulo-menu">Administração</h3>
                <ul className="nav-list-mobile-ul">
                    <li className="nav-item">
                        <NavLink to="/administracao-entidades" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Entidades
                    </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/administracao-newsletters" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Newsletters
                    </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="/administracao-sistema-informacao"
                            className="nav-link"
                            title="Sistema de Informação"
                            onClick={() => this.setState({ mobile: false })}
                        >
                            Sis. Info.
                    </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/administracao-suporte" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Suporte <Badge count={nr_tickets} style={{ backgroundColor: "#ff9933" }} />
                        </NavLink>
                    </li>
                </ul>
            </div>
        );

        const menuAdministracao2 = (
            <>
                <li className="nav-item">
                    <NavLink to="/user-minha-conta" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Conta
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/user-alterar-senha" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Alterar Senha
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/user-alterar-login" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Alterar Login
                    </NavLink>
                </li>
                <li className="nav-item">
                    <span className="nav-link" onClick={this.logOut}>
                        Sair
                    </span>
                </li>
            </>
        );

        const menuAdministracao2Mobile = (
            <div className="bloco-nav-list">
                <h3 className="titulo-menu">Administração</h3>
                <ul className="nav-list-mobile-ul">
                    <li className="nav-item">
                        <NavLink to="/user-minha-conta" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Conta
                    </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/user-alterar-senha" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Alterar Senha
                    </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/user-alterar-login" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Alterar Login
                    </NavLink>
                    </li>
                    <li className="nav-item">
                        <span className="nav-link" onClick={this.logOut}>
                            Sair
                    </span>
                    </li>
                </ul>
            </div>
        );

        const menuInstrutor = (
            <>
                <li className="nav-item">
                    <NavLink to="/formador-lista-cursos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Cursos
                    </NavLink>
                </li>
                {/*<li className="nav-item">
                    <NavLink to="/formador-competencias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                        Competências
                    </NavLink>
                </li>*/}
                {this.context.conf_total_participantes > 0 &&
                    <li className="nav-item">
                    <NavLink to="/formador-videoconferencias" className="nav-link" onClick={() => { this.setState({ mobile: false }); localStorage.removeItem("menu_mensagens"); }}>
                        Stepmeet
                        </NavLink>
                    </li>}
                {this.context.conf_total_participantes > 0 &&
                    <li className="nav-item">
                    <NavLink to="/formador-webinars" className="nav-link" onClick={() => { this.setState({ mobile: false }); localStorage.removeItem("menu_mensagens"); }}>
                        Webinar
                        </NavLink>
                    </li>}
            </>
        );

        const menuInstrutorMobile = (
            <div className="bloco-nav-list">
                <Collapse className="collapse-nav-list-gestao" defaultActiveKey={this.state.menuInstrutorMobileEstado} onChange={(menuInstrutorMobileEstado) => this.setState({ menuInstrutorMobileEstado })}>
                    <Panel header={<h3>Instrutor</h3>} key="1">
                        <ul className="nav-list-mobile-ul">
                            <li className="nav-item">
                                <NavLink to="/formador-lista-cursos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Cursos
                    </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/formador-competencias" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                    Competências
                    </NavLink>
                            </li>
                            {this.context.conf_total_participantes > 0 &&
                                <li className="nav-item">
                                <NavLink to="/formador-videoconferencias" className="nav-link" onClick={() => { this.setState({ mobile: false }); localStorage.removeItem("menu_mensagens"); }}>
                                        Stepmeet
                            </NavLink>
                                </li>}
                            {this.context.conf_total_participantes > 0 &&
                                <li className="nav-item">
                                    <NavLink to="/formador-webinars" className="nav-link" onClick={() => { this.setState({ mobile: false }); localStorage.removeItem("menu_mensagens"); }}>
                                        Webinar
                                    </NavLink>
                                </li>}
                        </ul>
                    </Panel>
                </Collapse>
            </div>
        );

        const menuUtilizador =
            (!this.state.entidade_chave && this.context.role == "UTILIZADOR") || this.context.role != "UTILIZADOR" ? (
                <>
                    <li className="nav-item">
                        <NavLink to="/user-minha-conta" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Conta
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/user-alterar-senha" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Alterar Senha
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/user-alterar-login" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Alterar Login
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/user-configuracoes" className="nav-link" title="" onClick={() => this.setState({ mobile: false })}>
                            Configurações
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/sistema-informacao" className="nav-link" title="" onClick={() => this.setState({ mobile: false })}>
                            <strong>Meus Cursos</strong>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/favoritos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Favoritos
                        </NavLink>
                    </li>
                    {this.context.role != "ENTIDADE" && (
                        <li className="nav-item">
                            <NavLink to="/user-suporte" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                Suporte <Badge count={nr_tickets} style={{ backgroundColor: "#ff9933" }} />
                            </NavLink>
                        </li>
                    )}
                    <li className="nav-item">
                        <NavLink to="/user-estatisticas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                            Estatísticas
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <span className="nav-link botao-principal" onClick={this.logOut} style={{ color: "white", fontSize: "14px", paddingTop: 5, paddingBottom: 5 }}>
                            Sair
                        </span>
                    </li>
                </>
            ) : (
                    <li className="nav-item">
                        <span className="nav-link botao-principal" onClick={this.logOut} style={{ color: "white", fontSize: "14px", paddingTop: 5, paddingBottom: 5 }}>
                        Sair
                    </span>
                </li>
                );

        const menuUtilizadorMobile =
            (!this.state.entidade_chave && this.context.role == "UTILIZADOR") || this.context.role != "UTILIZADOR" ? (
                <div className="bloco-nav-list">
                    <Collapse className="collapse-nav-list-gestao" defaultActiveKey={this.state.menuUtilizadorMobileEstado} onChange={(menuUtilizadorMobileEstado) => this.setState({ menuUtilizadorMobileEstado })}>
                        <Panel header={<h3>Utilizador</h3>} key="1">
                            <ul className="nav-list-mobile-ul">
                                <li className="nav-item">
                                    <NavLink to="/user-minha-conta" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Conta
                        </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/user-alterar-senha" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Alterar Senha
                        </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/user-alterar-login" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Alterar Login
                        </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/user-configuracoes" className="nav-link" title="" onClick={() => this.setState({ mobile: false })}>
                                        Configurações
                        </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/sistema-informacao" className="nav-link" title="" onClick={() => this.setState({ mobile: false })}>
                                        <strong>Meus Cursos</strong>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/favoritos" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Favoritos
                        </NavLink>
                                </li>
                                {this.context.role != "ENTIDADE" && (
                                    <li className="nav-item">
                                        <NavLink to="/user-suporte" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                            Suporte <Badge count={nr_tickets} style={{ backgroundColor: "#ff9933" }} />
                                        </NavLink>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <NavLink to="/user-estatisticas" className="nav-link" onClick={() => this.setState({ mobile: false })}>
                                        Estatísticas
                        </NavLink>
                                </li>
                                <li className="nav-item">
                                    <span className="nav-link botao-principal" onClick={this.logOut}>
                                        Sair
                        </span>
                                </li>
                            </ul>
                        </Panel>
                    </Collapse>
                </div>
            ) : (
                    <div className="bloco-nav-list">
                        <Collapse className="collapse-nav-list-gestao" defaultActiveKey={['1']}>
                            <Panel header={<h3>Utilizador</h3>} key="1">
                                <ul className="nav-list-mobile-ul">
                                    <li className="nav-item">
                                        <span className="nav-link botao-principal" onClick={this.logOut}>
                                            Sair
                                </span>
                                    </li>
                                </ul>
                            </Panel>
                        </Collapse>
                    </div>
                );

        return (
            <div className="navbar-log-in">
                <div className="container container-v2">
                    <Link to="/user" className="brand" title={entidade_nome}>
                        <img src={entidade_imagem_propria ? (entidade_logo ? entidade_logo : noimage) : logo} alt="Logo" className="logo" />
                    </Link>
                    <div className="links">
                        <ul className="nav-list nav-list-esquerda nav-list-esquerda-desktop">
                            {this.context.role != "ADMIN" && (
                                <li className="nav-item">
                                    <NavLink to="/pesquisa-cursos" className="nav-link">
                                        CATÁLOGO
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                        <ul className="nav-list nav-list-esquerda nav-list-esquerda-mobile">
                            <li className="nav-item nav-item-icon">
                                <Badge
                                    count={this.context.totalEventosHoje}
                                    showZero={false}
                                    style={{ backgroundColor: "#f79071" }}
                                >
                                    <Link to="/calendario" style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.6)" }}>
                                        <i className="fas fa-calendar-alt"></i>
                                    </Link>
                                </Badge>
                            </li>
                            {this.context.monetizacao_entidade && this.context.monetizacao_stepforma ?
                                <li className="nav-item">
                                    <Badge
                                        count={this.context.carrinho.length}
                                        showZero={false}
                                        style={{ backgroundColor: "#f79071" }}
                                    >
                                        <Link to="#" className="carrinho-compras" onClick={() => this.setState({ visibleCart: true })} style={{ fontSize: 25, color: "rgba(0, 0, 0, 0.6)" }}>
                                            <i className="fas fa-shopping-cart"></i>
                                        </Link>
                                    </Badge>
                                </li>
                                : null}
                            <li className="nav-item nav-item-icon">
                                <Badge
                                    count={this.context.totalConversasPorLer}
                                    showZero={false}
                                    style={{ backgroundColor: "#f79071" }}
                                >
                                    <Link to="/chat" className="link-chat-ipad" style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.6)" }}>
                                        <i className="fas fa-comment-dots"></i>
                                    </Link>
                                    <Link to="/chat-m" className="link-chat-mobile" style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.6)" }}>
                                        <i className="fas fa-comment-dots"></i>
                                    </Link>
                                </Badge>
                            </li>
                        </ul>
                        {this.state.mobile ?
                            <ul className="nav-list nav-list-direita nav-list-mobile">
                                <li className="nav-item nav-item-btn"><Link to="#" className="btn-close-nav-list" onClick={() => this.setState({ mobile: !this.state.mobile })}><Icon type="close" /></Link></li>
                                <li className="nav-item">
                                    <NavLink to="/pesquisa-cursos" className="nav-link link-catalogo" onClick={() => this.setState({ mobile: false })}>
                                        CATÁLOGO
                                    </NavLink>
                                </li>
                                {this.context.role == "ENTIDADE" &&
                                    <li className="nav-item bloco-nav-item">
                                        {menuGestaoMobile}
                                    </li>}
                                {this.context.role == "ADMIN" &&
                                    <li className="nav-item bloco-nav-item">
                                        {menuAdministracao1Mobile}
                                    </li>}
                                {this.context.role == "FORMADOR" &&
                                    <li className="nav-item bloco-nav-item">
                                        {menuInstrutorMobile}
                                    </li>}
                                {this.context.role == "ADMIN" ? (
                                    <li className="nav-item bloco-nav-item">
                                        {menuAdministracao2Mobile}
                                    </li>
                                ) : <li className="nav-item bloco-nav-item">
                                        {menuUtilizadorMobile}
                                    </li>}
                            </ul>
                            :
                            <ul className="nav-list nav-list-direita">
                                <li className="nav-item nav-item-icon">
                                    <Badge
                                        count={this.context.totalEventosHoje}
                                        showZero={false}
                                        style={{ backgroundColor: "#f79071" }}
                                    >
                                        <Link to="/calendario" className="link-chat-ipad" style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.6)" }}>
                                            <i className="fas fa-calendar-alt"></i>
                                        </Link>
                                    </Badge>
                                </li>
                                {this.context.monetizacao_entidade && this.context.monetizacao_stepforma ?
                                    <li className="nav-item">
                                        <Badge
                                            count={this.context.carrinho.length}
                                            showZero={false}
                                            style={{ backgroundColor: "#f79071" }}
                                        >
                                            <Link to="#" className="carrinho-compras" onClick={() => this.setState({ visibleCart: true })} style={{ fontSize: 25, color: "rgba(0, 0, 0, 0.6)" }}>
                                                <i className="fas fa-shopping-cart"></i>
                                            </Link>
                                        </Badge>
                                    </li>
                                    : null}
                                <li className="nav-item nav-item-icon">
                                    <Badge
                                        count={this.context.totalConversasPorLer}
                                        showZero={false}
                                        style={{ backgroundColor: "#f79071" }}
                                    >
                                        <Link to="/chat" style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.6)" }}>
                                            <i className="fas fa-comment-dots"></i>
                                        </Link>
                                    </Badge>
                                </li>
                                {this.context.role == "ENTIDADE" &&
                                    <li className="nav-item">
                                        <Dropdown
                                            overlay={menuGestao}
                                            overlayClassName="dropdown-menu-navbar"
                                            onVisibleChange={this.onVisibleChangeEntidade}
                                        >
                                            <span className="nav-link">
                                                Gestão <Icon type="down" />
                                            </span>
                                        </Dropdown>
                                    </li>}
                                {this.context.role == "ADMIN" &&
                                    <li className="nav-item">
                                        <Dropdown
                                            overlay={menuAdministracao1}
                                            overlayClassName="dropdown-menu-navbar"
                                            onVisibleChange={this.onVisibleChangeEntidade}
                                        >
                                            <span className="nav-link">
                                                Administração <Icon type="down" />
                                            </span>
                                        </Dropdown>
                                    </li>}
                                {this.context.role == "FORMADOR" &&
                                    <li className="nav-item">
                                        <Dropdown
                                            overlay={menuInstrutor}
                                            overlayClassName="dropdown-menu-navbar"
                                            onVisibleChange={this.onVisibleChangeEntidade}
                                        >
                                            <span className="nav-link">
                                                Instrutor <Icon type="down" />
                                            </span>
                                        </Dropdown>
                                    </li>}
                                {this.context.role == "ADMIN" ? (
                                    <li className="nav-item">
                                        <Dropdown overlay={menuAdministracao2} overlayClassName="dropdown-menu-navbar">
                                            <span className="nav-link">
                                                <div className="container-img">
                                                    <img src={foto ? foto : noimage} alt="Avatar" title={nome} />
                                                </div>
                                                <Icon type="down" />
                                            </span>
                                        </Dropdown>
                                    </li>
                                ) : <li className="nav-item">
                                        <Dropdown
                                            overlay={menuUtilizador}
                                            overlayClassName="dropdown-menu-navbar"
                                            onVisibleChange={this.onVisibleChange}
                                        >
                                            <span className="nav-link">
                                                <div className="container-img">
                                                    <img
                                                        src={this.context.foto_utilizador ? this.context.foto_utilizador : noimage}
                                                        alt="Avatar"
                                                        title={this.context.nome_utilizador}
                                                    />
                                                </div>
                                                <Icon type="down" />
                                            </span>
                                        </Dropdown>
                                    </li>}
                            </ul>
                            }
                    </div>
                    {/*{!this.state.mobile &&
                        <div className="burger" onClick={() => this.setState({ mobile: !this.state.mobile })}>
                            <Icon type="menu" />
                        </div>}*/}
                    <div className="burger" onClick={() => this.setState({ mobile: !this.state.mobile })}>
                        <i className="fas fa-bars"></i>
                    </div>
                </div>

                {/* DRAWER CHAT */}
                {/*<DrawerChat state={this.state} fecharChat={this.fecharChat} />*/}
                <DrawerCarrinho visibleCart={visibleCart} onClose={() => this.setState({ visibleCart: false })} voltarCarrinho={this.voltarCarrinho} />
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A sair...</p>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Navbar;
