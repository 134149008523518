import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import { GlobalContext } from "../GlobalState";
import Navbar from "../User/Navbar";
import User from "../User/Main";
import CursoLayout from "../Cursos/CursoLayout";
import GerirCursoLayout from "../GerirCursos/CursoLayout";
import GerirPlayer from "../GerirPlayer/PlayerLayout";
import Player from "../Player/PlayerLayout";
import MinhaConta from "../Perfil/Conta/Main";
import AlterarPassword from "../Perfil/AlterarPassword";
import AlterarLogin from "../Perfil/AlterarLogin";
import UserConfiguracoes from "../Perfil/Configuracoes";
import UserEstatisticas from "../Perfil/Estatisticas/Main";
import Calendario from "../Calendario/Main";
import Chat from "../Chat/Main";
import ChatMobile from "../Chat/MainMobile";
import Tickets from "../Tickets/Main";
import TicketsDetalhe from "../Tickets/Detalhe/Main";
import TicketsNovo from "../Tickets/Adicionar/Main";
import Empresa from "../Gestao/Empresa/Main";
import GestaoCursos from "../Gestao/Cursos/Cursos";
import GestaoCursosOrganismos from "../Gestao/Cursos/CursosOrganismos";
import GestaoVideoconferencias from "../Gestao/Videoconferencias/Main";
import GestaoWebinar from "../Gestao/Webinar/Main";
import GestaoCursosInscricoes from "../Gestao/Cursos/CursosInscricoes";
import GestaoCursosEstatisticas from "../Gestao/Cursos/CursosEstatisticas";
import GestaoCursosRanking from "../Gestao/Cursos/CursosRanking";
import GestaoCursosResultados from "../Gestao/Cursos/CursosResultados";
import GestaoCursosDisciplinaResultados from "../Gestao/Cursos/CursosDisciplinaResultados";
import GestaoCompetencias from "../Gestao/Competencias/Competencias";
import GestaoCategorias from "../Gestao/Categorias";
import GestaoEdicoes from "../Gestao/Edicoes";
import GestaoPagamentos from "../Gestao/Pagamentos";
import GestaoNewsletters from "../Gestao/Newsletters";
import GestaoEstatisticas from "../Gestao/Estatisticas/Main";
import GestaoQuestionarios from "../Gestao/Questionarios";
import ColaboradoresTodos from "../Colaboradores/Todos";
import ColaboradoresSistemaInformacao from "../Colaboradores/SistemaInformacao";
import ColaboradoresResultados from "../Colaboradores/CursosResultados";
import ColaboradoresDocumentacao from "../Colaboradores/Documentacao";
import ColaboradoresPagamentos from "../Colaboradores/Pagamentos";
import ColaboradoresNovaMensagem from "../Colaboradores/NovaMensagem";
import ColaboradoresAssociar from "../Colaboradores/Associar";
import ColaboradoresAssociarAlunos from "../Colaboradores/AssociarAlunos";
import ColaboradoresPendentes from "../Colaboradores/Pendentes";
import ColaboradoresEstatisticas from "../Colaboradores/Estatisticas/Main";
import PesquisaCursos from "../PesquisaCursos/Main";
import SistemaInformacao from "../Perfil/SistemaInformacao";
import PerfilQuestionario from "../Perfil/QuestionarioSatisfacao/Questionario";
import PerfilRating from "../Perfil/Rating";
import Ranking from "../Perfil/CursosRanking";
import Resultados from "../Perfil/CursosResultados";
import Favoritos from "../Favoritos/Favoritos";
import FormadoresListaCursos from "../Perfil/Formador/Cursos";
import FormadoresCompetencias from "../Gestao/Competencias/Competencias";
import FormadoresVideoconferencias from "../Gestao/Videoconferencias/Main";
import FormadoresWebinars from "../Gestao/Webinar/Main";
import FormadoresCursosRanking from "../Perfil/Formador/CursosRanking";
import FormadoresCursoResultados from "../Perfil/Formador/CursosResultados";
import FormadoresCursosDisciplinaResultados from "../Perfil/Formador/CursosDisciplinaResultados";
import FormadoresCursoEstatisticas from "../Perfil/Formador/CursosEstatisticas";
import FormadoresCursoQuestionario from "../Perfil/Formador/QuestionarioSatisfacao/Questionario";
import FormadoresListaCursoInscricoes from "../Perfil/Formador/CursosInscricoes";
import AdministracaoEntidades from "../Administracao/Entidades";
import AdministracaoDocumentacao from "../Administracao/Documentacao";
import AdministracaoNewsletters from "../Administracao/Newsletters";
import Footer from "../Geral/Footer";
import UserContacto from "../User/Contacto";
import Tutoriais from "../User/Tutoriais";
import Versoes from "../User/Versoes";
import Informacoes from "../User/Informacoes";
import Pagina from "../User/Pagina";
import Home from "../User/Home";
import axios from "axios";

class Layout extends Component {
    static contextType = GlobalContext;
    state = {
        redessociais: [],
        fundoBranco: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        if (localStorage.getItem("accessToken"))
            axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;

        this.validarSenha();
        this.entidadeRS();
    }

    componentDidUpdate() {
        if (this.context.atualizarEntidadeFooter) {
            this.entidadeRS();
            this.context.atualizarState({
                atualizarEntidadeFooter: false
            });
        }
    }

    validarSenha = () => {
        axios({
            method: "get",
            url: "/api/alterar-password/validar-senha"
        })
            .then(response => {
                if (!response.data.senhaValida) {
                    localStorage.removeItem("accessToken")
                    delete axios.defaults.headers.common.Authorization;
                    var link = "/login";
                    if (response.data.paginaPublica && response.data.loginSimplificado)
                        link = `/${response.data.entidade}/login-escola`;
                    else
                        link = `/${response.data.entidade}/login`;

                    this.setState({
                        redirect: true,
                        redirectLink: link
                    })
                }
            })
            .catch(() => { });
    }

    //VERIFICA SE O UTILIZADOR ESTÁ AUTENTICADO
    isAuthenticated = () => {
        axios({
            method: "get",
            url: "/api/anonimo/is-authenticated"
        })
            .then(response => {
                this.context.atualizarState({
                    is_authenticated: response.data.autenticado,
                    is_authenticated_nav: response.data.autenticado,
                    role: response.data.role
                });
            })
            .catch(() => {});
    };

    entidadeRS = () => {
        axios({
            method: "get",
            timeout: 60 * 10 * 1000,
            url: "/api/home-utilizador/entidade-rs"
        })
            .then(response => {
                this.setState({
                    redessociais: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            redessociais,
            fundoBranco,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const opcoes = (
            <ul className="links_uteis" style={{ textAlign: "center" }}>
                {/*<li>
                    <Link to="/user-contacto">Contato</Link>
                </li>*/}
                <li>
                    <Link to="/tutoriais">Tutoriais e Manuais</Link>
                </li>
                <li>
                    <Link to="/versoes">Versões</Link>
                </li>
                <li>
                    <Link to="/informacoes">Informações</Link>
                </li>
            </ul>
        );

        return (
            <>
                <Navbar />
                <Switch>
                    <Route exact path="/curso/:curso/curso-mensagens-detalhe" component={CursoLayout} />
                    <Route exact path="/gerir-curso/:curso/curso-mensagens-detalhe" component={GerirCursoLayout} />
                    <Route path="/user" component={User} />
                    {/*<Route path="/user-contacto" component={UserContacto} />*/}
                    <Route path="/tutoriais" component={Tutoriais} />
                    <Route path="/versoes" component={Versoes} />
                    <Route path="/informacoes" component={Informacoes} />
                    <Route path="/pagina" component={Pagina} />
                    <Route path="/hometeste" component={Home} />
                    <Route path="/sistema-informacao" component={SistemaInformacao} />
                    <Route path="/sistema-informacao-questionario/:curso/player-questionario" component={PerfilQuestionario} />
                    <Route path="/sistema-informacao-avaliacao/:curso" component={PerfilRating} />
                    <Route path="/ranking" component={Ranking} />
                    {/*<Route path="/resultados" component={Resultados} />*/}
                    <Route path="/favoritos" component={Favoritos} />
                    <Route path="/formador-lista-cursos" component={FormadoresListaCursos} />
                    <Route path="/formador-competencias" component={FormadoresCompetencias} />
                    <Route path="/formador-videoconferencias" component={FormadoresVideoconferencias} />
                    <Route path="/formador-webinars" component={FormadoresWebinars} />
                    <Route exact path="/administracao-entidades" component={AdministracaoEntidades} />
                    <Route exact path="/administracao-entidades/documentacao" component={AdministracaoDocumentacao} />
                    <Route path="/administracao-newsletters" component={AdministracaoNewsletters} />
                    <Route path="/gestao-suporte" component={Tickets} />
                    <Route path="/gestao-suporte-detalhe" component={TicketsDetalhe} />
                    <Route path="/gestao-suporte-novo" component={TicketsNovo} />
                    <Route path="/administracao-suporte" component={Tickets} />
                    <Route path="/administracao-suporte-detalhe" component={TicketsDetalhe} />
                    <Route path="/administracao-suporte-novo" component={TicketsNovo} />
                    <Route path="/calendario" component={Calendario} />
                    <Route path="/chat" component={Chat} />
                    <Route path="/chat-m" component={ChatMobile} />
                    <Route path="/user-suporte" component={Tickets} />
                    <Route path="/user-suporte-detalhe" component={TicketsDetalhe} />
                    <Route path="/user-suporte-novo" component={TicketsNovo} />
                    <Route path="/user-minha-conta" component={MinhaConta} />
                    <Route path="/user-alterar-senha" component={AlterarPassword} />
                    <Route path="/user-alterar-login" component={AlterarLogin} />
                    <Route path="/user-configuracoes" component={UserConfiguracoes} />
                    <Route path="/user-estatisticas" component={UserEstatisticas} />
                    <Route path="/gestao-conta" component={Empresa} />
                    <Route path="/gestao-cursos" component={GestaoCursos} />
                    <Route path="/gestao-cursos-organismos" component={GestaoCursosOrganismos} />
                    <Route path="/gestao-videoconferencias" component={GestaoVideoconferencias} />
                    <Route path="/gestao-webinars" component={GestaoWebinar} />
                    <Route path="/:curso/gestao-cursos-inscricoes" component={GestaoCursosInscricoes} />
                    <Route path="/:curso/gestao-cursos-estatisticas" component={GestaoCursosEstatisticas} />
                    <Route path="/gestao-competencias" component={GestaoCompetencias} />
                    <Route path="/gestao-categorias" component={GestaoCategorias} />
                    <Route path="/gestao-edicoes" component={GestaoEdicoes} />
                    <Route path="/gestao-pagamentos" component={GestaoPagamentos} />
                    <Route path="/gestao-newsletters" component={GestaoNewsletters} />
                    <Route path="/gestao-estatisticas" component={GestaoEstatisticas} />
                    <Route path="/gestao-questionarios" component={GestaoQuestionarios} />
                    <Route exact path="/gestao-utilizadores-todos/novo-ticket/:tipo" component={ColaboradoresNovaMensagem} />
                    <Route exact path="/gestao-utilizadores-todos" component={ColaboradoresTodos} />
                    <Route exact path="/gestao-utilizadores-sistema-informacao/:utilizador" component={ColaboradoresSistemaInformacao} />
                    <Route exact path="/gestao-utilizadores-resultados/:utilizador" component={ColaboradoresResultados} />
                    <Route exact path="/gestao-utilizadores-documentacao/:utilizador" component={ColaboradoresDocumentacao} />
                    <Route exact path="/gestao-utilizadores-pagamentos/:utilizador" component={ColaboradoresPagamentos} />
                    <Route exact path="/gestao-utilizadores-associar" component={ColaboradoresAssociar} />
                    <Route exact path="/gestao-associar-alunos" component={ColaboradoresAssociarAlunos} />
                    <Route exact path="/gestao-utilizadores-pendentes" component={ColaboradoresPendentes} />
                    <Route exact path="/gestao-utilizadores-estatisticas" component={ColaboradoresEstatisticas} />
                    <Route path="/pesquisa-cursos" component={PesquisaCursos} />
                    <Route path="/:curso/gestao-cursos-ranking" component={GestaoCursosRanking} />
                    <Route path="/:curso/gestao-cursos-resultados" component={GestaoCursosResultados} />
                    <Route path="/:curso/gestao-cursos-disciplinas-resultados" component={GestaoCursosDisciplinaResultados} />
                    <Route path="/:curso/formador-lista-cursos/inscricoes" component={FormadoresListaCursoInscricoes} />
                    <Route exact path="/gerir-curso/:curso/:pagina" component={GerirCursoLayout} />
                    <Route exact path="/curso/:curso/:pagina" component={CursoLayout} />
                    <Route path="/:curso/formador-lista-cursos/ranking" component={FormadoresCursosRanking} />
                    <Route path="/:curso/formador-lista-cursos/resultados" component={FormadoresCursoResultados} />
                    <Route
                        path="/:curso/formador-lista-cursos/resultados-curso-disciplinas"
                        component={FormadoresCursosDisciplinaResultados}
                    />
                    <Route path="/:curso/formador-lista-cursos/estatisticas" component={FormadoresCursoEstatisticas} />
                    <Route path="/:curso/formador-lista-cursos/player-questionario" component={FormadoresCursoQuestionario} />
                    {/***************ROUTING PLAYERS GESTAO/INSTRUTOR***************/}
                    <Route path="/gerir-curso/:curso/:aula/player-videoconferencia" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-video" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-audio" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-pagina" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-pdf" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-dirigido" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-dirigido-resumo" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-resumo" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-resolucao" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-avaliacao" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-avaliacao-resumo" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-avaliacao-resolucao" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-progresso" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-progresso-resumo" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-questionario-progresso-resolucao" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-download" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-trabalho" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-link" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-sumario" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-sessao-presencial" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:aula/player-assiduidade" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:ficheiro/player-trabalho-ficheiro" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:ficheiro/player-ficheiro" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/:ficheiro/player-editor" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/vconf/:vconfid/player-videoconferencia" component={GerirPlayer} />
                    <Route path="/gerir-curso/:curso/vconf/:vconfid/player-video" component={GerirPlayer} />
                    <Route path="/gestao-videoconferencia/:vconfid/player-videoconferencia" component={GerirPlayer} />
                    <Route path="/gestao-webinar/:vconfid/player-webinar" component={GerirPlayer} />
                    <Route path="/gestao-videoconferencia/:vconfid/player-video" component={GerirPlayer} />
                    <Route path="/gestao-videoconferencia/:vconfid/player-video-meets" component={GerirPlayer} />
                    <Route path="/gestao-webinar/:vconfid/player-video" component={GerirPlayer} />
                    <Route path="/gestao-webinar/:vconfid/player-video-meets" component={GerirPlayer} />
                    {/***************ROUTING PLAYERS GESTAO/INSTRUTOR***************/}
                    {/***************ROUTING PLAYERS***************/}
                    <Route path="/curso/:curso/:aula/player-videoconferencia" component={Player} />
                    <Route path="/curso/:curso/:aula/player-video" component={Player} />
                    <Route path="/curso/:curso/:aula/player-audio" component={Player} />
                    <Route path="/curso/:curso/:aula/player-pagina" component={Player} />
                    <Route path="/curso/:curso/:aula/player-pdf" component={Player} />
                    <Route path="/curso/:curso/:aula/player-questionario-dirigido" component={Player} />
                    <Route path="/curso/:curso/:aula/player-questionario-dirigido-resumo" component={Player} />
                    <Route path="/curso/:curso/:aula/player-questionario" component={Player} />
                    <Route path="/curso/:curso/:aula/player-questionario-resumo" component={Player} />
                    <Route path="/curso/:curso/:aula/player-questionario-resolucao" component={Player} />
                    <Route path="/curso/:curso/:aula/player-avaliacao" component={Player} />
                    <Route path="/curso/:curso/:aula/player-avaliacao-resumo" component={Player} />
                    <Route path="/curso/:curso/:aula/player-avaliacao-resolucao" component={Player} />
                    <Route path="/curso/:curso/:aula/player-questionario-progresso" component={Player} />
                    <Route path="/curso/:curso/:aula/player-questionario-progresso-resumo" component={Player} />
                    <Route path="/curso/:curso/:aula/player-questionario-progresso-resolucao" component={Player} />
                    <Route path="/curso/:curso/:aula/player-download" component={Player} />
                    <Route path="/curso/:curso/:aula/player-trabalho" component={Player} />
                    <Route path="/curso/:curso/:aula/player-link" component={Player} />
                    <Route path="/curso/:curso/:aula/player-sumario" component={Player} />
                    <Route path="/curso/:curso/:aula/player-sessao-presencial" component={Player} />
                    <Route path="/curso/:curso/:ficheiro/player-trabalho-ficheiro" component={Player} />
                    <Route path="/curso/:curso/:ficheiro/player-ficheiro" component={Player} />
                    <Route path="/curso/:curso/vconf/:vconfid/player-videoconferencia" component={Player} />
                    <Route path="/curso/:curso/vconf/:vconfid/player-videoconferencia-aguardar" component={Player} />
                    <Route path="/curso/:curso/vconf/:vconfid/player-videoconferencia-blocked" component={Player} />
                    <Route path="/curso/:curso/vconf/:vconfid/player-video" component={Player} />
                    <Route path="/curso/:curso/vconf/:vconfid/player-video-meets" component={Player} />
                    {/***************ROUTING PLAYERS***************/}
                </Switch>
                <Footer redessociais={redessociais} opcoes={opcoes} fundoBranco={fundoBranco} />
            </>
        );
    }
}

export default Layout;
