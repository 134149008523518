import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Table, Form, Drawer, Menu, Dropdown } from "antd";
import axios from "axios";

export class UnidadesOrganizacionais extends Component {
    state = {
        lista: [],
        listaMobile: [],
        //TABELA
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER DETALHE
        visibleDetalhe: false,
        nrGestores: 0,
        nrInstrutores: 0,
        nrAlunos: 0,
        nrCursos: 0,
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    componentDidUpdate() {
        if (this.props.atualizar) {
            this.listar();
            this.props.paraAtualizar();
        }
    }

    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-estatistica/unidades-organizacionais",
                    params: {
                        organismoId: this.props.organismoId,
                        dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.props.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        var lista = [];
                        var listaMobile = [];

                        response.data.map((dado, index) => {
                            lista.push({
                                key: index,
                                ano: `${dado.mes}/${dado.ano}`,
                                gestores: dado.gestores,
                                instrutores: dado.instrutores,
                                alunos: dado.alunos,
                                cursos: dado.cursos
                            });
                            listaMobile.push({
                                key: index,
                                ano: `${dado.mes}/${dado.ano}`,
                                opcoes: (
                                    <Dropdown
                                        overlay={this.montarMenu(dado)}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )
                            });
                        });

                        this.setState({
                            lista,
                            listaMobile,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    montarMenu = (registo) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalhe(registo)}>
                        Detalhe
                    </Link>
                </Menu.Item>
            </Menu>
        );
    }

    abrirDetalhe = (registo) => {
        this.setState({
            visibleDetalhe: true,
            nrGestores: registo.gestores,
            nrInstrutores: registo.instrutores,
            nrAlunos: registo.alunos,
            nrCursos: registo.cursos,
        })
    }

    render() {
        const {
            lista,
            listaMobile,
            //TABELA
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER DETALHE
            visibleDetalhe,
            nrGestores,
            nrInstrutores,
            nrAlunos,
            nrCursos,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const columns = [
            {
                title: "Mês/Ano",
                dataIndex: "ano",
                key: "ano"
            },
            {
                title: "Nº Gestores",
                dataIndex: "gestores",
                key: "gestores"
            },
            {
                title: "Nº Instrutores",
                dataIndex: "instrutores",
                key: "instrutores"
            },
            {
                title: "Nº Alunos",
                dataIndex: "alunos",
                key: "alunos"
            },
            {
                title: "Nº Cursos Ativos",
                dataIndex: "cursos",
                key: "cursos"
            }
        ];

        const columnsMobile = [
            {
                title: "Mês/Ano",
                dataIndex: "ano",
                key: "ano"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50",
            }
        ];

        return (
            <>
                <Table
                    columns={columns}
                    className="tabela-estatisticas-desktop"
                    dataSource={lista}
                    pagination={false}
                    locale={{ emptyText: "Não existem registos!" }}
                    loading={{
                        spinning: loading_table,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                />
                <Table
                    columns={columnsMobile}
                    className="tabela-estatisticas-mobile tabela-estatisticas-organismos"
                    dataSource={listaMobile}
                    pagination={false}
                    locale={{ emptyText: "Não existem registos!" }}
                    loading={{
                        spinning: loading_table,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                />
                <Drawer
                    className="drawer-detalhe-stepmeets"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Nº Gestores">
                                    {nrGestores}
                                </Form.Item>
                                <Form.Item label="Nº Instrutores">
                                    {nrInstrutores}
                                </Form.Item>
                                <Form.Item label="Nº Alunos">
                                    {nrAlunos}
                                </Form.Item>
                                <Form.Item label="Nº Cursos Ativos">
                                    {nrCursos}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </>
        );
    }
}

export default UnidadesOrganizacionais;
