import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Drawer, Icon, Form, Radio } from "antd";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";

class DrawerCarrinho extends Component {
    static contextType = GlobalContext;

    state = {
        metodo: 1,
        total: 0,
        //ENTIDADE
        nome: "",
        iban: "",
        entidade: "",
        cnpj: "",
        banco: "",
        agencia: "",
        conta: "",
        operacao: "",
        paypal: false,
        transferencia: false,
        //LOADING
        iconLoading: false,
        parte: 1,
        erro: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
    };

    handleChange = event => {
        this.setState({
            metodo: event.target.value
        })
    }

    carregarEntidadeInfo = () => {
        axios({
            method: "get",
            url: "/api/carrinho/entidade-info"
        })
            .then(response => {
                var metodo = 1;

                if (!response.data.paypal && response.data.transferencia)
                    metodo = 2;

                this.setState({
                    nome: response.data.nome,
                    iban: response.data.iban,
                    entidade: response.data.entidade,
                    cnpj: response.data.cnpj,
                    banco: response.data.banco,
                    agencia: response.data.agencia,
                    conta: response.data.conta,
                    operacao: response.data.operacao,
                    paypal: response.data.paypal,
                    transferencia: response.data.transferencia,
                    metodo
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    finalizarCompra = () => {
        this.props.abrirLogin()
        this.context.atualizarState({
            carrinhoProdutos: true
        });
    }

    calcularTotal = () => {
        var total = 0;
        this.context.carrinho.map(produto => {
            total = total + produto.preco
        })

        this.setState({
            total
        })

        return total;
    }

    concluirPaypal = (orderID) => {
        var item = new FormData();
        item.append("order", orderID);
        item.append("total", this.state.total)//(this.state.total + "").replace(".", ","));
        item.append("carrinho", JSON.stringify(this.tratarCarrinho()));

        axios({
            method: "post",
            url: "/api/carrinho/pagamento-paypal",
            data: item
        })
            .then(response => {
                this.setState({
                    parte: 3
                })
            })
            .catch(error => {
                this.setState({
                    parte: 3,
                    erro: true
                })
            });
    }

    concluirTransferencia = (data) => {
        var item = new FormData();
        item.append("total", this.state.total)//(this.state.total + "").replace(".", ","));
        item.append("carrinho", JSON.stringify(this.tratarCarrinho()));

        axios({
            method: "post",
            url: "/api/carrinho/pagamento-transferencia",
            data: item
        })
            .then(response => {
                this.setState({
                    parte: 3
                })
            })
            .catch(error => {
                this.setState({
                    parte: 3,
                    erro: true
                })
            });
    }

    tratarCarrinho = () => {
        var carrinho = [];
        carrinho = this.context.carrinho.map(x => {
            return {
                id_curso: x.id,
                preco: x.preco,//(x.preco + "").replace(".", ","),
                dt_inicio: x.dt_inicio,
                dt_fim: x.dt_fim,
                nr_meses: x.nr_meses,
                tipo: x.tipo
            }
        });

        return carrinho;
    }

    erro = () => {
        this.setState({
            parte: 3,
            erro: true
        })
    }

    onClick = tipo => {
        if (tipo === "paypal")
            this.setState({
                iconLoading: true
            })
    }

    onCancel = () => {
        this.setState({
            iconLoading: false
        })
    }

    excluir = id => {
        var carrinho = this.context.carrinho.filter(x => x.id !== id);

        this.context.atualizarState({
            carrinho
        })
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.setState({
                metodo: 1,
                //LOADING
                iconLoading: false,
                parte: 1,
                erro: false
            })
            this.calcularTotal();
        }
    };

    voltar = () => {
        //var cursoNoCarrinho = this.context.carrinho.find(x => x.id === localStorage.getItem("codigo_curso"))
        ////if (cursoNoCarrinho && this.props.match.params.pagina === "curso-visao-geral" || this.props.location.pathname === "/user" || this.props.location.pathname === "/sistema-informacao")
        //if ((this.props.match.params.pagina === "curso-visao-geral" || this.props.location.pathname === "/user" || this.props.location.pathname === "/sistema-informacao") && this.context.carrinho.length)
        //    window.location = window.location.href;
        //else
            this.props.voltarCarrinho()
    }

    render() {
        const {
            metodo,
            total,
            //ENTIDADE
            parte,
            erro,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        let carrinho = this.context.carrinho;

        if (redirect)
            return <Redirect to={redirectLink} />;

        return (
            <Drawer
                className="drawer-carrinho"
                title={(carrinho.length || parte === 3) && !iconLoading ? parte === 1 ? "Carrinho de compras" : "Finalizar compra" : ""}
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visibleCart}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    {parte !== 3 && carrinho.length ?
                        <>
                            {parte === 1 && carrinho.length ?
                                <>
                                    <div className="bloco-lista">
                                        <ul className="lista-produtos-carrinho">
                                            {this.context.carrinho.map((produto, index) => (
                                                <li key={index} className="produto-carrinho">
                                                    <div className="imagem-produto">
                                                        <img src={produto.capa} />
                                                    </div>
                                                    <div className="info-produto">
                                                        <div className="info-bloco">
                                                            <span className="produto-nome">{produto.nome}</span>
                                                            <span className="produto-descricao">{produto.descricao}</span>
                                                            <span className="produto-preco" dangerouslySetInnerHTML={{ __html: `${this.context.moeda_entidade} ${(produto.preco + "").replace(".", ",")}`}}></span>
                                                        </div>
                                                        <div className="info-bloco-opcoes">
                                                            <Link to="#" className="opcao-remover" onClick={() => this.excluir(produto.id)}>
                                                                <Icon type="delete" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                                : null}
                            <div className="bloco-total">
                                <span className="total-info">Total: <strong dangerouslySetInnerHTML={{ __html: `${this.context.moeda_entidade} ${(total + "").replace(".", ",")}` }}></strong></span>
                            </div>
                        </>
                        : null}
                    {parte === 3 || !carrinho.length ?
                        <div className="bloco-mensagem">
                            <div className="mensagem-info">
                                <i className="fas fa-shopping-cart mensagem-icon mensagem-icon-vazio"></i>
                                <span className="mensagem-titulo">O carrinho está vazio!</span>
                                <p className="mensagem-texto">Procure no catálogo o curso pretendido.</p>
                            </div>
                        </div>
                        : null}
                </div>
                <div className="ant-drawer-footer">
                    {parte !== 3 && carrinho.length ?
                        <>
                            <button className="botao-secundario" disabled={iconLoading} onClick={this.props.onClose}>
                                Voltar
                            </button>
                            <button className="botao-principal" title="Finalizar compra" style={{ marginLeft: 20 }} onClick={this.finalizarCompra}>
                                Finalizar compra
                                </button>
                        </>
                        :
                        <button className="botao-secundario" onClick={this.voltar}>
                            Voltar
                        </button>
                    }
                </div>
            </Drawer>
        );
    }
}
export default withRouter(DrawerCarrinho);
