import React, { Component } from "react";
import { Collapse, Menu, Dropdown, notification, Icon, Modal } from "antd";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import moment from "moment";
import screenfull from "screenfull";
import noimage from "../../images/noimage.png";

const confirm = Modal.confirm;
const Panel = Collapse.Panel;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Modulo extends Component {
    static contextType = GlobalContext;
    //_isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            moduloid: 0,
            tituloModulo: "",
            ordemModulo: "",
            drawerModuloVisible: false,
            precedencia_nome: "",
            precedencia_concluida: false,
            //COLLAPSE
            aberto: false,
            collapsed: ["0"],
            //PERMISSOES
            curso_iniciado: false,
            //INSCRICAO
            inscrito: false,
            //COPIAR
            visibleCopiar: false,
            //FULLSCREEN
            fullscreen: false,
            //MODAL LISTA PARTICIPANTES
            visibleParticipantes: false,
            carregarLista: false,
            participantes: [],
        };

        this.elementFullScreen = React.createRef();
    }

    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
    }

    //CHAMADA ANTES DO RENDER
    componentDidMount() {
        if (this.props.index == this.props.modulo_aberto)
            this.setState({
                aberto: true
            });
    }

    //MONTA AS OPÇÕES DE MENU POR MODULO
    montarMenuModulo = (moduloid, competencias, ordemAula, ativo) => {
        return (
            <Menu>
                <Menu.Item key={"modulo_" + moduloid + "_add"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.onOpenDrawerAula(moduloid, ordemAula, competencias);
                        }}
                    >
                        Adicionar tarefa
                    </Link>
                </Menu.Item>
                {this.context.id_copia_tarefa > 0 ? (
                    <Menu.Item key={"modulo_" + moduloid + "_colar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.colarTarefa(this.context.id_copia_tarefa, moduloid);
                            }}
                        >
                            Colar tarefa
                        </Link>
                    </Menu.Item>
                ) : null}
                <Menu.Item key={"modulo_" + moduloid + "_editar"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.carregarModulo(moduloid, competencias);
                        }}
                    >
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item key={"modulo_" + moduloid + "_excluir"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.excluirModulo(moduloid);
                        }}
                    >
                        Excluir
                    </Link>
                </Menu.Item>
                <Menu.Item key={"modulo_" + moduloid + "_ativar"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.ativarModulo(moduloid, ativo);
                        }}
                    >
                        {ativo ? "Ocultar" : "Mostrar"}
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuModulo2 = (moduloid, competencias, ordemAula, ativo, aula) => {
        return (
            <Menu>
                <Menu.Item key={"modulo_" + moduloid + "_add"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.onOpenDrawerAula(moduloid, ordemAula, competencias);
                        }}
                    >
                        Adicionar tarefa
                    </Link>
                </Menu.Item>
                {this.context.id_copia_tarefa > 0 ? (
                    <Menu.Item key={"modulo_" + moduloid + "_colar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.colarTarefa(this.context.id_copia_tarefa, moduloid);
                            }}
                        >
                            Colar tarefa
                        </Link>
                    </Menu.Item>
                ) : null}
                <Menu.Item key={"modulo_" + moduloid + "_copiar"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.copiarTarefa(aula.id);
                        }}
                    >
                        Copiar tarefa
                    </Link>
                </Menu.Item>
                <Menu.Item key={"modulo_" + moduloid + "_editar"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.carregarModulo(moduloid, competencias);
                        }}
                    >
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item key={"modulo_" + moduloid + "_excluir"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.excluirModulo(moduloid);
                        }}
                    >
                        Excluir
                    </Link>
                </Menu.Item>
                <Menu.Item key={"modulo_" + moduloid + "_ativar"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.ativarModulo(moduloid, ativo);
                        }}
                    >
                        {ativo ? "Ocultar" : "Mostrar"}
                    </Link>
                </Menu.Item>
                <Menu.Item key={"modulo_" + moduloid + "_editar_aula"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.carregarAulaHtml(moduloid, aula.id, false);
                        }}
                    >
                        Editar tarefa
                    </Link>
                </Menu.Item>
                <Menu.Item key={"modulo_" + moduloid + "_excluir_aula"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.excluirAulaUnica(moduloid, aula.id);
                        }}
                    >
                        Excluir tarefa
                    </Link>
                </Menu.Item>
                <Menu.Item key={"modulo_" + moduloid + "_ativar_aula"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.ativarAulaUnica(moduloid, aula.id, aula.ativo);
                        }}
                    >
                        {ativo ? "Ocultar tarefa" : "Mostrar tarefa"}
                    </Link>
                </Menu.Item>
                {/*<Menu.Item key={"modulo_" + moduloid + "_presencas"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.onOpenDrawerPresencas();
                        }}
                    >
                        Lista Presenças
                    </Link>
                </Menu.Item>*/}
            </Menu>
        );
    };

    //MONTA AS OPÇÕES DE MENU POR AULA DE PRIMEIRO NIVEL
    /*montarMenuAula = (moduloid, competencias, ordemAula, aulaid, tipo, ativo, excluir, recurso) => {*/
    montarMenuAula = (moduloid, competencias, aula) => {
        //var ordemAula = aula.modulo_Aula_lvl2.length > 0 ?
        //    aula.modulo_Aula_lvl2[aula.modulo_Aula_lvl2.length - 1].ordem + 1
        //    : 1;
        var aulaid = aula.id;
        var tipo = aula.tipo_Recurso.id;
        var ativo = aula.ativo;
        var recurso = aula.recurso;
        //var subTarefaAssiduidade = aula.subTarefaAssiduidade;
        //var subTarefaSumario = aula.subTarefaSumario;

        return (
            <Menu>
                <Menu.Item key={moduloid + "_" + aulaid + "_add"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            //this.props.onOpenDrawerAulalvl2(moduloid, competencias, aulaid, tipo, ordemAula, subTarefaAssiduidade, subTarefaSumario);
                            this.props.onOpenDrawerAulalvl2(moduloid, competencias, aula);
                        }}
                    >
                        Adicionar tarefa
                    </Link>
                </Menu.Item>
                {this.context.id_copia_tarefa > 0 ? (
                    <Menu.Item key={"modulo_" + moduloid + "_colar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.colarTarefa(this.context.id_copia_tarefa, moduloid, aulaid);
                            }}
                        >
                            Colar tarefa
                        </Link>
                    </Menu.Item>
                ) : null}
                <Menu.Item key={"modulo_" + moduloid + "_copiar"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.copiarTarefa(aulaid);
                        }}
                    >
                        Copiar tarefa
                    </Link>
                </Menu.Item>
                {tipo === 1 && (
                    <Menu.Item key={moduloid + "_" + aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaVideo(moduloid, aulaid, false);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 2 && (
                    <Menu.Item key={moduloid + "_" + aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaAudio(moduloid, aulaid, false);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 3 && (
                    <Menu.Item key={moduloid + "_" + aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaHtml(moduloid, aulaid, false);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 4 && (
                    <Menu.Item key={moduloid + "_" + aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaPdf(moduloid, aulaid, false);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {(tipo === 5 || tipo === 6 || tipo === 15) && (
                    <Menu.Item key={moduloid + "_" + aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaQuestionario(moduloid, competencias, aulaid, false);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 7 && (
                    <Menu.Item key={moduloid + "_" + aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaDownload(moduloid, aulaid, false);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 8 && (
                    <Menu.Item key={moduloid + "_" + aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaTrabalho(moduloid, aulaid, false);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {(tipo > 8 && tipo !== 15 && tipo !== 13) && (
                    <Menu.Item key={moduloid + "_" + aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAula(moduloid, aulaid, false);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {(tipo === 13) && (
                    <Menu.Item key={moduloid + "_" + aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaPasta(moduloid, aulaid, false);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 1 && (
                    <Menu.Item key={moduloid + "_" + aulaid + "_excluir"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.excluirVideoVimeo(this.props.index, aulaid, recurso);
                            }}
                        >
                            Excluir
                        </Link>
                    </Menu.Item>
                )}
                {tipo > 1 && (
                    <Menu.Item key={moduloid + "_" + aulaid + "_excluir"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.excluirAula(this.props.index, aulaid);
                            }}
                        >
                            Excluir
                        </Link>
                    </Menu.Item>
                )}
                <Menu.Item key={moduloid + "_" + aulaid + "_ativar"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.ativarAula(moduloid, this.props.index, aulaid, ativo, tipo == 12);
                        }}
                    >
                        {ativo ? "Ocultar" : "Mostrar"}
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //MONTA AS OPÇÕES DE MENU POR AULA DE SEGUNDO NIVEL
    /*montarMenuAulalvl2 = (moduloid, competencias, aulaLvl1Index, aulaid, tipo, ativo, excluir, recurso) => {*/
    montarMenuAulalvl2 = (moduloid, competencias, aulaLvl1Index, aula) => {
        var aulaid = aula.id;
        var tipo = aula.tipo_Recurso.id;
        var ativo = aula.ativo;
        var recurso = aula.recurso;
        //aula.id,
                                            //aula.tipo_Recurso.id,
                                            //aula.ativo,
                                            //aula.excluir_aula,
                                            //aula.recurso

        return (
            <Menu>
                <Menu.Item key={aulaid + "_copiar"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.copiarTarefa(aulaid);
                        }}
                    >
                        Copiar tarefa
                    </Link>
                </Menu.Item>
                {tipo === 1 && (
                    <Menu.Item key={aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaVideo(moduloid, aulaid, true);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 2 && (
                    <Menu.Item key={aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaAudio(moduloid, aulaid, true);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 3 && (
                    <Menu.Item key={aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaHtml(moduloid, aulaid, true);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 4 && (
                    <Menu.Item key={aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaPdf(moduloid, aulaid, true);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {(tipo === 5 || tipo === 6 || tipo === 14) && (
                    <Menu.Item key={aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaQuestionario(moduloid, competencias, aulaid, true);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 7 && (
                    <Menu.Item key={aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaDownload(moduloid, aulaid, true);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 8 && (
                    <Menu.Item key={aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaTrabalho(moduloid, aulaid, true);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {(tipo > 8 && tipo !== 14 && tipo !== 17) && (
                    <Menu.Item key={aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAula(moduloid, aulaid, true);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {(tipo === 17) && (
                    <Menu.Item key={aulaid + "_editar"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.carregarAulaPresencas(moduloid, aulaid, true);
                            }}
                        >
                            Editar tarefa
                        </Link>
                    </Menu.Item>
                )}
                {tipo === 1 && (
                    <Menu.Item key={aulaid + "_excluir"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.excluirVideoVimeoLvl2(this.props.index, aulaLvl1Index, aulaid, recurso);
                            }}
                        >
                            Excluir
                        </Link>
                    </Menu.Item>
                )}
                {tipo > 1 && (
                    <Menu.Item key={aulaid + "_excluir"}>
                        <Link
                            to="#"
                            onClick={event => {
                                event.stopPropagation();
                                this.props.excluirAulaLvl2(this.props.index, aulaLvl1Index, aulaid);
                            }}
                        >
                            Excluir
                        </Link>
                    </Menu.Item>
                )}
                <Menu.Item key={aulaid + "_ativar"}>
                    <Link
                        to="#"
                        onClick={event => {
                            event.stopPropagation();
                            this.props.ativarAulaLvl2(this.props.index, aulaLvl1Index, aulaid, ativo);
                        }}
                    >
                        {ativo ? "Ocultar" : "Mostrar"}
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    colarTarefa = (aulaId, moduloId, aulaPaiId) => {
        confirm({
            title: "Pretende copiar a tarefa?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.setState(
                    {
                        visibleCopiar: true,
                        textoModal: "copiar"
                    },
                    () => {
                        axios({
                            method: "post",
                            url: "/api/curso-aula/copiar-aula",
                            params: {
                                id_modulo_aula: aulaId,
                                id_curso_modulo: moduloId,
                                id_aula_pai: aulaPaiId
                            }
                        })
                            .then(() => {
                                this.props.listarModulo(moduloId);

                                this.setState({
                                    visibleCopiar: false
                                });

                                this.context.atualizarState({
                                    id_copia_tarefa: 0
                                })

                                this.context.atualizarEventosHoje();
                            })
                            .catch(error => {
                                openNotificationWithIcon("error", "Erro", error.response.data);
                                this.setState({
                                    visibleCopiar: false
                                });
                            });
                    }
                );
            }
        });
    };

    //FORMATA AS HORAS EM STRING 00H00
    formatarHoras = hora => {
        var horas = moment(hora, "HH:mm");
        if (moment.duration(hora, "minutes").asMinutes() > 59) return horas.format("HH").slice(0, 2) + "h" + horas.format("mm").slice(0, 2);
        else return horas.format("m").slice(0, 2) + "m";
    };

    //CALCULA O TOTAL DE HORAS POR MODULO
    calcularAulasPorModulo = aulas => {
        var _aulas = 0;

        _aulas = aulas.length;

        aulas.map(aula => {
            _aulas += aula.modulo_Aula_lvl2.length;
        });

        return _aulas;
    };

    //CALCULA O NUMERO DE AULAS JÁ CONCLUIDAS
    calcularAulasConcluidas = aulas => {
        var aulas_concluidas = 0;

        aulas.map(aula => {
            if (aula.modulo_Aulas_Utilizadores.length > 0)
                if (aula.modulo_Aulas_Utilizadores[0].concluido == true || aula.modulo_Aulas_Utilizadores[0].reprovado == true)
                    aulas_concluidas++;

            aula.modulo_Aula_lvl2.map(aulalvl2 => {
                if (aulalvl2.modulo_Aulas_Utilizadores.length > 0)
                    if (aulalvl2.modulo_Aulas_Utilizadores[0].concluido == true || aulalvl2.modulo_Aulas_Utilizadores[0].reprovado == true)
                        aulas_concluidas++;
            });
        });

        return aulas_concluidas;
    };

    //alertar = event => {
    //    this.setState(
    //        {
    //            aberto: !this.state.aberto
    //        },
    //        () => {
    //            if (this.state.aberto) {
    //                if (this.props.modulo.precedencia && !this.props.modulo.obrigatoria && !this.state.precedencia_concluida && this.context.role == "UTILIZADOR")
    //                    openNotificationWithIcon(
    //                        "info",
    //                        "Atenção",
    //                        <span>
    //                            Sugerimos que conclua primeiro a atividade <strong>{this.state.precedencia_nome}</strong>
    //                        </span>
    //                    );
    //                if (this.props.modulo.precedencia && this.props.modulo.obrigatoria && !this.state.precedencia_concluida && this.context.role == "UTILIZADOR")
    //                    openNotificationWithIcon(
    //                        "warning",
    //                        "Atenção",
    //                        <span>
    //                            Tem de finalizar primeiro a atividade <strong>{this.state.precedencia_nome}</strong>
    //                        </span>
    //                    );
    //            }
    //        }
    //    );
    //};

    validarDisponibilidade = () => {
        if (
            this.props.modulo.precedencia &&
            this.props.modulo.obrigatoria &&
            !this.state.precedencia_concluida &&
            this.context.role == "UTILIZADOR"
        )
            return true;

        return false;
    };

    calcular = aula => {
        var total = 0;

        if (aula.dt_inicio && aula.hora) {
            let d1 = moment(aula.hora, "HH:mm:ss");
            let d2 = moment(aula.dt_inicio);

            d2 = d2.set({
                hour: d1.get("hour"),
                minute: d1.get("minute"),
                second: d1.get("second")
            });

            total = d2.diff(moment(), "hours");
        } else if (aula.dt_inicio) total = moment(aula.dt_inicio).diff(moment(), "hours");
        else if (aula.hora) {
            let d1 = moment(aula.hora, "HH:mm:ss");
            let d2 = moment();

            d2 = d2.set({
                hour: d1.get("hour"),
                minute: d1.get("minute"),
                second: d1.get("second")
            });

            total = d2.diff(moment(), "hours");
        }

        return total;
    };

    notificar = (aula, tipo) => {
        if (aula.dt_inicio && aula.hora) {
            let d1 = moment(aula.hora, "HH:mm:ss");
            let d2 = moment(aula.dt_inicio);

            d2 = d2.set({
                hour: d1.get("hour"),
                minute: d1.get("minute"),
                second: d1.get("second")
            });

            openNotificationWithIcon("warning", "Atenção", tipo + " disponível a " + d2.format("DD/MM/YYYY") + " às " + d2.format("HH:mm"));
        } else if (aula.dt_inicio)
            openNotificationWithIcon("warning", "Atenção", tipo + " disponível a " + moment(aula.dt_inicio).format("DD/MM/YYYY"));
        else if (aula.hora) {
            let d1 = moment(aula.hora, "HH:mm:ss");
            let d2 = moment();

            d2 = d2.set({
                hour: d1.get("hour"),
                minute: d1.get("minute"),
                second: d1.get("second")
            });

            openNotificationWithIcon("warning", "Atenção", tipo + " disponível a " + d2.format("DD/MM/YYYY") + " às " + d2.format("HH:mm"));
        }
    };

    onClink = aulaId => {
        localStorage.setItem("modulo_aberto", this.props.index);
        localStorage.setItem("codigo_aula", aulaId);
    };

    onClinkConcluido = (moduloId, aulaId) => {
        localStorage.setItem("modulo_aberto", this.props.index);
        this.props.concluirAula(moduloId, aulaId);
    };

    onClinkValidar = (aula, disponivel, tipo, aulaId) => {
        localStorage.setItem("modulo_aberto", this.props.index);
        localStorage.setItem("codigo_aula", aulaId);

        if (!disponivel) {
            this.notificar(aula, tipo);
        }
    };

    onChangeCollapse = collapsed => {
        this.setState({
            collapsed
        });
    };

    onClickFullscreenElement = () => {
        screenfull.toggle(this.elementFullScreen.current);
        this.setState({ fullscreen: !this.state.fullscreen });
    };

    listarParticipantes = atividadeId => {
        this.setState({
            visibleParticipantes: true,
            carregarLista: true
        })
        axios({
            method: "get",
            url: "/api/gerir-curso-modulo/listar-participantes",
            params: {
                atividadeId
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data,
                    carregarLista: false
                })
            })
            .catch(() => { });
    };

    render() {
        const {
            //MODAL LISTA PARTICIPANTES
            visibleParticipantes,
            carregarLista,
            participantes,
        } = this.state;

        var semaforo = estado => {
            if (estado === "ATIVO") return <span className="modulo-semaforo verde" title="Ativo" />;
            else if (estado === "FECHADO") {
                return <span className="modulo-semaforo amarelo" title="Fechado" />;
            } else {
                return <span className="modulo-semaforo vermelho" title="Bloqueado" />;
            }
        };

        //MONTA O TITULO E LOGICA NO MODULO
        const tituloModulo = (
            <div>
                <div className="modulo-header">
                    <div className="modulo-container-esquerda">
                        {semaforo(this.props.modulo.estado)}
                        <span className="modulo-num">{this.props.modulo.nome}</span>
                        {this.props.modulo.precedencia && (
                            <span className="modulo-precedencia" title={`Precedência${this.props.modulo.obrigatoria ? " obrigatória" : ""}: ${this.props.modulo.precedencia}`}>
                                <Icon type="api" className={this.props.modulo.obrigatoria ? "obrigatoria" : ""} />
                            </span>
                        )}
                        <span className="modulo-opcoes">
                            {this.props.botoesVisiveis ? (
                                <Dropdown
                                    overlay={
                                        this.props.modulo.pagina_unica
                                            ? this.montarMenuModulo2(
                                                this.props.modulo.id,
                                                this.props.modulo.competencias,
                                                this.props.modulo.modulo_Aula.length > 0
                                                    ? this.props.modulo.modulo_Aula[this.props.modulo.modulo_Aula.length - 1].ordem + 1
                                                    : 1,
                                                this.props.modulo.ativo,
                                                this.props.modulo.modulo_Aula[0]
                                            )
                                            : this.montarMenuModulo(
                                                this.props.modulo.id,
                                                this.props.modulo.competencias,
                                                this.props.modulo.modulo_Aula.length > 0
                                                    ? this.props.modulo.modulo_Aula[this.props.modulo.modulo_Aula.length - 1].ordem + 1
                                                    : 1,
                                                this.props.modulo.ativo
                                            )
                                    }
                                    placement="bottomLeft"
                                    onClick={event => {
                                        event.stopPropagation();
                                    }}
                                >
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            ) : null}
                        </span>
                        {!this.props.modulo.todos &&
                            <div className="info-participante">
                                {this.props.modulo.totalParticipantes > 1 ?
                                    <Link to="#" className="link-model-participantes" onClick={event => { this.listarParticipantes(this.props.modulo.id); event.stopPropagation(); }}>
                                        <i className="fas fa-users icon-tipo-partilha partilhado"></i>
                                    </Link>
                                    :
                                    <>
                                        <div className="container-img">
                                            <img src={this.props.modulo?.participante?.foto ? this.props.modulo?.participante?.foto : noimage} />
                                        </div>
                                        <div className="info">
                                            <span className="nome" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                                                {this.props.modulo?.participante?.nome_completo}
                                            </span>
                                            <p className="numero">{this.props.modulo?.participante?.numero}</p>
                                        </div>
                                    </>
                                }
                            </div>}
                    </div>
                    <div className="modulo-container-direita">
                        <span className="modulo-datas">
                            {this.props.modulo.dt_inicio ? (
                                <>
                                    Inicio: <strong>{moment(this.props.modulo.dt_inicio).format("DD/MM/YYYY")}</strong>
                                </>
                            ) : (
                                    ""
                                )}{" "}
                            {this.props.modulo.dt_termino ? (
                                <>
                                    Término: <strong>{moment(this.props.modulo.dt_termino).format("DD/MM/YYYY")}</strong>
                                </>
                            ) : (
                                    ""
                                )}
                        </span>
                    </div>
                </div>
                {this.props.modulo.objetivos && (
                    <div className="modulo-header-objetivos">
                        <span>{this.props.modulo.objetivos}</span>
                    </div>
                )}
            </div>
        );

        const montarHoraTermino = (horaInicial, duracao) => {
            const hora = moment(horaInicial).add(duracao.hours(), "hours").add(duracao.minutes(), "minutes");
            return hora.format("HH:mm");
        }

        const montaAula = (moduloId, aula) => {
            var tipoId = aula.tipo_Recurso.id;
            var inicioUrl = `/gerir-curso/${this.props.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${aula.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}-${Math.floor(100000 + Math.random() * 900000)}`;

            if (tipoId === 1) {
                //VIDEO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/video.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                    <i className="fas fa-star" />
                                    {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/video.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //VIDEO
            else if (tipoId === 2) {
                //AUDIO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/audio.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                            </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/audio.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //AUDIO
            else if (tipoId === 3 || tipoId === 12) {
                //HTML
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/html.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                            </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/html.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //HTML
            else if (tipoId === 4) {
                //PDF
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/pdf.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                            </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/pdf.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //PDF
            else if (tipoId === 5) {
                //QUESTIONARIO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/questionario.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/questionario.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //QUESTIONARIO
            else if (tipoId === 6) {
                //AVALIACAO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/avaliacao.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                            {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                        </strong>
                                    </span>
                                )}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/avaliacao.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {aula.dt_inicio && (
                                        <span className="data-hora">
                                            <strong>
                                                {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                                {aula.hora && <> - {aula.hora}</>}
                                            </strong>
                                        </span>
                                    )}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //AVALIACAO
            else if (tipoId === 7) {
                //DOWNLOAD
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/download.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/download.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //DOWNLOAD
            else if (tipoId === 8) {
                //TRABALHO
                const icon = () => {
                    if (aula.tipo_trabalho === "INDIVIDUAL")
                        return (
                            <img
                                className="tarefa-icon"
                                src={require("../../images/icons/trabalho_individual.png")}
                            />
                        );
                    else if (aula.tipo_trabalho === "GRUPO")
                        return (
                            <img
                                className="tarefa-icon"
                                src={require("../../images/icons/trabalho_grupo.png")}
                            />
                        );
                    else
                        return (
                            <img
                                className="tarefa-icon"
                                src={require("../../images/icons/trabalho_colaborativo.png")}
                            />
                        );
                };

                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                {icon()}
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong title="Inicio do Trabalho">{moment(aula.dt_inicio).format("DD/MM/YYYY")}</strong>
                                        {" "}até{" "}
                                        <strong title="Entregar até">{moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                    </span>
                                )}
                                {(aula.dt_fim && !aula.dt_inicio) && (
                                    <span className="data-hora">
                                        <strong title="Entregar até">Até {moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                    </span>
                                )}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    {icon()}
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {aula.dt_inicio && (
                                        <span className="data-hora">
                                            <strong title="Inicio do Trabalho">{moment(aula.dt_inicio).format("DD/MM/YYYY")}</strong>
                                            {" "}a{" "}
                                            <strong title="Entregar até">{moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                        </span>
                                    )}
                                    {(aula.dt_fim && !aula.dt_inicio) && (
                                        <span className="data-hora">
                                            <strong title="Entregar até">{moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                        </span>
                                    )}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //TRABALHO
            else if (tipoId === 9) {
                //LINK
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/link.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/link.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //LINK
            else if (tipoId === 10) {
                //SUMÁRIO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/sumario.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                            {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                        </strong>
                                    </span>
                                )}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/sumario.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {aula.dt_inicio && (
                                        <span className="data-hora">
                                            <strong>
                                                {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                                {aula.hora && <> - {aula.hora}</>}
                                            </strong>
                                        </span>
                                    )}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //SUMÁRIO
            else if (tipoId === 11) {
                //PRESENCIAL
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/sessao-presencial.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.dt_inicio && (
                                    <span className="data-hora" title="Data - Hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                            {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                        </strong>
                                    </span>
                                )}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/sessao-presencial.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {aula.dt_inicio && (
                                        <span className="data-hora" title="Data - Hora">
                                            <strong>
                                                {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                                {aula.hora && <> - {aula.hora}</>}
                                            </strong>
                                        </span>
                                    )}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //PRESENCIAL
            else if (tipoId === 13) {//PASTA
                return (
                    <>
                        <Link to="#" className="tarefa-sem-link" className="link-licao-desktop">
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/pasta.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                            {aula.hora && <> - {aula.hora}</>}
                                        </strong>
                                    </span>
                                )}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                        </Link>
                        <Link to="#" className="tarefa-sem-link" className="link-licao-mobile">
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/pasta.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {aula.dt_inicio && (
                                        <span className="data-hora">
                                            <strong>
                                                {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                                {aula.hora && <> - {aula.hora}</>}
                                            </strong>
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            }//PASTA
            else {
                //QUESTIONARIO DIRIGIDO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/dirigido.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/dirigido.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis ? (
                                    <Dropdown
                                        overlay={this.montarMenuAula(
                                            this.props.modulo.id,
                                            this.props.modulo.competencias,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ) : null}
                            </div>
                        </Link>
                    </>
                );
            } //QUESTIONARIO DIRIGIDO
        };

        const montaAulaLvl2 = (moduloId, aula, index, sessaoPresencial) => {
            var tipoId = aula.tipo_Recurso.id;
            var inicioUrl = `/gerir-curso/${this.props.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${aula.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}-${Math.floor(100000 + Math.random() * 900000)}`;

            if (tipoId === 1) {
                //VIDEO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/video.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/video.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //VIDEO
            else if (tipoId === 2) {
                //AUDIO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/audio.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/audio.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //AUDIO
            else if (tipoId === 3 || tipoId === 12) {
                //HTML
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/html.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/html.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //HTML
            else if (tipoId === 4) {
                //PDF
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/pdf.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/pdf.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //PDF
            else if (tipoId === 5) {
                //QUESTIONARIO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/questionario.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/questionario.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //QUESTIONARIO
            else if (tipoId === 6) {
                //AVALIACAO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/avaliacao.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                            {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                        </strong>
                                    </span>
                                )}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/avaliacao.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {aula.dt_inicio && (
                                        <span className="data-hora">
                                            <strong>
                                                {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                                {aula.hora && <> - {aula.hora}</>}
                                            </strong>
                                        </span>
                                    )}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //AVALIACAO
            else if (tipoId === 7) {
                //DOWNLOAD
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/download.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/download.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //DOWNLOAD
            else if (tipoId === 8) {
                //TRABALHO
                const icon = () => {
                    if (aula.tipo_trabalho === "INDIVIDUAL")
                        return (
                            <img
                                className="tarefa-icon"
                                src={require("../../images/icons/trabalho_individual.png")}
                            />
                        );
                    else if (aula.tipo_trabalho === "GRUPO")
                        return (
                            <img
                                className="tarefa-icon"
                                src={require("../../images/icons/trabalho_grupo.png")}
                            />
                        );
                    else
                        return (
                            <img
                                className="tarefa-icon"
                                src={require("../../images/icons/trabalho_colaborativo.png")}
                            />
                        );
                };

                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                {icon()}
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong title="Inicio do Trabalho">{moment(aula.dt_inicio).format("DD/MM/YYYY")}</strong>
                                        {" "}até{" "}
                                        <strong title="Entregar até">{moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                    </span>
                                )}
                                {(aula.dt_fim && !aula.dt_inicio) && (
                                    <span className="data-hora">
                                        <strong title="Entregar até">Até {moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                    </span>
                                )}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    {icon()}
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {aula.dt_inicio && (
                                        <span className="data-hora">
                                            <strong title="Inicio do Trabalho">{moment(aula.dt_inicio).format("DD/MM/YYYY")}</strong>
                                            {" "}até{" "}
                                            <strong title="Entregar até">{moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                        </span>
                                    )}
                                    {(aula.dt_fim && !aula.dt_inicio) && (
                                        <span className="data-hora">
                                            <strong title="Entregar até">Até {moment(aula.dt_fim).format("DD/MM/YYYY")}</strong>
                                        </span>
                                    )}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //TRABALHO
            else if (tipoId === 9) {
                //LINK
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/link.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/link.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //LINK
            else if (tipoId === 10) {
                //SUMÁRIO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/sumario.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {!sessaoPresencial ?
                                    aula.dt_inicio && (
                                        <span className="data-hora">
                                            <strong>
                                                {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                                {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                            </strong>
                                        </span>
                                    ) : null}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null && !sessaoPresencial ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/sumario.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {aula.dt_inicio && (
                                        <span className="data-hora">
                                            <strong>
                                                {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                                {aula.hora && <> - {aula.hora}</>}
                                            </strong>
                                        </span>
                                    )}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //SUMÁRIO
            else if (tipoId === 11) {
                //PRESENCIAL
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/sessao-presencial.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {aula.dt_inicio && (
                                    <span className="data-hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                            {aula.hora && <> <i className="fas fa-circle separador-data-hora"></i> {aula.hora} - {montarHoraTermino(moment(aula.hora, "HH:mm"), moment(aula.duracao, "HH:mm"))}</>}
                                        </strong>
                                    </span>
                                )}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/sessao-presencial.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {aula.dt_inicio && (
                                        <span className="data-hora">
                                            <strong>
                                                {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                                {aula.hora && <> - {aula.hora}</>}
                                            </strong>
                                        </span>
                                    )}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //PRESENCIAL
            else if (tipoId === 14) {//QUESTIONARIO PROGRESSO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/progresso.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/progresso.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //QUESTIONARIO PROGRESSO
            else if (tipoId === 15) {
                //QUESTIONARIO DIRIGIDO
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-desktop" onClick={() => this.onClink(aula.id)}>
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/dirigido.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} className="link-licao-mobile" onClick={() => this.onClink(aula.id)}>
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/dirigido.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            } //QUESTIONARIO DIRIGIDO
            else {
                //PRESENÇAS
                return (
                    <>
                        <Link to={`${inicioUrl}${aula.tipo_Recurso.url}`} onClick={() => this.onClink(aula.id)} className="link-licao-desktop">
                            <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                <img
                                    className="tarefa-icon"
                                    src={require("../../images/icons/presencas.png")}
                                />
                            </span>
                            <span className="licao">
                                {aula.nome}
                                {/*<span className="data-hora">
                                    <strong>
                                        {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                    </strong>
                                    {(aula.hora && !aula.hora_fim) && <> - <strong>{aula.hora}</strong></>}
                                    {(!aula.hora && aula.hora_fim) && <> - <strong>{aula.hora_fim}</strong></>}
                                    {(aula.hora && aula.hora_fim) && <> - <strong>{aula.hora}</strong> a <strong>{aula.hora_fim}</strong></>}
                                </span>*/}
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </span>
                            {/*<span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                            <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                <i className="fas fa-star" />
                                {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                        </span>*/}
                        </Link>
                        <Link to="#" onClick={() => this.props.carregarPresencas(aula.id)} className="link-licao-mobile">
                            <div className="licao-bloco-esquerda">
                                <span className="start" title={aula.tipo_Recurso.nome} style={{ width: 30, height: 30 }}>
                                    <img
                                        className="tarefa-icon"
                                        src={require("../../images/icons/presencas.png")}
                                    />
                                </span>
                                <span className="licao">
                                    {aula.nome}
                                    {/*<span className="data-hora">
                                        <strong>
                                            {moment(aula.dt_inicio).format("DD/MM/YYYY")}
                                        </strong>
                                        {(aula.hora && !aula.hora_fim) && <> - <strong>{aula.hora}</strong></>}
                                        {(!aula.hora && aula.hora_fim) && <> - <strong>{aula.hora_fim}</strong></>}
                                        {(aula.hora && aula.hora_fim) && <> - <strong>{aula.hora}</strong> a <strong>{aula.hora_fim}</strong></>}
                                    </span>*/}
                                    {/*<div className="licao-bloco-info">
                                        <span className={`rating ${aula.totalAvaliacoes === 0 ? "sem-rating" : ""}`}>
                                            <i className="fas fa-star" />
                                            {aula.mediaAvaliacoes} ({aula.totalAvaliacoes})
                                        </span>
                                        <span className="duracao">{aula.duracao != null ? this.formatarHoras(aula.duracao) : ""}</span>
                                    </div>*/}
                                </span>
                            </div>
                            <div className="licao-bloco-direita">
                                {this.props.botoesVisiveis && (
                                    <Dropdown
                                        overlay={this.montarMenuAulalvl2(
                                            moduloId,
                                            this.props.modulo.competencias,
                                            index,
                                            aula
                                        )}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs-tarefa">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )}
                            </div>
                        </Link>
                    </>
                );
            }//PRESENÇAS
        };

        return (
            <>
                <Collapse
                    defaultActiveKey={[localStorage.getItem("modulo_aberto")]}
                    bordered={false}
                    //onChange={this.alertar}
                    onChange={this.onChangeCollapse}
                    key={this.props.index}
                >
                    <Panel
                        header={tituloModulo}
                        key={this.props.index}
                        className={!this.props.modulo.ativo ? "modulo ativo" : "modulo"}
                        disabled={this.props.disabled}
                    >
                        {this.props.modulo.pagina_unica && !this.props.player ? (
                            this.state.collapsed.find(x => x === this.props.index + "") && (
                                <div
                                    ref={this.elementFullScreen}
                                    style={this.state.fullscreen ? { padding: 40, background: "white" } : { background: "white" }}
                                >
                                    <Icon
                                        type={this.state.fullscreen ? "fullscreen-exit" : "fullscreen"}
                                        onClick={this.onClickFullscreenElement}
                                        className="btn-fullscreen"
                                        style={{ padding: "0 0 20px 0" }}
                                    />
                                    <div className="bloco-pagina-unica" dangerouslySetInnerHTML={{ __html: this.props.modulo.modulo_Aula[0].recurso }}></div>
                                </div>
                            )
                        ) : (
                                <ul>
                                    {this.props.modulo.modulo_Aula.map((aula, index) => (
                                        <li key={index}>
                                            <div className={aula.ativo ? "nivel1" : "nivel1 inativo"}>
                                                {montaAula(this.props.modulo.id, aula)}
                                            </div>
                                            <div className="nivel2">
                                                <ul>
                                                    {aula.modulo_Aula_lvl2.map((aulalvl2, index2) => (
                                                        <li className={!aulalvl2.ativo ? "inativo" : ""} key={index2}>
                                                            {montaAulaLvl2(this.props.modulo.id, aulalvl2, index, aula.tipo_Recurso.id === 11)}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                    </Panel>
                </Collapse>
                <Modal visible={this.state.visibleCopiar} maskClosable={false} className="exportar-csv" closable={false} footer={null}>
                    <div className="exportar-csv-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A copiar...</p>
                    </div>
                </Modal>
                <Modal
                    title={<>Participantes<br /><span style={{ fontSize: 13, color: "#8e9aa0" }}>Total: {participantes.length}</span></>}
                    visible={visibleParticipantes}
                    onCancel={() => this.setState({ visibleParticipantes: false })}
                    maskClosable={false}
                    className="modal-lista-participantes"
                    footer={null}
                >
                    <div className="bloco-lista-participantes">
                        {carregarLista ? (
                            <div className="bloco-loading">
                                <p>
                                    <Icon className="icon-loading" type="loading" />
                                </p>
                                <p className="texto">A carregar...</p>
                            </div>
                        ) : (
                                <>
                                    {participantes.map((participante, index) => (
                                        <div key={index} className="info-participante">
                                            <div className="bloco-img">
                                                <img src={participante.foto ? participante.foto : noimage} />
                                            </div>
                                            <div className="info">
                                                <span className="nome">
                                                    {participante.nome}
                                                </span>
                                                <p className="numero">{participante.numero}</p>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                    </div>
                </Modal>
            </>
        );
    }
}

export default Modulo;
