import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../GlobalState";

class AdicionarCarrinho extends Component {
    static contextType = GlobalContext;

    adicionarProduto = () => {
        var carrinho = [...this.context.carrinho, this.props.produto];

        this.context.atualizarState({
            carrinho
        })

        document.getElementsByClassName("carrinho-compras")[0].click()
    }

    validar = () => {
        return this.context.carrinho.find(x => x.id === this.props.produto.id || x.tipo === "SUBSCRICAO")
    }

    render() {
        return (<button className="botao-principal" onClick={this.adicionarProduto}>
            <i className="fas fa-plus" style={{ marginRight: 5 }}></i>
            <i className="fas fa-shopping-cart" style={{ marginRight: 5 }}></i>
            <span dangerouslySetInnerHTML={{ __html: `${this.context.moeda_entidade} ${this.props.produto.preco}` }}></span>
        </button>);
    }
}

export default AdicionarCarrinho;
