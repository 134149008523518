import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { notification, Menu, Dropdown, Modal, Icon } from "antd";
import Anotacao from "../_Aux/Anotacao";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import axios from "axios";
import { GeralContext } from "../ContextProvider";
import { GlobalContext } from "../../GlobalState";
import DrawerNovaAnotacao from "./DrawerNovaAnotacao";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Anotacoes extends Component {
    static contextType = GeralContext;
    state = {
        curso: "",
        cursoid: 0,
        retomar: {},
        anotacoes_curso: [],
        anotacoes_curso_modulo: [],
        abrir_detalhe: false,
        anotacaoid: 0,
        periodo: "",
        texto: "",
        anexo: "",
        total_conteudos: 0,
        total_conteudos_realizados: 0,
        loading: true,
        //DRAWER
        visible: false,
        anotacaoId: 0,
        descricao: "",
        fileList: [],
        redirect: false,
        status: "",
        helper: "",
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        update: false,
        //EDITOR
        paginaHTML: false,
        setHTML: false,
        resetEditor: false,
        //REDIRECT
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoid: cursoId,
            curso: this.props.match.params.curso
        });
        this.CarregarAnotacoesCurso(cursoId);
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeEditor = descricao => {
        this.setState({
            descricao
        });
    };

    CarregarAnotacoesCurso = cursoid => {
        axios({
            method: "get",
            url: "/api/anotacoes/anotacoes-curso",
            params: {
                cursoid: cursoid
            }
        })
            .then(response => {
                this.setState({
                    anotacoes_curso: response.data,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    resetCaixaProcura = () => {
        this.CarregarAnotacoesCurso(this.state.cursoid);
    };

    excluirAnotacao = anotacaoId => {
        confirm({
            title: "Pretende excluir esta anotação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/anotacoes/excluir-anotacao",
                    params: {
                        anotacaoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Anotação excluida!");
                        this.context.atualizarAnotacoes(this.context.total_anotacoes - 1);

                        this.CarregarAnotacoesCurso(this.state.cursoid);
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a anotação!");
                    });
            }
        });
    };

    //DRAWER
    carregarAnotacao = anotacaoId => {
        axios({
            method: "get",
            url: "/api/anotacoes/carregar-anotacao",
            params: {
                anotacaoId
            }
        })
            .then(response => {
                var fileList = [];

                if (response.data.anexo)
                    fileList.push({
                        uid: "1",
                        name: "Documentos." + response.data.anexo.split(".").pop(),
                        status: "done",
                        linkProps: '{"download": ""}',
                        url: response.data.anexo
                    });

                this.setState({
                    anotacaoId,
                    descricao: response.data.texto,
                    visible: true,
                    paginaHTML: true,
                    setHTML: true,
                    fileList,
                    update: true,
                    anexo: response.data.anexo
                });
            })
            .catch(() => {});
    };

    stopUpdate = () => {
        this.setState({
            update: false
        });
    };

    onOpen = () => {
        this.setState({
            anotacaoId: 0,
            texto: "",
            anexo: "",
            descricao: "",
            fileList: [],
            status: "",
            helper: "",
            visible: true,
            //EDITOR
            resetEditor: true,
            update: true
        });
    };

    onClose = () => {
        this.setState({
            visible: false
        });
    };

    carregaHTML = () => {
        this.setState({
            setHTML: false
        });
    };

    resetStateEditor = () => {
        this.setState({
            resetEditor: false
        });
    };

    downloadFicheiro = (index, nome, anotacaoId) => {
        axios({
            method: "post",
            url: "/api/anotacoes/download-ficheiro",
            params: {
                anotacaoId,
                index
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data;
                link.setAttribute(
                    'download',
                    nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    montarMenuAnexos = (ficheiros, anotacaoId) => {
        var montar = JSON.parse(ficheiros).map((ficheiro, index) => (
            <Menu.Item>
                <Link to="#" onClick={() => this.downloadFicheiro(index, ficheiro.Nome, anotacaoId)}>
                    {ficheiro.Nome}
                </Link>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    render() {
        const {
            curso,
            cursoid,
            anotacoes_curso,
            anotacoes_curso_modulo,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <GlobalContext.Consumer>
                {globalContext => {
                    return (
                        <div className="container container-body">
                            {this.state.loading ? (
                                <div className="loading-data">
                                    <div className="loading" />
                                </div>
                            ) : (
                                <div className="curso-anotacoes">
                                    <div className="anotacoes">
                                        <div className="anotacao-header-btn">
                                            <div className="info">
                                                <div className="lado-direito">
                                                        {(!this.context.inscrito &&
                                                            globalContext.role == "UTILIZADOR" &&
                                                            this.context.inscricao) ||
                                                            this.context.fechado ?
                                                            <Link
                                                                to="#"
                                                                className="botao-principal botao-disabled"
                                                                disabled
                                                            >
                                                                Nova anotação
                                                            </Link>
                                                            :
                                                            <Link
                                                                to="#"
                                                                className="botao-principal"
                                                                onClick={this.onOpen}
                                                            >
                                                                Nova anotação
                                                            </Link>
                                                        }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="anotacao anotacao-topo">
                                            <div className="anotacoes-listagem">
                                                {anotacoes_curso.length > 0 ? (
                                                    <div className="anotacao-content">
                                                        <div className="info-anotacao">
                                                            <ul>
                                                                {anotacoes_curso.map((anotacao, index) => (
                                                                    <li key={index}>
                                                                        <div className="bloco-anotacao">
                                                                            <div className="bloco-anotacao-body">
                                                                                <div className="bloco-anotacao-esquerda">
                                                                                    <Link
                                                                                        to={`/curso/${curso
                                                                                            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                                                                            .toLowerCase()}/curso-anotacoes-detalhe`}
                                                                                        className="texto"
                                                                                        onClick={() => {
                                                                                            localStorage.removeItem("info");
                                                                                            localStorage.setItem("codigo_anotacao", anotacao.id);
                                                                                        }}
                                                                                        dangerouslySetInnerHTML={{ __html: anotacao.texto.replace(/(?:\r\n|\r|\n)/g, "<br>") }}
                                                                                    >
                                                                                    </Link>
                                                                                </div>
                                                                                <div className="bloco-anotacao-direita">
                                                                                    <span className="anotacao-data-hora">{anotacao.data}</span>
                                                                                </div>                                                                            </div>
                                                                            <div className="bloco-anotacao-footer" style={{ justifyContent: "end" }}>
                                                                                <div className="bloco-anotacao-direita">
                                                                                    {JSON.parse(anotacao.anexo).length ?
                                                                                        <Dropdown
                                                                                            overlay={this.montarMenuAnexos(anotacao.anexo, anotacao.id)}
                                                                                            placement="bottomLeft"
                                                                                        >
                                                                                            <button className="botao-icon-clip" title="Anexos">
                                                                                                <Icon type="paper-clip" />
                                                                                            </button>
                                                                                        </Dropdown> : null}
                                                                                    <Link
                                                                                        to="#"
                                                                                        className="botao-icon-excluir"
                                                                                        title="Excluir"
                                                                                        onClick={() => this.excluirAnotacao(anotacao.id)}
                                                                                    >
                                                                                        <Icon type="delete" />
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <DrawerNovaAnotacao
                                {...this.state}
                                handleChange={this.handleChange}
                                handleChangeCheckboxFormadores={this.handleChangeCheckboxFormadores}
                                handleChangeCheckboxAlunos={this.handleChangeCheckboxAlunos}
                                handleChangeEditor={this.handleChangeEditor}
                                CarregarAnotacoesCurso={this.CarregarAnotacoesCurso}
                                onClose={this.onClose}
                                carregaHTML={this.carregaHTML}
                                resetStateEditor={this.resetStateEditor}
                                stopUpdate={this.stopUpdate}
                            />
                        </div>
                    );
                }}
            </GlobalContext.Consumer>
        );
    }
}

export default Anotacoes;
