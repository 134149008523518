import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Form, Input, Upload, notification, Icon, Modal } from "antd";
import Header from "../User/Header";
import { GlobalContext } from "../GlobalState";
import ControlosSimples from "./_Aux/ControlosSimples";
import LazyLoad from "react-lazy-load";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class JitsiMeetingAguardar extends Component {
    static contextType = GlobalContext;
    state = {
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //VERIFICA SE O MODERADOR JA ESTÁ NA CHAMADA. CASO SIM DEIXA ENTRAR
    verificar = () => {
        axios({
            method: "get",
            url: "/api/videoconferencia/videoconferencia-aguardar",
            params: {
                vconfid: this.props.match.params.vconfid
            }
        })
            .then(response => {
                if (response.data.iniciada) {
                    if (response.data.gravarSessao) {
                        confirm({
                            title: "Esta sessão está a ser gravada!",
                            okText: "Continuar",
                            okType: "Cancelar",
                            onOk: () => {
                                this.setState({
                                    redirectLink: `/curso/${this.props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/vconf/${this.props.match.params.vconfid}/player-videoconferencia`
                                }, () => this.registarEntrada())
                            }
                        });
                    }
                    else {
                        this.setState({
                            redirectLink: `/curso/${this.props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/vconf/${this.props.match.params.vconfid}/player-videoconferencia`
                        }, () => this.registarEntrada())
                    }
                }
                else
                    openNotificationWithIcon("warning", "Atenção", "Moderador ainda não iniciou a sessão! Aguarde um pouco.");
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Ocorreu um erro!");
                this.setState({ iconLoading: false });
            });
    };

    registarEntrada = () => {
        axios({
            method: "post",
            url: "/api/videoconferencia/add-participant-entry",
            params: {
                meetingID: this.props.match.params.vconfid,
                TempId: "",
                tipoEvento: 0
            }
        })
            .then(response => {
                //localStorage.setItem("participanteNaMeet", true)
                this.setState({
                    redirect: true
                })
            })
            .catch(() => { });
    }

    render() {
        const {
            redirect,
            redirectLink,
        } = this.state;

        if (redirect)
            return <Redirect to={redirectLink} />;

        return (
            <div className="questionario">
                <div className="player-wrapper">
                    <LazyLoad offsetVertical={200}>
                        <img src={require("../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />
                    </LazyLoad>
                    <div className="container">
                        <div className="bloco-presencial">
                            <div className="bloco-download-link">
                                <div className="bloco-info-tarefa">
                                    <span className="titulo-tarefa">Aguarde a entrada do moderador</span>
                                </div>
                                <Link className="botao-principal" to="#" onClick={this.verificar}>
                                    Tentar entrar
                                </Link>
                            </div>
                        </div>
                    </div>
                    <ControlosSimples
                        backUrl={`/curso/${this.props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-videoconferencias`}
                    />
                </div>
            </div>
        );
    }
}
export default withRouter(JitsiMeetingAguardar);
