import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, Dropdown, Menu, Modal, Select, Spin, Radio, Tooltip, Switch } from "antd";
import Navbar from "../User/Navbar";
import Header from "../User/Header";
import DrawerHTML from "../GerirCursos/_Aux/FormPaginaHTML2";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../GlobalState";

const RadioGroup = Radio.Group;
const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

//OK
class Newsletters extends Component {
    static contextType = GlobalContext;
    state = {
        //TABELA
        newsletters: [],
        newslettersMobile: [],
        ativo: true,
        //DETALHE
        newsletterid: 0,
        nome: "",
        assunto: "",
        texto: "",
        iconLoading: false,
        visible: false,
        editar: false,
        //ENVIAR
        tipo_enviar: 1,
        categoriaId: 0,
        listaCategorias: [],
        categoria: 0,
        visibleEnviar: false,
        cursoId: 0,
        listaCursos: [],
        //EDITOR
        setHTML: false,
        resetEditor: false,
        paginaHTML: false,
        //LOADING
        loading_table: false,
        //DRAWER NEWSLETTER
        visibleNewsletter: false,
        newsletterData: "",
        newsletterAssunto: "",
        newsletterTexto: "",
        //EDITOR
        show: false,
        //DRAWER HTML
        visibleHTML: false,
        //DRAWER DETALHE
        visibleDetalhe: false,
        detalheDataCriada: "",
        //DRAWER DETALHE LVL2
        visibleDetalheLvl2: false,
        detalheDataEnviada: "",
        detalheDestinatario: "",
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    componentDidUpdate() {
        if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
            this.setState({
                redirect: true,
                redirectLink: "/user"
            });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSelectCategoria = value => {
        this.setState({ categoria: value });
    };

    handleChangeRadio = event => {
        this.setState({ tipo_enviar: event.target.value });
    };

    handleChangeEditor = (value, index) => {
        this.setState({
            texto: value
        });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //CARREGA TODAS AS CATEGORIAS DA EMPRESA
    listarCategorias = () => {
        axios({
            method: "get",
            url: "/api/gestao-newsletters/listar-categorias"
        })
            .then(response => {
                this.setState({
                    categorias: response.data
                });
            })
            .catch(() => {

            });
    };

    //CARREGA TODAS AS NEWSLETTERS
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-newsletters",
                    params: {
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var newsletters = [];
                        var newslettersMobile = [];

                        response.data.map((newsletter, index) => {
                            var enviados = [];
                            var enviadosMobile = [];
                            newsletter.newslettersEnvios.map((envio, index) => {
                                enviados.push({
                                    key: index,
                                    assunto: (
                                        <div>
                                            <span>{envio.conteudo}</span>
                                        </div>
                                    ),
                                    data: moment(envio.dt_criado).format("DD/MM/YYYY HH:mm"),
                                    destinatarios: this.devolveTipo(envio.tipo, envio.quantidade, envio.opcao),
                                    opcoes: (
                                        <Dropdown overlay={this.montarMenuLvl2(envio.id)} placement="bottomLeft">
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    )
                                });
                                enviadosMobile.push({
                                    key: index,
                                    assunto: envio.conteudo,
                                    opcoes: (
                                        <Dropdown overlay={this.montarMenuLvl2Mobile(envio)} placement="bottomLeft">
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    )
                                });
                            });

                            newsletters.push({
                                key: index,
                                id: newsletter.id,
                                nome: newsletter.nome,
                                dt_criado: moment(newsletter.dt_criado).format("DD/MM/YYYY"),
                                opcoes: (
                                    <Dropdown overlay={this.montarMenu(newsletter.id)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ),
                                enviados
                            });

                            newslettersMobile.push({
                                key: index,
                                id: newsletter.id,
                                nome: newsletter.nome,
                                dt_criado: moment(newsletter.dt_criado).format("DD/MM/YYYY"),
                                opcoes: (
                                    <Dropdown overlay={this.montarMenuMobile(newsletter)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ),
                                enviadosMobile
                            });
                        });

                        this.setState({
                            newsletters,
                            newslettersMobile,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //DEVOLVE QUAL O TIPO DE NEWSLETTER ENVIADA
    devolveTipo = (tipo, quantidade, opcao) => {
        switch (tipo) {
            case 1:
                return (
                    <div>
                        <span>
                            Todos ({quantidade}){" "}
                            <Tooltip className="info-icon" title="Todos os utilizadores">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                        <span style={{ display: "block", color: "#009082", fontSize: "12px" }}>{opcao}</span>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <span>
                            Uma categoria ({quantidade}){" "}
                            <Tooltip className="info-icon" title="Todos de uma categoria nos seus favoritos">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                        <span style={{ display: "block", color: "#009082", fontSize: "12px" }}>{opcao}</span>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <span>
                            Um Curso ({quantidade}){" "}
                            <Tooltip className="info-icon" title="Todos os formandos a frequentar um dado curso">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                        <span style={{ display: "block", color: "#009082", fontSize: "12px" }}>{opcao}</span>
                    </div>
                );
        }
    };

    //CARREGA O DETALHE DA NEWSLETTER
    detalhe = newsletterid => {
        axios({
            method: "get",
            url: "/api/gestao-newsletters/detalhe",
            params: {
                newsletterid: newsletterid
            }
        })
            .then(response => {
                var conteudo = JSON.parse(response.data.conteudo);

                this.setState({
                    newsletterid: response.data.id,
                    nome: response.data.nome,
                    assunto: conteudo.assunto,
                    texto: conteudo.texto,
                    visible: true,
                    editar: true,
                    paginaHTML: true,
                    setHTML: true
                });
            })
            .catch(() => {

            });
    };

    //CRIA NOVA NEWSLETTER
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                var conteudo = {
                    assunto: this.state.assunto,
                    texto: this.state.texto
                };

                var item = new FormData();
                item.append("nome", this.state.nome);
                item.append("conteudo", JSON.stringify(conteudo));

                axios({
                    method: "post",
                    url: "/api/gestao-newsletters/add",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Newsletter adicionada com sucesso!");
                        this.setState({
                            visible: false,
                            iconLoading: false
                        });
                        this.listar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar a newsletter.");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //ATUALIZA NEWSLETTER
    guardar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                var conteudo = {
                    assunto: this.state.assunto,
                    texto: this.state.texto
                };

                var item = new FormData();
                item.append("id", this.state.newsletterid);
                item.append("nome", this.state.nome);
                item.append("conteudo", JSON.stringify(conteudo));

                axios({
                    method: "post",
                    url: "/api/gestao-newsletters/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Newsletter editada com sucesso!");
                        this.setState({
                            visible: false,
                            iconLoading: false
                        });
                        this.listar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível editar a newsletter.");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //MONTA OPÇÕES DE MENU POR NEWSLETTER
    montarMenu = newsletterid => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.detalhe(newsletterid)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluir(newsletterid)}>
                        Excluir
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ newsletterid: newsletterid, visibleEnviar: true })}>
                        Enviar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.ativar(newsletterid)}>
                        {this.state.ativo ? "Inativar" : "Ativar"}
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuMobile = newsletter => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalhe(newsletter)}>
                        Detalhe
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.detalhe(newsletter.id)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluir(newsletter.id)}>
                        Excluir
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ newsletterid: newsletter.id, visibleEnviar: true })}>
                        Enviar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.ativar(newsletter.id)}>
                        {this.state.ativo ? "Inativar" : "Ativar"}
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //ATIVA E INATIVAR ENTIDADE
    ativar = newsletterid => {
        confirm({
            title: this.state.ativo ? "Pretende inativar esta newsletter?" : "Pretende ativar esta newsletter?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-newsletters/ativar",
                    params: {
                        newsletterid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", this.state.ativo ? "Newsletter inativada!" : "Newsletter ativada!");
                        var newsletters = this.state.newsletters.filter(x => x.id != newsletterid);
                        this.setState({
                            newsletters
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", this.state.ativo ? "Não foi possível inativar a newsletter!" : "Não foi possível ativar a newsletter!"
                        );
                    });
            }
        });
    };

    //EXCLUIR NEWSLETTER
    excluir = newsletterid => {
        confirm({
            title: "Pretende excluir esta newsletter?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-newsletters/excluir",
                    params: {
                        newsletterid: newsletterid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Newsletter excluida!");
                        var newsletters = this.state.newsletters.filter(x => x.id != newsletterid);
                        this.setState({
                            newsletters
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir esta newsletter!");
                    });
            }
        });
    };

    //PESQUISA POR NOME DA CATEGORIA PARA ENVIO DE NEWSLETTER
    procurarCategorias = value => {
        if (value.length === 4) {
            this.setState({
                listaCategorias: [],
                procurarCategorias: true
            });
            axios({
                method: "get",
                url: "/api/gestao-newsletters/procurar-categorias",
                params: {
                    nome: value
                }
            })
                .then(response => {
                    this.setState({
                        listaCategorias: response.data,
                        listaCategoriasBackUp: response.data,
                        procurarCategorias: false
                    });
                })
                .catch(() => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar a categoria!");
                });
        } else if (value.length > 4) {
            if (this.state.listaCategoriasBackUp) {
                var _listaCategorias = this.state.listaCategoriasBackUp;

                this.setState({
                    listaCategorias: _listaCategorias.filter(x => x.nome.toLowerCase().includes(value.toLowerCase()))
                });
            }
        } else {
            this.setState({
                listaCategorias: []
            });
        }
    };

    //SELECIONA O CURSO OU CURSOS
    checkCategorias = value => {
        this.setState({
            categoriaId: value.key
        });
    };

    //PESQUISA POR NOME DO CURSO PARA ENVIO DE NEWSLETTER
    procurarCursos = value => {
        if (value.length === 4) {
            this.setState({
                listaCursos: [],
                procurar: true
            });
            axios({
                method: "get",
                url: "/api/gestao-newsletters/procurar-curso",
                params: {
                    nome: value
                }
            })
                .then(response => {
                    this.setState({
                        listaCursos: response.data,
                        listaCursosBackUp: response.data,
                        procurar: false
                    });
                })
                .catch(() => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível pesquisar o curso!");
                });
        } else if (value.length > 4) {
            if (this.state.listaCursosBackUp) {
                var _listaCursos = this.state.listaCursosBackUp;

                this.setState({
                    listaCursos: _listaCursos.filter(x => x.nome.toLowerCase().includes(value.toLowerCase()))
                });
            }
        } else {
            this.setState({
                listaCursos: []
            });
        }
    };

    //SELECIONA O CURSO OU CURSOS
    checkCursos = value => {
        this.setState({
            cursoId: value.key
        });
    };

    //ENVIA A NEWSLETTER
    enviar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            this.setState({ iconLoading: true });

            axios({
                method: "post",
                url: "/api/gestao-newsletters/enviar",
                params: {
                    newsletterid: this.state.newsletterid,
                    tipo: this.state.tipo_enviar,
                    categoriaId: this.state.categoriaId,
                    cursoId: this.state.cursoId
                }
            })
                .then(response => {
                    if (this.state.tipo_enviar < 4)
                        openNotificationWithIcon(
                            "success",
                            "Sucesso",
                            "Newsletter enviada com sucesso para " + response.data + " utilizadores!"
                        );
                    else openNotificationWithIcon("success", "Sucesso", "Newsletter enviada com sucesso!");

                    this.props.form.resetFields();
                    this.setState(
                        {
                            tipo_enviar: 1,
                            listaCategorias: [],
                            listaCursos: [],
                            visibleEnviar: false,
                            iconLoading: false
                        },
                        () => {
                            this.listar();
                        }
                    );
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                    this.setState({ iconLoading: false });
                });
        });
    };

    //ABRE O DRAWER PARA CRIAR NOVA NEWSLETTER
    showDrawerAdd = () => {
        this.setState({
            nome: "",
            assunto: "",
            texto: "",
            visible: true,
            editar: false,
            paginaHTML: true,
            setHTML: true
        });
    };

    //FECHA O DRAWER SEM GAURDAR A NEWSLETTER
    onCloseAdd = () => {
        this.setState({
            visible: false,
            editar: false
        });
    };

    //FECHA O DRAWER DE ENVIO DE NEWSLETTER
    onCloseEnviar = () => {
        this.setState({
            visibleEnviar: false
        });
    };

    //MONTA OPÇÕES DE MENU POR NEWSLETTER ENVIADA
    montarMenuLvl2 = newsletterid => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarNewsletter(newsletterid)}>
                        Visualizar
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuLvl2Mobile = newsletter => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheLvl2(newsletter)}>
                        Detalhe
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarNewsletter(newsletter.id)}>
                        Visualizar
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //FECHA O DRAWER NEWSLETTER
    carregarNewsletter = newsletterid => {
        axios({
            method: "get",
            url: "/api/gestao-newsletters/detalhe-enviadas",
            params: {
                newsletterid
            }
        })
            .then(response => {
                var conteudo = JSON.parse(response.data.conteudo);
                this.setState({
                    newsletterData: moment(response.data.dt_criado).format("DD/MM/YYYY HH:mm"),
                    newsletterAssunto: conteudo.assunto,
                    newsletterTexto: conteudo.texto,
                    visibleNewsletter: true
                });
            })
            .catch(() => {

            });
    };

    //FECHA O DRAWER NEWSLETTER
    fecharNewsletter = () => {
        this.setState({
            visibleNewsletter: false
        });
    };

    afterVisibleChange = visible => {
        if (visible) this.props.form.resetFields();

        this.setState({ show: visible });
    };

    onCloseDrawerHTML = () => {
        this.props.form.resetFields();
        this.setState(
            {
                texto: localStorage.getItem("editor_conteudo") ? localStorage.getItem("editor_conteudo") : "",
                visibleHTML: false
            },
            () => localStorage.removeItem("editor_conteudo")
        );
    };

    abrirDetalhe = newsletter => {
        const detalheDataCriada = moment(newsletter.dt_criado).format("DD/MM/YYYY")

        this.setState({
            visibleDetalhe: true,
            detalheDataCriada
        })
    }

    abrirDetalheLvl2 = envio => {
        const detalheDataEnviada = moment(envio.dt_criado).format("DD/MM/YYYY HH:mm");
        const detalheDestinatario = this.devolveTipo(envio.tipo, envio.quantidade, envio.opcao);

        this.setState({
            visibleDetalheLvl2: true,
            detalheDataEnviada,
            detalheDestinatario
        })
    }

    render() {
        const {
            newsletters,
            newslettersMobile,
            nome,
            assunto,
            texto,
            iconLoading,
            visible,
            editar,
            //ENVIAR
            tipo_enviar,
            listaCategorias,
            listaCursos,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER NEWSLETTER
            visibleNewsletter,
            newsletterData,
            newsletterAssunto,
            newsletterTexto,
            //DRAWER HTML
            visibleHTML,
            //DRAWER DETALHE
            visibleDetalhe,
            detalheDataCriada,
            //DRAWER DETALHE LVL2
            visibleDetalheLvl2,
            detalheDataEnviada,
            detalheDestinatario,
        } = this.state;

        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const columns = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td2"
            },
            {
                title: "Criada a",
                dataIndex: "dt_criado"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        const columnsMobile = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td2"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        const radioStyle = {
            display: "block",
            height: "30px",
            lineHeight: "30px"
        };

        const expandedRowRender = dados => {
            const columns = [
                {
                    title: "Assunto",
                    dataIndex: "assunto",
                    key: "assunto"
                },
                {
                    title: "Enviada a",
                    dataIndex: "data",
                    key: "data"
                },
                {
                    title: "Destinatários",
                    dataIndex: "destinatarios",
                    key: "destinatarios"
                },
                {
                    title: "",
                    dataIndex: "opcoes",
                    key: "opcoes"
                }
            ];

            return (
                <Table columns={columns} dataSource={dados.enviados} pagination={false} locale={{ emptyText: "Não existem registos!" }} />
            );
        };

        const expandedRowRenderMobile = dados => {
            const columns = [
                {
                    title: "Assunto",
                    dataIndex: "assunto",
                    key: "assunto",
                    className: "td2"
                },
                {
                    title: "",
                    dataIndex: "opcoes",
                    key: "opcoes"
                }
            ];

            return (
                <Table columns={columns} dataSource={dados.enviadosMobile} pagination={false} locale={{ emptyText: "Não existem registos!" }} />
            );
        };

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Newsletters" />
                <div className="container container-body">
                    <div className="bloco-formadores bloco-gestao-newsletters">
                        <div className="bloco-tabela">
                            <div className="filtros">
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />
                            </div>
                            <Table
                                id="tabela_newsletters"
                                className="tabela-newsletters-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={newsletters}
                                expandedRowRender={expandedRowRender}
                                pagination={false}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Table
                                id="tabela_newsletters"
                                className="tabela-newsletters-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsMobile}
                                dataSource={newslettersMobile}
                                expandedRowRender={expandedRowRenderMobile}
                                pagination={false}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="opcoes-meus-cursos">
                    <Link to="#" className="btn-adicionar" title="Adicionar" onClick={this.showDrawerAdd}>
                        +
                    </Link>
                </div>
                <Drawer
                    className="drawer-add-cursos"
                    title={editar ? "Alterar newsletter" : "Adicionar nova newsletter"}
                    width={720}
                    onClose={this.onCloseAdd}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label={<span>Nome</span>}>
                                {getFieldDecorator("nome", {
                                    initialValue: nome,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="nome" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label={<span>Assunto</span>}>
                                {getFieldDecorator("assunto", {
                                    initialValue: assunto
                                })(<Input name="assunto" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator("texto", {
                                    rules: [
                                        {
                                            required: texto === "",
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: texto
                                })(
                                    <>
                                        <h3 className="titulo-separador">Editor Web</h3>
                                        {texto ? (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                style={{ paddingLeft: 0 }}
                                                onClick={() => this.setState({ visibleHTML: true })}
                                            >
                                                Editar
                                            </Link>
                                        ) : (
                                            <Link
                                                to="#"
                                                className="link-principal"
                                                style={{ paddingLeft: 0 }}
                                                onClick={() => this.setState({ visibleHTML: true })}
                                            >
                                                Adicionar
                                            </Link>
                                        )}
                                    </>
                                )}
                            </Form.Item>
                            {/*<Form.Item label={<span>Texto</span>}>{show && <Editor value={texto} onChange={this.handleChangeEditor} height="500px" />}</Form.Item>*/}
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onCloseAdd} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {editar ? (
                            <button className="botao-principal" onClick={this.guardar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                            <button className="botao-principal" onClick={this.criar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                            </button>
                        )}
                    </div>
                </Drawer>
                <DrawerHTML visible={visibleHTML} recurso={texto} onClose={this.onCloseDrawerHTML} />
                <Drawer
                    className="drawer-visualizar-newsletter"
                    title={newsletterAssunto}
                    width={720}
                    onClose={this.fecharNewsletter}
                    visible={visibleNewsletter}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <div dangerouslySetInnerHTML={{ __html: newsletterTexto }}></div>
                    </div>
                    <div className="ant-drawer-footer">
                        <button
                            className="botao-secundario"
                            onClick={this.fecharNewsletter}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Modal
                    title="Selecionar tipo de destinatário"
                    visible={this.state.visibleEnviar}
                    onOk={this.enviar}
                    okText="Enviar"
                    confirmLoading={iconLoading}
                    onCancel={() => this.setState({ visibleEnviar: false })}
                    cancelText="Cancelar"
                >
                    <Form className="form-categorias" layout="horizontal" style={{ padding: "0" }}>
                        <Form.Item>
                            <RadioGroup onChange={this.handleChangeRadio} value={tipo_enviar}>
                                <Radio style={radioStyle} value={1}>
                                    Todos{" "}
                                    <Tooltip className="info-icon" title="Enviar para todos os utilizadores">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Radio>
                                <Radio style={radioStyle} value={2}>
                                    Uma categoria{" "}
                                    <Tooltip className="info-icon" title="Enviar para todos de uma categoria nos seus favoritos">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Radio>
                                <Radio style={radioStyle} value={3}>
                                    Um Curso{" "}
                                    <Tooltip className="info-icon" title="Enviar para todos os formandos a frequentar um dado curso">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Radio>
                                <Radio style={radioStyle} value={4}>
                                    Teste{" "}
                                    <Tooltip className="info-icon" title="Enviar para mim uma newsletter de teste">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Radio>
                            </RadioGroup>
                        </Form.Item>
                        {tipo_enviar == 2 && (
                            <Form.Item label={<>
                                Categoria
                                <Tooltip className="info-icon" title="Preencha o nome da categoria pretendida">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </>}>
                                {getFieldDecorator("categorias")(
                                    <Select
                                        allowClear={true}
                                        showSearch
                                        showArrow={false}
                                        labelInValue
                                        style={{ width: "100%" }}
                                        placeholder="Selecionar"
                                        filterOption={false}
                                        defaultActiveFirstOption={false}
                                        notFoundContent={this.state.procurarCategoria ? <Spin size="small" /> : null}
                                        onSearch={this.procurarCategorias}
                                        onChange={this.checkCategorias}
                                    >
                                        {listaCategorias.map(categoria => (
                                            <Option key={categoria.id}>{categoria.nome}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        )}
                        {tipo_enviar == 3 && (
                            <Form.Item label={<>
                                Curso
                                <Tooltip className="info-icon" title="Preencha o nome do curso pretendido">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </>}>
                                {getFieldDecorator("cursos")(
                                    <Select
                                        allowClear={true}
                                        showSearch
                                        showArrow={false}
                                        labelInValue
                                        style={{ width: "100%" }}
                                        placeholder="Selecionar"
                                        filterOption={false}
                                        defaultActiveFirstOption={false}
                                        notFoundContent={this.state.procurar ? <Spin size="small" /> : null}
                                        onSearch={this.procurarCursos}
                                        onChange={this.checkCursos}
                                    >
                                        {listaCursos.map(curso => (
                                            <Option key={curso.id}>{curso.nome}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        )}
                    </Form>
                </Modal>
                <Drawer
                    className="drawer-detalhe-curso"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Criada a">
                                    {detalheDataCriada}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Drawer
                    className="drawer-detalhe-curso"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalheLvl2: false })}
                    visible={visibleDetalheLvl2}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Enviada a">
                                    {detalheDataEnviada}
                                </Form.Item>
                                <Form.Item label="Destinatários">
                                    {detalheDestinatario}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalheLvl2: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </div>
        );
    }
}

const FormNewsletters = Form.create({ name: "form-newsletters" })(Newsletters);

export default FormNewsletters;
