import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, DatePicker, TimePicker, notification, Dropdown, Icon, Drawer, Menu, Modal, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../images/noimage.png";
import { GlobalContext } from "../../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Informacoes extends Component {
    static contextType = GlobalContext;
    state = {
        noticias: [],
        //DRAWER
        visible: false,
        noticiaId: 0,
        capa: "",
        preview: "",
        titulo: "",
        texto: "",
        link: "",
        data: null,
        hora: null,
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //UPLOAD IMAGEM VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listarNoticias();
    }

    handlerChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handlerChangeData = data => {
        this.setState({ data });
    };

    handlerChangeTime = hora => {
        this.setState({ hora });
    };

    listarNoticias = () => {
        axios({
            method: "get",
            url: "/api/empresa/listar-informacoes"
        })
            .then(response => {
                var noticias = [];

                response.data.map(noticia => {
                    noticias.push({
                        id: noticia.id,
                        titulo: noticia.titulo,
                        data: (noticia.data ? moment(noticia.data).format("DD/MM/YYYY") : "") + (noticia.hora ? " " + noticia.hora : "")
                    });
                });

                this.setState({
                    noticias
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    adicionarNoticias = () => {
        this.setState({
            visible: true,
            noticiaId: 0
        });
    };

    excluir = noticiaId => {
        confirm({
            title: "Pretende excluir esta informação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/empresa/excluir-informacao",
                    params: {
                        noticiaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Informação excluida!");
                        var noticias = this.state.noticias.filter(x => x.id != noticiaId);
                        this.setState({
                            noticias
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a informação!");
                    });
            }
        });
    };

    guardarNoticia = () => {
        this.props.form.validateFieldsAndScroll(err => {
            if (!err && this.state.totalBytes <= 500 * 1024 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("capa", this.state.capa);
                item.append("titulo", this.state.titulo);
                item.append("texto", this.state.texto);
                if (this.state.link)
                    item.append("link", this.state.link);
                if (this.state.data)
                    item.append("data", moment(this.state.data).format("YYYY/MM/DD"));
                if (this.state.hora)
                    item.append("hora", this.state.hora.format("HH:mm"));
                axios({
                    method: "post",
                    url: "/api/empresa/adicionar-informacao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Informação adicionada!");
                        this.listarNoticias();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    alterarNoticia = () => {
        this.props.form.validateFieldsAndScroll(err => {
            if (!err && this.state.totalBytes <= 500 * 1024 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.noticiaId);
                item.append("capa", this.state.capa);
                item.append("titulo", this.state.titulo);
                item.append("texto", this.state.texto);
                if (this.state.link)
                    item.append("link", this.state.link);
                if (this.state.data)
                    item.append("data", moment(this.state.data).format("YYYY/MM/DD"));
                if (this.state.hora)
                    item.append("hora", this.state.hora.format("HH:mm"));

                axios({
                    method: "put",
                    url: "/api/empresa/alterar-informacao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Informação alterada!");
                        this.listarNoticias();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    carregarDetalhe = noticiaid => {
        axios({
            method: "get",
            url: "/api/empresa/detalhe-informacao",
            params: {
                noticiaid
            }
        })
            .then(response => {
                this.props.form.resetFields();
                this.setState({
                    noticiaId: noticiaid,
                    capa: response.data.capa,
                    preview: response.data.capa,
                    titulo: response.data.titulo,
                    texto: response.data.texto,
                    link: response.data.link ? response.data.link : "",
                    data: response.data.data ? moment(response.data.data) : null,
                    hora: response.data.hora ? moment(response.data.hora, "HH:mm") : null,
                    visible: true
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar informação");
                this.setState({ iconLoading: false });
            });
    };

    //UPLOAD DA FOTO/IMAGEM DO CURSO
    uploadFoto = event => {
        if (event.target.files[0].size < 500 * 1024) {
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                this.setState({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    capa: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0])
                });
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        } else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 500 KB para a imagem.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //ABRE O FILE BROWSER
    ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //REMOVE A FOTO
    removerFoto = () => {
        document.getElementById("input-foto").value = "";
        this.setState({
            capa: "",
            preview: ""
        });
    };

    afterVisibleChange = aberto => {
        if (aberto && this.state.noticiaId === 0) {
            this.props.form.resetFields();
            document.getElementById("input-foto").value = "";
            this.setState({
                capa: "",
                preview: "",
                titulo: "",
                texto: "",
                link: "",
                data: null,
                hora: null
            });
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            noticias,
            //DRAWER
            visible,
            noticiaId,
            capa,
            preview,
            titulo,
            texto,
            link,
            data,
            hora,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink,
            //UPLOAD IMAGEM VALIDATE
            totalBytes,
            formatoValido,
            validateStatus,
            help,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const opcoes = noticia => {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.carregarDetalhe(noticia.id)}>
                            Editar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluir(noticia.id)}>
                            Excluir
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        };

        return (
            <div className="bloco-minha-conta">
                <div className="noticias container-body">
                    <div className="controlos">
                        <button className="botao-principal" onClick={this.adicionarNoticias}>
                            Adicionar
                        </button>
                    </div>
                    <div className="box-noticias box-noticias-desktop">
                        <div className="header">
                            <span className="desc">Título</span>
                            <span className="desc">Data/Hora</span>
                            <span className="desc"></span>
                        </div>
                        {noticias.length > 0 ? (
                            noticias.map(noticia => (
                                <div className="content" key={noticia.id}>
                                    <span className="desc">{noticia.titulo}</span>
                                    <span className="desc">{noticia.data}</span>
                                    <span className="desc">
                                        <Dropdown overlay={opcoes(noticia)} placement="bottomLeft">
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    </span>
                                </div>
                            ))
                        ) : (
                            <div className="sem-registos">Não existem registos!</div>
                        )}
                    </div>
                    <div className="box-noticias box-noticias-mobile">
                        <div className="header">
                            <span className="desc">Título</span>
                            <span className="desc"></span>
                        </div>
                        {noticias.length > 0 ? (
                            noticias.map(noticia => (
                                <div className="content" key={noticia.id}>
                                    <span className="desc">
                                        <div className="box-noticia">
                                            <div className="info-noticia">
                                                <span className="titulo-noticia">
                                                    {noticia.titulo}
                                                </span>
                                                <div className="data-noticia">
                                                    {noticia.data}
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    <span className="desc">
                                        <Dropdown overlay={opcoes(noticia)} placement="bottomLeft">
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    </span>
                                </div>
                            ))
                        ) : (
                                <div className="sem-registos">Não existem registos!</div>
                            )}
                    </div>
                </div>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias"
                    title={noticiaId > 0 ? "Alterar" : "Adicionar"}
                    width={720}
                    onClose={() => this.setState({ visible: false })}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-img">
                        <Form>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <div className="container-img">
                                    <img src={preview ? preview : noimage} />
                                </div>
                                <input
                                    type="file"
                                    id="input-foto"
                                    accept="image/*"
                                    onChange={this.uploadFoto}
                                    style={{ display: "none" }}
                                />
                                {!preview ? (
                                    <>
                                        <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                            Adicionar
                                        </Link>{" "}
                                        <Tooltip
                                            className="info-icon"
                                            title={
                                                <div>
                                                    <p>Tamanho máximo: 500KB</p>
                                                    <p>Tamanho recomendado: 670x400px</p>
                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                </div>
                                            }
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                ) : (
                                        <>
                                            <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                                Alterar
                                            </Link>
                                            <Tooltip
                                                className="info-icon"
                                                title={
                                                    <div>
                                                        <p>Tamanho máximo: 500KB</p>
                                                        <p>Tamanho recomendado: 670x400px</p>
                                                        <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                    </div>
                                                }
                                            >
                                                <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                            </Tooltip>
                                            <Link to="#" className="link-principal-border" onClick={this.removerFoto}>
                                                Remover
                                            </Link>
                                        </>
                                    )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label="Título">
                                {getFieldDecorator("titulo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: titulo
                                })(<Input name="titulo" maxLength={80} onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Texto">
                                {getFieldDecorator("texto", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: texto
                                })(<TextArea rows={6} name="texto" onChange={this.handlerChange} style={{ height: "100%" }} />)}
                            </Form.Item>
                            <Form.Item label="Link">
                                <Input name="link" value={link} onChange={this.handlerChange} />
                            </Form.Item>
                            <Form.Item label="Data">
                                {getFieldDecorator("data", {
                                    initialValue: data
                                })(<DatePicker name="data" className="input-25" onChange={this.handlerChangeData} format="DD/MM/YYYY" />)}
                            </Form.Item>
                            <Form.Item label="Hora">
                                {getFieldDecorator("hora", {
                                    initialValue: hora
                                })(
                                    <TimePicker
                                        className="input-25"
                                        name="hora"
                                        onChange={this.handlerChangeTime}
                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                        format="HH:mm"
                                        disabled={!data}
                                    />
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <Link
                            to="#"
                            className="botao-secundario"
                            onClick={() => this.setState({ visible: false })}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                        </Link>
                        {noticiaId > 0 ? (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.alterarNoticia}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.guardarNoticia}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        )}
                    </div>
                </Drawer>
            </div>
        );
    }
}

const FormInformacoes = Form.create({ name: "form-informacoes" })(Informacoes);

export default FormInformacoes;
