import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, Select, Upload, notification, Icon, Checkbox, Row, Col, Tooltip } from "antd";
import Header from "./Header";
import { GlobalContext } from "../GlobalState";
import axios from "axios";

const Dragger = Upload.Dragger;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Contacto extends Component {
    static contextType = GlobalContext;
    state = {
        tipo_destinatario: 0,
        assunto: "",
        mensagem: "",
        sem_resposta: false,
        fileList: [],
        //VALIDAR FICHEIROS
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    //ENVIA O CONTACTO
    Enviar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_destinatario", this.context.role === "ENTIDADE" ? this.state.tipo_destinatario : 2);
                item.append("assunto", this.state.assunto);
                item.append("mensagem", this.state.mensagem);
                item.append("sem_resposta", this.state.sem_resposta);
                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });
                item.append("notificar", true);

                //CM não estou certo desta chamada
                axios({
                    method: "post",
                    url: "/api/tickets/form-novo-ticket",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Pedido de suporte enviado!");
                        this.setState({
                            redirect: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };
    //Enviar = () => {
    //    this.props.form.validateFieldsAndScroll((err, values) => {
    //        if (!err) {
    //            this.setState({ iconLoading: true });

    //            var item = new FormData();
    //            item.append("nome", this.state.nome);
    //            item.append("email", this.state.email);
    //            item.append("assunto", this.state.assunto);
    //            item.append("mensagem", this.state.mensagem);
    //            item.append("entidadeId", localStorage.getItem("entidadeId"));

    //            axios({
    //                method: "post",
    //                url: "/api/pagina-entidade/form-footer-publico",
    //                data: item
    //            })
    //                .then(() => {
    //                    openNotificationWithIcon("success", "Sucesso", "Mensagem de contato enviada!");
    //                    this.setState({
    //                        iconLoading: false,
    //                        redirect: true
    //                    });
    //                    this.reset();
    //                })
    //                .catch(() => {
    //                    openNotificationWithIcon("error", "Erro", "Não foi possível enviar a mensagem de contato!");
    //                    this.setState({ iconLoading: false });
    //                });
    //        }
    //    });
    //};

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormato = formato => {
        var formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //LIMPA AS CAIXAS DE TEXTO APOS O ENVIO DO CONTACTO
    reset = () => {
        this.props.form.resetFields();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const { TextArea } = Input;
        const {
            fileList,
            validateStatus,
            help,
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to="/user" />;

        const props = {
            multiple: true,
            onChange: info => {
                var tamanhoTotal = 0;
                var ficheirosValidos = true;
                info.fileList.forEach(file => {
                    tamanhoTotal += file.size;

                    if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                        ficheirosValidos = false;
                    }
                });

                if (tamanhoTotal > 26214400)
                    this.setState({
                        totalBytes: tamanhoTotal,
                        validateStatus: "error",
                        help: "Limite de 25 MB por upload.."
                    });
                else {
                    if (!ficheirosValidos)
                        this.setState({
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Ficheiro(s) com formato inválido"
                        });
                }
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    var tamanhoTotal = 0;
                    var ficheirosValidos = true;
                    newFileList.forEach(file => {
                        tamanhoTotal += file.size;

                        if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                            ficheirosValidos = false;
                        }
                    });

                    if (tamanhoTotal > 26214400 || !ficheirosValidos)
                        return {
                            totalBytes: tamanhoTotal,
                            fileList: newFileList
                        };
                    else
                        return {
                            totalBytes: tamanhoTotal,
                            formatoValido: true,
                            validateStatus: "",
                            help: "",
                            fileList: newFileList
                        };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            fileList
        };

        return (
            <>
                <Header titulo="Contato" />
                <div className="container">
                    <div className="content">
                        <Form className="suporte-contacto">
                            {this.context.role === "ENTIDADE" && (
                                <Form.Item label="Destinatários">
                                    {getFieldDecorator("tipo_assunto", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ]
                                    })(
                                        <Select placeholder="Selecionar" name="tipo_destinatario" allowClear={true} onChange={value => this.setState({ tipo_destinatario: value })}>
                                            <Option value="1">Stepforma</Option>
                                            <Option value="2">{this.context.nome_entidade}</Option>
                                        </Select>
                                    )}

                                </Form.Item>
                            )}
                            <Form.Item label="Assunto">
                                {getFieldDecorator("assunto", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="assunto" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Mensagem">
                                {getFieldDecorator("mensagem", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<TextArea name="mensagem" onChange={this.handleChange} style={{ height: "180px" }} />)}
                            </Form.Item>
                            {this.context.role === "ENTIDADE" &&
                                <Form.Item>
                                    <Checkbox name="sem_resposta" onChange={this.handleChangeCheckbox}>Assumir o estado FECHADO{" "}
                                    <Tooltip className="info-icon" title="Ao assinalar o estado como fechado, este pedido apenas terá como objetivo ser lido, sem necessidade de ser respondido.">
                                            <Icon type="question-circle-o" />
                                        </Tooltip></Checkbox>
                                </Form.Item>}
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <div className="anexos">
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <i className="fas fa-upload" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Adicionar anexos{" "}
                                            <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </p>
                                        <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                    </Dragger>
                                </div>
                            </Form.Item>
                            <div className="btn_plano">
                                <Link to="#" className="botao-principal" disabled={iconLoading} onClick={this.Enviar}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Enviar
                                </Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}
const FormContacto = Form.create({ name: "contactos" })(Contacto);

export default FormContacto;
