import React, { Component } from "react";
import Curso from "./_Aux/Curso";

class Cursos extends Component {
    render() {
        const { cursos, continuar, loading, keytext } = this.props;

        return (
            <>
                <div className="cursos">
                    {cursos.map((curso, index) => (
                        <Curso
                            key={index}
                            keytext={keytext + curso.id}
                            loading={loading}
                            curso={curso}
                        />
                    ))}
                </div>
            </>
        );
    }
}

export default Cursos;
