import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
    Form,
    Icon,
    notification,
    Upload,
    Button,
    Tooltip,
    Progress,
    Modal
} from "antd";
import axios from "axios";
import { Upload as UploadVimeo } from "tus-js-client";
import noimage from "../../images/noimage.png";
import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const accessToken = "c25f3d22c7bcd79d2044ad6eb33ea514";

const headerPost = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    Authorization: `bearer ${accessToken}`,
    "Content-Type": "application/json"
};

const headerPut = {
    Accept: "application/vnd.vimeo.*+json;version=3.4"
};

class Personalizacao extends Component {
    static contextType = GlobalContext;

    state = {
        //CAPA PAGINA PUBLICA
        capa: "",
        preview: null,
        //UPLOAD VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //VIDEO PAGINA PUBLICA
        videoId: "",
        video: [],
        //PROGRESS
        upload_progress: 0,
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
    };

    UNSAFE_componentWillMount() {
        this.carregar();
    }

    carregar = () => {
        axios({
            method: "get",
            url: "/api/empresa/personalizacao"
        })
            .then(response => {
                this.setState({
                    preview: response.data.capa,
                    videoId: response.data.capavideo
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //ATUALIZA O CAPA
    uploadCapa = event => {
        if (event.target.files[0].size < 1024 * 1024) {
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                var capa = URL.createObjectURL(event.target.files[0]);
                var item = new FormData();
                item.append("capa", event.target.files[0]);

                axios({
                    method: "put",
                    url: "/api/empresa/adicionar-capa",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Capa atualizada!");
                        this.setState({
                            preview: capa,
                            validateStatus: "",
                            help: ""
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível atualizar capa!");
                    });
            } else {
                document.getElementById("input-capa").value = "";
                openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
            }
        }
        else {
            document.getElementById("input-capa").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 1MB para o ficheiro.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //ABRE O FILE BROWSER
    ativarUploadCapa = () => {
        document.getElementById("input-capa").click();
    };

    //REMOVE A FOTO
    removerCapa = () => {
        axios({
            method: "delete",
            url: "/api/empresa/remover-capa"
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Capa removida!");
                document.getElementById("input-capa").value = "";
                this.setState({
                    preview: ""
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível remover a capa!");
            });
    };

    //CRIA UMA NOVA AULA DO TIPO VIDEO
    criarVideoVimeo = () => {
        this.setState({ iconLoading: true, upload_progress: 0 });

        const file = this.state.video[0];
        const fileSize = file.size.toString();

        axios({
            method: "post",
            url: `https://api.vimeo.com/me/videos`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost,
            data: {
                upload: {
                    status: "in_progress",
                    approach: "tus",
                    size: fileSize
                },
                name: "Video Capa",
                privacy: { view: "disable" },
                outro: "Stepforma"
            }
        }).then(response => {
            this.uploadVimeo(file, response);
        });
    };

    uploadVimeo = (file, response) => {
        const upload = new UploadVimeo(file, {
            endPoint: "https://api.vimeo.com/me/videos",
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            headers: {},
            onError: error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar o vídeo!");
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1);
                this.setState({
                    upload_progress: percentage
                });
            },
            onSuccess: () => {
                var videoId = response.data.link.split("/").pop();
                this.criarVideo(videoId, file.name);
                this.moverParaPasta(videoId);
                this.adicionarDominioEPreset(videoId);
            }
        });

        upload.start();
    };

    moverParaPasta = videoId => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/me/projects/${this.context.albumId_entidade}/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    adicionarDominioEPreset = videoId => {
        axios({
            method: "get",
            url: "/api/curso/carregar-vimeo-configs"
        }).then(response => {
            this.adicionarPreset(videoId, response.data.presetId);
            this.adicionarDominio(videoId, response.data.dominio);
        });
    };

    adicionarPreset = (videoId, presetId) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/presets/${presetId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    adicionarDominio = (videoId, dominio) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/privacy/domains/${dominio}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    criarVideo = (videoId, FileName) => {
        var item = new FormData();
        item.append("capavideo", videoId);

        axios({
            method: "put",
            url: "/api/empresa/adicionar-capav",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Vídeo guardado!");
                this.setState({
                    videoId,
                    iconLoading: false
                });
            })
            .catch(error => {
                this.setState({ iconLoading: false });
                openNotificationWithIcon("error", "Erro", "Não foi possível guardar o video!");
            });
    };

    alterarVideoVimeo = () => {
        this.setState({ iconLoading: true, upload_progress: 0 });

        const file = this.state.video[0];
        const fileSize = file.size.toString();

        axios({
            method: "post",
            url: `https://api.vimeo.com/me/videos`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost,
            data: {
                upload: {
                    status: "in_progress",
                    approach: "tus",
                    size: fileSize
                },
                name: "Video Capa",
                privacy: { view: "disable" }
            }
        }).then(response => {
            this.alterarUploadVimeo(file, response);
        });
    };

    alterarUploadVimeo = (file, response) => {
        const upload = new UploadVimeo(file, {
            endPoint: `https://api.vimeo.com/videos/${this.state.videoId}/versions`,
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            headers: {},
            onError: error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível alterar o vídeo!");
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1);
                this.setState({
                    upload_progress: percentage
                });
            },
            onSuccess: () => {
                this.removerVideoVimeoAnterior(this.state.videoId);
                var videoId = response.data.link.split("/").pop();
                this.criarVideo(videoId, file.name);
                this.moverParaPasta(videoId);
                this.adicionarDominioEPreset(videoId);
            }
        });

        upload.start();
    };

    removerVideoVimeoAnterior = videoId => {
        axios({
            method: "delete",
            url: `https://api.vimeo.com/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    removerVideoVimeo = () => {
        axios({
            method: "delete",
            url: `https://api.vimeo.com/videos/${this.state.videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        }).then(() => {
            this.removerVideo();
        });
    };

    removerVideo = () => {
        axios({
            method: "delete",
            url: "/api/empresa/remover-capav",
            timeout: 60 * 30 * 1000
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Vídeo removido!");
                this.setState({
                    videoId: ""
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível remover o vídeo!");
            });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatos = (formato, formatos_aceites) => {
        var valido = false;

        for (var i = 0; i < formatos_aceites.length; i++) {
            if (formato == formatos_aceites[i]) valido = true;
        }

        return valido;
    };

    //MP4, MOV, WMV, AVI e FLV - Video
    validar = (rule, value, callback) => {
        if (!this.validarFormatos(value.file.name.split(".").pop().toLowerCase(), ["mp4", "mov", "wmv", "avi", "flv"]))
            callback("Campo obrigatório");

        callback();
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            //CAPA PAGINA PUBLICA
            preview,
            //VIDEO PAGINA PUBLICA
            videoId,
            video,
            //UPLOAD VALIDATE
            validateStatus,
            help,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        //validarFormatos MP4, MOV, WMV, AVI e FLV
        const propsVideo = {
            accept: ".mp4, .mov, .wmv, .avi, .flv",
            multiple: false,
            showUploadList: false,
            onRemove: file => {
                this.props.form.resetFields("video");
                this.setState({
                    video: []
                });
            },
            defaultFileList: this.state.video,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["mp4", "mov", "wmv", "avi", "flv"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
                    return false;
                }

                if (file.size > 5368709120) {
                    openNotificationWithIcon("error", "Erro", "Limite de 5 GB por upload");
                    return false;
                }

                this.setState(
                    state => ({
                        video: [file]
                    }),
                    () => this.criarVideoVimeo()
                );
            },
            video
        };

        return (
            <div className="bloco-minha-conta">
                <div className="bloco-personalizacao">
                    <Form>
                        <div className="bloco-img bloco">
                            <h3 className="titulo-separador" style={{ marginBottom: 20, textAlign: "left" }}>Capa (Opcional)</h3>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <div className="container-img">
                                    <img src={preview ? preview : noimage} alt="Imagem de topo da página Home do Canal" />
                                </div>
                                <input
                                    type="file"
                                    id="input-capa"
                                    accept="image/*"
                                    onChange={this.uploadCapa}
                                    style={{ display: "none" }}
                                />
                                {!preview ? (
                                    <>
                                        <Link to="#" className="link-principal-border" onClick={this.ativarUploadCapa}>
                                            <span>Adicionar imagem de topo para a página pública do Canal</span>
                                        </Link>{" "}
                                        <Tooltip
                                            className="info-icon"
                                            title={
                                                <>
                                                    <p>Tamanho máximo: 1MB</p>
                                                    <p>Tamanho recomendado: 1170x280px</p>
                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                </>
                                            }
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                        <Link
                                            to="#"
                                            className="link-principal-border"
                                            onClick={this.ativarUploadCapa}
                                        >
                                                Alterar
                                        </Link>
                                            <Tooltip
                                                className="info-icon"
                                                title={
                                                    <>
                                                        <p>Tamanho máximo: 1MB</p>
                                                        <p>Tamanho recomendado: 1170x280px</p>
                                                        <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                    </>
                                                }
                                            >
                                                <Icon type="question-circle-o" style={{ marginRight: 20 }}/>
                                            </Tooltip>
                                        <Link to="#" className="link-principal-border" onClick={this.removerCapa}>
                                            <span>Remover</span>
                                        </Link>
                                    </>
                                )}
                            </Form.Item>
                        </div>
                        <div className="bloco-img bloco">
                            <h3 className="titulo-separador" style={{ marginBottom: 20, textAlign: "left" }}>Video (Opcional)</h3>
                            <Form.Item>
                                {getFieldDecorator("video", {
                                    initialValue: this.state.video
                                })(
                                    !this.state.videoId ? (
                                        <>
                                            <div className="container-img">
                                                <img src={noimage} alt="Vídeo de topo da página Home do Canal" />
                                            </div>
                                            <Upload {...propsVideo} fileList={this.state.video}>
                                                <Button id="btn-adicionar-video" style={{ display: "none" }}></Button>
                                            </Upload>
                                            <Link
                                                to="#"
                                                className="link-principal-border"
                                                onClick={() => document.getElementById("btn-adicionar-video").click()}
                                            >
                                                Adicionar vídeo para a página pública do Canal
                                            </Link>
                                            <Tooltip
                                                className="info-icon"
                                                title={
                                                    <>
                                                        <p>Tamanho máximo: 5GB</p>
                                                        <p>Formatos válidos: .mp4, .mov, .wmv, .avi, .flv</p>
                                                    </>
                                                }
                                            >
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </>
                                    ) : (
                                            <>
                                                <div className="bloco-video">
                                                    <iframe
                                                        className="video"
                                                        src={`https://player.vimeo.com/video/${videoId}`}
                                                        id="player"
                                                        frameBorder="0"
                                                    ></iframe>
                                                </div>
                                                <Upload {...propsVideo} fileList={this.state.video}>
                                                    <Button id="btn-alterar-video" style={{ display: "none" }}></Button>
                                                </Upload>
                                                <Link
                                                    to="#"
                                                    className="link-principal-border"
                                                    onClick={() => document.getElementById("btn-alterar-video").click()}
                                                >
                                                    Alterar
                                                </Link>
                                                <Tooltip
                                                    className="info-icon"
                                                    title={
                                                        <>
                                                            <p>Tamanho máximo: 5GB</p>
                                                            <p>Formatos válidos: .mp4, .mov, .wmv, .avi, .flv</p>
                                                        </>
                                                    }
                                                >
                                                    <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                                </Tooltip>
                                                <Link to="#" className="link-principal-border" onClick={this.removerVideoVimeo}>Remover</Link>
                                            </>
                                        )
                                )}
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Progress type="circle" percent={Number(this.state.upload_progress)} />
                        </p>
                        <p className="texto">A enviar...</p>
                    </div>
                </Modal>
            </div>
        );
    }
}

const FormPersonalizacao = Form.create({ name: "form-minha-conta" })(Personalizacao);

export default FormPersonalizacao;
