import React, { Component } from "react";
import { matchPath, Redirect } from "react-router-dom";
import { Modal, Rate, notification } from "antd";
import Header from "../../User/Header";
import Info from "./Info";
import ControlosTopo from "./ControlosTopo";
import Responder from "./Responder";
import Mensagem from "./Mensagem";
import MensagemInicial from "./MensagemInicial";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
import "moment/locale/pt.js";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        atualizar_mensagens: false,
        ticketId: 0,
        nr_ticket: "",
        id_tipo_destinatario: "",
        //ini_nome: "",
        ini_email: "",
        nome: "",
        assunto: "",
        sem_resposta: false,
        codigo: "",
        estado: "",
        estado_cor: "",
        data: "",
        rating: "",
        autor: "",
        mensagens: [],

        //UTILIZADOR
        foto: "",
        nome_completo: "",
        email: "",
        role: "",

        mostrar_responder_topo: false,
        controlos_responder_topo: true,
        controlos_edicao_topo: false,

        mostrar_responder_baixo: false,
        controlos_responder_baixo: true,
        controlos_edicao_baixo: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //RATING
        rate: 0,
        //RESPONDER
        mensagem: "",
        fileList: [],
        iconLoading: false,
        status: "",
        helper: "",
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        ////NOTIFICAR
        //notificar: false
    };

    UNSAFE_componentWillMount() {
        const match = matchPath(window.location.pathname, {
            path: "/:pagina/:ticketid"
        });

        if (match && match.params.ticketid) {
            this.setState({
                ticketId: match.params.ticketid
            });
            this.carregarTicketDetalhe(match.params.ticketid, false);
        }
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChangeRate = rate => {
        this.setState({ rate });
    };

    handlerChangeCheck = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    carregarTicketDetalhe = (ticketid, nova) => {
        axios({
            method: "get",
            url: "/api/tickets/ticket-detalhe",
            params: {
                ticketId: ticketid,
                nova
            }
        })
            .then(response => {
                this.setState({
                    nr_ticket: response.data.id,
                    id_tipo_destinatario: response.data.id_tipo_destinatario,
                    nome: response.data.nome,
                    assunto: response.data.assunto,
                    sem_resposta: response.data.sem_resposta,
                    codigo: response.data.codigo,
                    estado: response.data.estado.nome,
                    estado_cor: response.data.estado.cor,
                    rating: response.data.rating,
                    autor: response.data.utilizador,
                    mensagens: response.data.mensagens
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    mensagemEnviada = () => {
        this.carregarTicketDetalhe(this.state.ticketId, true);
        this.setState({
            mostrar_responder_topo: false,
            controlos_responder_topo: true,
            controlos_edicao_topo: false,

            mostrar_responder_baixo: false,
            controlos_responder_baixo: true,
            controlos_edicao_baixo: false
        });
    };

    responder_topo = () => {
        this.setState({ mostrar_responder_topo: true, controlos_responder_topo: false, controlos_edicao_topo: true, controlos_responder_baixo: false });
    };

    fechar_topo = () => {
        confirm({
            title: "Pretende fechar este pedido?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "post",
                    url: "/api/tickets/fechar-ticket",
                    params: {
                        ticketid: this.state.ticketId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Pedido fechado!");
                        switch (this.context.role) {
                            case "ADMIN":
                                this.setState({
                                    redirect: true,
                                    redirectLink: "/administracao-suporte"
                                })
                                break;
                            case "ENTIDADE":
                                this.setState({
                                    redirect: true,
                                    redirectLink: "/gestao-suporte"
                                })
                                break;
                            default:
                                this.setState({
                                    redirect: true,
                                    redirectLink: "/user-suporte"
                                })
                                break;
                        }
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possivel fechar o pedido!");
                    });
            }
        });
    };

    avaliar = () => {
        const desc = ["(1)", "(2)", "(3)", "(4)", "(5)"];

        confirm({
            title: "Como avalia a resolução deste pedido?",
            content: <Rate tooltips={desc} onChange={this.handleChangeRate} />,
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/tickets/rating",
                    params: {
                        ticketid: this.state.ticketId,
                        rate: this.state.rate
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Obrigado pela sua avaliação");
                        this.setState({
                            rating: this.state.rate
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possivel efetuar a avaliação!");
                    });
            }
        });
    };

    cancelar_topo = () => {
        this.setState({ mostrar_responder_topo: false, controlos_edicao_topo: false, controlos_responder_topo: true, controlos_responder_baixo: true });
    };

    enviar_topo = () => {
        this.setState({ mostrar_responder_topo: false, controlos_edicao_topo: false, controlos_responder_topo: true, controlos_responder_baixo: true });
    };

    responder_baixo = () => {
        this.setState({ mostrar_responder_baixo: true, controlos_responder_baixo: false, controlos_edicao_baixo: true, controlos_responder_topo: false });
    };

    cancelar_baixo = () => {
        this.setState({ mostrar_responder_baixo: false, controlos_edicao_baixo: false, controlos_responder_baixo: true, controlos_responder_topo: true });
    };

    enviar_baixo = () => {
        this.setState({ mostrar_responder_baixo: false, controlos_edicao_baixo: false, controlos_responder_baixo: true, controlos_responder_topo: true });
    };

    //******************************
    handleChangeTextArea = event => {
        this.setState({
            status: "",
            helper: "",
            mensagem: event.target.value
        });
    };

    onChangeUpload = info => {
        var tamanhoTotal = 0;
        var ficheirosValidos = true;
        info.fileList.forEach(file => {
            tamanhoTotal += file.size;

            if (!this.validarFormatoImagem(file.name.split(".").pop().toLowerCase())) {
                ficheirosValidos = false;
            }
        });

        if (tamanhoTotal > 26214400)
            this.setState({
                totalBytes: tamanhoTotal,
                validateStatus: "error",
                help: "Limite de 25 MB por upload."
            });
        else {
            if (!ficheirosValidos)
                this.setState({
                    formatoValido: false,
                    validateStatus: "error",
                    help: "Ficheiro(s) com formato inválido"
                });
        }
    };

    onRemoveUpload = file => {
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);

            var tamanhoTotal = 0;
            var ficheirosValidos = true;
            newFileList.forEach(file => {
                tamanhoTotal += file.size;

                if (!this.validarFormatoImagem(file.name.split(".").pop().toLowerCase())) {
                    ficheirosValidos = false;
                }
            });

            if (tamanhoTotal > 26214400 || !ficheirosValidos)
                return {
                    totalBytes: tamanhoTotal,
                    fileList: newFileList
                };
            else
                return {
                    totalBytes: tamanhoTotal,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    fileList: newFileList
                };
        });
    };

    beforeUpload = file => {
        this.setState(state => ({
            fileList: [...state.fileList, file]
        }));
        return false;
    };

    validarFormatoImagem = formato => {
        var formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    enviar = event => {
        event.preventDefault();

        if (this.state.mensagem != "" && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
            this.setState({ iconLoading: true });

            var item = new FormData();
            item.append("id_ticket", this.state.ticketId);
            item.append("mensagem", this.state.mensagem);
            this.state.fileList.forEach(file => {
                item.append("anexo[]", file);
            });
            /*item.append("notificar", this.state.notificar);*/

            axios({
                method: "post",
                url: "/api/tickets/responder",
                data: item
            })
                .then(() => {
                    this.setState({
                        mensagem: "",
                        /*notificar: false,*/
                        fileList: [],
                        iconLoading: false
                    });
                    this.mensagemEnviada();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                    this.setState({ iconLoading: false });
                });
        } else {
            if (this.state.mensagem == "")
                this.setState({
                    status: "error",
                    helper: "Introduza uma nova mensagem"
                });
        }
    };

    remover = () => {
        confirm({
            title: "Pretende remover este pedido?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/tickets/remover",
                    params: {
                        ticketId: this.state.ticketId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Pedido removido!");
                        switch (this.context.role) {
                            case "ADMIN":
                                this.setState({
                                    redirect: true,
                                    redirectLink: "/administracao-suporte"
                                })
                                break;
                            case "ENTIDADE":
                                this.setState({
                                    redirect: true,
                                    redirectLink: "/gestao-suporte"
                                })
                                break;
                            default:
                                this.setState({
                                    redirect: true,
                                    redirectLink: "/user-suporte"
                                })
                                break;
                        }
                    })
                    .catch((error) => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível remover o pedido");
                    });
            }
        });
    };
    //******************************

    render() {
        const {
            ticketId,
            id_tipo_destinatario,
            codigo,
            //ini_nome,
            nome,
            assunto,
            autor,
            mensagens,
            //REDIRECT
            redirect,
            redirectLink,
            ////NOTIFICAR
            //notificar
        } = this.state;

        if (redirect) {
            localStorage.setItem("voltou", true);
            return <Redirect to={redirectLink} />;
        }

        return (
            <>
                <Header titulo="Pedido" />
                <div className="container">
                    <div className="tickets-detalhe">
                        <Info
                            assunto={assunto}
                            autor={autor}
                        />
                        <ControlosTopo
                            fechar_topo={this.fechar_topo}
                            responder_topo={this.responder_topo}
                            {...this.state}
                            remover={this.remover}
                        />
                        {this.state.mostrar_responder_topo ? (
                            <Responder
                                ticketId={ticketId}
                                mensagemEnviada={this.mensagemEnviada}
                                handleChangeTextArea={this.handleChangeTextArea}
                                onChangeUpload={this.onChangeUpload}
                                onRemoveUpload={this.onRemoveUpload}
                                beforeUpload={this.beforeUpload}
                                status={this.state.status}
                                helper={this.state.helper}
                                validateStatus={this.state.validateStatus}
                                help={this.state.help}
                                fileList={this.state.fileList}
                                iconLoading={this.state.iconLoading}
                                enviar={this.enviar}
                                cancelar_topo={this.cancelar_topo}
                                //notificar={notificar}
                                handlerChangeCheck={this.handlerChangeCheck}
                            />
                        )
                            :
                            mensagens.map((mensagem, index) => (
                                index === (mensagens.length - 1) ?
                                    <MensagemInicial
                                        key={index}
                                        nome={nome}
                                        mensagem={mensagem}
                                        id_tipo_destinatario={id_tipo_destinatario}
                                    />
                                    :
                                    <Mensagem key={index} mensagem={mensagem} />
                            ))
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
