import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Table } from "antd";
import axios from "axios";
import moment from "moment";

export class Sessoes extends Component {
    state = {
        lista: [],
        listaMobile: [],
        listaPeriodo: [],
        //TABELA
        loading_table: false,
        loading_table_periodo: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //COLUNAS
        columns2: [],
        cartoes: []
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
        this.listarPeriodo();
    }

    componentDidUpdate() {
        if (this.props.atualizar) {
            this.listar();
            this.listarPeriodo();
            this.props.paraAtualizar();
        }
    }

    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-estatistica/sessoes",
                    params: {
                        dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.props.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        var lista = [];
                        var listaMobile = [];

                        response.data.map((dado, index) => {
                            lista.push({
                                key: index,
                                ano: dado.ano,
                                mes: dado.mes,
                                numero: dado.total,
                                tempo: dado.totalt,
                                tempoMedio: dado.media
                            });

                            listaMobile.push({
                                key: index,
                                ano: `${dado.mes}/${dado.ano}`,
                                numero: dado.total,
                                tempo: `${dado.totalt}/${dado.media}`
                            });
                        });

                        this.setState({
                            lista,
                            listaMobile,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    listarPeriodo = () => {
        this.setState(
            {
                loading_table_periodo: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-estatistica/sessoes-periodos",
                    params: {
                        dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.props.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        var cartoes = [];

                        var columns2 = [{
                            title: "Período",
                            dataIndex: "periodoHorario",
                            key: "periodoHorario",
                            fixed: "left",
                            width: 120
                        }]
                        var dt_termino = this.props.dt_termino.format("YYYY/MM/DD");
                        var dateStart = moment(dt_termino).subtract(5, 'month');
                        var dateEnd = moment(dt_termino);

                        while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
                            columns2.push({
                                title: dateStart.format('MMM').charAt(0).toUpperCase() + dateStart.format('MMM').slice(1) + (dateStart.format('MMM') === "jan" || dateStart.format('MMM') === "dez" ? "/" + dateStart.format('YY') : ""),
                                dataIndex: dateStart.format('MMMM').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                                key: dateStart.format('MMMM').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                                width: 120
                            })
                            cartoes.push({
                                titulo: dateStart.format('MMMM').charAt(0).toUpperCase() + dateStart.format('MMMM').slice(1) + (dateStart.format('MMM') === "jan" || dateStart.format('MMM') === "dez" ? "/" + dateStart.format('YY') : ""),
                                key: dateStart.format('MMMM').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            })
                            dateStart.add(1, 'month');
                        }

                        var listaPeriodo = [];

                        response.data.forEach((registo, index) => {
                            listaPeriodo.push({
                                key: index,
                                periodoHorario: registo.hora,
                                janeiro: 0,
                                fevereiro: 0,
                                marco: 0,
                                abril: 0,
                                maio: 0,
                                junho: 0,
                                julho: 0,
                                agosto: 0,
                                setembro: 0,
                                outubro: 0,
                                novembro: 0,
                                dezembro: 0
                            });

                            registo.meses.forEach(mes => {
                                if (mes.mes == "1") listaPeriodo[index].janeiro = mes.total;
                                if (mes.mes == "2") listaPeriodo[index].fevereiro = mes.total;
                                if (mes.mes == "3") listaPeriodo[index].marco = mes.total;
                                if (mes.mes == "4") listaPeriodo[index].abril = mes.total;
                                if (mes.mes == "5") listaPeriodo[index].maio = mes.total;
                                if (mes.mes == "6") listaPeriodo[index].junho = mes.total;
                                if (mes.mes == "7") listaPeriodo[index].julho = mes.total;
                                if (mes.mes == "8") listaPeriodo[index].agosto = mes.total;
                                if (mes.mes == "9") listaPeriodo[index].setembro = mes.total;
                                if (mes.mes == "10") listaPeriodo[index].outubro = mes.total;
                                if (mes.mes == "11") listaPeriodo[index].novembro = mes.total;
                                if (mes.mes == "12") listaPeriodo[index].dezembro = mes.total;
                            });
                        });

                        this.setState({
                            columns2,
                            cartoes,
                            listaPeriodo,
                            loading_table_periodo: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    render() {
        const {
            lista,
            listaMobile,
            listaPeriodo,
            //TABELA
            loading_table,
            loading_table_periodo,
            //REDIRECT
            redirect,
            redirectLink,
            //COLUNAS
            columns2,
            cartoes
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const columns = [
            {
                title: "Ano",
                dataIndex: "ano",
                key: "ano"
            },
            {
                title: "Mês",
                dataIndex: "mes",
                key: "mes"
            },
            {
                title: "Sessões",
                dataIndex: "numero",
                key: "numero"
            },
            {
                title: "Tempo Total",
                dataIndex: "tempo",
                key: "tempo"
            },
            {
                title: "Tempo Médio",
                dataIndex: "tempoMedio",
                key: "tempoMedio"
            }
        ];

        const columnsMobile = [
            {
                title: "Mês/Ano",
                dataIndex: "ano",
                key: "ano"
            },
            {
                title: "Sessões",
                dataIndex: "numero",
                key: "numero"
            },
            {
                title: "Total/Médio",
                dataIndex: "tempo",
                key: "tempo"
            }
        ];

        return (
            <div style={{ maxWidth: 920 }}>
                <Table
                    columns={columns}
                    className="tabela-estatisticas-desktop"
                    dataSource={lista}
                    pagination={false}
                    locale={{ emptyText: "Não existem registos!" }}
                    loading={{
                        spinning: loading_table,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                />
                <Table
                    columns={columnsMobile}
                    className="tabela-estatisticas-mobile"
                    dataSource={listaMobile}
                    pagination={false}
                    locale={{ emptyText: "Não existem registos!" }}
                    loading={{
                        spinning: loading_table,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                />
                <Table
                    className="tabela-sessoes-desktop"
                    columns={columns2}
                    dataSource={listaPeriodo}
                    pagination={false}
                    locale={{ emptyText: "Não existem registos!" }}
                    loading={{
                        spinning: loading_table_periodo,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                />
                <div className="lista-sessoes lista-sessoes-gestao lista-sessoes-mobile">
                    {loading_table_periodo ? 
                        <div className="loading-data">
                            <div className="loading" />
                        </div>
                        :
                        null}
                    {cartoes.map(mes => (
                        <div className="cartao-sessao">
                            <div className="bloco-titulo">
                                {mes.titulo}
                            </div>
                            <div className="bloco-info">
                                <ul>
                                    {listaPeriodo.map(periodo => (
                                        <li><span>{periodo.periodoHorario}:</span> <span>{periodo[mes.key]}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default Sessoes;
