import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Select, Badge, Icon } from "antd";
import Header from "../User/Header";
import Cursos from "../Cursos/Cursos";
import Oops from "../Geral/Oops.jsx";
import axios from "axios";
import { GlobalContext } from "../GlobalState";

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        titulo: "Cursos",
        tipoId: 0,
        idiomaId: 0,
        categoriaId: 0,
        tipos: [],
        idiomas: [],
        categorias: [],
        cursos: [],
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LOADING
        loading: false
    };

    componentDidMount() {
        this.listarCategorias();
        this.listarCursos();
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    onChandeSelectTipos = tipoId => {
        this.setState(
            {
                tipoId
            },
            () => this.listarCursos()
        );
    };

    onChandeSelectIdiomas = idiomaId => {
        this.setState(
            {
                idiomaId
            },
            () => this.listarCursos()
        );
    };

    selecionarCategoria = (categoriaId, categoriaNome) => {
        this.setState(
            {
                categoriaId,
                titulo: categoriaNome
            },
            () => this.listarCursos()
        );
    };

    limparFiltro = () => {
        this.setState(
            {
                categoriaId: 0,
                titulo: "Cursos",
            },
            () => this.listarCursos()
        );
    };

    //LISTA DAS CATEGORIAS
    listarCategorias = () => {
        axios({
            method: "get",
            url: "/api/pesquisa-por-curso/lista-categorias"
        })
            .then(response => {
                this.setState({
                    categorias: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //CARREGA OS CURSOS
    listarCursos = () => {
        this.setState({
            loading: true
        });
        axios({
            method: "get",
            url: "/api/pesquisa-por-curso/listar-cursos",
            params: {
                categoriaId: this.state.categoriaId
            }
        })
            .then(response => {
                this.setState({
                    cursos: response.data,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const { Option } = Select;
        const {
            titulo,
            cursos,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        var categorias = this.state.categorias.map(
            categoria =>
                categoria.cursos > 0 && (
                    <li key={categoria.id} className={categoria.id === this.state.categoriaId ? "ativa" : ""}>
                        <Link to="#" onClick={() => this.selecionarCategoria(categoria.id, categoria.nome)}>
                            {categoria.nome}
                        </Link>
                        <Badge count={categoria.cursos} />
                    </li>
                )
        );

        return (
            <>
                <Header titulo={titulo} negativeTop />
                <div className="categorias">
                    <div className={cursos.length > 0 ? "container bloco-grid container-body" : "container container-body"}>
                        <div className="lado-esquerdo" style={cursos.length > 0 ? {} : { visibility: "hidden" }}>
                            <div className="filtros-categorias">
                                <div className="listagem-categorias">
                                    <Form className="filtros-categorias">
                                    </Form>
                                    {this.state.categorias.length > 0 && !categorias.every(v => v === false) && (
                                        <>
                                            <h3>
                                                Categorias
                                                {this.state.categoriaId > 0 &&
                                                    <Link to="#" className="limpar-filtro-categorias" onClick={this.limparFiltro}><Icon type="close-circle" /></Link>}
                                            </h3>
                                            <ul>{categorias}</ul>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        {this.state.loading ? (
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                        ) : cursos.length > 0 ? (
                            <Cursos loading={false} cursos={cursos} keytext="categoria" />
                        ) : (
                            <div>
                                <Oops tem_texto texto="De momento, não existem cursos disponíveis!" />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
