import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Icon, notification, Radio, Modal } from "antd";
import { Link, Redirect } from "react-router-dom";
import { GlobalContext } from "../GlobalState";
import axios from "axios";
import Header from "../User/Header";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class Login extends Component {
    static contextType = GlobalContext;
    state = {
        email: "",
        password: "",
        redirect: false,
        redirectLink: "",
        iconLoading: false,
        remember: false,
        modal_visible: false,
        selected1: "",
        selected2: "",
        mobile: false,
        entidades: [],
        redirect2: false,
        //MODAL MENSAGEM
        visibleMensagem: false
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            remember: false
        });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleKeyPress = event => {
        if (event.key == "Enter") this.login(event);
    };

    pesquisarEntidades = pesquisa => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/pesquisar-entidade",
            params: {
                pesquisa
            }
        })
            .then(response => {
                this.setState({
                    entidades: response.data
                })
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível procurar canal!")
            })
    }

    login = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("email", this.state.email);
                item.append("password", this.state.password);
                item.append("rememberMe", this.state.remember);

                axios({
                    method: "post",
                    url: "/api/autenticacaonew/login",
                    data: item
                })
                    .then(response => {
                        if (response.data.token) {
                            localStorage.setItem("accessToken", response.data.token)
                            localStorage.removeItem("redirecionado")
                            axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
                            this.context.atualizarState({
                                atualizarEntidadeFooter: true,
                                is_authenticated: true,
                                is_authenticated_nav: true,
                                role: response.data.role ? response.data.role : "",
                                informacoes: response.data.informacoes
                            });

                            if (response.data.senhaValida)
                                this.setState({
                                    redirectLink: "/user",
                                    redirect: true
                                });
                            else
                                this.setState({
                                    redirectLink: "/alteracao-senha",
                                    redirect: true
                                });
                        }
                        else {
                            localStorage.removeItem("accessToken")
                            delete axios.defaults.headers.common.Authorization;
                        }
                    })
                    .catch(error => {
                        if (error.response.data.split(";")[0] === "NAO_PAGO") {
                            this.setState({
                                visibleMensagem: true,
                                redirectLink: error.response.data.split(";")[1]
                            });
                        }
                        else if (error.response.data.split(";")[0] === "INATIVA") {
                            openNotificationWithIcon(
                                "warning",
                                "Aviso",
                                <>
                                    Conta inativa ou excluida. Por favor entre em contato com o <button style={{ border: "none", background: "none", color: "#16817a", cursor: "pointer", padding: 0 }} onClick={() => this.setState({ redirect: true, redirectLink: `/#formulario` })}><strong>suporte</strong></button>
                                </>
                            );
                        }
                        else if (error.response.data.split(";")[0] === "SEM_TERMOS_POLITICA") {
                            openNotificationWithIcon(
                                "warning",
                                "Aviso",
                                <>
                                    Não concluiu o seu registo. <br /> Para finalizar o tem de aceitar de aceitar a <strong>Política de Privacidade</strong> e os <strong>Termos de Uso</strong>
                                </>
                            );
                            localStorage.setItem("codigo_utilizador", error.response.data.split(";")[1])
                            this.setState({
                                redirect: true,
                                redirectLink: "/utilizador-registar-passo-2"
                            });
                        }
                        else {
                            openNotificationWithIcon("error", "Erro", error.response.data);
                        }

                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    handleOk = () => {
        if (this.state.selected1 == true) {
            this.setState({ redirect2: true });
        }
        if (this.state.selected2 == true) {
            this.context.atualizarState({ search_entidade: true })
            this.setState({ modal_visible: false });
        }
    };

    render() {
        const { redirect, redirectLink, iconLoading } = this.state;
        const { getFieldDecorator } = this.props.form;

        if (redirect)
            return <Redirect to={redirectLink} />;

        if (this.state.redirect2)
            return <Redirect to="/registar-passo1" />;

        const RadioGroup = Radio.Group;

        const { Search } = Input;

        return (
            <>
                <Header titulo="Iniciar Sessão" marginTop0={true} />
                <div className="content">
                    <Form onSubmit={this.handleSubmit} className="login">
                        <Form.Item label="E-mail">
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Introduza o e-mail"
                                    }
                                ]
                            })(<Input name="email" onChange={this.handleChange} onKeyPress={this.handleKeyPress} />)}
                        </Form.Item>
                        <Form.Item label="Senha">
                            {getFieldDecorator("password", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Introduza a senha"
                                    }
                                ]
                            })(<Input.Password type="password" name="password" onChange={this.handleChange} onKeyPress={this.handleKeyPress} />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Link to="#">
                                <Button type="primary" className="login-form-button" onClick={this.login}>
                                    Entrar
                                </Button>
                            </Link>
                            {getFieldDecorator("remember", {
                                valuePropName: "checked"
                            })(
                                <Checkbox name="remember" onChange={this.handleChangeCheckbox}>
                                    <span className="ant-lembrar-dados">Lembrar os meus dados</span>
                                </Checkbox>
                            )}
                            <div className="bloco-login-links">
                                <Link to="/recuperar-senha">
                                    Esqueci a senha <i className="fas fa-chevron-right"></i>
                                </Link>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <Modal
                    title="Criar conta na StepForma"
                    visible={this.state.modal_visible}
                    onOk={this.handleOk}
                    okText="Confirmar"
                    onCancel={() =>
                        this.setState({
                            modal_visible: false
                        })
                    }
                    cancelText="Cancelar"
                >
                    <Form className="minha-conta">
                        <Form.Item>
                            <RadioGroup>
                                <Radio key="1" value={1} checked={this.state.selected1} onChange={() => this.setState({ selected1: true, selected2: false })}>
                                    <span className="opcao">Pretendo usar o StepForma para a minha comunidade (alunos colaboradores, clientes, ...)</span>
                                    <span className="info">Depois de criar o seu canal carregue os seus conteúdos e associe os utilizadores da sua comunidade</span>
                                </Radio>
                                <Radio key="2" value={2} checked={this.state.selected2} onChange={() => this.setState({ selected1: false, selected2: true })}>
                                    <span className="opcao">Pretendo aceder aos conteúdos de um Canal</span>
                                    <span className="info">
                                        Feche esta janela e use a opção "PROCURAR CANAL" que se encontra no topo e depois de seleccionar o canal pretendido solicite nessa página a criação de conta ou use o formulário de contato disponível no fundo da página
                                    </span>
                                </Radio>
                            </RadioGroup>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A entrar...</p>
                    </div>
                </Modal>
                <Modal
                    title=""
                    visible={this.state.visibleMensagem}
                    onOk={() => window.location.href = this.state.redirectLink}
                    onCancel={() => this.setState({ visibleMensagem: false })}
                    okText="Subscrição de Serviços"
                    className="modal-falta-pagamento"
                >
                    <Icon type="exclamation-circle" className="anticon-exclamation-circle" />
                    <h3>Regularize a sua situação para poder aceder!</h3>
                </Modal>
            </>
        );
    }
}

const LoginForm = Form.create({ name: "form_login" })(Login);

export default LoginForm;
