import React, { Component } from "react";
import SameWindow from "../GerirPlayer/SameWindow";

const Testes = () => {
    const isMain = SameWindow();

    return (<>
        {isMain ? (
            <h2>Main window!</h2>
        ) : (
            <h2>App already opened on another tab!</h2>
        )}
    </>
    );
}

export default Testes;
