import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import PlayerVideo from "./Video";
import PlayerAudio from "./Audio";
import PlayerPagina from "./Pagina";
import PlayerPdf from "./Pdf";
import PlayerQuestionarioDirigido from "./QuestionarioDirigido/Questionario";
import PlayerQuestionarioDirigidoResumo from "./QuestionarioDirigido/Resumo";
import PlayerQuestionario from "./Questionario/Questionario";
import PlayerQuestionarioResumo from "./Questionario/Resumo";
import PlayerQuestionarioResolucao from "./QuestionarioResolucao/Resolucao";
import PlayerAvaliacao from "./Avaliacao/Avaliacao";
import PlayerAvaliacaoResumo from "./Avaliacao/Resumo";
import PlayerAvaliacaoResolucao from "./AvaliacaoResolucao/Resolucao";
import PlayerQuestionarioProcesso from "./QuestionarioProgresso/Questionario";
import PlayerQuestionarioProcessoResumo from "./QuestionarioProgresso/Resumo";
import PlayerDownload from "./Download";
import PlayerTrabalho from "./Trabalho/Trabalho";
import PlayerLink from "./Link";
import PlayerSumario from "./Sumario";
import PlayerSessaoPresencial from "./SessaoPresencial";
import PlayerFicheiro from "../Cursos/Ficheiros/Pagina";
import PlayerTrabalhoFicheiro from "./Trabalho/Pagina";
import PlayerJitsiMeeting from "./JitsiMeeting";
import PlayerJitsiMeetingAguardar from "./JitsiMeetingAguardar";
import PlayerJitsiMeetingBlock from "./JitsiMeetingBlock";
import PlayerJitsiMeetingVideo from "../Cursos/Videoconferencias/Pagina";
import PlayerVideoSimples from "./VideoSimples";
import { GlobalContext } from "../GlobalState";

class PlayerLayout extends Component {
    static contextType = GlobalContext;
    state = {
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    render() {
        const {
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <Switch>
                <Route exact path="/curso/:curso/:aula/player-video" component={PlayerVideo} />
                <Route exact path="/curso/:curso/:aula/player-audio" component={PlayerAudio} />
                <Route exact path="/curso/:curso/:aula/player-pagina" component={PlayerPagina} />
                <Route exact path="/curso/:curso/:aula/player-pdf" component={PlayerPdf} />
                <Route exact path="/curso/:curso/:aula/player-questionario-dirigido" component={PlayerQuestionarioDirigido} />
                <Route exact path="/curso/:curso/:aula/player-questionario-dirigido-resumo" component={PlayerQuestionarioDirigidoResumo} />
                <Route exact path="/curso/:curso/:aula/player-questionario" component={PlayerQuestionario} />
                <Route exact path="/curso/:curso/:aula/player-questionario-resumo" component={PlayerQuestionarioResumo} />
                <Route exact path="/curso/:curso/:aula/player-questionario-resolucao" component={PlayerQuestionarioResolucao} />
                <Route exact path="/curso/:curso/:aula/player-avaliacao" component={PlayerAvaliacao} />
                <Route exact path="/curso/:curso/:aula/player-avaliacao-resumo" component={PlayerAvaliacaoResumo} />
                <Route exact path="/curso/:curso/:aula/player-avaliacao-resolucao" component={PlayerAvaliacaoResolucao} />
                <Route exact path="/curso/:curso/:aula/player-questionario-progresso" component={PlayerQuestionarioProcesso} />
                <Route exact path="/curso/:curso/:aula/player-questionario-progresso-resumo" component={PlayerQuestionarioProcessoResumo} />
                <Route exact path="/curso/:curso/:aula/player-download" component={PlayerDownload} />
                <Route exact path="/curso/:curso/:aula/player-trabalho" component={PlayerTrabalho} />
                <Route exact path="/curso/:curso/:aula/player-link" component={PlayerLink} />
                <Route exact path="/curso/:curso/:aula/player-sumario" component={PlayerSumario} />
                <Route exact path="/curso/:curso/:aula/player-sessao-presencial" component={PlayerSessaoPresencial} />
                <Route exact path="/curso/:curso/:ficheiro/player-trabalho-ficheiro" component={PlayerTrabalhoFicheiro} />
                <Route exact path="/curso/:curso/:ficheiro/player-ficheiro" component={PlayerFicheiro} />
                <Route exact path="/curso/:curso/vconf/:vconfid/player-videoconferencia" component={PlayerJitsiMeeting} />
                <Route exact path="/curso/:curso/vconf/:vconfid/player-videoconferencia-aguardar" component={PlayerJitsiMeetingAguardar} />
                <Route exact path="/curso/:curso/vconf/:vconfid/player-videoconferencia-blocked" component={PlayerJitsiMeetingBlock} />
                <Route exact path="/curso/:curso/vconf/:vconfid/player-video" component={PlayerJitsiMeetingVideo} />
                <Route exact path="/curso/:curso/vconf/:vconfid/player-video-meets" component={PlayerVideoSimples} />
            </Switch>
        );
    }
}

export default PlayerLayout;
