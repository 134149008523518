import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Checkbox, Tooltip, Icon, Input, notification, InputNumber } from "antd";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Empresa extends Component {
    static contextType = GlobalContext;

    state = {
        //GESTOR
        nome_completo_gestor: "",
        email_gestor: "",
        contacto_gestor: "",
        formacao: false,
        projeto: false,
        organizacional: false,
        responsaveis_stepforma: false,
        responsaveis: false,
        login2_stepforma: false,
        login2: false,
        autoconta_stepforma: false,
        autoconta: false,
        conteudos_animados_stepforma: false,
        conteudos_animados: false,
        gamificacao_stepforma: false,
        gamificacao: false,
        tz: "",
        organismos_stepforma: false,
        escolas: false,
        licencas: 0,
        dt_adesao: "",
        monetizacao: false,
        monetizacaoEntidade: false,
        subscricao: false,
        valorMensal: 0,
        vendaCursos: false,
        vendaCertificados: false,
        //METODOS PAGAMENTO
        metodos: [],
        paypal: false,
        cartao: false,
        transferencia: false,
        iban: "",
        servicoExterno: false,
        linkPagamento: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LOADING
        iconLoading: false
    };

    UNSAFE_componentWillMount() {
        this.carregarDados();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeValorMensal = value => {
        this.setState({ valorMensal: value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });

        if (event.target.name === "escolas" && !event.target.checked)
            this.setState({
                login2: false
            })
    };

    handleChangeCheckboxMetodos = metodos => {
        this.setState({ metodos });
    };

    carregarDados = () => {
        axios({
            method: "get",
            url: "/api/empresa/gestao-conta"
        })
            .then(response => {
                var metodos = [];
                if (response.data.paypal)
                    metodos.push("paypal")

                if (response.data.cartao)
                    metodos.push("cartao")

                if (response.data.transferencia)
                    metodos.push("transferencia")

                if (response.data.pagamentoExterno)
                    metodos.push("servicoExterno")

                this.setState({
                    nome_completo_gestor: response.data.utilizador.nome_completo,
                    email_gestor: response.data.utilizador.email,
                    contacto_gestor: response.data.utilizador.contacto,
                    formacao: response.data.formacao,
                    projeto: response.data.projeto,
                    organizacional: response.data.organizacional,
                    licencas: response.data.licencas,
                    responsaveis_stepforma: response.data.responsaveis_stepforma,
                    responsaveis: response.data.responsaveis,
                    login2_stepforma: response.data.login2_stepforma,
                    login2: response.data.login2,
                    autoconta_stepforma: response.data.autoconta_stepforma,
                    autoconta: response.data.autoconta,
                    conteudos_animados_stepforma: response.data.conteudos_animados_stepforma,
                    conteudos_animados: response.data.conteudos_animados,
                    gamificacao_stepforma: response.data.gamificacao_stepforma,
                    gamificacao: response.data.gamificacao,
                    tz: response.data.tz,
                    organismos_stepforma: response.data.organismos_stepforma,
                    escolas: response.data.escolas,
                    dt_adesao: moment(response.data.dt_criado).format("DD/MM/YYYY"),
                    monetizacao: response.data.monetizacao,
                    monetizacaoEntidade: response.data.monetizacao_entidade,
                    subscricao: response.data.subscricao,
                    valorMensal: (response.data.valorMensal + "").replace(".",","),
                    vendaCursos: response.data.vendaCursos,
                    vendaCertificados: response.data.vendaCertificados,
                    metodos,
                    iban: response.data.iban ? response.data.iban : "",
                    linkPagamento: response.data.linkPagamento ? response.data.linkPagamento : ""
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    atualizarDados = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("login2", this.state.login2);
                item.append("autoconta", this.state.autoconta);
                item.append("conteudos_animados", this.state.conteudos_animados);
                item.append("escolas", this.state.escolas);
                item.append("responsaveis", this.state.responsaveis);
                item.append("gamificacao", this.state.gamificacao);
                item.append("monetizacao_entidade", this.state.monetizacaoEntidade);
                item.append("subscricao", this.state.subscricao);
                item.append("valorMensal", (this.state.valorMensal + "").replace(",","."));
                item.append("vendaCursos", this.state.vendaCursos);
                item.append("vendaCertificados", this.state.vendaCertificados);
                item.append("paypal", this.state.metodos.find(x => x === "paypal") ? true : false);
                item.append("cartao", this.state.metodos.find(x => x === "cartao") ? true : false);
                item.append("transferencia", this.state.metodos.find(x => x === "transferencia") ? true : false);
                if (this.state.metodos.find(x => x === "transferencia"))
                    item.append("iban", this.state.iban);
                item.append("pagamentoExterno", this.state.metodos.find(x => x === "servicoExterno") ? true : false);
                if (this.state.metodos.find(x => x === "servicoExterno"))
                    item.append("linkPagamento", this.state.linkPagamento);

                axios({
                    method: "put",
                    url: "/api/empresa/alterar-dados-conta",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                        this.context.atualizarState({
                            escolas_entidade: this.state.escolas,
                            login2_entidade: this.state.login2,
                            responsaveis_entidade: this.state.responsaveis,
                            gamificacao_entidade: this.state.gamificacao,
                            subscricao_entidade: this.state.subscricao,
                            conteudos_animados_entidade: this.state.conteudos_animados,
                            monetizacao_entidade: this.state.monetizacaoEntidade,
                            monetizacao_stepforma: ~this.state.monetizacao,
                        });

                        //this.props.alteracoesConfigs(this.state.escolas ? this.context.visivel_entidade ? 9 : 5 : this.context.visivel_entidade ? 8 : 4);

                        this.setState({
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    validarLink = (rule, value, callback) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$', 'i');
        if (!pattern.test(value))
            callback("Introduza uma página válida")

        callback();
    }

    validarValor = (rule, value, callback) => {
        if (isNaN(value.replace(",",".")))
            callback("O valor deve ser um inteiro")

        callback();
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            //GESTOR
            nome_completo_gestor,
            email_gestor,
            contacto_gestor,
            dt_adesao,
            responsaveis_stepforma,
            responsaveis,
            login2_stepforma,
            login2,
            autoconta_stepforma,
            autoconta,
            conteudos_animados_stepforma,
            conteudos_animados,
            gamificacao_stepforma,
            gamificacao,
            tz,
            organismos_stepforma,
            escolas,
            licencas,
            monetizacao,
            monetizacaoEntidade,
            linkPagamento,
            subscricao,
            valorMensal,
            vendaCursos,
            vendaCertificados,
            //METODOS PAGAMENTO
            metodos,
            iban,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="bloco-gestor-conta">
                <div className="bloco-gestor">
                    <div className="bloco">
                        <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                            Gestor da Conta
                            </h3>
                        <div className="line">
                            <span>Nome: </span>
                            {nome_completo_gestor}
                        </div>
                        <div className="line">
                            <span>E-mail: </span>
                            {email_gestor}
                        </div>
                        {contacto_gestor &&
                            <div className="line">
                            <span>Telefone / Telemóvel: </span>
                                {contacto_gestor}
                            </div>}
                        <div className="line">
                            <span>Adesão em: </span>
                            {dt_adesao}
                        </div>
                        <div className="line">
                            <span>Nº de Licenças: </span>
                            {licencas}
                        </div>
                        <div className="line">
                            <span>Fuso horário: </span>
                            {tz}
                        </div>
                    </div>
                    <div className="bloco">
                        <h3 className="titulo-separador" style={{ marginBottom: "20px" }}>
                            Funcionalidades Extra
                            </h3>
                        <Form className="personalizacao">
                            <Form.Item style={{ marginBottom: 20 }}>
                                <Checkbox name="escolas" checked={escolas} onChange={this.handleChangeCheckbox} disabled={!organismos_stepforma}>
                                    Habilitar Unidades Organizacionais{" "}
                                    <Tooltip className="info-icon" title="A criação de unidades organizacionais (escolas, faculdades, institutos, ...) permite a associação de utilizadores e de cursos a cada uma delas.">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 20 }}>
                                <Checkbox name="login2" checked={login2} onChange={this.handleChangeCheckbox} disabled={!login2_stepforma || !escolas || !this.context.visivel_entidade}>
                                    Habilitar acesso por Formulário de Login Simplificado{" "}
                                    <Tooltip className="info-icon" title="O formulário de login da página pública do Canal possui 2 modos: Instrutor/Gestor ou Simplificado. O segundo usa o Nome e Sobrenome, a Senha e a Unidade Organizacional (se aplicável) - para autenticação.">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 20 }}>
                                <Checkbox name="responsaveis" checked={responsaveis} onChange={this.handleChangeCheckbox} disabled={!responsaveis_stepforma}>
                                    Habilitar Comunicação com Responsáveis{" "}
                                    <Tooltip className="info-icon" title="Pai e/ou Mãe, Encarregado de educação, Chefe,...">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 20 }}>
                                <Checkbox name="gamificacao" checked={gamificacao} onChange={this.handleChangeCheckbox} disabled={!gamificacao_stepforma}>
                                    Habilitar Gamificação
                                </Checkbox>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 20 }}>
                                <Checkbox name="conteudos_animados" checked={conteudos_animados} onChange={this.handleChangeCheckbox} disabled={!conteudos_animados_stepforma}>
                                    Habilitar Conteúdos Animados{" "}
                                    <Tooltip className="info-icon" title="Disponibilização de grupo com tarefas animadas">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 20 }}>
                                <Checkbox name="autoconta" checked={autoconta} onChange={this.handleChangeCheckbox} disabled={!autoconta_stepforma}>
                                    Habilitar Criação de Conta{" "}
                                    <Tooltip title="Conta criada pelo utilizador, sem necessidade de aprovação pela gestão">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 20 }}>
                                <Checkbox name="monetizacaoEntidade" checked={monetizacaoEntidade} onChange={this.handleChangeCheckbox} disabled={!monetizacao}>
                                    Habilitar Monetização
                                </Checkbox>
                            </Form.Item>
                            {monetizacaoEntidade &&
                                <>
                                    <h3 className="titulo-separador" style={{ marginTop: "60px", marginBottom: "20px" }}>
                                        Monetização
                                    </h3>
                                    <Form.Item style={{ marginBottom: 20 }}>
                                        <Checkbox name="subscricao" checked={subscricao} onChange={this.handleChangeCheckbox}>
                                            Incluir subscrição
                                    </Checkbox>
                                    </Form.Item>
                                {/*{subscricao &&
                                        <Form.Item label="Valor mensal">
                                            {getFieldDecorator("valorMensal", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    },
                                                    {
                                                        validator: this.validarValor
                                                    }
                                                ],
                                                initialValue: valorMensal
                                            })(<InputNumber
                                                className="input-50"
                                                name="valorMensal"
                                                onChange={this.handleChangeValorMensal}
                                                formatter={value => `${this.context.moeda_entidade} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '').replace('R', '')}
                                            />)}
                                    </Form.Item>}*/}
                                {subscricao &&
                                    <Form.Item label="Valor mensal">
                                        {getFieldDecorator("valorMensal", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                },
                                                {
                                                    validator: this.validarValor
                                                }
                                            ],
                                            initialValue: valorMensal
                                        })(<Input
                                            className="input-50"
                                            name="valorMensal"
                                            onChange={this.handleChange}
                                            prefix={this.context.moeda_entidade}
                                        />)}
                                    </Form.Item>}
                                    <Form.Item style={{ marginBottom: 20 }}>
                                        <Checkbox name="vendaCursos" checked={vendaCursos} onChange={this.handleChangeCheckbox}>
                                            Incluir valor de venda nos cursos
                                    </Checkbox>
                                    </Form.Item>
                                {/*<Form.Item style={{ marginBottom: 20 }}>
                                        <Checkbox name="vendaCertificados" checked={vendaCertificados} onChange={this.handleChangeCheckbox}>
                                            Incluir valor de venda nos certificados dos cursos
                                    </Checkbox>
                                    </Form.Item>*/}
                                    <h4 className="titulo-separador" style={{ marginTop: "60px", marginBottom: "15px" }}>
                                        Metodos de pagamento
                            </h4>
                                    <Form.Item>
                                        {getFieldDecorator("metodos", {
                                            rules: [
                                                {
                                                    required: (subscricao || vendaCursos || vendaCertificados),
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: (subscricao || vendaCursos || vendaCertificados) ? metodos : []
                                        })(<Checkbox.Group name="metodos" style={{ width: '100%' }} disabled={!(subscricao || vendaCursos || vendaCertificados)} onChange={this.handleChangeCheckboxMetodos}>
                                            <Form.Item style={{ marginBottom: 20 }}>
                                                <Checkbox value="paypal">
                                                    Paypal e Cartão de Crédito
                                                </Checkbox>
                                            </Form.Item>
                                            {/*<Form.Item style={{ marginBottom: 20 }}>
                                                <Checkbox value="cartao">
                                                    Cartão de Crédito
                                                </Checkbox>
                                            </Form.Item>*/}
                                            <Form.Item style={{ marginBottom: 20 }}>
                                                <Checkbox value="transferencia">
                                                    Transferência para conta bancária
                                                </Checkbox>
                                            </Form.Item>
                                            {/*{(metodos.find(x => x === "transferencia") && (subscricao || vendaCursos || vendaCertificados)) &&
                                                <Form.Item label="IBAN">
                                                    {getFieldDecorator("iban", {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Campo obrigatório"
                                                            }
                                                        ],
                                                        initialValue: iban
                                                    })(<Input name="iban" className="input-50" onChange={this.handleChange} />)}
                                                </Form.Item>}*/}
                                            {/*<Form.Item style={{ marginBottom: 20 }}>
                                                <Checkbox value="servicoExterno">
                                                    Serviço Externo
                                        </Checkbox>
                                            </Form.Item>*/}
                                            {(metodos.find(x => x === "servicoExterno") && (subscricao || vendaCursos || vendaCertificados)) &&
                                                <Form.Item label="Endereço da página de pagamento de serviços">
                                                    {getFieldDecorator("linkPagamento", {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Campo obrigatório"
                                                            },
                                                            {
                                                                validator: this.validarLink
                                                            }
                                                        ],
                                                        initialValue: linkPagamento
                                                    })(<Input name="linkPagamento" className="input-50" onChange={this.handleChange} />)}
                                                </Form.Item>}
                                        </Checkbox.Group>)}
                                    </Form.Item>
                                </>
                            }
                            <button className="botao-principal" onClick={this.atualizarDados} disabled={iconLoading}>
                                {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

const FormEmpresa = Form.create({ name: "form-minha-conta" })(Empresa);

export default FormEmpresa;
