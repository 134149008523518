import React, { Component } from "react";
import { Modal, notification, List } from "antd";
import { Link, Redirect } from "react-router-dom";
import { GlobalContext } from "../GlobalState";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Matriz extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        curso: "",
        retomar: {},
        modulo_aberto: "0",
        cursos: [],
        botoesVisiveis: false,
        total_conteudos: 0,
        total_conteudos_realizados: 0,
        //DRAWER MODULO
        visible: false,
        cursoSubcursoId: 0,
        subcursoId: 0,
        precedencia: "",
        obrigatorio: false,
        periodo: 1,
        procurar: false,
        listaProcurarPrecedencia: [],
        listaPrecedencia: [],
        listaProcurarCurso: [],
        listaCurso: [],
        //LOADING
        loading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoid: cursoId,
            curso: this.props.match.params.curso
        });

        //if (this.context.lista_disciplinas.length === 0) this.listar(cursoId);
        //else
        //    this.setState({
        //        cursos: this.context.lista_disciplinas
        //    });

        this.listar(cursoId);
    }

    //APANHA PARAMETROS PASSADOS PELO URL
    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    handlerChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handlerChangeSelectCurso = value => {
        this.setState({ subcursoId: value ? value.key : undefined });
    };

    handlerChangeSelectPrecedencia = value => {
        if (value) this.setState({ precedencia: value.key });
        else
            this.setState({
                precedencia: undefined,
                obrigatorio: false
            });
    };

    handlerChangeCheckObrigatoria = event => {
        this.setState({ obrigatorio: event.target.checked });
    };

    handlerChangeNumber = value => {
        this.setState({ periodo: value });
    };

    //CARREGA E LISTA TODOS OS MODULOS PERTENCENTES AO CURSO E IDENTIFICA A ULTIMA POSIÇÃO NA ORDEM
    listar = cursoid => {
        this.setState({
            loading: true
        });

        axios({
            method: "get",
            url: "/api/curso/listar-sub-cursos",
            params: {
                cursoid: cursoid
            }
        })
            .then(response => {
                this.setState({
                    cursos: response.data,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //DRAWER MODULO
    //ATUALIZA LISTA
    atualizar = () => {
        //this.context.atualizarHeader(this.context.total_conteudos + 1, this.context.total_conteudos_realizados);
        this.listar(this.state.cursoid);
        this.setState({
            visible: false
        });
    };

    //ABRE O DRAWER COM A INFORMAÇÃO DO MODULO QUE ABRIMOS O DETALHE
    carregar = cursoSubcursoId => {
        axios({
            method: "get",
            url: "/api/curso/detalhe-sub-curso",
            params: {
                cursoSubcursoId
            }
        })
            .then(response => {
                var listaProcurarCurso = [];
                var listaProcurarPrecedencia = [];

                if (response.data.sub)
                    listaProcurarCurso.push({
                        key: response.data.sub.id,
                        label: response.data.sub.nome
                    });

                if (response.data.precedencia)
                    listaProcurarPrecedencia.push({
                        key: response.data.precedencia.id,
                        label: response.data.precedencia.nome
                    });

                this.setState({
                    cursoSubcursoId,
                    subcursoId: response.data.sub.id,
                    listaProcurarCurso,
                    precedencia: response.data.precedencia ? response.data.precedencia.id : undefined,
                    listaProcurarPrecedencia,
                    obrigatorio: response.data.obrigatoria,
                    periodo: response.data.periodo,
                    visible: true
                });
            })
            .catch(() => {});
    };

    //EXCLUI O MODULO
    excluir = (cursoSubcursoId, ativo) => {
        confirm({
            title: "Pretende excluir este sub-curso?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/curso/excluir-sub-curso",
                    params: {
                        cursoSubcursoId
                    }
                })
                    .then(() => {
                        var cursos = this.state.cursos;
                        var cursoIndex = cursos.findIndex(x => x.subcursoId == cursoSubcursoId);
                        cursos.splice(cursoIndex, 1);
                        this.setState({
                            cursos
                        });

                        //if (ativo)
                        //    this.context.atualizarHeader(this.context.total_conteudos - 1, this.context.total_conteudos_realizados);

                        openNotificationWithIcon("success", "Sucesso", "Sub-curso excluido!");
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    //ATIVAR/INATIVAR O MODULO
    ativar = (cursoSubcursoId, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar este sub-curso?" : "Pretende ativar este sub-curso?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/curso/ativar-sub-curso",
                    params: {
                        cursoSubcursoId
                    }
                })
                    .then(() => {
                        var cursos = this.state.cursos;
                        var cursoIndex = cursos.findIndex(x => x.subcursoId == cursoSubcursoId);
                        cursos[cursoIndex].ativo = !ativo;

                        this.setState({
                            cursos
                        });

                        //if (ativo)
                        //    this.context.atualizarHeader(this.context.total_conteudos - 1, this.context.total_conteudos_realizados);
                        //else
                        //    this.context.atualizarHeader(this.context.total_conteudos + 1, this.context.total_conteudos_realizados);
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Sub-curso inativado!" : "Sub-curso ativado!");
                    })
                    .catch(() => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar o sub-curso!" : "Não foi possível ativar o sub-curso!"
                        );
                    });
            }
        });
    };

    //ABRE O DRAWER PARA SE CRIAR UM NOVO MODULO
    onOpenDrawerModulo = () => {
        this.setState({
            cursoSubcursoId: 0,
            subcursoId: 0,
            precedencia: "",
            obrigatorio: false,
            periodo: 1,
            procurar: false,
            listaProcurarPrecedencia: [],
            listaPrecedencia: [],
            listaProcurarCurso: [],
            listaCurso: [],
            visible: true
        });
    };

    //FECHA O DRAWER DO MODULO(NOVO OU EDIÇÃO)
    onCloseDrawerModulo = () => {
        this.setState({
            visible: false
        });
    };

    //validarLink = (curso, concluido, role) => {
    //    if (curso.obrigatoria && !concluido && role == "UTILIZADOR") {
    //        return "#";
    //    } else return `/curso/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`;
    //};

    //ABRE UM ALERTA COM A MENSAGEM
    //alertar = (curso, concluido, role) => {
    //    if (curso.precedencia && !concluido && role == "UTILIZADOR")
    //        if (curso.obrigatoria)
    //            openNotificationWithIcon(
    //                "warning",
    //                "Atenção",
    //                <span>
    //                    Tem de finalizar primeiro o curso <strong>{curso.precedencia.nome}</strong>
    //                </span>
    //            );
    //        else
    //            openNotificationWithIcon(
    //                "info",
    //                "Atenção",
    //                <span>
    //                    Sugerimos que conclua primeiro o curso <strong>{curso.precedencia.nome}</strong>
    //                </span>
    //            );
    //};

    concluido = curso => {
        var total_conteudos = 0;
        var total_conteudos_realizados = 0;

        //CALCULA O TOTAL DE AULAS NO CURSO
        curso.curso_Modulos.forEach(modulo => {
            total_conteudos += modulo.modulo_Aulas.length;

            //CALCULA O TOTAL DE AULAS NO CURSO
            modulo.modulo_Aulas.forEach(aula => {
                total_conteudos_realizados += aula.modulo_Aulas_Utilizadores.length;
            });
        });
        return total_conteudos > 0 ? total_conteudos == total_conteudos_realizados : false;
    };

    onClick = item => {
        //this.alertar(item, this.concluido(item), this.context.role);
        //localStorage.setItem("id_curso_matriz", this.props.match.params.cursoid);
        localStorage.setItem("codigo_curso", item.id);
        this.context.atualizarState({
            lista_modulos: []
        });
    };

    render() {
        const {
            cursos,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="container">
                {this.state.loading ? (
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                ) : (
                    <>
                        <div className="curso-modulos">
                            <List
                                bordered
                                dataSource={cursos}
                                renderItem={item => (
                                    <List.Item>
                                        {(item.cursoIniciado && (item.inscricao && item.inscrito || !item.inscricao)) || item.instrutor ?
                                            <Link
                                                to={`/${item.instrutor ? "gerir-curso" : "curso"}/${item.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                                className={!item.ativo ? "inativo" : ""}
                                                onClick={() => this.onClick(item)}
                                            >
                                                <span>
                                                    {item.codigo} - {item.nome}
                                                </span>
                                                {this.concluido(item) && (
                                                    <span style={{ marginLeft: "auto" }}>
                                                        <i className="fa fa-check" />
                                                    </span>
                                                )}
                                            </Link>
                                            :
                                            <Link
                                                to={`/curso/${item.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-visao-geral`}
                                                className={!item.ativo ? "inativo" : ""}
                                                onClick={() => this.onClick(item)}
                                            >
                                                <span>
                                                    {item.codigo} - {item.nome}
                                                </span>
                                                {this.concluido(item) && (
                                                    <span style={{ marginLeft: "auto" }}>
                                                        <i className="fa fa-check" />
                                                    </span>
                                                )}
                                            </Link>
                                        }
                                    </List.Item>
                                )}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default Matriz;
