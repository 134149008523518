import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Icon, notification, Tooltip } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Header from "../User/Header";
import { GlobalContext } from "../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class CriarConta extends Component {
    static contextType = GlobalContext;
    state = {
        nome: "",
        email: "",
        senha: "",
        telefone: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    criar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("entidadeId", localStorage.getItem("entidadeId"));
                item.append("nome", this.state.nome);
                item.append("email", this.state.email);
                item.append("senha", this.state.senha);
                item.append("telefone", this.state.telefone);

                axios({
                    method: "post",
                    url: "/api/pagina-entidade/criar-conta",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Conta criada!");
                        this.setState({
                            redirect: true,
                            redirectLink: `/${this.props.match.params.entidade}/login`
                        })
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            nome,
            email,
            senha,
            telefone,
            //LOADING
            iconLoading,
            //REDIRECT  
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Criar conta" marginTop0={true} />
                <div className="content">
                    <Form onSubmit={this.handleSubmit} className="login">
                        <Form.Item label="Nome">
                            {getFieldDecorator("nome", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: nome
                            })(<Input name="nome" onChange={this.handleChange} />)}
                        </Form.Item>
                        <Form.Item label="E-mail">
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: email
                            })(<Input name="email" onChange={this.handleChange} />)}
                        </Form.Item>
                        <Form.Item label={<span>
                            Senha{" "}
                            <Tooltip className="info-icon" title="A senha deve conter pelo menos uma uma letra maiúscula, um número e um mínimo de 6 caracteres">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>}
                        >
                            {getFieldDecorator("senha", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: senha
                            })(<Input name="senha" onChange={this.handleChange} />)}
                        </Form.Item>
                        <Form.Item label="Telefone / Telemóvel">
                            <Input value={telefone} name="telefone" onChange={this.handleChange} />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button type="primary" className="login-form-button" disabled={iconLoading} onClick={this.criar}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                    criar
                                </Button>
                        </Form.Item>
                        <Form.Item>
                            <Link to={`/${this.props.match.params.entidade}/login`} className="link-principal-border">
                                Voltar
                            </Link>
                        </Form.Item>
                    </Form>
                </div>
            </>
        );
    }
}

const FormCriarConta = Form.create({ name: "form-criar-conta" })(CriarConta);

export default FormCriarConta;
