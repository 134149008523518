import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, Checkbox } from "antd";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import { GlobalContext } from "../../GlobalState";

class Opcoes extends Component {
    static contextType = GlobalContext;

    emitEmpty = () => {
        this.setState({ pesquisa: "" });
        this.props.resetCaixaProcura();
    };

    handleChange = e => {
        this.setState({ pesquisa: e.target.value });
        if (e.target.value.length == 0) this.props.resetCaixaProcura();
    };

    render() {
        return (
            <div className="controlos controlos-fundo controlos-com-blocos">
                <div className="bloco-esquerda">
                    <div className="controlos-opcoes controlo-search">
                        <BotaoProcurar
                            tem_placeholder
                            placeholder="Procurar Grupo"
                            HandleKeyPress={this.props.HandleKeyPress}
                            resetCaixaProcura={this.props.resetCaixaProcura}
                        />
                    </div>
                </div>
                <div className="bloco-direita">
                    <Link to="#" className="opcao-atualizar" onClick={this.props.recarregar}>
                        <Icon type="reload" spin={this.props.iconLoadingRecarregar} />
                    </Link>
                </div>
            </div>
        );
    }
}

export default Opcoes;
