import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, Dropdown, Menu, Modal, Select, Switch, Radio, Tooltip } from "antd";
import Header from "../User/Header";
import Editor from "../GerirCursos/_Aux/Editor";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../GlobalState";

const RadioGroup = Radio.Group;
const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Newsletters extends Component {
    static contextType = GlobalContext;
    state = {
        //TABELA
        newsletters: [],
        ativo: true,
        //DETALHE
        newsletterid: 0,
        nome: "",
        assunto: "",
        texto: "",
        iconLoading: false,
        visible: false,
        editar: false,
        //ENVIAR
        tipo_enviar: 1,
        categoriaId: 0,
        listaCategorias: [],
        categoria: 0,
        visibleEnviar: false,
        cursoId: 0,
        listaCursos: [],
        //EDITOR
        setHTML: false,
        resetEditor: false,
        paginaHTML: false,
        //LOADING
        loading_table: false,
        //DRAWER NEWSLETTER
        visibleNewsletter: false,
        newsletterData: "",
        newsletterAssunto: "",
        newsletterTexto: "",
        //EDITOR
        show: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    componentDidUpdate() {
        if (
            !this.context.is_authenticated ||
            this.context.role === "UTILIZADOR" ||
            this.context.role === "FORMADOR" ||
            this.context.role === "ENTIDADE"
        ) {
            if (!this.context.is_authenticated)
                this.setState({
                    redirect: true,
                    redirectLink: "/login"
                });
            else if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR" || this.context.role === "ENTIDADE")
                this.setState({
                    redirect: true,
                    redirectLink: "/user"
                });
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSelectCategoria = value => {
        this.setState({ categoria: value });
    };

    handleChangeRadio = event => {
        this.setState({ tipo_enviar: event.target.value });
    };

    handleChangeEditor = (value, index) => {
        this.setState({
            texto: value
        });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //CARREGA TODAS AS CATEGORIAS DA EMPRESA
    listarCategorias = () => {
        axios({
            method: "get",
            url: "/api/gestao-newsletters/listar-categorias"
        })
            .then(response => {
                this.setState({
                    categorias: response.data
                });
            })
            .catch(() => {
                //   this.log("Tentativa de carregar categorias", "/api/administracao-newsletters/listar-categorias");
            });
    };

    //CARREGA TODAS AS NEWSLETTERS
    listar = () => {
        this.setState(
            {
                loading_table: true,
                iconLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/administracao-newsletters",
                    params: {
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var newsletters = [];

                        response.data.map((newsletter, index) => {
                            var enviados = [];
                            newsletter.newslettersEnvios.map((envio, index) => {
                                var conteudo = JSON.parse(envio.conteudo);
                                enviados.push({
                                    key: index,
                                    assunto: (
                                        <div>
                                            <span>{conteudo.assunto}</span>
                                        </div>
                                    ),
                                    data: moment(envio.dt_criado).format("DD/MM/YYYY HH:mm"),
                                    destinatarios: this.devolveTipo(envio.tipo, envio.quantidade, envio.opcao),
                                    opcoes: (
                                        <Dropdown overlay={this.montarMenuLvl2(envio.id)} placement="bottomLeft">
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    )
                                });
                            });

                            newsletters.push({
                                key: index,
                                id: newsletter.id,
                                nome: newsletter.nome,
                                dt_criado: moment(newsletter.dt_criado).format("DD/MM/YYYY"),
                                opcoes: (
                                    <Dropdown overlay={this.montarMenu(newsletter.id)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                ),
                                enviados
                            });
                        });

                        this.setState({
                            newsletters,
                            loading_table: false,
                            iconLoading: false
                        });
                    })
                    .catch(() => {
                        //   this.log("Tentativa de carregar newsletters", "/api/administracao-newsletters");
                    });
            }
        );
    };

    devolveTipo = (tipo, quantidade, opcao) => {
        switch (tipo) {
            case 1:
                return (
                    <div>
                        <span>Todos ({quantidade})</span>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <span>Administradores ({quantidade})</span>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <span>Formadores ({quantidade})</span>
                    </div>
                );
            case 4:
                return (
                    <div>
                        <span>Formandos ({quantidade})</span>
                    </div>
                );
        }
    };

    //CARREGA O DETALHE DA NEWSLETTER
    detalhe = newsletterid => {
        axios({
            method: "get",
            url: "/api/gestao-newsletters/detalhe",
            params: {
                newsletterid: newsletterid
            }
        })
            .then(response => {
                var conteudo = JSON.parse(response.data.conteudo);

                this.setState({
                    newsletterid: response.data.id,
                    nome: response.data.nome,
                    assunto: conteudo.assunto,
                    texto: conteudo.texto,
                    visible: true,
                    editar: true,
                    paginaHTML: true,
                    setHTML: true
                });
            })
            .catch(() => {
                //  this.log("Tentativa de carregar newsletter", "/api/administracao-newsletters/detalhe");
            });
    };

    //CRIA NOVA NEWSLETTER
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                var conteudo = {
                    assunto: this.state.assunto,
                    texto: this.state.texto
                };

                var item = new FormData();
                item.append("nome", this.state.nome);
                item.append("conteudo", JSON.stringify(conteudo));

                axios({
                    method: "post",
                    url: "/api/gestao-newsletters/add",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Newsletter adicionada com sucesso!");
                        this.setState({
                            visible: false,
                            iconLoading: false
                        });
                        this.listar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar a newsletter.");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //ATUALIZA NEWSLETTER
    guardar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                var conteudo = {
                    assunto: this.state.assunto,
                    texto: this.state.texto
                };

                var item = new FormData();
                item.append("id", this.state.newsletterid);
                item.append("nome", this.state.nome);
                item.append("conteudo", JSON.stringify(conteudo));

                axios({
                    method: "post",
                    url: "/api/gestao-newsletters/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Newsletter editada com sucesso!");
                        this.setState({
                            visible: false,
                            iconLoading: false
                        });
                        this.listar();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível editar a newsletter.");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //MONTA OPÇÕES DE MENU POR NEWSLETTER
    montarMenu = newsletterId => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.detalhe(newsletterId)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluir(newsletterId)}>
                        Excluir
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ newsletterid: newsletterId, visibleEnviar: true })}>
                        Enviar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.ativar(newsletterId)}>
                        {this.state.ativo ? "Inativar" : "Ativar"}
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //ATIVA E INATIVAR ENTIDADE
    ativar = newsletterid => {
        confirm({
            title: this.state.ativo ? "Pretende inativar esta newsletter?" : "Pretende ativar esta newsletter?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/administracao-newsletters/ativar",
                    params: {
                        newsletterid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", this.state.ativo ? "Newsletter inativada!" : "Newsletter ativada!");
                        var newsletters = this.state.newsletters.filter(x => x.id != newsletterid);
                        this.setState({
                            newsletters
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            this.state.ativo ? "Não foi possível inativar a newsletter!" : "Não foi possível ativar a newsletter!"
                        );
                    });
            }
        });
    };

    //EXCLUIR NEWSLETTER
    excluir = newsletterid => {
        confirm({
            title: "Pretende excluir esta newsletter?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-newsletters/excluir",
                    params: {
                        newsletterid: newsletterid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Newsletter excluida!");
                        var newsletters = this.state.newsletters.filter(x => x.id != newsletterid);
                        this.setState({
                            newsletters
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir esta newsletter!");
                    });
            }
        });
    };

    //ENVIA A NEWSLETTER
    enviar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            this.setState({ iconLoading: true });

            axios({
                method: "post",
                url: "/api/administracao-newsletters/enviar",
                params: {
                    newsletterid: this.state.newsletterid,
                    tipo: this.state.tipo_enviar
                }
            })
                .then(response => {
                    switch (this.state.tipo_enviar) {
                        case 1: //TODOS OS UTILIZADORES
                            openNotificationWithIcon(
                                "success",
                                "Sucesso",
                                "Newsletter enviada com sucesso para " + response.data + " utilizadores!"
                            );
                            break;
                        case 2: //TODOS OS ADMINISTRADORES
                            openNotificationWithIcon(
                                "success",
                                "Sucesso",
                                "Newsletter enviada com sucesso para " + response.data + " administradores!"
                            );
                            break;
                        case 3: //TODOS OS FORMADORES
                            openNotificationWithIcon(
                                "success",
                                "Sucesso",
                                "Newsletter enviada com sucesso para " + response.data + " Formadores!"
                            );
                            break;
                        case 4: //TODOS OS E-FORMANDOS
                            openNotificationWithIcon(
                                "success",
                                "Sucesso",
                                "Newsletter enviada com sucesso para " + response.data + " Formandos!"
                            );
                            break;
                        case 5: //NEWSLETTER DE TESTE
                            openNotificationWithIcon("success", "Sucesso", "Newsletter enviada com sucesso!");
                            break;
                    }

                    this.props.form.resetFields();
                    this.setState(
                        {
                            tipo_enviar: 1,
                            listaCategorias: [],
                            listaCursos: [],
                            visibleEnviar: false,
                            iconLoading: false
                        },
                        () => {
                            this.listar();
                        }
                    );
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                    this.setState({ iconLoading: false });
                });
        });
    };

    //ABRE O DRAWER PARA CRIAR NOVA NEWSLETTER
    showDrawerAdd = () => {
        this.props.form.resetFields();
        this.setState({
            nome: "",
            assunto: "",
            texto: "",
            visible: true,
            editar: false,
            paginaHTML: true,
            setHTML: true
        });
    };

    //FECHA O DRAWER SEM GAURDAR A NEWSLETTER
    onCloseAdd = () => {
        this.setState({
            visible: false,
            editar: false
        });
    };

    //FECHA O DRAWER DE ENVIO DE NEWSLETTER
    onCloseEnviar = () => {
        this.setState({
            visibleEnviar: false
        });
    };

    //*******EDITOR*******
    carregaHTML = () => {
        this.setState({
            setHTML: false
        });
    };

    resetStateEditor = () => {
        this.setState({
            resetEditor: false
        });
    };

    //log = (erro, pedido) => {
    //    axios({
    //        method: "post",
    //        url: "/api/logs/registar-erros",
    //        params: {
    //            erro,
    //            pedido
    //        }
    //    });
    //};

    //MONTA OPÇÕES DE MENU POR NEWSLETTER ENVIADA
    montarMenuLvl2 = newsletterid => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarNewsletter(newsletterid)}>
                        Visualizar
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //FECHA O DRAWER NEWSLETTER
    carregarNewsletter = newsletterid => {
        axios({
            method: "get",
            url: "/api/gestao-newsletters/detalhe-enviadas",
            params: {
                newsletterid
            }
        })
            .then(response => {
                var conteudo = JSON.parse(response.data.conteudo);
                this.setState({
                    newsletterData: moment(response.data.dt_criado).format("DD/MM/YYYY HH:mm"),
                    newsletterAssunto: conteudo.assunto,
                    newsletterTexto: conteudo.texto,
                    visibleNewsletter: true
                });
            })
            .catch(() => {
                //   this.log("Tentativa de carregar newsletter", "/api/administracao-newsletters/detalhe");
            });
    };

    //FECHA O DRAWER NEWSLETTER
    fecharNewsletter = () => {
        this.setState({
            visibleNewsletter: false
        });
    };

    afterVisibleChange = visible => {
        this.setState({ show: visible });
    };

    render() {
        const {
            newsletters,
            nome,
            assunto,
            texto,
            iconLoading,
            visible,
            editar,
            //ENVIAR
            tipo_enviar,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER NEWSLETTER
            visibleNewsletter,
            newsletterData,
            newsletterAssunto,
            newsletterTexto,
            //EDITOR
            show
        } = this.state;

        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const columns = [
            {
                title: "Nome",
                dataIndex: "nome"
            },
            {
                title: "Criada a",
                dataIndex: "dt_criado"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        const radioStyle = {
            display: "block",
            height: "30px",
            lineHeight: "30px"
        };

        const expandedRowRender = dados => {
            const columns = [
                {
                    title: "Assunto",
                    dataIndex: "assunto",
                    key: "assunto"
                },
                {
                    title: "Enviada a",
                    dataIndex: "data",
                    key: "data"
                },
                {
                    title: "Destinatários",
                    dataIndex: "destinatarios",
                    key: "destinatarios"
                },
                {
                    title: "",
                    dataIndex: "opcoes",
                    key: "opcoes"
                }
            ];

            return (
                <Table columns={columns} dataSource={dados.enviados} pagination={false} locale={{ emptyText: "Não existem registos!" }} />
            );
        };

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Newsletters" />
                <div className="container">
                    <div className="bloco-formadores">
                        <div className="bloco-tabela">
                            <div className="filtros">
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />
                            </div>
                            <Table
                                id="tabela_categorias"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={!loading_table ? newsletters : null}
                                expandedRowRender={expandedRowRender}
                                pagination={false}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="opcoes-meus-cursos">
                    <Link to="#" className="btn-adicionar" title="Adicionar" onClick={this.showDrawerAdd}>
                        +
                    </Link>
                </div>
                <Drawer
                    className="drawer-add-cursos"
                    title={editar ? "Editar newsletter" : "Criar newsletter"}
                    width={720}
                    onClose={this.onCloseAdd}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label={<span>Nome</span>}>
                                {getFieldDecorator("nome", {
                                    initialValue: nome,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="nome" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label={<span>Assunto</span>}>
                                {getFieldDecorator("assunto", {
                                    initialValue: assunto
                                })(<Input name="assunto" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label={<span>Texto</span>}>
                                {show && <Editor value={texto} onChange={this.handleChangeEditor} />}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onCloseAdd} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {editar ? (
                            <button className="botao-principal" onClick={this.guardar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                            <button className="botao-principal" onClick={this.criar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                            </button>
                        )}
                    </div>
                </Drawer>
                <Drawer
                    className="drawer-add-cursos"
                    title="Newsletter"
                    width={720}
                    onClose={this.fecharNewsletter}
                    visible={visibleNewsletter}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form>
                            <Form.Item label="Data">{newsletterData}</Form.Item>
                            <Form.Item label="Assunto">{newsletterAssunto}</Form.Item>
                            <Form.Item label="Texto">
                                <p dangerouslySetInnerHTML={{ __html: newsletterTexto.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button
                            className="botao-secundario"
                            onClick={this.fecharNewsletter}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Modal
                    title="Selecionar tipo de destinatário"
                    visible={this.state.visibleEnviar}
                    onOk={this.enviar}
                    okText="Enviar"
                    confirmLoading={iconLoading}
                    onCancel={() => this.setState({ visibleEnviar: false })}
                    cancelText="Cancelar"
                >
                    <Form layout="horizontal">
                        <Form.Item>
                            <RadioGroup onChange={this.handleChangeRadio} value={tipo_enviar}>
                                <Radio style={radioStyle} value={1}>
                                    Todos
                                </Radio>
                                <Radio style={radioStyle} value={2}>
                                    Administradores
                                </Radio>
                                <Radio style={radioStyle} value={3}>
                                    Formadores
                                </Radio>
                                <Radio style={radioStyle} value={4}>
                                    Formandos
                                </Radio>
                                <Radio style={radioStyle} value={5}>
                                    Teste{" "}
                                    <Tooltip className="info-icon" title="Enviar para mim uma newsletter de teste">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Radio>
                            </RadioGroup>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const FormNewsletters = Form.create({ name: "form-newsletters" })(Newsletters);

export default FormNewsletters;
