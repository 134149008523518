import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
    Drawer,
    Table,
    Form,
    Input,
    InputNumber,
    Dropdown,
    Menu,
    notification,
    Icon,
    Modal,
    Select,
    DatePicker,
    Tooltip,
    Checkbox,
    Switch,
    Upload,
    Button
} from "antd";
import Header from "../User/Header";
import noimage from "../images/noimage.png";
import axios from "axios";
import moment from "moment";
import BotaoProcurar from "../Geral/_Aux/BotaoProcurar";
import { GlobalContext } from "../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Documentacao extends Component {
    static contextType = GlobalContext;
    state = {
        documentos: [],
        //DRAWER
        visible: false,
        documentoId: 0,
        nome: "",
        fileList: [],
        //LOADING
        iconLoading: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listarDocumentos();
    }

    handlerChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    listarDocumentos = () => {
        axios({
            method: "get",
            url: "/api/administracao-entidades/listar-documentos",
            params: {
                entidadeid: localStorage.getItem("entidade_id")
            }
        })
            .then(response => {
                var documentos = [];

                response.data.map(documento => {
                    documentos.push({
                        id: documento.id,
                        tipo: documento.nome.split(".").pop(),
                        nome: documento.nome,
                        data: documento.dt_alterado
                            ? moment(documento.dt_alterado).format("DD/MM/YYYY")
                            : moment(documento.dt_criado).format("DD/MM/YYYY"),
                        autor: documento.autor && documento.autor + (documento.entidade ? "(Stepforma)" : ""),
                        link: documento.ficheiro
                    });
                });

                this.setState({
                    documentos
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    carregarDetalhe = documento => {
        this.props.form.resetFields();
        this.setState({
            documentoId: documento.id,
            nome: documento.nome.replace("." + documento.nome.split(".").pop(), ""),
            fileList: [
                {
                    uid: "-1",
                    name: documento.nome,
                    status: "done",
                    url: documento.link
                }
            ],
            visible: true
        });
    };

    excluir = documentoId => {
        confirm({
            title: "Pretende excluir este ficheiro?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/administracao-entidades/excluir-documento",
                    params: {
                        documentoId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Ficheiro excluido!");
                        var documentos = this.state.documentos.filter(x => x.id != documentoId);
                        this.setState({
                            documentos
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o ficheiro!");
                    });
            }
        });
    };

    //ABRE O DRAWER A VAZIO PARA ADICIONAR UMA NOVA ENTIDADE
    abrirDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            visible: true,
            documentoId: 0,
            nome: "",
            fileList: []
        });
    };

    guardarFicheiro = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("nome", this.state.nome + "." + this.state.fileList[0].name.split(".").pop());
                item.append("ficheiro", this.state.fileList[0]);
                item.append("entidadeid", localStorage.getItem("entidade_id"));

                axios({
                    method: "post",
                    url: "/api/administracao-entidades/adicionar-documento",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Documento adicionado!");
                        this.listarDocumentos();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    alterarFicheiro = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.documentoId);
                item.append("nome", this.state.nome + "." + this.state.fileList[0].name.split(".").pop());
                item.append("ficheiro", this.state.fileList[0]);
                item.append("entidadeid", localStorage.getItem("entidade_id"));

                axios({
                    method: "put",
                    url: "/api/administracao-entidades/alterar-documento",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ficheiro alterado!");
                        this.listarDocumentos();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    validar = (rule, value, callback) => {
        if (value.file)
            if (!this.validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status != "removed")
                callback("Ficheiro com formato inválido");
            else if (value.file.status === "removed") callback("Campo obrigatório");

        callback();
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoFicheiro = formato => {
        var formatos = [
            "zip",
            "rar",
            "7z",
            "docx",
            "doc",
            "pdf",
            "odf",
            "txt",
            "rtf",
            "xlsx",
            "xls",
            "csv",
            "ods",
            "pptx",
            "ppt",
            "odp",
            "bmp",
            "gif",
            "png",
            "jpeg",
            "jpg"
        ];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const Dragger = Upload.Dragger;
        const {
            documentos,
            //DRAWER
            visible,
            documentoId,
            nome,
            fileList,
            //LOADING
            iconLoading
        } = this.state;

        const opcoes = documento => {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.carregarDetalhe(documento)}>
                            Editar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluir(documento.id)}>
                            Excluir
                        </Link>
                    </Menu.Item>
                </Menu>
            );
        };

        const montar_icon = tipo => {
            if (tipo === "pdf") {
                return (
                    <div className="start" title="Editor" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/pdf.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "zip" || tipo === "rar" || tipo === "7z") {
                return (
                    <div className="start" title="Ficheiro" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/compress.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "docx" || tipo === "doc" || tipo === "odf") {
                return (
                    <div className="start" title="Word" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/word.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "txt" || tipo === "rtf") {
                return (
                    <div className="start" title="Word" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/text.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "xlsx" || tipo === "xls" || tipo === "csv" || tipo === "ods") {
                return (
                    <div className="start" title="Word" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/excel.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "pptx" || tipo === "ppt" || tipo === "odp") {
                return (
                    <div className="start" title="Word" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/powerpoint.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else {
                return (
                    <div className="start" title="Word" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../images/icons/images.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            }
        };

        const props = {
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("ficheiro");
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    return {
                        fileList: newFileList
                    };
                });
            },
            defaultFileList: this.state.fileList,
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }));

                return false;
            },
            fileList
        };

        return (
            <div>
                <Header titulo="Documentação" />
                <div className="container container-body">
                    <div className="bloco-entidades">
                        <div className="bloco">
                            <div className="bloco-flex">
                                <div className="bloco-info" style={{ padding: 0 }}>
                                    <div className="dados-user">
                                        <div className="container-img">
                                            <img src={localStorage.getItem("entidade_logo") ? localStorage.getItem("entidade_logo") : noimage} alt="" />
                                        </div>
                                        <h3>{localStorage.getItem("entidade_nome")}</h3>
                                    </div>
                                </div>
                                <Link
                                    to="/administracao-entidades"
                                    className="botao-principal"
                                    style={{ display: "inline" }}
                                >
                                    Voltar
                                </Link>
                            </div>
                            <div className="bloco-documentacao">
                                <div className="box-ficheiros">
                                    <div className="header">
                                        <span className="desc"></span>
                                        <span className="desc">Nome</span>
                                        <span className="desc">Modificado</span>
                                        <span className="desc">Autor</span>
                                        <span className="desc"></span>
                                        <span className="desc"></span>
                                    </div>
                                    {documentos.length > 0 ? (
                                        documentos.map(documento => (
                                            <div className="content" key={documento.id}>
                                                <span className="desc">{montar_icon(documento.tipo)}</span>
                                                <span className="desc">{documento.nome}</span>
                                                <span className="desc">{documento.data}</span>
                                                <span className="desc">{documento.autor}</span>
                                                <span className="desc" style={{ textAlign: "right" }}>
                                                    <a href={documento.link} download={documento.nome} title="Descarregar">
                                                        <Icon type="download" />
                                                    </a>
                                                </span>
                                                <span className="desc">
                                                    <Dropdown overlay={opcoes(documento)} placement="bottomLeft">
                                                        <Link to="#" className="botao-icon-configs">
                                                            <i className="fas fa-cog" />
                                                        </Link>
                                                    </Dropdown>
                                                </span>
                                            </div>
                                        ))
                                    ) : (
                                            <div className="sem-registos">Não existem registos!</div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opcoes-meus-cursos">
                    <Link to="#" className="btn-adicionar" title="Adicionar" onClick={this.abrirDrawer}>
                        +
                    </Link>
                </div>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias"
                    title={documentoId > 0 ? "Alterar" : "Adicionar documento"}
                    width={720}
                    onClose={() => this.setState({ visible: false })}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Ficheiro">
                                <div className="anexos">
                                    {getFieldDecorator("ficheiro", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            },
                                            {
                                                validator: this.validar
                                            }
                                        ],
                                        initialValue: fileList
                                    })(
                                        <Dragger {...props}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexo{" "}
                                                <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                        </Dragger>
                                    )}
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <Link
                            to="#"
                            className="botao-secundario"
                            onClick={() => this.setState({ visible: false })}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                        </Link>
                        {documentoId > 0 ? (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.alterarFicheiro}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                                <button className="botao-principal" disabled={iconLoading} onClick={this.guardarFicheiro}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                                </button>
                            )}
                    </div>
                </Drawer>
            </div>
        );
    }
}
const FormDocumentacao = Form.create({ name: "admin-documentacao" })(Documentacao);

export default FormDocumentacao;
