import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Icon, notification, Radio, Modal } from "antd";
import { Link, Redirect } from "react-router-dom";
import { GlobalContext } from "../GlobalState";
import axios from "axios";
import Header from "../User/Header";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class CorrigirLogsJitsi extends Component {
    state = {
        iconLoading: false
    }
    criarCsv = () => {
        this.setState({ iconLoading: true });
        axios({
            method: "post",
            url: "/api/corrigir-jitsi-logs/criar-csv-meets"
        })
            .then(response => {
                this.setState({ iconLoading: false });
            })
            .catch(error => {
                this.setState({ iconLoading: false });
                openNotificationWithIcon("error", "Erro", "Falhou");
            });
    }

    registosAcima = () => {
        this.setState({ iconLoading: true });
        axios({
            method: "post",
            url: "/api/corrigir-jitsi-logs/meets-registos-acima"
        })
            .then(response => {
                this.setState({ iconLoading: false });
            })
            .catch(error => {
                this.setState({ iconLoading: false });
                openNotificationWithIcon("error", "Erro", "Falhou");
            });
    }

    registosAbaixo = () => {
        this.setState({ iconLoading: true });
        axios({
            method: "post",
            url: "/api/corrigir-jitsi-logs/meets-registos-abaixo"
        })
            .then(response => {
                this.setState({ iconLoading: false });
            })
            .catch(error => {
                this.setState({ iconLoading: false });
                openNotificationWithIcon("error", "Erro", "Falhou");
            });
    }

    corrigirLogOuts = () => {
        this.setState({ iconLoading: true });
        axios({
            method: "post",
            url: "/api/corrigir-jitsi-logs/corrigir-logouts"
        })
            .then(response => {
                this.setState({ iconLoading: false });
            })
            .catch(error => {
                this.setState({ iconLoading: false });
                openNotificationWithIcon("error", "Erro", "Falhou");
            });
    }

    corrigirUsersSemSaida = () => {
        this.setState({ iconLoading: true });
        axios({
            method: "post",
            url: "/api/corrigir-jitsi-logs/corrigir-users-sem-saida"
        })
            .then(response => {
                this.setState({ iconLoading: false });
            })
            .catch(error => {
                this.setState({ iconLoading: false });
                openNotificationWithIcon("error", "Erro", "Falhou");
            });
    }

    menosUmHora = () => {
        this.setState({ iconLoading: true });
        axios({
            method: "post",
            url: "/api/corrigir-jitsi-logs/menos-um-hora"
        })
            .then(response => {
                this.setState({ iconLoading: false });
            })
            .catch(error => {
                this.setState({ iconLoading: false });
                openNotificationWithIcon("error", "Erro", "Falhou");
            });
    }

    invalidarEntrys = () => {
        this.setState({ iconLoading: true });
        axios({
            method: "post",
            url: "/api/corrigir-jitsi-logs/invalidar-entrys"
        })
            .then(response => {
                this.setState({ iconLoading: false });
            })
            .catch(error => {
                this.setState({ iconLoading: false });
                openNotificationWithIcon("error", "Erro", "Falhou");
            });
    }

    render() {
        const {
            iconLoading
        } = this.state;

        return (
            <div>
                <h3>Criar CSV</h3>
                <Button type="primary" className="login-form-button" onClick={this.criarCsv}>
                    Iniciar
                </Button>
                <hr />
                <h3>Corrigir LogOuts - Passo 1</h3>
                <Button type="primary" className="login-form-button" onClick={this.corrigirLogOuts}>
                    Iniciar
                </Button>
                <hr />
                <h3>Corrigir Acima- Passo 2</h3>
                <Button type="primary" className="login-form-button" onClick={this.registosAcima}>
                    Iniciar
                </Button>
                <hr />
                <h3>Corrigir Abaixo - Passo 3</h3>
                <Button type="primary" className="login-form-button" onClick={this.registosAbaixo}>
                    Iniciar
                </Button>
                <hr />
                <h3>Adicionar registos para users sem Saida - Passo 4</h3>
                <Button type="primary" className="login-form-button" onClick={this.corrigirUsersSemSaida}>
                    Iniciar
                </Button>
                <hr />
                <h3>Corrigir menos que 1h, 1h a 2h e 2h a 3h - Passo 5</h3>
                <Button type="primary" className="login-form-button" onClick={this.menosUmHora}>
                    Iniciar
                </Button>
                <hr />
                <h3>Invalidar Entrys - Passo 6</h3>
                <Button type="primary" className="login-form-button" onClick={this.invalidarEntrys}>
                    Iniciar
                </Button>
                <hr />
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A tratar...</p>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default CorrigirLogsJitsi;
