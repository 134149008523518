import React, { Component } from "react";
import { Link, matchPath, withRouter } from "react-router-dom";
import { GlobalContext } from "../../GlobalState";

class Menu extends Component {
    static contextType = GlobalContext;

    render() {
        const { entidade, curso, cursoid } = this.props.match.params;
        var inicioUrl = `/${entidade}/curso/${curso}/${cursoid}`;

        let menuOpcaoAtivo = "";

        const match = matchPath(window.location.pathname, {
            path: "/:entidade/curso/:curso/:cursoid/:pagina"
        });

        if (match && match.params.pagina) menuOpcaoAtivo = match.params.pagina;

        return (
            <>
                {this.context.atividadesLivres &&
                    <div className="menu-detalhe-curso menu-detalhe-curso-v2">
                        <div className="container">
                            <ul>
                                <li>
                                    <Link to={`${inicioUrl}/curso-visao-geral`}
                                        className={menuOpcaoAtivo == "curso-visao-geral" ? "ativo" : ""}
                                    >
                                        Visão Geral
                                </Link>
                                </li>
                                <li>
                                    <Link to={`${inicioUrl}/curso-modulos`} className={menuOpcaoAtivo == "curso-modulos" ? "ativo" : ""}>
                                        Atividades
                                        </Link>
                                </li>
                            </ul>
                        </div>
                    </div>}
                {this.context.disciplinasVisiveis &&
                    <div className="menu-detalhe-curso menu-detalhe-curso-v2">
                        <div className="container">
                            <ul>
                                <li>
                                    <Link to={`${inicioUrl}/curso-visao-geral`}
                                        className={menuOpcaoAtivo == "curso-visao-geral" ? "ativo" : ""}
                                    >
                                        Visão Geral
                                </Link>
                                </li>
                                <li>
                                    <Link to={`${inicioUrl}/curso-cursos`} className={menuOpcaoAtivo == "curso-cursos" ? "ativo" : ""}>
                                    Disciplinas
                                        </Link>
                                </li>
                            </ul>
                        </div>
                    </div>}
            </>
        );
    }
}

export default withRouter(Menu);
