import React, { Component } from "react";
import { Link } from "react-router-dom";
import WhatsAppButton from "../../Geral/WhatsAppButton";
import RedesSociais from "../../Geral/_Aux/RedesSociais";
import axios from "axios";
import moment from "moment";
import { Form, Input, Checkbox, notification, Icon } from "antd";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Pagina extends Component {
    state = {
        titulo: "",
        artigos: [],
        total: 0,
        page: 2,
        loading: false,
        visivelTeste: false,
        //FORMULARIO
        nome: "",
        email: "",
        assunto: "",
        mensagem: "",
        novidades: false,
        politicaAberta: false,
        politica: false,
        //LOADING
        iconLoading: false,
    };

    UNSAFE_componentWillMount() {
        this.listar();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    listar = () => {
        this.setState(
            {
                loading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/anonimo/listar-informacoes"
                })
                    .then(response => {
                        if (response.data.informacoes)
                            this.setState({
                                titulo: response.data.titulo,
                                artigos: response.data.informacoes,
                                total: response.data.total,
                                loading: false
                            });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    }

    carregar = () => {
        this.setState({
            loading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/anonimo/carregar-mais-informacoes",
                params: {
                    page: this.state.page
                }
            })
                .then(response => {
                    var artigos = [...this.state.artigos, ...response.data];

                    this.setState({
                        artigos,
                        loading: false,
                        page: this.state.page + 1
                    });
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });
                });
        });

    }

    enviar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (!this.state.politica) {
                    openNotificationWithIcon("error", "Erro", "Aceite a nossa Politica de Privacida para poder entrar em contato connosco");
                    return;
                }

                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("nome", this.state.nome);
                item.append("email", this.state.email);
                item.append("assunto", this.state.assunto);
                item.append("mensagem", this.state.mensagem);
                item.append("novidades", this.state.novidades);

                axios({
                    method: "post",
                    url: "/api/anonimo/contact-home",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Contato enviado!");
                        this.setState({
                            iconLoading: false,
                            nome: "",
                            email: "",
                            assunto: "",
                            mensagem: "",
                            novidades: false,
                            politica: false,
                        });
                        this.props.form.resetFields();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível enviar a mensagem de contato!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            titulo,
            artigos,
            total,
            //FORMULARIO
            nome,
            email,
            assunto,
            mensagem,
            novidades,
            politicaAberta,
            politica,
            //LOADING
            iconLoading,
        } = this.state;

        return (
            <>
                <div className="informacoes">
                    {localStorage.getItem("whatsapp") ?
                        <RedesSociais
                            whatsapp
                            numero={localStorage.getItem("whatsapp")}
                            mensagem="Eu tenho interesse em aderir &agrave; Stepforma"
                            title="Canal Whatsapp Stepforma"
                        />
                        : null}
                    <div className="container container-body">
                        <div className="bloco-informacoes">
                            {artigos.map((artigo, index) => (
                                <>
                                    {artigo.imagem || artigo.video ?
                                        <>
                                            <div className="informacao informacao-bloco informacao-bloco-padding informacao-bloco-desktop" style={{ backgroundColor: artigo.corFundo, color: artigo.corFonte }}>
                                                {artigo.posicao === 1 ?
                                                    <>
                                                        <div className="container-img">
                                                            {artigo.imagem ?
                                                                <img src={artigo.imagem} />
                                                                :
                                                                <iframe src={`https://player.vimeo.com/video/${artigo.video}`} id="player" data-ready="true"></iframe>}
                                                        </div>
                                                        <div className="bloco-texto">
                                                            <span className="titulo">{artigo.titulo}</span>
                                                            {/*<span className="data">{moment(artigo.dt_alterado).format("DD MMMM YYYY")}</span>*/}
                                                            <div className="texto" dangerouslySetInnerHTML={{ __html: artigo.texto }}>

                                                            </div>
                                                            {artigo.link ?
                                                                <a href={artigo.link} className="link">Clique aqui</a>
                                                                : null}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="bloco-texto">
                                                            <span className="titulo">{artigo.titulo}</span>
                                                            {/*<span className="data">{moment(artigo.dt_alterado).format("DD MMMM YYYY")}</span>*/}
                                                            <div className="texto" dangerouslySetInnerHTML={{ __html: artigo.texto }}>

                                                            </div>
                                                            {artigo.link ?
                                                                <a href={artigo.link} className="link">Clique aqui</a>
                                                                : null}
                                                        </div>
                                                        <div className="container-img">
                                                            {artigo.imagem ?
                                                                <img src={artigo.imagem} />
                                                                :
                                                                <iframe src={`https://player.vimeo.com/video/${artigo.video}`} id="player" data-ready="true"></iframe>}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className="informacao informacao-bloco informacao-bloco-padding informacao-bloco-mobile" style={{ backgroundColor: artigo.corFundo, color: artigo.corFonte }}>
                                                <div className="bloco-texto">
                                                    <span className="titulo">{artigo.titulo}</span>
                                                    {/*<span className="data">{moment(artigo.dt_alterado).format("DD MMMM YYYY")}</span>*/}
                                                    <div className="texto" dangerouslySetInnerHTML={{ __html: artigo.texto }}>

                                                    </div>
                                                    {artigo.link ?
                                                        <a href={artigo.link} className="link">Clique aqui</a>
                                                        : null}
                                                </div>
                                                <div className="container-img">
                                                    {artigo.imagem ?
                                                        <img src={artigo.imagem} />
                                                        :
                                                        <iframe src={`https://player.vimeo.com/video/${artigo.video}`} id="player" data-ready="true"></iframe>}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="informacao informacao-bloco informacao-bloco-padding informacao-bloco-texto informacao-bloco-desktop" style={{ backgroundColor: artigo.corFundo, color: artigo.corFonte }}>
                                                <div className="bloco-texto">
                                                    <span className="titulo">{artigo.titulo}</span>
                                                    {/*<span className="data">{moment(artigo.dt_alterado).format("DD MMMM YYYY")}</span>*/}
                                                    <div className="texto" dangerouslySetInnerHTML={{ __html: artigo.texto }}>

                                                    </div>
                                                    {artigo.link ?
                                                        <a href={artigo.link} className="link">Clique aqui</a>
                                                        : null}
                                                </div>
                                            </div>
                                            <div className="informacao informacao-bloco informacao-bloco-padding informacao-bloco-texto informacao-bloco-mobile" style={{ backgroundColor: artigo.corFundo, color: artigo.corFonte }}>
                                                <div className="bloco-texto">
                                                    <span className="titulo">{artigo.titulo}</span>
                                                    {/*<span className="data">{moment(artigo.dt_alterado).format("DD MMMM YYYY")}</span>*/}
                                                    <div className="texto" dangerouslySetInnerHTML={{ __html: artigo.texto }}>

                                                    </div>
                                                    {artigo.link ?
                                                        <a href={artigo.link} className="link">Clique aqui</a>
                                                        : null}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {index % 2 === 0 ?
                                        <div className="separador-bloco sem-position" style={{ background: `linear-gradient(to right bottom, ${artigo.corFundo} 49%, ${(index + 1) < artigos.length ? artigos[index + 1].corFundo : "#16817a"} 50%), linear-gradient(-50deg, ${(index + 1) < artigos.length ? artigos[index + 1].corFundo : "#16817a"} 16px, #000 0)` }}></div>
                                        :
                                        <div className="separador-bloco sem-position" style={{ background: `linear-gradient(to left bottom, ${artigo.corFundo} 49%, ${(index + 1) < artigos.length ? artigos[index + 1].corFundo : "#16817a"} 50%)` }}></div>}

                                </>
                            )
                            )}
                            <div className="bloco-ver-mais bloco-ver-mais-verde">
                                {artigos.length < total ?
                                    <button className="botao-principal" onClick={this.carregar}>{this.state.loading ? "A carregar..." : "Ver mais"}</button>
                                    : null}
                            </div>
                            <div id="formulario" className="informacao-formulario">
                                <div className="bloco-formulario">
                                    <h2>Entre em contato conosco</h2>
                                    <h3>Coloque as suas questões ou comentários</h3>
                                    <Form className="formulario-contacto">
                                        <Form.Item>
                                            {getFieldDecorator("nome", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: nome
                                            })(<Input name="nome" placeholder="Nome*" onChange={this.handleChange} />)}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator("email", {
                                                rules: [
                                                    {
                                                        type: "email",
                                                        message: "Introduza um endereço de e-mail válido"
                                                    },
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: email
                                            })(<Input name="email" placeholder="Email*" onChange={this.handleChange} />)}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator("assunto", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: assunto
                                            })(<Input name="assunto" placeholder="Assunto*" onChange={this.handleChange} />)}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator("mensagem", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: mensagem
                                            })(<TextArea name="mensagem" placeholder="Mensagem*" style={{ height: "180px" }} resize onChange={this.handleChange} />)}
                                        </Form.Item>
                                        <Form.Item>
                                            <Checkbox name="novidades" checked={novidades} onChange={this.handleChangeCheckbox}>Eu concordo em receber novidades da Stepforma.</Checkbox>
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator("politica", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: politica
                                            })(<Checkbox name="politica" checked={politica} disabled={!politicaAberta} onChange={this.handleChangeCheckbox}>Eu concordo com a <Link to="/politica-privacidade" target="_blank" className="info-destaque" onClick={() => this.setState({ politicaAberta: true })}>política de privacidade</Link> da Stepforma.</Checkbox>)}
                                        </Form.Item>
                                        <div className="bloco-botao-enviar">
                                            <button className="botao-principal" disabled={iconLoading} onClick={this.enviar}>
                                                {iconLoading ? <Icon type="loading" /> : null}
                                                Enviar
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const FormPagina = Form.create({ name: "formulario-home" })(Pagina);

export default FormPagina;
