import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Icon, notification, Radio, Modal } from "antd";
import { Link, Redirect } from "react-router-dom";
import { GlobalContext } from "../GlobalState";
import axios from "axios";
import Header from "../User/Header";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class Testes extends Component {
    state = {
        link: "",
        iconLoading: false
    }

    testes = () => {
        this.setState({ iconLoading: true });
        axios({
            method: "post",
            url: "/api/anonimo-testes/finalizar"
        })
            .then(response => {
                this.setState({
                    link: response.data,
                    iconLoading: false
                });
            })
            .catch(error => {
                this.setState({ iconLoading: false });
            });
    }

    render() {
        const {
            link,
            iconLoading
        } = this.state;

        return (
            <div>
                <h3>Gerar PDF</h3>
                <Button type="primary" className="login-form-button" onClick={this.testes}>
                    Gerar
                                </Button>
                <hr />
                <a href={link} download>{iconLoading ? "A gerar..." : "Descarregar"}</a>
            </div>
        );
    }
}

export default Testes;
