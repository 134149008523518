import React, { Component } from "react";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import { Dropdown, notification, Icon, Select, Switch } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class HeaderColaboradores extends Component {
    static contextType = GlobalContext;
    state = {
        colaboradores: [],
        anos: [],
        ano: "",
        iconLoading: false
    };

    reenviarTodos = () => {
        this.setState({ iconLoading: true });

        axios({
            method: "put",
            url: "/api/colaboradores/reenviar-todos"
        })
            .then(() => {
                this.props.obterDados();
                openNotificationWithIcon("success", "Sucesso", "Pedidos de registo reenviados!");
                this.setState({ iconLoading: false });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível reenviar pedidos de registo!");
                this.setState({ iconLoading: false });
            });
    };

    render() {
        const { iconLoading } = this.state;

        return (
            <div className="header-colaboradores">
                {this.props.filtros && (
                    <div className="filtros-topo">
                        <div className="botao-procurar">
                            <BotaoProcurar HandleKeyPress={this.props.HandleKeyPress} resetCaixaProcura={this.props.resetCaixaProcura} />
                        </div>
                        <div className="select-filtro">
                            <Select
                                placeholder="Perfis"
                                disabled={!this.props.tem_filtro}
                                name="filtroPerfis"
                                allowClear={true}
                                onChange={this.props.filtrar}
                            >
                                <Option value="1">Gestores</Option>
                                <Option value="2">Instrutores</Option>
                                <Option value="3">Participantes</Option>
                            </Select>
                        </div>
                        {this.context.escolas_entidade && (
                            <div className="select-filtro">
                                <Select
                                    placeholder="Unidades Organizacionais"
                                    disabled={!this.props.tem_filtro}
                                    name="filtroEscolas"
                                    allowClear={true}
                                    onChange={this.props.filtrarOrganismo}
                                >
                                    {this.props.temEscolas &&
                                        this.props.escolas.map(escola => (
                                            <Option key={escola.id} value={escola.id}>
                                                {escola.nome}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                        )}
                        <Switch
                            checkedChildren={!this.props.switch_valido ? "Ativos" : "Válidos"}
                            unCheckedChildren={!this.props.switch_valido ? "Inativos" : "Inválidos"}
                            defaultChecked
                            disabled={!this.props.tem_switch}
                            onChange={this.props.handleChangeSwitch}
                        />
                    </div>
                )}
                {this.props.botoesMensagens && (
                    <>
                        <div className="botao-enviar-mensagens">
                            {this.props.headerTodos ? (
                                <>
                                    {(this.props.valor === "3" || !this.props.valor) && (
                                        <Link
                                            to="/gestao-utilizadores-todos/novo-ticket/todos"
                                            className={this.props.nr_colaboradores <= 0 ? "botao-principal-disabled" : "botao-principal"}
                                            disabled={this.props.nr_colaboradores <= 0}
                                            title="Enviar mensagem para todos os Participantes"
                                        >
                                            <i className="far fa-envelope"></i> Participantes
                                        </Link>
                                    )}
                                    {(this.props.valor === "2" || !this.props.valor) && (
                                        <Link
                                            to="/gestao-utilizadores-todos/novo-ticket/instrutores"
                                            className={this.props.nr_colaboradores <= 0 ? "botao-principal-disabled" : "botao-principal"}
                                            disabled={this.props.nr_colaboradores <= 0}
                                            title="Enviar mensagem para todos os Instrutores"
                                        >
                                            <i className="far fa-envelope"></i> Instrutores
                                        </Link>
                                    )}
                                    {(this.props.valor === "1" || !this.props.valor) && (
                                        <Link
                                            to="/gestao-utilizadores-todos/novo-ticket/gestores"
                                            className={this.props.nr_colaboradores <= 0 ? "botao-principal-disabled" : "botao-principal"}
                                            disabled={this.props.nr_colaboradores <= 0}
                                            title="Enviar mensagem para todos os Gestores"
                                        >
                                            <i className="far fa-envelope"></i> Gestores
                                        </Link>
                                    )}
                                    <Dropdown overlay={this.props.menu} placement="topCenter" className="botao-icon-configs">
                                        <i className={this.props.pendente ? "fas fa-cog pendente" : "fas fa-cog"} />
                                    </Dropdown>
                                </>
                            ) : null}
                            {this.props.headerPendentes ? (
                                <Link
                                    to="#"
                                    className="botao-principal"
                                    onClick={this.reenviarTodos}
                                    disabled={iconLoading || this.props.nr_colaboradores <= 0}
                                    title="Reenviar mensagem de registo para todos"
                                >
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Reenviar email de registo para todos
                                </Link>
                            ) : null}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default HeaderColaboradores;
