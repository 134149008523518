import React, { Component } from "react";
import Header from "./Header";
import axios from "axios";
import moment from "moment";

class Pagina extends Component {
    state = {
        titulo: "",
        artigos: [],
        total: 0,
        page: 2,
        loading: false,
        visivelTeste: false
    };

    UNSAFE_componentWillMount() {
        this.listar();
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.listar();
        }
    }

    listar = () => {
        this.setState(
            {
                loading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/pagina-entidade/listar-pagina-informacoes",
                    params: {
                        pagina: this.props.match.params.pagina
                    }
                })
                    .then(response => {
                        this.setState({
                            titulo: response.data.titulo,
                            artigos: response.data.informacoes,
                            total: response.data.total,
                            loading: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    }

    carregar = () => {
        this.setState({
            loading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/pagina-entidade/carregar-mais-informacoes",
                params: {
                    page: this.state.page,
                    pagina: this.props.match.params.pagina
                }
            })
                .then(response => {
                    var artigos = [...this.state.artigos, ...response.data];

                    this.setState({
                        artigos,
                        loading: false,
                        page: this.state.page + 1
                    });
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });
                });
        });

    }

    render() {
        const {
            titulo,
            artigos,
            total,
        } = this.state;

        return (
            <>
                <div className="informacoes">
                    {/*<Header titulo={titulo} />*/}
                    <div className="container container-body" style={{ marginTop: 80 }}>
                        <div className="bloco-informacoes">
                            {artigos.map((artigo, index) => (
                                <>
                                    {artigo.imagem || artigo.video ?
                                        <>
                                            <div className="informacao informacao-bloco informacao-bloco-desktop" style={{ backgroundColor: artigo.corFundo, color: artigo.corFonte }}>
                                                {artigo.posicao === 1 ?
                                                    <>
                                                        <div className="container-img">
                                                            {artigo.imagem ?
                                                                <img src={artigo.imagem} />
                                                                :
                                                                <iframe src={`https://player.vimeo.com/video/${artigo.video}`} id="player" data-ready="true"></iframe>}
                                                        </div>
                                                        <div className="bloco-texto">
                                                            <span className="titulo">{artigo.titulo}</span>
                                                            {/*<span className="data">{moment(artigo.dt_alterado).format("DD MMMM YYYY")}</span>*/}
                                                            <div className="texto" dangerouslySetInnerHTML={{ __html: artigo.texto }}>

                                                            </div>
                                                            {artigo.link ?
                                                                <a href={artigo.link} className="link">Clique aqui</a>
                                                                : null}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="bloco-texto">
                                                            <span className="titulo">{artigo.titulo}</span>
                                                            {/*<span className="data">{moment(artigo.dt_alterado).format("DD MMMM YYYY")}</span>*/}
                                                            <div className="texto" dangerouslySetInnerHTML={{ __html: artigo.texto }}>

                                                            </div>
                                                            {artigo.link ?
                                                                <a href={artigo.link} className="link">Clique aqui</a>
                                                                : null}
                                                        </div>
                                                        <div className="container-img">
                                                            {artigo.imagem ?
                                                                <img src={artigo.imagem} />
                                                                :
                                                                <iframe src={`https://player.vimeo.com/video/${artigo.video}`} id="player" data-ready="true"></iframe>}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className="informacao informacao-bloco informacao-bloco-mobile" style={{ backgroundColor: artigo.corFundo, color: artigo.corFonte }}>
                                                <div className="bloco-texto">
                                                    <span className="titulo">{artigo.titulo}</span>
                                                    {/*<span className="data">{moment(artigo.dt_alterado).format("DD MMMM YYYY")}</span>*/}
                                                    <div className="texto" dangerouslySetInnerHTML={{ __html: artigo.texto }}>

                                                    </div>
                                                    {artigo.link ?
                                                        <a href={artigo.link} className="link">Clique aqui</a>
                                                        : null}
                                                </div>
                                                <div className="container-img">
                                                    {artigo.imagem ?
                                                        <img src={artigo.imagem} />
                                                        :
                                                        <iframe src={`https://player.vimeo.com/video/${artigo.video}`} id="player" data-ready="true"></iframe>}
                                                </div>
                                            </div>
                                        </>
                                        : 
                                        <>
                                            <div className="informacao informacao-bloco informacao-bloco-texto informacao-bloco-desktop" style={{ backgroundColor: artigo.corFundo, color: artigo.corFonte }}>
                                                <div className="bloco-texto">
                                                    <span className="titulo">{artigo.titulo}</span>
                                                    {/*<span className="data">{moment(artigo.dt_alterado).format("DD MMMM YYYY")}</span>*/}
                                                    <div className="texto" dangerouslySetInnerHTML={{ __html: artigo.texto }}>

                                                    </div>
                                                    {artigo.link ?
                                                        <a href={artigo.link} className="link">Clique aqui</a>
                                                        : null}
                                                </div>
                                            </div>
                                            <div className="informacao informacao-bloco informacao-bloco-texto informacao-bloco-mobile" style={{ backgroundColor: artigo.corFundo, color: artigo.corFonte }}>
                                                <div className="bloco-texto">
                                                    <span className="titulo">{artigo.titulo}</span>
                                                    {/*<span className="data">{moment(artigo.dt_alterado).format("DD MMMM YYYY")}</span>*/}
                                                    <div className="texto" dangerouslySetInnerHTML={{ __html: artigo.texto }}>

                                                    </div>
                                                    {artigo.link ?
                                                        <a href={artigo.link} className="link">Clique aqui</a>
                                                        : null}
                                                </div>
                                            </div>
                                        </>
                                        }
                                    {index % 2 === 0 ?
                                        <div className="separador-bloco" style={{ background: `linear-gradient(to right bottom, ${artigo.corFundo} 49%, ${(index + 1) < artigos.length ? artigos[index + 1].corFundo : "#16817a"} 50%), linear-gradient(-50deg, ${(index + 1) < artigos.length ? artigos[index + 1].corFundo : "#16817a"} 16px, #000 0)` }}></div>
                                        :
                                        <div className="separador-bloco" style={{ background: `linear-gradient(to left bottom, ${artigo.corFundo} 49%, ${(index + 1) < artigos.length ? artigos[index + 1].corFundo : "#16817a"} 50%)` }}></div>}

                                </>
                            )
                            )}
                            {total > 0 ?
                                <div className="bloco-ver-mais bloco-ver-mais-verde">
                                    {artigos.length < total ?
                                        <button className="botao-principal" onClick={this.carregar}>{this.state.loading ? "A carregar..." : "Ver mais"}</button>
                                        : null}
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Pagina;
