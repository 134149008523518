import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
    Drawer,
    Table,
    Form,
    Input,
    InputNumber,
    Dropdown,
    Menu,
    notification,
    Icon,
    Modal,
    Select,
    DatePicker,
    Tooltip,
    Checkbox,
    Switch,
    Upload,
    Button
} from "antd";
import Header from "../User/Header";
import noimage from "../images/noimage.png";
import axios from "axios";
import moment from "moment";
import BotaoProcurar from "../Geral/_Aux/BotaoProcurar";
import { GlobalContext } from "../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Entidades extends Component {
    static contextType = GlobalContext;
    state = {
        entidades: [],
        planos: [],
        //DRAWER
        entidadeid: 0,
        logo: "",
        preview: null,
        nome: "",
        nome_abrev: "",
        email_entidade: "",
        contacto_entidade: "",
        nr_colaboradores: 1,
        nif: "",
        morada: "",
        cod_postal: "",
        localidade: "",
        pais: "",
        formacao: true,
        projeto: false,
        organizacional: false,
        entidade_visivel: false,
        licencas: 1,
        folderId: 0,
        //DADOS DO REPRESENTANTE DO CLUBE
        nome_completo: "",
        email: "",
        contacto: "",
        utilizador: "",
        ativo: true,
        observacoes: "",
        visible: false,
        iconLoading: false,
        editar: false,
        //UPLOAD VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //PAGINATION
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        sorter: {
            order: "ascend",
            field: "nome"
        },
        //listaEliminados: []
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.total();
    }

    componentDidUpdate() {
        if (
            !this.context.is_authenticated ||
            this.context.role === "UTILIZADOR" ||
            this.context.role === "FORMADOR" ||
            this.context.role === "ENTIDADE"
        ) {
            if (!this.context.is_authenticated)
                this.setState({
                    redirect: true,
                    redirectLink: "/login"
                });
            else if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR" || this.context.role === "ENTIDADE")
                this.setState({
                    redirect: true,
                    redirectLink: "/user"
                });
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeNrColaboradores = value => {
        this.setState({ nr_colaboradores: value });
    };

    handleChangeSelect = value => {
        this.setState({ pais: value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeNrLicencas = value => {
        this.setState({ licencas: value });
    };

    handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = this.state.pagination;
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination,
                sorter
            },
            () => {
                this.listar();
            }
        );
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.total();
            }
        );
    };

    //CARREGAR TOTAL DE EMPRESAS
    total = () => {
        axios({
            method: "get",
            url: "/api/administracao-entidades/total-empresas",
            params: {
                pesquisa: this.state.pesquisa,
                ativo: this.state.ativo
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState(
                    {
                        pagination: _pagination
                    },
                    () => {
                        this.listar();
                    }
                );
            })
            .catch(() => {
                //  this.log("Tentativa de carregar o total de entidades", "/api/administracao-entidades/total-empresas");
            });
    };

    //CARREGAR TODAS AS ENTIDADES EM STEPFORMA
    listar = () => {
        var entidades = [];

        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/administracao-entidades",
                    params: {
                        pesquisa: this.state.pesquisa,
                        pageSize: this.state.pagination.pageSize,
                        page: this.state.pagination.current,
                        ativo: this.state.ativo,
                        order: this.state.sorter.order,
                        field: this.state.sorter.field
                    }
                })
                    .then(response => {
                        response.data.map((empresa, index) => {
                            entidades.push({
                                key: index,
                                id: empresa.id,
                                nome: (
                                    <>
                                        <div className="foto-colaborador">
                                            <img src={empresa.logo ? empresa.logo : noimage} />
                                        </div>
                                        <div className="nome-colaborador">
                                            <span className="titulo-curso" title={empresa.nome}>
                                                {empresa.nome}
                                            </span>
                                        </div>
                                    </>
                                ),
                                colaboradores: empresa.nr_colaboradores,
                                licencas: empresa.licencas,
                                usadas: empresa.licencas_utilizadas,
                                adesao: moment(empresa.dt_criado).format("DD/MM/YYYY"),
                                pais: empresa.pais,
                                opcoes: (
                                    <Dropdown overlay={this.montarMenu(empresa)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )
                            });
                        });
                        this.setState({
                            entidades: entidades,
                            loading_table: false
                        });
                    })
                    .catch(() => {
                        //    this.log("Tentativa de carregar entidades", "/api/administracao-entidades");
                    });
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU PARA CADA ENTIDADE NA LISTA
    montarMenu = empresa => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to={"/administracao-entidades/" + empresa.id + "/novo-ticket"}>Novo Pedido</Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregar(empresa.id)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.ativar(empresa.id, empresa.ativo)}>
                        {empresa.ativo ? "Inativar" : "Ativar"}
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to={"administracao-entidades/documentacao"}
                        onClick={
                            () => {
                                localStorage.setItem("entidade_id", empresa.id);
                                localStorage.setItem("entidade_nome", empresa.nome);
                                localStorage.setItem("entidade_logo", empresa.logo);
                            }
                        }
                    >
                        Documentação
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa,
                    pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0
                    }
                },
                () => {
                    this.total();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                }
            },
            () => {
                this.total();
            }
        );
    };

    //ABRE O FILE BROWSER
    ativarUploadLogo = () => {
        document.getElementById("input-logo").click();
    };

    //UPLOAD DE UM LOGO PARA A ENTIDADE
    uploadLogo = event => {
        if (event.target.files[0].size < 1024 * 100)
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop().toLowerCase())) {
                this.setState({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    logo: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0])
                });
            } else {
                document.getElementById("input-logo").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
                //this.setState({
                //    preview: "",
                //    logo: "",
                //    totalBytes: 0,
                //    formatoValido: false,
                //    validateStatus: "error",
                //    help: "Imagem com formato inválido"
                //});
            }
        else {
            document.getElementById("input-logo").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 100 KB para a imagem.");
            //this.setState({
            //    totalBytes: event.target.files[0].size,
            //    validateStatus: "error",
            //    help: "Limite de 100 KB para a imagem."
            //});
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //REMOVE A FOTO
    removerLogo = () => {
        document.getElementById("input-logo").value = "";
        this.setState({
            logo: "",
            preview: ""
        });
    };

    //CARREGA INFORMAÇÃO DA ENTIDADE
    carregar = entidadeid => {
        this.props.form.resetFields();

        axios({
            method: "get",
            url: "/api/administracao-entidades/carregar-entidade",
            params: {
                entidadeid: entidadeid
            }
        })
            .then(response => {
                var dados = response.data;
                var fileList = [];

                response.data.ficheiros.forEach(fich => {
                    fileList.push({
                        uid: fich.uid,
                        name: fich.nome,
                        status: "done",
                        linkProps: '{"download": "' + fich.nome + '"}',
                        url: fich.ficheiro
                    });
                });

                this.setState({
                    entidadeid: dados.id,
                    preview: dados.logo,
                    logo: dados.logo,
                    nome: dados.nome,
                    nome_abrev: dados.nome_abrev,
                    email_entidade: dados.email_entidade ? dados.email_entidade : "",
                    contacto_entidade: dados.contacto_entidade ? dados.contacto_entidade : "",
                    nr_colaboradores: dados.nr_colaboradores ? dados.nr_colaboradores : 0,
                    licencas: dados.licencas ? dados.licencas : 0,
                    nif: dados.nif ? dados.nif : "",
                    cod_postal: dados.cod_postal ? dados.cod_postal : "",
                    localidade: dados.localidade ? dados.localidade : "",
                    morada: dados.morada ? dados.morada : "",
                    pais: dados.pais ? dados.pais : "",
                    utilizador: dados.utilizador,
                    ativo: dados.ativo,
                    observacoes: dados.observacoes ? dados.observacoes : "",
                    entidade_visivel: response.data.visivel,
                    formacao: dados.formacao,
                    projeto: dados.projeto,
                    organizacional: dados.organizacional,
                    folderId: dados.folderId,
                    visible: true,
                    editar: true,
                    fileList
                });
            })
            .catch(() => {});
    };

    //ATIVA E INATIVAR ENTIDADE
    ativar = (entidadeid, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar este canal?" : "Pretende ativar este canal?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/administracao-entidades/ativar-entidade",
                    params: {
                        entidadeid: entidadeid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Canal inativado!" : "Canal ativado!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar o canal!" : "Não foi possível ativar o canal!"
                        );
                    });
            }
        });
    };

    //CRIAR NOVA ENTIDADE
    criar = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll(
            ["nome", "nome_completo", "email", "licencas", "valor", "dt_inicio", "dt_fim"],
            (err, values) => {
                if (!err && this.state.totalBytes <= 1024 * 100 && this.state.formatoValido) {
                    this.setState({ iconLoading: true });

                    var item = new FormData();
                    item.append("logo", this.state.logo);
                    item.append("nome", this.state.nome);
                    item.append("nome_abrev", this.state.nome_abrev);
                    item.append("email_entidade", this.state.email_entidade);
                    item.append("contacto_entidade", this.state.contacto_entidade);
                    item.append("nr_colaboradores", this.state.nr_colaboradores);
                    item.append("nif", this.state.nif);
                    item.append("cod_postal", this.state.cod_postal);
                    item.append("localidade", this.state.localidade);
                    item.append("morada", this.state.morada);
                    item.append("pais", this.state.pais);
                    item.append("visivel", this.state.entidade_visivel);
                    item.append("nome_completo", this.state.nome_completo);
                    item.append("email", this.state.email);
                    item.append("contacto", this.state.contacto);
                    item.append("licencas", this.state.licencas);
                    item.append("formacao", this.state.formacao);
                    item.append("projeto", this.state.projeto);
                    item.append("organizacional", this.state.organizacional);

                    //this.state.fileList.forEach((file, index) => {
                    //    item.append("listaUID[" + index + "]", file.uid);
                    //    item.append("anexo[]", file);
                    //});

                    axios({
                        method: "post",
                        url: "/api/administracao-entidades/add-entidade",
                        data: item
                    })
                        .then(response => {
                            this.criarPastaVimeo(response.data, this.state.nome_abrev);
                            openNotificationWithIcon("success", "Sucesso", "Adicionada nova entidade!");
                            this.setState({
                                iconLoading: false,
                                visible: false
                            });
                            this.total();
                        })
                        .catch(error => {
                            openNotificationWithIcon("error", "Erro", error.response.data);
                            this.setState({
                                iconLoading: false
                            });
                        });
                }
            }
        );
    };

    criarPastaVimeo = (entidadeId, nome_abrev) => {
        axios({
            //CRIA PASTA NO VIMEO
            method: "post",
            url: "https://api.vimeo.com/users/97314783/projects",
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: {
                Accept: "application/vnd.vimeo.*+json;version=3.4",
                Authorization: `bearer ${"c25f3d22c7bcd79d2044ad6eb33ea514"}`,
                "Content-Type": "application/json"
            },
            data: {
                name: nome_abrev
            }
        })
            .then(response => {
                axios({
                    //ATUALIZA FOLDER ID NA BD
                    method: "put",
                    url: "/api/administracao-entidades/pasta-vimeo",
                    params: {
                        entidadeId,
                        folderId: response.data.uri
                    }
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Falha ao criar pasta vimeo");
            });
    };

    //ATUALIZAR A ENTIDADE
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(["nome", "nome_completo", "email"], (err, values) => {
            if (!err && this.state.totalBytes <= 1024 * 100 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.entidadeid);
                item.append("logo", this.state.logo);
                item.append("nome", this.state.nome);
                item.append("nome_abrev", this.state.nome_abrev);
                item.append("email_entidade", this.state.email_entidade);
                item.append("contacto_entidade", this.state.contacto_entidade);
                item.append("nr_colaboradores", this.state.nr_colaboradores);
                item.append("nif", this.state.nif);
                item.append("cod_postal", this.state.cod_postal);
                item.append("localidade", this.state.localidade);
                item.append("morada", this.state.morada);
                item.append("pais", this.state.pais);
                item.append("visivel", this.state.entidade_visivel);
                item.append("nome_completo", this.state.nome_completo);
                item.append("email", this.state.email);
                item.append("contacto", this.state.contacto);
                item.append("licencas", this.state.licencas);
                item.append("formacao", this.state.formacao);
                item.append("projeto", this.state.projeto);
                item.append("organizacional", this.state.organizacional);
                //item.append("listaEliminados", JSON.stringify(this.state.listaEliminados));

                //var count = 0;
                //this.state.fileList.forEach(file => {
                //    if (file.status != "done") {
                //        item.append("listaUID[" + count + "]", file.uid);
                //        count += 1;
                //    }
                //    item.append("anexo[]", file);
                //});

                axios({
                    method: "put",
                    url: "/api/administracao-entidades/alterar-entidade",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Entidade alterada!");
                        this.setState({
                            iconLoading: false,
                            visible: false,
                            //listaEliminados: []
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ABRE O DRAWER A VAZIO PARA ADICIONAR UMA NOVA ENTIDADE
    showDrawer = () => {
        this.props.form.resetFields();

        this.setState({
            entidadeid: 0,
            logo: "",
            nome: "",
            nome_abrev: "",
            email_entidade: "",
            contacto_entidade: "",
            nr_colaboradores: 1,
            nif: "",
            morada: "",
            cod_postal: "",
            localidade: "",
            pais: "Brasil",
            preview: null,
            formacao: true,
            projeto: false,
            organizacao: false,
            //DADOS DO REPRESENTANTE DO CLUBE
            nome_completo: "",
            email: "",
            contacto: "",
            utilizador: "",
            ativo: true,
            observacoes: "",
            visible: true,
            editar: false,
            //fileList: [],
            //listaEliminados: []
        });
    };

    //FECHA O DRAWER SEM GUARDAR A ENTIDADE
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    //validarFormatoFicheiro = formato => {
    //    var formatos = [
    //        "zip",
    //        "rar",
    //        "7z",
    //        "docx",
    //        "doc",
    //        "pdf",
    //        "odf",
    //        "txt",
    //        "rtf",
    //        "xlsx",
    //        "xls",
    //        "csv",
    //        "ods",
    //        "pptx",
    //        "ppt",
    //        "odp",
    //        "bmp",
    //        "gif",
    //        "png",
    //        "jpeg",
    //        "jpg"
    //    ];
    //    var valido = false;
    //    for (var i = 0; i < formatos.length; i++) {
    //        if (formato.toLowerCase() == formatos[i]) {
    //            valido = true;
    //            break;
    //        }
    //    }
    //    return valido;
    //};

    //BLOQUEIA O UPLAOD DO RECURSO CASO JA EXISTA
    //disabledButton = () => {
    //    this.setState({
    //        subscricaoDocExiste: !this.state.subscricaoDocExiste,
    //        subscricao: []
    //    });
    //};

    afterVisibleChange = aberto => {
        if (aberto) {
            if (document.getElementById("input-logo")) document.getElementById("input-logo").value = "";
        }
    };

    render() {
        const Option = Select.Option;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            planos,
            //DRAWER
            preview,
            nome,
            nome_abrev,
            email_entidade,
            contacto_entidade,
            nr_colaboradores,
            nif,
            cod_postal,
            localidade,
            morada,
            pais,
            entidade_visivel,
            formacao,
            projeto,
            organizacional,
            licencas,
            //DADOS DO REPRESENTANTE DO CLUBE
            nome_completo,
            email,
            contacto,
            utilizador,
            ativo,
            observacoes,
            iconLoading,
            editar,
            //UPLOAD VALIDATE
            validateStatus,
            help,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //PAGINATION
            pagination,
            //UPLOADS
            fileList
        } = this.state;

        const columns = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "dados-user",
                sorter: true,
                defaultSortOrder: "ascend"
            },
            {
                title: "Nº Licenças",
                dataIndex: "licencas"
            },
            {
                title: "Licenças Usadas",
                dataIndex: "usadas"
            },
            {
                title: "Adesão",
                dataIndex: "adesao"
            },
            {
                title: "País",
                dataIndex: "pais"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        //const propsDocs = {
        //    multiple: false,
        //    onRemove: file => {
        //        this.setState(state => {
        //            const lista = this.state.listaEliminados;
        //            const index = state.fileList.indexOf(file);
        //            const newFileList = state.fileList.slice();
        //            newFileList.splice(index, 1);
        //            lista.push(file.uid);

        //            return {
        //                fileList: newFileList,
        //                listaEliminados: lista
        //            };
        //        });
        //    },
        //    defaultFileList: this.state.fileList,
        //    beforeUpload: file => {
        //        if (this.validarFormatoFicheiro(file.name.split(".").pop().toLowerCase())) {
        //            this.setState(state => ({
        //                fileList: [...state.fileList, file]
        //            }));
        //        } else openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");

        //        return false;
        //    },
        //    fileList
        //};

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Entidades" />
                <div className="container">
                    <div className="bloco-entidades">
                        <div className="bloco-tabela">
                            <div className="multiplos-filtros">
                                <BotaoProcurar
                                    tem_placeholder
                                    placeholder="Procurar por nome da entidade"
                                    HandleKeyPress={this.HandleKeyPress}
                                    resetCaixaProcura={this.resetCaixaProcura}
                                />
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />
                            </div>
                            <Table
                                id="tabela_categorias"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={!loading_table ? this.state.entidades : null}
                                pagination={pagination}
                                locale={{ emptyText: "Não existem registos!" }}
                                onChange={this.handleChangeTable}
                            />
                        </div>
                    </div>
                </div>
                <div className="opcoes-meus-cursos">
                    <Link to="#" className="btn-adicionar" title="Adicionar" onClick={this.showDrawer}>
                        +
                    </Link>
                </div>
                <Drawer
                    className="drawer-add-entidade"
                    title={editar ? "Alterar entidade" : "Adicionar nova entidade"}
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-img">
                        <Form>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <div className="container-img">
                                    <div style={{ position: "relative", height: "100%" }}>
                                        <img src={preview ? preview : noimage} alt="Logo da Entidade" />
                                    </div>
                                </div>
                                <input
                                    type="file"
                                    id="input-logo"
                                    accept="image/*"
                                    onChange={this.uploadLogo}
                                    style={{ display: "none" }}
                                />
                                {!preview ? (
                                    <>
                                        <Link to="#" className="link-principal-border" onClick={this.ativarUploadLogo}>
                                            <span>Adicionar</span>
                                        </Link>
                                        <Tooltip
                                            className="info-icon"
                                            title={
                                                <div>
                                                    <p>Tamanho recomendado: 200x200px</p>
                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                </div>
                                            }
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                            <Link to="#" className="link-principal-border" style={{ marginRight: 20 }} onClick={this.ativarUploadLogo}>
                                            <span>Alterar</span>
                                        </Link>
                                            <Link to="#" className="link-principal-border" onClick={this.removerLogo}>
                                            <span>Remover</span>
                                        </Link>
                                    </>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="bloco-info" style={{ marginBottom: "40px" }}>
                        <Form className="form-add-entidade" layout="horizontal">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    initialValue: nome,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatorio"
                                        }
                                    ]
                                })(<Input name="nome" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Nome Abreviado">
                                {getFieldDecorator("nome_abrev", {
                                    initialValue: nome_abrev,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatorio"
                                        }
                                    ]
                                })(<Input name="nome_abrev" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="E-mail">
                                {getFieldDecorator("email_entidade", {
                                    initialValue: email_entidade,
                                    rules: [
                                        {
                                            type: "email",
                                            message: "Introduza um endereço de e-mail válido"
                                        }
                                    ]
                                })(<Input name="email_entidade" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Contacto">
                                <Input name="contacto_entidade" value={contacto_entidade} onChange={this.handleChange} />
                            </Form.Item>
                            <Form.Item label="Nº Colaboradores">
                                {getFieldDecorator("nr_colaboradores", {
                                    initialValue: nr_colaboradores
                                    //rules: [
                                    //    {
                                    //        validator: this.handleValidation
                                    //    }
                                    //]
                                })(<InputNumber name="nr_colaboradores" onChange={this.handleChangeNrColaboradores} />)}
                            </Form.Item>
                            <Form.Item label={<span>Nº Licenças</span>}>
                                {getFieldDecorator("licencas", {
                                    initialValue: licencas,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<InputNumber name="licencas" onChange={this.handleChangeNrLicencas} />)}
                            </Form.Item>
                            <Form.Item label="NIF/NIPC">
                                {getFieldDecorator("nif", {
                                    initialValue: nif
                                })(<Input name="nif" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Código Postal">
                                {getFieldDecorator("cod_postal", {
                                    initialValue: cod_postal
                                })(<Input name="cod_postal" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Localidade">
                                {getFieldDecorator("localidade", {
                                    initialValue: localidade
                                })(<Input name="localidade" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Morada">
                                {getFieldDecorator("morada", {
                                    initialValue: morada
                                })(<TextArea name="morada" onChange={this.handleChange} rows={4} style={{ height: 100 }} />)}
                            </Form.Item>
                            <Form.Item label="País">
                                {getFieldDecorator("pais", {
                                    initialValue: pais
                                })(
                                    <Select showSearch onChange={this.handleChangeSelect}>
                                        <Option value="África do Sul">África do Sul</Option>
                                        <Option value="Albânia">Albânia</Option>
                                        <Option value="Alemanha">Alemanha</Option>
                                        <Option value="Andorra">Andorra</Option>
                                        <Option value="Angola">Angola</Option>
                                        <Option value="Anguilla">Anguilla</Option>
                                        <Option value="Antigua">Antigua</Option>
                                        <Option value="Arábia Saudita">Arábia Saudita</Option>
                                        <Option value="Argentina">Argentina</Option>
                                        <Option value="Armênia">Armênia</Option>
                                        <Option value="Aruba">Aruba</Option>
                                        <Option value="Austrália">Austrália</Option>
                                        <Option value="Áustria">Áustria</Option>
                                        <Option value="Azerbaijão">Azerbaijão</Option>
                                        <Option value="Bahamas">Bahamas</Option>
                                        <Option value="Bahrein">Bahrein</Option>
                                        <Option value="Bangladesh">Bangladesh</Option>
                                        <Option value="Barbados">Barbados</Option>
                                        <Option value="Bélgica">Bélgica</Option>
                                        <Option value="Benin">Benin</Option>
                                        <Option value="Bermudas">Bermudas</Option>
                                        <Option value="Botsuana">Botsuana</Option>
                                        <Option value="Brasil">Brasil</Option>
                                        <Option value="Brunei">Brunei</Option>
                                        <Option value="Bulgária">Bulgária</Option>
                                        <Option value="Burkina Fasso">Burkina Fasso</Option>
                                        <Option value="botão">botão</Option>
                                        <Option value="Cabo Verde">Cabo Verde</Option>
                                        <Option value="Camarões">Camarões</Option>
                                        <Option value="Camboja">Camboja</Option>
                                        <Option value="Canadá">Canadá</Option>
                                        <Option value="Cazaquistão">Cazaquistão</Option>
                                        <Option value="Chade">Chade</Option>
                                        <Option value="Chile">Chile</Option>
                                        <Option value="China">China</Option>
                                        <Option value="Cidade do Vaticano">Cidade do Vaticano</Option>
                                        <Option value="Colômbia">Colômbia</Option>
                                        <Option value="Congo">Congo</Option>
                                        <Option value="Coréia do Sul">Coréia do Sul</Option>
                                        <Option value="Costa do Marfim">Costa do Marfim</Option>
                                        <Option value="Costa Rica">Costa Rica</Option>
                                        <Option value="Croácia">Croácia</Option>
                                        <Option value="Dinamarca">Dinamarca</Option>
                                        <Option value="Djibuti">Djibuti</Option>
                                        <Option value="Dominica">Dominica</Option>
                                        <Option value="EUA">EUA</Option>
                                        <Option value="Egito">Egito</Option>
                                        <Option value="El Salvador">El Salvador</Option>
                                        <Option value="Emirados Árabes">Emirados Árabes</Option>
                                        <Option value="Equador">Equador</Option>
                                        <Option value="Eritréia">Eritréia</Option>
                                        <Option value="Escócia">Escócia</Option>
                                        <Option value="Eslováquia">Eslováquia</Option>
                                        <Option value="Eslovênia">Eslovênia</Option>
                                        <Option value="Espanha">Espanha</Option>
                                        <Option value="Estônia">Estônia</Option>
                                        <Option value="Etiópia">Etiópia</Option>
                                        <Option value="Fiji">Fiji</Option>
                                        <Option value="Filipinas">Filipinas</Option>
                                        <Option value="Finlândia">Finlândia</Option>
                                        <Option value="França">França</Option>
                                        <Option value="Gabão">Gabão</Option>
                                        <Option value="Gâmbia">Gâmbia</Option>
                                        <Option value="Gana">Gana</Option>
                                        <Option value="Geórgia">Geórgia</Option>
                                        <Option value="Gibraltar">Gibraltar</Option>
                                        <Option value="Granada">Granada</Option>
                                        <Option value="Grécia">Grécia</Option>
                                        <Option value="Guadalupe">Guadalupe</Option>
                                        <Option value="Guam">Guam</Option>
                                        <Option value="Guatemala">Guatemala</Option>
                                        <Option value="Guiana">Guiana</Option>
                                        <Option value="Guiana Francesa">Guiana Francesa</Option>
                                        <Option value="Guiné-bissau">Guiné-bissau</Option>
                                        <Option value="Haiti">Haiti</Option>
                                        <Option value="Holanda">Holanda</Option>
                                        <Option value="Honduras">Honduras</Option>
                                        <Option value="Hong Kong">Hong Kong</Option>
                                        <Option value="Hungria">Hungria</Option>
                                        <Option value="Iêmen">Iêmen</Option>
                                        <Option value="Ilhas Cayman">Ilhas Cayman</Option>
                                        <Option value="Ilhas Cook">Ilhas Cook</Option>
                                        <Option value="Ilhas Curaçao">Ilhas Curaçao</Option>
                                        <Option value="Ilhas Marshall">Ilhas Marshall</Option>
                                        <Option value="Ilhas Turks & Caicos">Ilhas Turks & Caicos</Option>
                                        <Option value="Ilhas Virgens (brit.)">Ilhas Virgens (brit.)</Option>
                                        <Option value="Ilhas Virgens(amer.)">Ilhas Virgens(amer.)</Option>
                                        <Option value="Ilhas Wallis e Futuna">Ilhas Wallis e Futuna</Option>
                                        <Option value="Índia">Índia</Option>
                                        <Option value="Indonésia">Indonésia</Option>
                                        <Option value="Inglaterra">Inglaterra</Option>
                                        <Option value="Irlanda">Irlanda</Option>
                                        <Option value="Islândia">Islândia</Option>
                                        <Option value="Israel">Israel</Option>
                                        <Option value="Itália">Itália</Option>
                                        <Option value="Jamaica">Jamaica</Option>
                                        <Option value="Japão">Japão</Option>
                                        <Option value="Jordânia">Jordânia</Option>
                                        <Option value="Kuwait">Kuwait</Option>
                                        <Option value="Latvia">Latvia</Option>
                                        <Option value="Líbano">Líbano</Option>
                                        <Option value="Liechtenstein">Liechtenstein</Option>
                                        <Option value="Lituânia">Lituânia</Option>
                                        <Option value="Luxemburgo">Luxemburgo</Option>
                                        <Option value="Macau">Macau</Option>
                                        <Option value="Macedônia">Macedônia</Option>
                                        <Option value="Madagascar">Madagascar</Option>
                                        <Option value="Malásia">Malásia</Option>
                                        <Option value="Malaui">Malaui</Option>
                                        <Option value="Mali">Mali</Option>
                                        <Option value="Malta">Malta</Option>
                                        <Option value="Marrocos">Marrocos</Option>
                                        <Option value="Martinica">Martinica</Option>
                                        <Option value="Mauritânia">Mauritânia</Option>
                                        <Option value="Mauritius">Mauritius</Option>
                                        <Option value="México">México</Option>
                                        <Option value="Moldova">Moldova</Option>
                                        <Option value="Mônaco">Mônaco</Option>
                                        <Option value="Montserrat">Montserrat</Option>
                                        <Option value="Nepal">Nepal</Option>
                                        <Option value="Nicarágua">Nicarágua</Option>
                                        <Option value="Niger">Niger</Option>
                                        <Option value="Nigéria">Nigéria</Option>
                                        <Option value="Noruega">Noruega</Option>
                                        <Option value="Nova Caledônia">Nova Caledônia</Option>
                                        <Option value="Nova Zelândia">Nova Zelândia</Option>
                                        <Option value="Omã">Omã</Option>
                                        <Option value="Palau">Palau</Option>
                                        <Option value="Panamá">Panamá</Option>
                                        <Option value="Papua-nova Guiné">Papua-nova Guiné</Option>
                                        <Option value="Paquistão">Paquistão</Option>
                                        <Option value="Peru">Peru</Option>
                                        <Option value="Polinésia Francesa">Polinésia Francesa</Option>
                                        <Option value="Polônia">Polônia</Option>
                                        <Option value="Porto Rico">Porto Rico</Option>
                                        <Option value="Portugal">Portugal</Option>
                                        <Option value="Qatar">Qatar</Option>
                                        <Option value="Quênia">Quênia</Option>
                                        <Option value="Rep. Dominicana">Rep. Dominicana</Option>
                                        <Option value="Rep. Tcheca">Rep. Tcheca</Option>
                                        <Option value="Reunion">Reunion</Option>
                                        <Option value="Romênia">Romênia</Option>
                                        <Option value="Ruanda">Ruanda</Option>
                                        <Option value="Rússia">Rússia</Option>
                                        <Option value="Saipan">Saipan</Option>
                                        <Option value="Samoa Americana">Samoa Americana</Option>
                                        <Option value="Senegal">Senegal</Option>
                                        <Option value="Serra Leone">Serra Leone</Option>
                                        <Option value="Seychelles">Seychelles</Option>
                                        <Option value="Singapura">Singapura</Option>
                                        <Option value="Síria">Síria</Option>
                                        <Option value="Sri Lanka">Sri Lanka</Option>
                                        <Option value="St. Kitts & Nevis">St. Kitts & Nevis</Option>
                                        <Option value="St. Lúcia">St. Lúcia</Option>
                                        <Option value="St. Vincent">St. Vincent</Option>
                                        <Option value="Sudão">Sudão</Option>
                                        <Option value="Suécia">Suécia</Option>
                                        <Option value="Suiça">Suiça</Option>
                                        <Option value="Suriname">Suriname</Option>
                                        <Option value="Tailândia">Tailândia</Option>
                                        <Option value="Taiwan">Taiwan</Option>
                                        <Option value="Tanzânia">Tanzânia</Option>
                                        <Option value="Togo">Togo</Option>
                                        <Option value="Trinidad & Tobago">Trinidad & Tobago</Option>
                                        <Option value="Tunísia">Tunísia</Option>
                                        <Option value="Turquia">Turquia</Option>
                                        <Option value="Ucrânia">Ucrânia</Option>
                                        <Option value="Uganda">Uganda</Option>
                                        <Option value="Uruguai">Uruguai</Option>
                                        <Option value="Venezuela">Venezuela</Option>
                                        <Option value="Vietnã">Vietnã</Option>
                                        <Option value="Zaire">Zaire</Option>
                                        <Option value="Zâmbia">Zâmbia</Option>
                                        <Option value="Zimbábue">Zimbábue</Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Checkbox name="entidade_visivel" checked={entidade_visivel} onChange={this.handleChangeCheckbox}>
                                    Página pública{" "}
                                    <Tooltip className="info-icon" title="A Entidade ficará visível na pesquisa da Home da StepForma">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>
                            <h3 className="titulo-separador" style={{ marginTop: "60px" }}>
                                Módulos
                            </h3>
                            <Form.Item>
                                <Checkbox name="formacao" checked={formacao} onChange={this.handleChangeCheckbox}>
                                    Formação
                                </Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox name="projeto" checked={projeto} onChange={this.handleChangeCheckbox}>
                                    Projeto
                                </Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox name="organizacional" checked={organizacional} onChange={this.handleChangeCheckbox}>
                                    Organizacional
                                </Checkbox>
                            </Form.Item>
                            <h3 className="titulo-separador" style={{ marginTop: "60px" }}>
                                Dados do representante
                            </h3>
                            {!editar ? (
                                <>
                                    <Form.Item label="Nome completo">
                                        {getFieldDecorator("nome_completo", {
                                            initialValue: nome_completo,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(<Input name="nome_completo" onChange={this.handleChange} />)}
                                    </Form.Item>
                                    <Form.Item label="E-mail">
                                        {getFieldDecorator("email", {
                                            initialValue: email,
                                            rules: [
                                                {
                                                    type: "email",
                                                    message: "Introduza um endereço de e-mail válido"
                                                },
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ]
                                        })(<Input name="email" onChange={this.handleChange} />)}
                                    </Form.Item>
                                    <Form.Item label="Telefone / Telemóvel">
                                        {getFieldDecorator("contacto", {
                                            initialValue: contacto
                                        })(<Input name="contacto" onChange={this.handleChange} />)}
                                    </Form.Item>
                                </>
                            ) : (
                                <>
                                    <Form.Item label="Nome completo">
                                        <span>{utilizador.nome_completo}</span>
                                    </Form.Item>
                                    <Form.Item label="E-mail">
                                        <span>{utilizador.email}</span>
                                    </Form.Item>
                                        <Form.Item label="Telefone / Telemóvel">
                                        <span>{utilizador.contacto}</span>
                                    </Form.Item>
                                </>
                            )}
                            <Form.Item label="Observações">
                                {getFieldDecorator("observacoes", {
                                    initialValue: observacoes
                                })(<TextArea name="observacoes" onChange={this.handleChange} rows={5} style={{ height: 100 }} />)}
                            </Form.Item>
                            {/*<h3 className="titulo-separador" style={{ marginTop: "60px" }}>
                                {" "}
                                Documentos{" "}
                            </h3>
                            <Form.Item>
                                <Upload {...propsDocs} fileList={this.state.fileList}>
                                    <Button>
                                        <i className="fas fa-upload" style={{ marginRight: "10px" }} /> Carregar ficheiro
                                    </Button>
                                </Upload>
                            </Form.Item>*/}
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {this.state.editar ? (
                            <button className="botao-principal" onClick={this.alterar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                            <button className="botao-principal" onClick={this.criar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                            </button>
                        )}
                    </div>
                </Drawer>
            </div>
        );
    }
}
const FormEntidades = Form.create({ name: "entidades" })(Entidades);

export default FormEntidades;
