import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, Icon, Input, notification } from "antd";
import Detalhe from "./Detalhe";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Main = (props) => {
    const [detalheVisivel, setDetalheVisivel] = useState(false);
    const [data, setData] = useState([])
    const [info, setInfo] = useState({})
    const [utilizadorId, setUtilizadorId] = useState("")
    const [registos, setRegistos] = useState([])
    const [loading, setLoading] = useState([])
    const [sessaoInfo, setSessaoInfo] = useState([])
    const [dataSessao, setDataSessao] = useState("")
    const [redirect, setRedirect] = useState(false)
    const [redirectLink, setRedirectLink] = useState("")
    const { TextArea } = Input;
    const link = `/gerir-curso/${props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-videoconferencias`;

    const columns = [
        {
            title: "Nº",
            dataIndex: "numero"
        },
        {
            title: "Aluno",
            dataIndex: "aluno"
        },
        {
            title: "Permanencia",
            dataIndex: "permanencia"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const carregarInfoSessao = () => {
        axios({
            method: "get",
            url: "/api/gerir-relatorio-participacoes/carregar-meet-info",
            params: {
                videoConfId: localStorage.getItem("meetingCode")
            }
        })
            .then(response => {
                const info = response.data;

                setSessaoInfo({
                    titulo: info.assunto,
                    dataHora: moment(info.meetingDate).format("DD/MM/YYYY") + " " + info.hora,
                    criador: `${info.criador.nome_completo.length > 1 ? `${info.criador.nome_completo.split(" ")[0]} ${info.criador.nome_completo.split(" ")[info.criador.nome_completo.split(" ").length - 1]}` : info.criador.nome_completo}(${info.criador.numero})`,
                    fechada: info.fechada
                })
                setDataSessao(info.meetingDate);
            })
            .catch(error => {

            });
    }

    const carregarLista = () => {
        axios({
            method: "get",
            url: "/api/gerir-relatorio-participacoes/lista-participacoes",
            params: {
                videoConfId: localStorage.getItem("meetingCode")
            }
        })
            .then(response => {
                const data = response.data.map(item => {
                    return {
                        id: item.id,
                        numero: item.numero,
                        aluno: item.nome,
                        permanencia: <>{item.permanencia} {item.alteracoes ? <Icon type="warning" className="alerta-alteracao" title={`Registos alterados por ${item.alteracoes}`} /> : null}</>,
                        opcoes: <Link className={!item.bloquear ? "botao-icon-aceder" : "botao-icon-aceder-disabled"} to="#" disabled={item.bloquear} title="Editar" onClick={() => { setUtilizadorId(item.id); setInfo({ numero: item.numero, nome: item.nome, role: item.role }); setRegistos(item.registos); setDetalheVisivel(true) }}>
                            <Icon type="login" />
                        </Link>
                    }
                })

                setData(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const atualizarRegistosUtilizador = (utilizadorId) => {
        axios({
            method: "get",
            url: "/api/gerir-relatorio-participacoes/atualizar-registos",
            params: {
                videoConfId: localStorage.getItem("meetingCode"),
                utilizadorId
            }
        })
            .then(response => {
                const info = response.data;

                let _data = data;
                const dataIndex = data.findIndex(x => x.id === utilizadorId);
                _data[dataIndex].permanencia = <>{info.permanencia} {info.alteracoes ? <Icon type="warning" className="alerta-alteracao" title={`Registos alterados por ${info.alteracoes}`} /> : null}</>;
                _data[dataIndex].opcoes = <Link className="botao-icon-aceder" to="#" title="Editar" onClick={() => { setUtilizadorId(info.id); setInfo({ numero: info.numero, nome: info.nome, role: info.role }); setRegistos(info.registos); setDetalheVisivel(true) }}>
                    <Icon type="login" />
                </Link>;

                setData(_data);
                setRegistos(info.registos);
            })
            .catch(error => {

            });
    }

    const confirmarRealizada = () => {
        if (!sessaoInfo.fechada)
            axios({
                method: "put",
                url: "/api/gerir-videoconferencia/marcar-realizada",
                params: {
                    videoConfId: localStorage.getItem("meetingCode")
                }
            })
                .then(() => {
                    openNotificationWithIcon("success", "Sucesso", "Stepmeet marcada como realizada!")
                    setRedirectLink(link);
                    setRedirect(true);
                })
                .catch(() => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível marcar a Stepmeet como realizada!")
                });
        else {
            setRedirectLink(link);
            setRedirect(true);
        }
    }

    const atualizarData = (registos) => {
        const temp = registos.sort(function (a, b) {
            return new Date(a.data) - new Date(b.data);
        });
        setRegistos([...temp]);
    }

    const voltar = (registosAlterados) => {
        if (registosAlterados)
            atualizarRegistosUtilizador(utilizadorId)

        setDetalheVisivel(false)
    }

    useEffect(() => {
        carregarInfoSessao();
        carregarLista();
    }, []);

    if (redirect)
        return <Redirect to={redirectLink} />

    if (!detalheVisivel)
        return (
            <>
                <div className="container container-body">
                    <div className="curso-mensagens curso-videoconferencias">
                        <div className="controlos controlos-fundo controlos-direita">
                            <div className="controlos-opcoes">
                                <Link to={link} className="botao-principal">
                                    voltar
                                </Link>
                            </div>
                        </div>
                        <div className="bloco-relatorio-participacoes">
                            <div className="relatorio-header">
                                <h1 className="relatorio-titulo">
                                    {sessaoInfo.titulo} - {sessaoInfo.dataHora}
                                </h1>
                                <span>{!sessaoInfo.fechada ? "Criada" : "Iniciada"} por: <strong>{sessaoInfo.criador}</strong></span>
                            </div>
                            <div className="relatorio-body">
                                <div className="relatorio-resumo">
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        pagination={false}
                                        loading={{
                                            spinning: loading,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        locale={{ emptyText: "Não existem registos!" }}
                                    />
                                </div>
                                <div className="relatorio-info">
                                    {!sessaoInfo.fechada &&
                                        <button className="botao-principal" disabled={loading} onClick={confirmarRealizada}>
                                            Confirmar e marcar como realizada
                                        </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    else
        return <Detalhe
            voltar={voltar}
            videoConfId={localStorage.getItem("meetingCode")}
            utilizadorId={utilizadorId}
            sessaoInfo={sessaoInfo}
            info={info}
            registos={registos}
            atualizarData={atualizarData}
            atualizarRegistosUtilizador={atualizarRegistosUtilizador}
            dataSessao={dataSessao}
        />
}

export default Main;