import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Sidebar from "./Menu";
import { Drawer, Form, Input, Menu, Checkbox, notification, Modal, Select, Dropdown, Icon, DatePicker, TimePicker, Row, Col, Tooltip, Carousel } from "antd";
import TabelaProximas from "./TabelaProximas";
import TabelaProximasMobile from "./TabelaProximasMobile";
import TabelaAnteriores from "./TabelaAnteriores";
import TabelaAnterioresMobile from "./TabelaAnterioresMobile";
import noimage from "../../images/noimage.png";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import DrawerForm from "./_Aux/FormVideoConf";
import DrawerFormUploadVideo from "./_Aux/FormUploadVideo";
import DrawerFormUploadFicheiro from "./_Aux/FormUploadFicheiro";
import { GlobalContext } from "../../GlobalState";
import Video from "../../GerirPlayer/Video";
import Header from "../../User/Header";
import chrome from "../../images/browsers/chrome.png";
import edgeChromium from "../../images/browsers/edge-chromium.png";
import firefox from "../../images/browsers/firefox.png";
import safari from "../../images/browsers/safari.png";
import encerrarStepmeet from "../../images/browsers/encerrar-stepmeet2.png";
import encerrarStepmeetTodos from "../../images/browsers/encerrar-stepmeet-todos2.png";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import CarouselGestor from "../../Geral/CarouselGestor";
import AlertInfo from "../../Helpers/Alertas/Info";

const accessToken = "c25f3d22c7bcd79d2044ad6eb33ea514"

const headerPost = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    Authorization: `bearer ${accessToken}`,
    "Content-Type": "application/json"
}

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        videoConfId: "",
        meetingName: "",
        videoconferencias: [],
        videoconferenciasMobile: [],
        total_recebidos: 0,
        proximas_ativo_on: "ativo",
        total_enviados: 0,
        anteriores_ativo_on: "",
        nr_proximas_videoconferencias: 0,
        nr_anteriores_videoconferencias: 0,
        //TABELA
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        loading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER
        visible: false,
        alterarVideoconferencia: null,
        //DRAWER UPLOAD
        visibleUpload: false,
        //DRAWER IMPORTAR
        visibleImportar: false,
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        ficheiroNomeExportar: "",
        //HORA SAIDA MODAL
        visibleDataSaida: false,
        dataHoraEntrada: null,
        sugestaoDataHora: null,
        dataSaida: moment(),
        horaSaida: moment(),
        //BROWSERS INFO
        visibleBrowsers: false,
        meetID: 0,
        indexCarousel: 0,
        tituloCarousel: "Informação sobre como deve sair da Stepmeet",
        //MODAL PREPARAR MEET
        visibleIniciarMeet: false,
        prepararMeet: false,
        linkIniciar: "",
        meetExterno: false,
        meetExternaConvite: "",
        //MODAL LISTA PARTICIPANTES
        visibleParticipantes: false,
        carregarLista: false,
        participantes: [],
        //MODAL MEET EXTERNA
        visibleMeetExterna: false,
        //FILTROS
        pesquisa: "",
        //DRAWER PROXIMAS
        visibleProximas: false,
        proximasAgendada: "",
        proximasParticipantes: "",
        //DRAWER ANTERIORES
        visibleAnteriores: false,
        anterioresRealizada: "",
        anterioresParticipantes: "",
        //VALIDAR INFORMACOES
        mensagemId: 1,
        visivelMensagem1: false,
        visivelMensagem2: false,
        naoMostrarMensagem: false,
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.validarUtilizadorInformacoes();
        if (localStorage.getItem("menu_mensagens")) {
            if (localStorage.getItem("menu_mensagens") == "proximas") {
                this.setState(
                    {
                        proximas_ativo_on: "ativo",
                        anteriores_ativo_on: ""
                    },
                    () => {
                        this.totalProximas();
                        this.listarProximas();
                    }
                );
            }
            else {
                this.setState(
                    {
                        proximas_ativo_on: "",
                        anteriores_ativo_on: "ativo"
                    },
                    () => {
                        this.totalAnteriores();
                        this.listarAnteriores();
                    }
                );
            }
        }
        else {
            this.totalProximas();
            this.listarProximas();
        }
    }

    componentDidUpdate() {
        if (this.context.role === "UTILIZADOR")
            this.setState({
                redirect: true,
                redirectLink: "/user"
            });
    }

    handleChangeSelect = value => {
        const pagination = { ...this.state.pagination };
        pagination.current = 1;
        pagination.pageSize = value;

        this.setState(
            {
                pagination
            },
            () => {
                if (this.state.anteriores_ativo_on == "ativo") {
                    this.totalAnteriores();
                    this.listarAnteriores();
                } else {
                    this.totalProximas();
                    this.listarProximas();
                }
            }
        );
    };

    handleTableChange = (pagination, filters, sorter) => {
        const _pagination = { ...this.state.pagination };
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                if (this.state.anteriores_ativo_on == "ativo") {
                    this.totalAnteriores();
                    this.listarAnteriores();
                } else {
                    this.totalProximas();
                    this.listarProximas();
                }
            }
        );
    };

    HandleKeyPress = e => {
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa: e.target.value,
                    pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0
                    }
                },
                () => {
                    //if (this.state.anteriores_ativo_on == "ativo") {
                    this.totalAnteriores();
                    this.listarAnteriores();
                    //} else {
                    //    this.totalProximas();
                    //    this.listarProximas();
                    //}
                }
            );
        }
    };

    //LIMPA A CAIXA DE PESQUISA E LISTA NOVAMENTE O TOTAL DE TICKETS
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                }
            },
            () => {
                //if (this.state.anteriores_ativo_on == "ativo") {
                this.totalAnteriores(false);
                this.listarAnteriores(this.state.current);
                //} else {
                //    this.totalProximas();
                //    this.listarProximas(this.state.current);
                //}
            }
        );
    };

    validarUtilizadorInformacoes = () => {
        axios({
            method: "get",
            url: "/api/utilizadores-informacoes/validar-informacao-stepmeets"
        })
            .then(response => {
                this.setState({
                    naoMostrarMensagem: response.data
                })
            })
            .catch(() => { });
    }

    filtro_proximas = () => {
        localStorage.setItem("menu_mensagens", "proximas");

        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                anteriores_ativo_on: "",
                proximas_ativo_on: "ativo",
                loading: true
            },
            () => {
                this.totalProximas();
                this.listarProximas();
            }
        );
    };

    filtro_anteriores = () => {
        localStorage.setItem("menu_mensagens", "anteriores");

        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                anteriores_ativo_on: "ativo",
                proximas_ativo_on: "",
                loading: true
            },
            () => {
                this.totalAnteriores();
                this.listarAnteriores();
            }
        );
    };

    totalProximas = () => {
        axios({
            method: "get",
            url: "/api/gestao-videoconferencia/total-proximas",
            params: {
                pesquisa: this.state.pesquisa
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(() => { });
    };

    listarProximas = () => {
        this.setState({ loading: true });
        axios({
            method: "get",
            url: "/api/gestao-videoconferencia/listar-proximas",
            params: {
                pesquisa: this.state.pesquisa,
                pageSize: this.state.pagination.pageSize,
                page: this.state.pagination.current
            }
        })
            .then(response => {
                var videoconferencias = [];
                var videoconferenciasMobile = [];
                var visivelMensagem1 = false;
                var visivelMensagem2 = false;

                response.data.map((videoConf, index) => {
                    if (videoConf.mensagens) {
                        visivelMensagem1 = videoConf.mensagens.mensagem1;
                        visivelMensagem2 = videoConf.mensagens.mensagem2;
                    }

                    videoconferencias.push({
                        key: index,
                        id: videoConf.id,
                        inicio: (
                            <div className="info-data">
                                <span className="data">{moment(videoConf.meetingDate).format("ddd, DD MMM")}</span>
                                <p className="hora">{videoConf.hora}</p>
                            </div>
                        ),
                        datahora: moment(videoConf.meetingDate).format("MM/DD/YYYY") + " " + videoConf.hora,
                        assunto: videoConf.assunto,
                        agendada: (<div className="info-participante">
                            <div className="bloco-img">
                                <img src={videoConf.criador.foto ? videoConf.criador.foto : noimage} />
                            </div>
                            <div className="info">
                                <span className="nome">
                                    {videoConf.criador.nome.length > 1 ? `${videoConf.criador.nome.split(" ")[0]} ${videoConf.criador.nome.split(" ")[videoConf.criador.nome.split(" ").length - 1]}` : videoConf.criador.nome}
                                </span>
                                <p className="numero">{videoConf.criador.numero}</p>
                            </div>
                        </div>),
                        controlos: (
                            <div className="controlos">
                                {videoConf.meetExterna ?
                                    <button className="botao-icon-convite" onClick={() => this.iniciarExterna(videoConf.id, videoConf.meetExternaConvite)} title="Iniciar">
                                        <Icon type="play-circle" />
                                    </button>
                                    :
                                    <button className="botao-icon-iniciar" onClick={() => this.iniciar(videoConf.id, videoConf.gravarSessao, videoConf.connected, visivelMensagem1, visivelMensagem2)} title="Iniciar">
                                        <Icon type="play-circle" />
                                    </button>
                                }
                                <Dropdown
                                    overlay={this.montarMenuProximas(videoConf.id, videoConf.token, videoConf.realizada, videoConf.meetingName, videoConf.meetExterna)}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="botao-icon-configs" style={{ marginLeft: 10 }}>
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            </div>
                        )
                    });

                    const agendada = (<div className="info-participante">
                        <div className="container-img">
                            <img src={videoConf.criador?.foto ? videoConf.criador.foto : noimage} />
                        </div>
                        <div className="info">
                            <span className="nome">
                                {videoConf.criador?.nome_completo?.length > 1 ? `${videoConf.criador.nome_completo.split(" ")[0]} ${videoConf.criador.nome_completo.split(" ")[videoConf.criador.nome_completo.split(" ").length - 1]}` : videoConf.criador.nome_completo}
                            </span>
                            <p className="numero">{videoConf.criador.numero}</p>
                        </div>
                    </div>);

                    videoconferenciasMobile.push({
                        key: index,
                        id: videoConf.id,
                        assunto: (
                            <div className="info-videoconferencias">
                                <span className="assunto">{videoConf.assunto}</span>
                                <p className="data-hora">
                                    {moment(videoConf.meetingDate).format("ddd, DD MMM")} {videoConf.hora}
                                </p>
                            </div>
                        ),
                        controlos: (
                            <div className="controlos controlos-vertical">
                                <div>
                                    {videoConf.meetExterna ?
                                        <button className="botao-icon-convite" onClick={() => this.iniciarExterna(videoConf.id, videoConf.meetExternaConvite)} title="Iniciar">
                                            <Icon type="play-circle" />
                                        </button>
                                        :
                                        <button className="botao-icon-iniciar" onClick={() => this.iniciar(videoConf.id, videoConf.gravarSessao, videoConf.connected, visivelMensagem1, visivelMensagem2)} title="Iniciar">
                                            <Icon type="play-circle" />
                                        </button>
                                    }
                                    <Dropdown
                                        overlay={this.montarMenuProximasMobile(videoConf.id, videoConf.token, videoConf.realizada, agendada, videoConf.meetingName, videoConf.meetExterna)}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                </div>
                            </div>
                        )
                    });
                });

                this.setState({
                    loading: false,
                    videoconferencias,
                    videoconferenciasMobile,
                    visivelMensagem1,
                    visivelMensagem2
                });
            })
            .catch(() => {

            });
    };

    montarMenuProximas = (videoConfId, token, realizada, meetingName, meetExterna) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarDetalhe(videoConfId)}>
                        Editar
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluir(videoConfId)}>
                        Excluir
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.copiarConvite(token)}>
                        Copiar convite
                        </Link>
                </Menu.Item>
                {realizada || meetExterna ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.marcarRealizada(videoConfId)}>
                            Marcar como realizada
                        </Link>
                    </Menu.Item>
                    :
                    <Menu.Item>
                        <Link to="#" onClick={() => this.marcarNaoRealizada(videoConfId)}>
                            Marcar como não realizada
                        </Link>
                    </Menu.Item>
                }
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ videoConfId, visibleUpload: true, meetingName })}>
                        Vídeos
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ videoConfId, visibleImportar: true, meetingName })}>
                        Anexos
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuProximasMobile = (videoConfId, token, realizada, agendada, meetingName, meetExterna) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleProximas: true, proximasAgendada: agendada })}>
                        Detalhe
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarDetalhe(videoConfId)}>
                        Editar
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluir(videoConfId)}>
                        Excluir
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.copiarConvite(token)}>
                        Copiar convite
                        </Link>
                </Menu.Item>
                {realizada || meetExterna ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.marcarRealizada(videoConfId)}>
                            Marcar como realizada
                        </Link>
                    </Menu.Item>
                    :
                    <Menu.Item>
                        <Link to="#" onClick={() => this.marcarNaoRealizada(videoConfId)}>
                            Marcar como não realizada
                        </Link>
                    </Menu.Item>
                }
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ videoConfId, visibleUpload: true, meetingName })}>
                        Vídeos
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ videoConfId, visibleImportar: true, meetingName })}>
                        Anexos
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    validarVerBotao = dataHora => {
        const agora = moment();
        const inicio = moment(dataHora);

        const diff = inicio.diff(agora);

        const diffDuration = moment.duration(diff);

        return diffDuration.hours() <= 0 && diffDuration.minutes() <= 15
    }

    copiarConvite = (token) => {
        var regex = /<br\s*[\/]?>/gi;
        var _token = token.replace(regex, "\n");

        var link = document.createElement("textarea");
        link.value = _token;

        link.style.top = "0";
        link.style.left = "0";
        link.style.position = "fixed";

        document.body.appendChild(link);
        link.focus();
        link.select();
        link.setSelectionRange(0, 99999)
        var sucesso = document.execCommand("copy");
        if (sucesso)
            openNotificationWithIcon("success", "Sucesso", "Convite copiado!")
        else
            openNotificationWithIcon("error", "Erro", "Ocorreu um erro ao copiar o convite!")
        document.body.removeChild(link);
    }

    marcarRealizada = videoConfId => {
        confirm({
            title: "Pretende marcar como realizada esta Stepmeet?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-videoconferencia/marcar-realizada",
                    params: {
                        videoConfId
                    }
                })
                    .then(response => {
                        if (response.data) {
                            this.setState({
                                visibleDataSaida: true,
                                dataHoraEntrada: `${moment(response.data.dataInicio).format("DD/MM/YYYY")} ${moment(response.data.dataHora, "HH:mm").format("HH:mm")}`,
                                sugestaoDataHora: `${moment(response.data.dataInicio).format("DD/MM/YYYY")} ${moment(response.data.dataHora, "HH:mm").add({ hours: moment(response.data.duracao, "HH:mm").hour(), minutes: moment(response.data.duracao, "HH:mm").minutes() }).format("HH:mm")}`,
                                videoConfId
                            })
                        }
                        else {
                            openNotificationWithIcon("success", "Sucesso", "Stepmeet marcada como realizada!")
                            this.totalProximas();
                            this.listarProximas(this.state.current);
                        }
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível marcar a Stepmeet como realizada!")
                    });
            }
        });
    }

    marcarNaoRealizada = videoConfId => {
        confirm({
            title: "Pretende marcar como não realizada esta Stepmeet?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-videoconferencia/marcar-nao-realizada",
                    params: {
                        videoConfId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Stepmeet marcada como não realizada!")
                        this.totalProximas();
                        this.listarProximas(this.state.current);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível marcar a Stepmeet como não realizada!")
                    });
            }
        });
    }

    totalAnteriores = () => {
        axios({
            method: "get",
            url: "/api/gestao-videoconferencia/total-anteriores",
            params: {
                pesquisa: this.state.pesquisa
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(() => { });
    };

    listarAnteriores = () => {
        this.setState({ loading: true });
        axios({
            method: "get",
            url: "/api/gestao-videoconferencia/listar-anteriores",
            params: {
                pesquisa: this.state.pesquisa,
                pageSize: this.state.pagination.pageSize,
                page: this.state.pagination.current
            }
        })
            .then(response => {
                var videoconferencias = [];
                var videoconferenciasMobile = [];

                response.data.map((videoConf, index) => {
                    videoconferencias.push({
                        key: index,
                        id: videoConf.id,
                        assunto: videoConf.assunto,
                        inicio: (
                            <div className="info-data">
                                <span className="data">{moment(videoConf.meetingDate).format("ddd, DD MMM")}</span>
                                <p className="hora">{videoConf.hora}</p>
                            </div>
                        ),
                        realizada: (<div className="info-participante">
                            <div className="bloco-img">
                                <img src={videoConf.iniciada.foto ? videoConf.iniciada.foto : noimage} />
                            </div>
                            <div className="info">
                                <span className="nome">
                                    {videoConf.iniciada.nome.length > 1 ? `${videoConf.iniciada.nome.split(" ")[0]} ${videoConf.iniciada.nome.split(" ")[videoConf.iniciada.nome.split(" ").length - 1]}` : videoConf.iniciada.nome}
                                </span>
                                <p className="numero">{videoConf.iniciada.numero}</p>
                            </div>
                        </div>),
                        controlos:
                            <div className="controlos">
                                <Dropdown
                                    overlay={this.montarMenuRealizadas(videoConf.id, videoConf.totalParticipantes, videoConf.meetingName, videoConf.ficheiro)}
                                    placement="bottomLeft"
                                >
                                    <Link to="#" className="botao-icon-configs">
                                        <i className="fas fa-cog" />
                                    </Link>
                                </Dropdown>
                            </div>
                    });

                    const realizada = (<div className="info-participante">
                        <div className="container-img">
                            <img src={videoConf.iniciada.foto ? videoConf.iniciada.foto : noimage} />
                        </div>
                        <div className="info">
                            <span className="nome">
                                {videoConf.iniciada.nome.length > 1 ? `${videoConf.iniciada.nome.split(" ")[0]} ${videoConf.iniciada.nome.split(" ")[videoConf.iniciada.nome.split(" ").length - 1]}` : videoConf.iniciada.nome}
                            </span>
                            <p className="numero">{videoConf.iniciada.numero}</p>
                        </div>
                    </div>);

                    videoconferenciasMobile.push({
                        key: index,
                        id: videoConf.id,
                        assunto: (
                            <div className="info-videoconferencias">
                                <span className="assunto">{videoConf.assunto}</span>
                                <p className="data-hora">
                                    {moment(videoConf.meetingDate).format("ddd, DD MMM")} {videoConf.hora}
                                </p>
                            </div>
                        ),
                        controlos:
                            <div className="controlos controlos-vertical">
                                <div>
                                    <Dropdown
                                        overlay={this.montarMenuRealizadasMobile(videoConf.id, videoConf.totalParticipantes, videoConf.meetingName, videoConf.ficheiro, realizada)}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                </div>
                            </div>
                    });
                });

                this.setState({
                    loading: false,
                    videoconferencias,
                    videoconferenciasMobile
                }, () => {
                    //if (response.data.find(x => !x.sessaoVideo && x.gravarSessao))
                    response.data.map((videoConf, index) => {
                        //if (!videoConf.sessaoVideo && videoConf.gravarSessao)
                        if (videoConf.gravarSessao)
                            this.carregarVideo(videoConf.meetingName, videoConf.id, videoConf.totalParticipantes)
                    })
                });
            })
            .catch(() => { });
    };

    atualizarListagemVisivel = () => {
        if (localStorage.getItem("menu_mensagens") == "anteriores")
            this.listarAnteriores();
        else
            this.listarProximas();
    }

    carregarVideo = (meetingName, videoConfId, totalParticipantes) => {
        axios({
            method: "get",
            url: `https://api.vimeo.com/me/videos`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost,
            params: {
                query: meetingName
            }
        }).then(response => {
            if (response.data.data.length) {
                var videos = [];

                response.data.data.forEach(video => {
                    videos = [...videos, video.uri.split("/").pop()]
                })

                var videoconferencias = this.state.videoconferencias;
                var confIndex = videoconferencias.findIndex(x => x.id === videoConfId)
                videoconferencias[confIndex].controlos = (
                    <div className="controlos">
                        <Dropdown
                            overlay={this.montarMenuVideosRealizadas(JSON.stringify(videos))}
                            placement="bottomLeft"
                        >
                            <button className="btn-start">Ver</button>
                        </Dropdown>
                        <Dropdown
                            overlay={this.montarMenuRealizadas(videoConfId, totalParticipantes, meetingName)}
                            placement="bottomLeft"
                        >
                            <Link to="#" className="botao-icon-configs">
                                <i className="fas fa-cog" />
                            </Link>
                        </Dropdown>
                    </div>
                )
                this.setState({
                    videoconferencias
                }, () => {
                    response.data.data.forEach(video => {
                        this.moverParaPasta(video.uri.split("/").pop());
                        this.carregarDominio(video.uri.split("/").pop());
                    })
                    this.guardarVideoID(videoConfId, JSON.stringify(videos));
                    //this.alterarVideoNome(response.data.data[0].uri.split("/").pop());
                });
            }
        })
    }

    atualizarRealizadas = (videosID, meetingName, videoConfId) => {
        var videos = [];

        videosID.forEach(video => {
            videos = [...videos, video]
        })

        var videoconferencias = this.state.videoconferencias;
        var confIndex = videoconferencias.findIndex(x => x.id === videoConfId)
        videoconferencias[confIndex].controlos = (
            <div className="controlos">
                <Dropdown
                    overlay={this.montarMenuVideosRealizadas(JSON.stringify(videos))}
                    placement="bottomLeft"
                >
                    <button className="btn-start">Ver</button>
                </Dropdown>
                <Dropdown
                    overlay={this.montarMenuRealizadas(videoConfId, 1, meetingName)}
                    placement="bottomLeft"
                >
                    <Link to="#" className="botao-icon-configs">
                        <i className="fas fa-cog" />
                    </Link>
                </Dropdown>
            </div>
        )
        this.setState({
            visibleUpload: false,
            videoconferencias
        });
    }

    guardarVideoID = (videoConfId, videoId) => {
        axios({
            method: "put",
            url: "/api/gestao-videoconferencia/guardar-video-id",
            params: {
                videoConfId,
                videoId
            }
        })
            .catch(() => { });
    };

    moverParaPasta = videoId => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/me/projects/${this.context.albumId_entidade}/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    carregarDominio = videoId => {
        axios({
            method: "get",
            url: "/api/curso/carregar-vimeo-configs",
        }).then(response => {
            this.adicionarDominio(videoId, response.data.dominio);
        });
    };

    adicionarDominio = (videoId, dominio) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/privacy/domains/${dominio}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    montarMenuVideosRealizadas = sessaoVimeoID => {
        var montar = JSON.parse(sessaoVimeoID).map((video, index) => (
            <Menu.Item>
                <Link to="#" onClick={() => this.iniciarVideo(video)}>
                    Video {index + 1}
                </Link>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    montarMenuRealizadas = (videoConfId, totalParticipantes, meetingName, Ficheiro) => {

        return (
            <Menu>
                {(totalParticipantes > 0 || Ficheiro) &&
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportar(videoConfId)}>
                            Exportar
                        </Link>
                    </Menu.Item>}
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ videoConfId, visibleUpload: true, meetingName })}>
                        Vídeos
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ videoConfId, visibleImportar: true, meetingName })}>
                        Anexos
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuRealizadasMobile = (videoConfId, totalParticipantes, meetingName, Ficheiro, realizadaPor) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleAnteriores: true, anterioresRealizada: realizadaPor })}>
                        Detalhe
                        </Link>
                </Menu.Item>
                {(totalParticipantes > 0 || Ficheiro) &&
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportar(videoConfId)}>
                            Exportar
                        </Link>
                    </Menu.Item>}
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ videoConfId, visibleUpload: true, meetingName })}>
                        Editar video
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ videoConfId, visibleImportar: true, meetingName })}>
                        Editar Ficheiros
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuRealizadas2 = (videoConfId, totalParticipantes, meetingName, Ficheiro) => {
        return (
            <Menu>
                {(totalParticipantes > 0 || Ficheiro) &&
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportar(videoConfId)}>
                            Exportar
                        </Link>
                    </Menu.Item>}
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ videoConfId, visibleUpload: true, meetingName })}>
                        Editar video
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ videoConfId, visibleImportar: true, meetingName })}>
                        Importar
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    downloadVideo = sessaoVimeoID => {
        axios({
            method: "get",
            url: `https://api.vimeo.com/me/videos/${sessaoVimeoID}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        }).then(response => {
            response.data.download.forEach(download => {
                if (response.data.download) {
                    var element = document.createElement('a');
                    element.setAttribute('href', response.data.download.find(x => x.quality === "source").link);

                    element.style.display = 'none';

                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                }
            })
        })
    }

    carregarDetalhe = id => {
        this.setState({
            videoConfId: id,
            visible: true
        });
    };

    criarVideconferencia = () => {
        this.setState({
            videoConfId: "",
            visible: true,
        });
    };

    iniciar = (id, gravarSessao, connected, visivelMensagem1, visivelMensagem2) => {
        const meet = this.state.videoconferencias.find(x => x.id === id);
        const listaMeets = this.state.videoconferencias.filter(x => x.id !== id);

        if (moment().isAfter(moment(meet.datahora), 'days')) {
            this.iniciarForaDeData(id, gravarSessao, connected, visivelMensagem1, visivelMensagem2);
        }
        else {
            let count = 0;
            for (let i = 0; listaMeets.length > i; i++) {
                if (moment(meet.datahora).diff(moment(listaMeets[i].datahora), 'minutes') >= 0) {
                    count += 1;
                }
            }

            if (count > 0)
                openNotificationWithIcon("warning", "Atenção", "Tem Stepmeets por finalizar. Deve fazê-lo assim que possível.");

            if (gravarSessao && connected) {
                confirm({
                    title: "Esta sessão está a ser gravada!",
                    okText: "Continuar",
                    okType: "Cancelar",
                    onOk: () => {
                        localStorage.setItem("player_back_url", this.props.location.pathname)
                        if (visivelMensagem1 && visivelMensagem2) {
                            this.setState({
                                meetID: id,
                                redirectLink: `/gestao-videoconferencia/${id}/player-videoconferencia`
                            }, () => this.registarEntrada());
                        }
                        else {
                            this.setState({
                                visibleBrowsers: true,
                                meetID: id,
                                redirectLink: `/gestao-videoconferencia/${id}/player-videoconferencia`
                            });
                        }
                    }
                });
            }
            else {
                localStorage.setItem("player_back_url", this.props.location.pathname)
                if (visivelMensagem1 && visivelMensagem2) {
                    this.setState({
                        meetID: id,
                        redirectLink: `/gestao-videoconferencia/${id}/player-videoconferencia`
                    }, () => this.registarEntrada());
                }
                else {
                    this.setState({
                        visibleBrowsers: true,
                        meetID: id,
                        redirectLink: `/gestao-videoconferencia/${id}/player-videoconferencia`
                    });
                }
            }
        }
    };

    confirmarEntrada = () => {
        if (this.state.naoMostrarMensagem)
            this.marcarNaoMostrar();

        this.registarEntrada();
    }

    registarEntrada = () => {
        axios({
            method: "post",
            url: "/api/gestao-videoconferencia/add-participant-entry",
            params: {
                meetingID: this.state.meetID,
                TempId: "",
                tipoEvento: 0
            }
        })
            .then(response => {
                this.setState({
                    redirect: true
                })
            })
            .catch(() => { });
    }

    marcarNaoMostrar = () => {
        axios({
            method: "post",
            url: "/api/utilizadores-informacoes/marcar-não-mostrar",
            params: {
                mensagemId: this.state.mensagemId,
            }
        })
    }

    inicioDireto = () => {
        axios({
            method: "post",
            url: "/api/gestao-videoconferencia/add-participant-entry",
            params: {
                meetingID: this.state.meetID,
                TempId: "",
                tipoEvento: 0
            }
        })
            .then(response => {
                this.setState({
                    redirect: true,
                    redirectLink: this.state.linkIniciar
                })
            })
            .catch(() => { });
    }

    iniciarForaDeData = (id, gravarSessao, connected, visivelMensagem1, visivelMensagem2) => {
        confirm({
            title: "Esta Stepmeet está agendada para um dia anterior ao de hoje. Tem a certeza que pretende iniciar/reiniciar.",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                if (gravarSessao && connected) {
                    confirm({
                        title: "Esta sessão está a ser gravada!",
                        okText: "Continuar",
                        okType: "Cancelar",
                        onOk: () => {
                            localStorage.setItem("player_back_url", this.props.location.pathname)
                            if (visivelMensagem1 && visivelMensagem2)
                                this.setState({
                                    meetID: id,
                                    redirectLink: `/gestao-videoconferencia/${id}/player-videoconferencia`
                                }, () => this.registarEntrada());
                            else
                                this.setState({
                                    visibleBrowsers: true,
                                    meetID: id,
                                    redirectLink: `/gestao-videoconferencia/${id}/player-videoconferencia`
                                });
                        }
                    });
                }
                else {
                    localStorage.setItem("player_back_url", this.props.location.pathname)
                    if (visivelMensagem1 && visivelMensagem2)
                        this.setState({
                            meetID: id,
                            redirectLink: `/gestao-videoconferencia/${id}/player-videoconferencia`
                        }, () => this.registarEntrada());
                    else
                        this.setState({
                            visibleBrowsers: true,
                            meetID: id,
                            redirectLink: `/gestao-videoconferencia/${id}/player-videoconferencia`
                        });
                }
            }
        });
    };

    iniciarExterna = (id, meetExternaConvite) => {
        var meet = this.state.videoconferencias.find(x => x.id === id);
        var listaMeets = this.state.videoconferencias.filter(x => x.id !== id);

        let count = 0;
        for (var i = 0; listaMeets.length > i; i++) {
            if (moment(meet.datahora).diff(moment(listaMeets[i].datahora), 'minutes') >= 0) {
                count += 1;
            }
        }

        if (count > 0)
            openNotificationWithIcon("warning", "Atenção", "Tem Stepmeets por finalizar. Deve fazê-lo assim que possível.");

        this.setState({
            visibleMeetExterna: true,
            meetExternaConvite
        })
    };

    iniciarVideo = id => {
        localStorage.setItem("player_back_url", this.props.location.pathname)
        this.setState({
            redirect: true,
            redirectLink: `/gestao-videoconferencia/${id}/player-video`
        });
    };

    excluir = id => {
        confirm({
            title: "Pretende excluir esta Stepmeet?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-videoconferencia/excluir",
                    params: {
                        id
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Stepmeet excluida");
                        this.context.atualizarEventosHoje();
                        this.atualizarListagem();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir a Stepmeet");
                    });
            }
        });
    };

    atualizarListagem = () => {
        if (localStorage.getItem("menu_mensagens") == "recebidos") {
            this.setState(
                {
                    visible: false
                },
                () => {
                    this.totalAnteriores();
                    this.listarAnteriores();
                }
            );
        }
        else {
            this.setState(
                {
                    visible: false
                },
                () => {
                    this.totalProximas();
                    this.listarProximas();
                }
            );
        }
    }

    atualizarListagemNovo = () => {
        this.setState(
            {
                proximas_ativo_on: "ativo",
                anteriores_ativo_on: "",
                visible: false
            },
            () => {
                this.totalProximas();
                this.listarProximas();
            }
        );
    }

    exportar = videoConfId => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/geral-videoconferencia",
                    params: {
                        videoConfId
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data.ficheiro,
                            ficheiroNomeExportar: response.data.nome
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    inserirHoraSaida = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                axios({
                    method: "put",
                    url: "/api/gestao-videoconferencia/inserir-hora-saida",
                    params: {
                        videoConfId: this.state.videoConfId,
                        horaSaida: this.state.horaSaida.format("HH:mm")
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Stepmeet excluido");
                        this.atualizarListagem();
                        this.setState({
                            visibleDataSaida: false
                        })
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o Stepmeet");
                    });
            }
        })
    }

    validarDataHoraSaida = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var dataHoraSaida = moment(`${this.state.dataSaida.format("DD/MM/YYYY")} ${this.state.horaSaida.format("HH:mm")}`, "DD/MM/YYYY HH:mm");

                const maisTrintaMins = moment(this.state.sugestaoDataHora).add(30, 'minutes').format("DD/MM/YYYY HH:mm")
                const menosTrintaMins = moment(this.state.sugestaoDataHora).subtract(30, 'minutes').format("DD/MM/YYYY HH:mm")

                if (dataHoraSaida.isAfter(maisTrintaMins, 'minutes')) {
                    confirm({
                        title: "AVISO!!!",
                        content: "Está a dar como finalizada a Stepmeet 30 minutos depois da hora prevista (de acordo com o agendamento)!!! Pretende confirmar?",
                        okText: "Confirmar",
                        okType: "Cancelar",
                        onOk: () => {
                            this.adicionarDataHoraSaida(dataHoraSaida);
                        }
                    });
                }
                else if (dataHoraSaida.isBefore(menosTrintaMins, 'minutes')) {
                    confirm({
                        title: "AVISO!!!",
                        content: "Está a dar como finalizada a Stepmeet 30 minutos antes da hora prevista (de acordo com o agendamento)!!! Pretende confirmar?",
                        okText: "Confirmar",
                        okType: "Cancelar",
                        onOk: () => {
                            this.adicionarDataHoraSaida(dataHoraSaida);
                        }
                    });
                }
                else {
                    this.adicionarDataHoraSaida(dataHoraSaida);
                }
            }
        })
    }

    adicionarDataHoraSaida = dataHoraSaida => {
        axios({
            method: "put",
            url: "/api/gestao-videoconferencia/inserir-hora-saida",
            params: {
                videoConfId: this.state.videoConfId,
                dataHoraSaida: dataHoraSaida.format("YYYY/MM/DD HH:mm")
            }
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Stepmeet excluido");
                this.atualizarListagem();
                this.setState({
                    visibleDataSaida: false
                })
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível marcar a Stepmeet como realizada!");
            });
    }

    listarParticipantes = meetingId => {
        this.setState({
            visibleParticipantes: true,
            carregarLista: true
        })
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/inserir-hora-saida",
            params: {
                meetingId
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data,
                    carregarLista: false
                })
            })
            .catch(() => { });
    };

    alterarTituloCarousel = index => {
        if (index === 0)
            this.setState({
                mensagemId: index + 1,
                tituloCarousel: "Informação sobre como deve sair da Stepmeet"
            })
        else
            this.setState({
                mensagemId: index + 1,
                tituloCarousel: "Informação sobre como deve aceder a esta Stepmeet"
            })
    }

    render() {
        const {
            anteriores_ativo_on,
            proximas_ativo_on,
            videoconferencias,
            videoconferenciasMobile,
            pagination,
            loading,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER
            videoConfId,
            meetingName,
            visible,
            //DRAWER UPLOAD
            visibleUpload,
            //DRAWER IMPORTAR
            visibleImportar,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            ficheiroNomeExportar,
            //HORA SAIDA MODAL
            visibleDataSaida,
            dataHoraEntrada,
            sugestaoDataHora,
            dataSaida,
            horaSaida,
            //BROWSERS INFO
            visibleBrowsers,
            tituloCarousel,
            //MODAL PREPARAR MEET
            visibleIniciarMeet,
            prepararMeet,
            linkIniciar,
            meetExterno,
            meetExternaConvite,
            //MODAL LISTA PARTICIPANTES
            visibleParticipantes,
            carregarLista,
            participantes,
            //MODAL MEET EXTERNA
            visibleMeetExterna,
            //DRAWER PROXIMAS
            visibleProximas,
            proximasAgendada,
            proximasParticipantes,
            //DRAWER ANTERIORES
            visibleAnteriores,
            anterioresRealizada,
            anterioresParticipantes,
            //VALIDAR INFORMACOES
            visivelMensagem1,
            visivelMensagem2,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const { Option } = Select;

        return (
            <div>
                <Header titulo="Stepmeets" />
                <div className="container container-body">
                    <div className="curso-mensagens curso-videoconferencias">
                        <div className="controlos controlos-fundo">
                            <div className="controlos-opcoes">
                                <button className="botao-principal" onClick={this.criarVideconferencia}>
                                    Nova Stepmeet
                                                </button>
                            </div>
                            {anteriores_ativo_on ?
                                <div className="controlos-opcoes controlo-search">
                                    <BotaoProcurar
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                        tem_placeholder
                                        placeholder="Procurar no Assunto"
                                    />
                                </div>
                                : null}
                        </div>
                        <div className="tickets stepmeets min-height">
                            <Sidebar
                                filtro_proximas={this.filtro_proximas}
                                filtro_anteriores={this.filtro_anteriores}
                                proximas_ativo={this.state.proximas_ativo_on}
                                anteriores_ativo={this.state.anteriores_ativo_on}
                            //HandleKeyPress={this.HandleKeyPress}
                            //resetCaixaProcura={this.resetCaixaProcura}
                            //criarVideconferencia={this.criarVideconferencia}
                            />
                            <div className="bloco-formadores bloco-videoconferencias-desktop">
                                {proximas_ativo_on === "ativo" ? (
                                    <TabelaProximas
                                        videoconferencias={videoconferencias}
                                        pagination={pagination}
                                        loading={loading}
                                        handleTableChange={this.handleTableChange}
                                        handleChangeSelect={this.handleChangeSelect}
                                    />
                                ) : (
                                    <TabelaAnteriores
                                        videoconferencias={videoconferencias}
                                        pagination={pagination}
                                        loading={loading}
                                        handleTableChange={this.handleTableChange}
                                        handleChangeSelect={this.handleChangeSelect}
                                    />
                                )}
                            </div>
                            <div className="bloco-formadores bloco-videoconferencias-mobile">
                                {proximas_ativo_on === "ativo" ? (
                                    <TabelaProximasMobile
                                        videoconferencias={videoconferenciasMobile}
                                        pagination={pagination}
                                        loading={loading}
                                        handleTableChange={this.handleTableChange}
                                        handleChangeSelect={this.handleChangeSelect}
                                    />
                                ) : (
                                    <TabelaAnterioresMobile
                                        videoconferencias={videoconferenciasMobile}
                                        pagination={pagination}
                                        loading={loading}
                                        handleTableChange={this.handleTableChange}
                                        handleChangeSelect={this.handleChangeSelect}
                                    />
                                )}
                            </div>
                        </div>
                        <DrawerForm
                            videoConfId={videoConfId}
                            visible={visible}
                            onClose={() => this.setState({ visible: false })}
                            atualizarListagem={this.atualizarListagemNovo}
                            prepararMeet={estado =>
                                this.setState({
                                    visibleIniciarMeet: estado,
                                    prepararMeet: true
                                })
                            }
                            IniciarMeet={(linkIniciar, meetExterno, meetID) =>
                                this.setState({
                                    prepararMeet: false,
                                    linkIniciar,
                                    meetID,
                                    meetExterno
                                })
                            }
                        />
                        <DrawerFormUploadVideo
                            videoConfId={videoConfId}
                            meetingName={meetingName}
                            visible={visibleUpload}
                            onClose={() => this.setState({ visibleUpload: false })}
                            atualizarListagemVisivel={this.atualizarListagemVisivel}
                        />
                        <DrawerFormUploadFicheiro
                            videoConfId={videoConfId}
                            meetingName={meetingName}
                            visible={visibleImportar}
                            onClose={() => this.setState({ visibleImportar: false })}
                            atualizarListagemVisivel={this.atualizarListagemVisivel}
                        />
                    </div>
                </div>
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download={ficheiroNomeExportar} onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A gerar documento...</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                </p>
                                <p className="texto">Dados exportados com sucesso!</p>
                                <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                            </>
                        )}
                    </div>
                </Modal>
                <Modal
                    //title="Atualizar data e hora"
                    visible={visibleDataSaida}
                    okText="Confirmar"
                    onOk={this.validarDataHoraSaida}
                    onCancel={() => this.setState({ visibleDataSaida: false })}
                    cancelText="Cancelar"
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <AlertInfo titulo="Atualizar data e hora" texto="Insira a data e hora de término da sessão. Abaixo aparece a sugestão com base na hora de início e duração da mesma." />
                        <Form className="form-categorias" layout="horizontal" style={{ padding: 0 }}>
                            <h3 className="titulo-separador">Início</h3>
                            <div className="bloco">
                                <Form.Item label="Data/Hora">
                                    {sugestaoDataHora}
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador">Término</h3>
                                <Form.Item label="Data">
                                    {getFieldDecorator("dataSaida", {
                                        rules: [{
                                            required: true,
                                            message: "Campo obrigatório"
                                        }]
                                    })(<DatePicker
                                        className="input-50"
                                        placeholder="Selecionar"
                                        format="DD-MM-YYYY"
                                        name="dataSaida"
                                        onChange={dataSaida => this.setState({ dataSaida })}
                                    />)}
                                </Form.Item>
                                <Form.Item label="Hora">
                                    {getFieldDecorator("horaSaida", {
                                        rules: [{
                                            required: true,
                                            message: "Campo obrigatório"
                                        }]
                                    })(<TimePicker
                                        className="input-50"
                                        name="hora"
                                        defaultOpenValue={moment("00:00", "HH:mm")}
                                        format="HH:mm"
                                        onChange={horaSaida => this.setState({ horaSaida })}
                                        placeholder="Selecionar"
                                        suffixIcon={false}
                                        clearIcon={false}
                                    />)}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </Modal>
                <CarouselGestor
                    tituloCarousel={tituloCarousel}
                    visibleBrowsers={visibleBrowsers}
                    confirmarEntrada={this.confirmarEntrada}
                    cancelarEntrada={() => this.setState({ visibleBrowsers: false })}
                    alterarTituloCarousel={this.alterarTituloCarousel}
                    selecionarNaoMostrar={naoMostrarMensagem => this.setState({ naoMostrarMensagem })}
                    visivelMensagem1={visivelMensagem1}
                    visivelMensagem2={visivelMensagem2}
                />
                <Modal
                    visible={visibleIniciarMeet}
                    okText={
                        meetExterno ?
                            <a href={linkIniciar} target="_blank" rel="noopener noreferrer" onClick={() => this.setState({ visibleIniciarMeet: false })}>
                                Entrar
                            </a>
                            :
                            <Link to="#" onClick={this.inicioDireto}>
                                Entrar
                            </Link>
                    }
                    onCancel={() => this.setState({ visibleIniciarMeet: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: prepararMeet }}
                    maskClosable={false}
                    className="modal-loading"
                >
                    <div className="modal-loading-bloco">
                        {prepararMeet ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A preparar Stepmeet...</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                </p>
                                <p className="texto">Stepmeet pronto!</p>
                            </>
                        )}
                    </div>
                </Modal>
                <Modal
                    title={<>Participantes<br /><span style={{ fontSize: 13, color: "#8e9aa0" }}>Total: {participantes.length}</span></>}
                    visible={visibleParticipantes}
                    onCancel={() => this.setState({ visibleParticipantes: false })}
                    maskClosable={false}
                    className="modal-lista-participantes"
                    footer={null}
                >
                    <div className="bloco-lista-participantes">
                        {carregarLista ? (
                            <div className="bloco-loading">
                                <p>
                                    <Icon className="icon-loading" type="loading" />
                                </p>
                                <p className="texto">A carregar...</p>
                            </div>
                        ) : (
                            <>
                                {participantes.map((participante, index) => (
                                    <div key={index} className="info-participante">
                                        <div className="info">
                                            <span className="nome">
                                                {participante.nome}
                                            </span>
                                            <p className="numero">{participante.email}</p>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </Modal>
                <Modal
                    title="Convite"
                    visible={visibleMeetExterna}
                    onCancel={() => this.setState({ visibleMeetExterna: false })}
                    maskClosable={false}
                    className="modal-lista-participantes"
                    footer={null}
                >
                    <div className="bloco-lista-participantes" dangerouslySetInnerHTML={{ __html: meetExternaConvite?.replace("\n", '<br />') }}>
                    </div>
                </Modal>
                <Drawer
                    className="drawer-detalhe-proximas"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleProximas: false })}
                    visible={visibleProximas}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Agendada por">
                                    {proximasAgendada}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleProximas: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Drawer
                    className="drawer-detalhe-anteriores"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleAnteriores: false })}
                    visible={visibleAnteriores}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Iniciada por">
                                    {anterioresRealizada}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleAnteriores: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </div>
        );
    }
}

const MainForm = Form.create({ name: "form-categorias" })(Main);

export default MainForm;
