import React, { Component } from "react";
import Header from "../User/Header";

class PoliticaPrivacidade extends Component {
    state = {
        termos: [
            {
                titulo: "1. QUEM SOMOS",
                texto:
                    "A NextOpinion Lda é uma startup Portuguesa que tem como objetivos desenvolver soluções e serviços inovadores com suporte tecnológico. É ela a responsável pelo tratamento de dados pessoais dos seus clientes e colaboradores.",
                listagem: [],
                texto2: ""
            },
            {
                titulo: "2. COMO RECOLHEMOS INFORMAÇÕES",
                texto: "Poderemos recolher informações que dizem respeito a si, apenas dentro do estritamente legal, nos seguintes contextos distintos:",
                listagem: [
                    {
                        pontos:
                            "Dados anónimos que são guardados enquanto o titular de dados pessoais visita as plataformas digitais ou quando do envio das comunicações (ex. e-mail, mensagens internas do website www.stepforma.com.br, etc), com o objetivo de melhorar o funcionamento das mesmas, incluindo a otimização e a priorização de conteúdos; a informação que recolhemos pode incluir IPs, sistema operativo, tipo de browser, atividade do browser e outras informações relacionadas com aspetos da infraestrutura utilizada, por exemplo operador móvel, momento da receção no telemóvel e tipo de terminal. Isto pode ser recolhido em forma de log ou na forma de cookies;"
                    },
                    {
                        pontos:
                            "Dados pessoais que são fornecidos voluntariamente pelo titular aquando do registro, subscrição, troca de correspondência e utilização de algumas das suas soluções. Estes são processados de forma automática, sendo que a informação do titular de dados pessoais é recolhida de forma encriptada e gerida com segurança;"
                    },
                    {
                        pontos: "Interação conosco através das redes sociais;"
                    },
                    {
                        pontos: "Contatos offline por exemplo, por telefone, SMS, correio eletrónico ou correio postal."
                    }
                ],
                texto2:
                    "Ao concordar com esta política de privacidade, quando nos fornece dados pessoais confidenciais, seja por meio do site ou de qualquer um de nossos produtos ou serviços, é porque concorda explicitamente com nosso uso e processamento desses dados. Além disso, não registamos intencionalmente dados pessoais de menores de treze (13) anos (idade definida pelo estado Português para a necessidade de consentimento dos pais ou representantes legais dos jovens) e, na medida do possível, tomamos medidas para obter consentimento dos pais ou representantes legais quando for possível verificar que possui menos de 13 anos. Ao utilizar as nossas plataformas, produtos ou serviços, e ao enviar quaisquer dados para nós, VOCÊ garante que tem treze (13) anos de idade ou que foi para tal autorizado."
            },
            {
                titulo: "3. COMO UTILIZAMOS SUAS INFORMAÇÕES",
                texto: "Na medida do permitido pela legislação aplicável, utilizamos as suas informações para:",
                listagem: [
                    {
                        pontos: "Prestar qualquer informação ou serviço que VOCÊ solicitou ou qualquer aplicação ou serviço que encomendou ou solicitou;"
                    },
                    {
                        pontos: "Disponibilizar, manter, proteger e melhorar qualquer aplicação, produto, serviço e informação que nos solicitou;"
                    },
                    {
                        pontos: "Gerir e administrar a sua utilização de aplicações, produtos e serviços fornecidos ou prestados a seu pedido;"
                    },
                    {
                        pontos: "Gerir a nossa relação consigo (por exemplo, serviços ao cliente e atividades de apoio);"
                    },
                    {
                        pontos:
                            "Controlar, medir, melhorar e proteger o nosso conteúdo, sítio web, as nossas aplicações e serviços e proporcionar-lhe uma experiência de utilizador melhorada e personalizada;"
                    },
                    {
                        pontos: "Prestar-lhe qualquer informação que formos obrigados a comunicar-lhe, em cumprimento das nossas obrigações regulamentares ou legais;"
                    },
                    {
                        pontos:
                            "Contactá-lo para saber se está interessado em participar no nosso estudo sobre clientes (por exemplo, feedback sobre a sua utilização das nossas aplicações, produtos e serviços);"
                    },
                    {
                        pontos:
                            "Acompanhar, realizar análises estatísticas e definir indicadores de desempenho, devendo entender-se, porém, que neste caso as informações serão agregadas, pelo que permitirão associá-lo a si ou a qualquer pessoa viva;"
                    },
                    {
                        pontos:
                            "Apresentar publicidade direcionada, marketing (incluindo mensagens de notificação do próprio dispositivo) ou enviar-lhe informações que lhe podem ser úteis, com base na sua utilização das nossas aplicações e serviços;"
                    },
                    {
                        pontos: "Realizar atividades de investigação e desenvolvimento para melhorar os nossos serviços, produtos e aplicações;"
                    },
                    {
                        pontos:
                            "Desenvolver e disponibilizar funcionalidades e serviços novos e existentes (incluindo análise estatística, definição de indicadores de desempenho e serviços de previsão);"
                    },
                    {
                        pontos: "Disponibilizar conteúdo e serviços em conjunto com terceiros com os quais tem uma relação distinta (por exemplo, operadores de redes sociais);"
                    },
                    {
                        pontos:
                            "Prestar-lhe serviços com base na localização (por exemplo, publicidade e outro conteúdo personalizado), no contexto dos quais poderemos recolher dados de geolocalização."
                    }
                ],
                texto2:
                    "Poderemos utilizar as informações geradas e guardadas durante a sua utilização dos nossos serviços para os nossos interesses comerciais legítimos, de forma a oferecer-lhe o melhor serviço e/ou soluções e a melhor experiência. Sempre que utilizarmos as suas informações com base nos nossos interesses legítimos, garantimos que as suas informações são tratadas de forma pseudonimizada e apresentada em níveis agregados que não permitam ser associadas a si ou a qualquer pessoa viva."
            },
            {
                titulo: "4. PARTILHAR AS SUAS INFORMAÇÕES",
                texto: "Poderemos partilhar as suas informações com:",
                listagem: [
                    {
                        pontos:
                            "Os nossos prestadores de serviços e agentes (incluindo os respetivos subcontratantes) ou terceiros que tratam informações em nosso nome (por exemplo, fornecedores de serviços da Internet e plataformas, prestadores de serviços de processamento de pagamentos e as organizações que contratamos para nos ajudarem a enviar-lhe comunicações) e que colaboram conosco para lhe disponibilizar as aplicações, produtos, serviços e informações que solicitou ou que acreditamos serem do seu interesse;"
                    },
                    {
                        pontos:
                            "Parceiros, incluindo responsáveis pela instalação de sistemas, revendedores, distribuidores de valor agregado, fornecedores de software independentes e programadores que eventualmente nos ajudam a disponibilizar-lhe as aplicações, produtos, serviços e informações que solicitou ou que acreditamos serem do seu interesse;"
                    },
                    {
                        pontos:
                            "Terceiros, a quem recorremos para facilitar as operações de pagamento, por exemplo, câmaras de compensação, sistemas de compensação, instituições financeiras e beneficiários de transações;"
                    },
                    {
                        pontos:
                            "Terceiros, com os quais tem uma relação e aos quais nos autorizou o envio de informações (por exemplo, sites de redes sociais ou outros operadores de aplicações terceiros); terceiros, para fins de comercialização (por exemplo, os nossos parceiros e outros terceiros com quem trabalhamos e cujos produtos ou serviços acreditamos serem do seu interesse no contexto das suas atividades comerciais. Por exemplo, organizações de serviços financeiros (como bancos, seguradoras, prestadores de serviços financeiros), fornecedores de soluções de pagamento, software e prestadores de serviços que disponibilizam soluções comerciais);"
                    },
                    {
                        pontos:
                            "Qualquer terceiro, para dar cumprimento às nossas obrigações legais ou regulamentares, incluindo a prestação de informação estatutária ou regulamentar, ou a deteção ou prevenção de atos ilícitos;"
                    },
                    {
                        pontos: "Departamentos governamentais, sempre que a comunicação de informações for obrigatória por força da lei aplicável."
                    }
                ],
                texto2:
                    "Poderemos partilhar publicamente, ou com terceiros, informações não identificáveis pessoalmente sobre a utilização do nosso sítio web, aplicações, produtos ou serviços, sem, contudo, incluir informações que puderem ser utilizadas para o identificar. Sempre que responder a comunicações que publicamos em plataformas de terceiros (como o Facebook, Google e Twitter), poderemos eventualmente partilhar as suas informações com os terceiros em causa para lhe apresentar publicidade/conteúdo direcionado através da plataforma do terceiro em causa, com base no seu perfil/nos seus interesses. As suas informações são utilizadas pelo fornecedor da plataforma do terceiro para identificar a sua conta e lhe apresentar publicidade."
            },
            {
                titulo: "5. CONSERVAÇÃO DOS SEUS DADOS PESSOAIS",
                texto:
                    "Conservaremos as suas informações pessoais enquanto durar a nossa relação e, posteriormente, enquanto forem necessárias e pertinentes para a prossecução dos nossos interesses legítimos, ou conforme permitido pela legislação e regulamentação aplicável. A lei determina a conservação dos dados por um período mínimo, designadamente:",
                listagem: [
                    {
                        pontos: "Por um ano: dados de tráfego dados de localização isto para efeitos de investigação, deteção e repressão de crime grave;"
                    },
                    {
                        pontos: "Por 10 anos: os dados necessários para informação à Autoridade Tributária."
                    }
                ],
                texto2:
                    "A NextOpinion Lda conserva seus dados pessoais, sempre de acordo com a lei, as orientações e as decisões dos órgãos de supervisão onde opera ou, consoante o que for aplicável, até que exerça o seu direito de oposição, direito a ser esquecido, ou que revogue o seu consentimento. Contudo serão sempre observáveis todas as obrigações legais e contratuais que definem esses períodos. Alertamos que quando as suas informações deixarem de ser necessárias, poderão ser eliminadas em segurança (sem qualquer aviso prévio)."
            },
            {
                titulo: "6. DIREITOS DO TITULAR DE DADOS PESSOAIS",
                texto: "O titular de dados pessoais pode exercer os seguintes direitos:",
                listagem: [
                    {
                        pontos: "Direito à informação: esta política encontra-se explicita sempre que se interage com os nossos serviços;"
                    },
                    {
                        pontos:
                            "Direito ao acesso e retificação: pode aceder e editar diretamente e de forma autónoma os seus dados, quer nas mensagens que lhe enviamos como na edição das propriedades sua conta;"
                    },
                    {
                        pontos: (
                            <>
                                Direito à portabilidade dos dados: pode-lhe ser devolvida qualquer informação que nos tenha facultado de forma automatizada, em formato estruturado, de uso corrente e
                                de leitura automática, ou que nos seja enviada, sempre que tecnicamente viável, devendo para tal enviar uma mensagem de e-mail para{" "}
                                <a href="mailto:privacidade@stepforma.com">privacidade@stepforma.com</a> com a sua identificação e um pedido a solicitar a portabilidade dos seus dados (se
                                solicitar uma cópia das suas informações, poderá ter de pagar uma taxa legal);
                            </>
                        )
                    },
                    {
                        pontos:
                            "Direito de limitar ou retirar o consentimento para os tratamentos dos seus dados: através edição das propriedades sua conta, embora sujeito a restrições de ordem legal ou contratual;"
                    },
                    {
                        pontos: (
                            <>
                                Direito ao esquecimento: poderá solicitar a remoção total dos seus dados enviando uma mensagem de e-mail para{" "}
                                <a href="mailto:privacidade@stepforma.com">privacidade@stepforma.com</a> com a sua identificação e um pedido de remoção;
                            </>
                        )
                    },
                    {
                        pontos: "Caso necessite, o titular de dados pessoais poderá, ainda, apresentar reclamação junto da entidade de supervisão do tratamento de dados."
                    }
                ],
                texto2: ""
            },
            {
                titulo: "7. SEGURANÇA E CONSERVAÇÃO DAS INFORMAÇÕES",
                texto: (
                    <>
                        Dispomos de uma diversidade de medidas de segurança da informação, alinhadas com as boas práticas em vigor, de modo a proteger os seus dados pessoais, incluindo controlos
                        tecnológicos, medidas administrativas, técnicas, físicas e procedimentos que garantem a proteção dos seus dados pessoais, impedindo a sua utilização indevida, o acesso e
                        divulgação não autorizada, a sua perda, a sua alteração indevida ou inadvertida, ou a sua destruição não autorizada. Se tiver razões para acreditar que a segurança da sua conta
                        foi comprometida, contacte-nos através do endereço <a href="mailto:privacidade@stepforma.com">privacidade@stepforma.com</a>.
                    </>
                ),
                listagem: [],
                texto2: ""
            },
            {
                titulo: "8. TRANSFERÊNCIAS PARA FORA DO ESPAÇO ECONÓMICO EUROPEU",
                texto:
                    "As informações pessoais na União Europeia estão protegidas por leis sobre proteção de dados, embora os outros países não protejam necessariamente as suas informações pessoais da mesma forma. As plataformas digitais STEPFORMA estão desenhadas de forma a prescindir de terceirização, adotando uma abordagem “privacy by design”, pelo que não existe transferência recorrente de dados para o exterior da plataforma. Contudo a NextOpinion Lda poderá, ocasionalmente, transferir dados para um país fora da UE com o objetivo de melhorar o funcionamento da plataforma. A acontecer, será assegurado que todas as transferências de dados se realizam no estrito cumprimento das normas legais aplicáveis.",
                listagem: [],
                texto2: ""
            },
            {
                titulo: "9. OUTROS SITES E REDES SOCIAIS",
                texto:
                    "Se seguir uma ligação a partir do nosso sítio web, aplicação ou serviço, para outro site ou serviço, a presente Política de Privacidade deixará de ser aplicável, pois não somos responsáveis pelas práticas em matéria de tratamento das informações aplicadas por sítios web ou serviços de terceiros e recomendamos a leitura dos avisos de privacidade que constam nos referidos sites e serviços. Os nossos sítios web, aplicações ou serviços poderão permitir-lhe partilhar informações em sites de redes sociais ou utilizar sites de redes sociais para criar a sua conta ou associar a sua conta de rede social. Estes sites de redes sociais podem disponibilizar-nos automaticamente o acesso a determinadas informações que conservam a seu respeito (por exemplo, qualquer conteúdo que tenha visualizado). Alertamos que neste caso a sua utilização é feita por sua conta e risco, não estando garantida qualquer medida de privacidade, dada a impossibilidade técnica de controlo de serviços de terceiros.",
                listagem: [],
                texto2: ""
            },
            {
                titulo: "10. COOKIES, DADOS ANALÍTICOS E DE TRÁFEGO",
                texto:
                    'Cookies são pequenos ficheiros de texto que são transferidos dos nossos sítios web, aplicações ou serviços e armazenados no seu dispositivo. Utilizamos cookies para nos ajudar a prestar-lhe um serviço personalizado e melhorar os nossos sítios web, aplicações e serviços, para si. Os nossos cookies podem ser cookies de sessão (cookies temporários que identificam e seguem os utilizadores nos nossos sítios web, aplicações ou serviços e que são eliminados quando encerra o seu navegador ou abandona a sua sessão na aplicação ou serviço) ou cookies persistentes (cookies que permitem que os nossos sítios web, aplicações ou serviços "memorizem" quem é, bem como as suas preferências nos nossos sítios web, aplicações ou serviços, e que permanecem no seu computador ou dispositivo depois de encerrar o seu navegador ou abandonar a sua aplicação na aplicação ou serviço).',
                listagem: [],
                texto2: ""
            },
            {
                titulo: "11. COMO DESATIVAR COOKIES",
                texto: (
                    <>
                        Se o desejar, poderá configurar o seu navegador ou o nosso sítio web, aplicação ou serviço de forma a restringir cookies ou bloquear todos os cookies. Contudo, se desativar os
                        cookies, a sua possibilidade de utilizar determinadas partes do nosso sítio web, aplicações ou serviços poderá ser afetada. Para mais informações sobre cookies e instruções
                        sobre como ajustar as definições do seu navegador de forma a aceitar, eliminar ou rejeitar cookies, consultar o sítio web{" "}
                        <a href="allaboutcookies.org" target="_blank" rel="noopener noreferrer">
                            allaboutcookies.org
                        </a>
                    </>
                ),
                listagem: [],
                texto2: ""
            },
            {
                titulo: "12. ALTERAÇÕES À PRESENTE POLÍTICA DE PRIVACIDADE",
                texto: "A presente Política de Privacidade pode ser revista e atualizada, o que será objeto de divulgação através do nosso website. Nela constará a data da última atualização.",
                listagem: [],
                texto2: ""
            },
            {
                titulo: "13. INFORMAÇÕES ADICIONAIS",
                texto: (
                    <>
                        Em caso de dúvida sobre a forma como tratamos as suas informações, o conteúdo da presente Política de Privacidade, os seus direitos ao abrigo da legislação local, como
                        atualizar os seus registos ou como obter uma cópia das informações que conservamos a seu respeito, recomendamos enviar uma mensagem de correio eletrónico para{" "}
                        <a href="mailto:privacidade@stepforma.com">privacidade@stepforma.com</a>.
                    </>
                ),
                listagem: [],
                texto2: ""
            }
        ]
    };

    render() {
        return (
            <div className="suporte">
                <Header titulo="Política de Privacidade" marginTop0={true} />
                <div className="container">
                    <div className="content">
                        <div className="termos">
                            {this.state.termos.map((termo, index) => (
                                <div key={index} className="item">
                                    <div className="titulo">{termo.titulo}</div>
                                    <div className="texto">
                                        {termo.texto}
                                        <ul>
                                            {termo.listagem.map((listagem, index2) => (
                                                <li key={index2}>
                                                    <i className="fa fa-check" />
                                                    {listagem.pontos}
                                                </li>
                                            ))}
                                        </ul>
                                        {termo.texto2}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PoliticaPrivacidade;
