import React, { Component } from "react";
import Indice from "./_Aux/Indice";
import Artigo from "./_Aux/Artigo";
import Header from "./Header";
import axios from "axios";
import { GlobalContext } from "../GlobalState";

class Suporte extends Component {
    static contextType = GlobalContext;

    state = {
        opcao: 0,
        niveis: [],
        artigos: []
    };

     UNSAFE_componentWillMount() {
         this.listarTemas();
     }

     listarTemas = () => {
         axios({
             method: "get",
             url: "/api/home-utilizador/listar-temas"
         })
             .then(response => {
                 var niveis = [];
                 switch (this.context.role) {
                     case "UTILIZADOR":
                         niveis = [
                             {
                                 nome: "Sobre a Stepforma"
                             },
                             {
                                 nome: "Aluno"
                             }
                         ];
                         break;
                     case "FORMADOR":
                         niveis = [
                             {
                                 nome: "Sobre a Stepforma"
                             },
                             {
                                 nome: "Aluno"
                             },
                             {
                                 nome: "Instrutor"
                             }
                         ];
                         break;
                     default:
                         niveis = [
                             {
                                 nome: "Sobre a Stepforma"
                             },
                             {
                                 nome: "Aluno"
                             },
                             {
                                 nome: "Instrutor"
                             },
                             {
                                 nome: "Gestor"
                             }
                         ];
                         break;
                 }

                 niveis.forEach((nivel, index) => {
                     var _indice = [];
                     var indices = response.data.filter(x => x.nivel === (index + 1))
                     indices.map((indice, index2) => {
                         _indice.push({
                             id: indice.id,
                             tema: indice.tema
                         })

                         if ((index === 0 && index2 === 0)) {
                             this.listarPerguntasRespostas(indice.id)
                             this.setState({
                                 opcao: indice.id
                             })
                         }
                     });

                     niveis[index] = { ...niveis[index], indices: _indice }
                 })

                 this.setState({
                     niveis
                 });
             })
             .catch(() => {});
     };

    listarPerguntasRespostas = (temaid) => {
         axios({
             method: "get",
             url: "/api/home-utilizador/listar-perguntas-respostas",
             params: {
                 temaid: temaid
             }
         })
             .then(response => {
                 this.setState({
                     opcao: temaid,
                     artigos: response.data,
                 });
             })
             .catch(() => {});
     };

    render() {
        const { opcao, niveis, artigos } = this.state;

        return (
            <>
                <div className="suporte">
                    <Header titulo="Tutoriais" />
                    <div className="container container-body">
                        <div className="content bloco-grid">
                            <div className="indice">
                                <ul>
                                    {niveis.map((nivel, index) => (
                                        <Indice
                                            key={index}
                                            opcao={opcao}
                                            nivel={nivel}
                                            nivelIndex={index}
                                            listarPerguntasRespostas={this.listarPerguntasRespostas}
                                            collapsed={index === 0}
                                        />
                                    ))}
                                </ul>
                            </div>
                            <div className="artigo">
                                {artigos.map((artigo, index) => (
                                    <Artigo key={index} dados={artigo} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Suporte;
