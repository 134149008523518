import React, { Component } from "react";
import Gestor from "./_Aux/Gestor"
import Instrutor from "./_Aux/Instrutor"
import { GlobalContext } from "../../GlobalState";

export class Main extends Component {
    static contextType = GlobalContext;

    render() {

        return (
            <div className="container container-body">
                {this.context.role === "ENTIDADE" ?
                    <Gestor />
                    : null}
                {this.context.role === "FORMADOR" ?
                    <Instrutor />
                    : null}
            </div>
        );
    }
}

export default Main;
