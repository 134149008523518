import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Form, Input, Upload, notification, Icon, Modal } from "antd";
import Header from "../User/Header";
import { GlobalContext } from "../GlobalState";
import ControlosSimples from "./_Aux/ControlosSimples";
import LazyLoad from "react-lazy-load";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class JitsiMeetingBlock extends Component {
    static contextType = GlobalContext;
    state = {
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    render() {
        const {
            redirect,
            redirectLink,
        } = this.state;

        if (redirect)
            return <Redirect to={redirectLink} />;

        return (
            <div className="questionario">
                <div className="player-wrapper">
                    <LazyLoad offsetVertical={200}>
                        <img src={require("../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />
                    </LazyLoad>
                    <div className="container">
                        <div className="bloco-presencial">
                            <div className="bloco-download-link">
                                <div className="bloco-info-tarefa" style={{ textAlign: "center", display: "unset" }}>
                                    <span className="titulo-tarefa">Já está a participar na Stepmeet em outro dispositivo ou browser</span>
                                    <span className="subtitulo-tarefa" style={{ display: "block", fontSize: 18, color: "gray" }}>Deve terminar a sessão anterior para entrar novamente</span>
                                </div>
                                <Link className="botao-principal" to={`/curso/${this.props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-videoconferencias`}>
                                    Voltar
                                </Link>
                            </div>
                        </div>
                    </div>
                    <ControlosSimples
                        backUrl={`/curso/${this.props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-videoconferencias`}
                    />
                </div>
            </div>
        );
    }
}
export default withRouter(JitsiMeetingBlock);
