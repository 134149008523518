import React, { Component } from "react";
import { Link, matchPath } from "react-router-dom";
import { Modal, notification, Popover, Dropdown } from "antd";
import { GlobalContext } from "../../GlobalState";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import noimage from "../../images/noimage.png";
import axios from "axios";
import moment from "moment";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Anotacao extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        curso: "",
        abrir_detalhe: 0,
        anotacaoid: 0,
        periodo: "",
        texto: "",
        anexo: ""
    };

    UNSAFE_componentWillMount() {
        const match = matchPath(window.location.pathname, {
            path: "/:curso/:cursoid/curso-anotacoes"
        });

        if (match && match.params.cursoid) {
            this.setState({
                cursoid: match.params.cursoid,
                curso: match.params.curso
            });
        }
    }

    listarPartilhas = partilhas => {
        var _aux = partilhas.map(partilha => {
            return (
                <div className="content" key={partilha.id}>
                    <div className="container-img">
                        <img className="" src={partilha.utilizador.foto ? partilha.utilizador.foto : noimage} />
                    </div>
                    <div className="container-text">
                        <span className="nome">{partilha.utilizador.nome_completo}</span>
                        <span className="role">
                            {partilha.utilizador.curso_Formadores.length === 0
                                ? "Formando"
                                : partilha.utilizador.curso_Formadores[0].responsavel
                                ? "Coordenador"
                                : "Formador"}
                        </span>
                    </div>
                </div>
            );
        });

        return _aux;
    };

    render() {
        const { index, modulo } = this.props;
        const { cursoid, curso } = this.state;

        return (
            <>
                <div className="anotacao-header-modulos">
                    <span className="modulo-num">Módulo {index + 1}</span>
                    <span className="modulo-nome">{modulo.nome}</span>
                </div>
                <div className="anotacao anotacao-body">
                    <div className="anotacoes-listagem">
                        {modulo.modulo_Aula.map((aula, index) => (
                            <>
                                <span className="nome-aula">
                                    <span className="start" title={"Aula com " + aula.tipo_Recurso.nome.toLowerCase()}>
                                        <i className={aula.tipo_Recurso.icon} />
                                    </span>
                                    {aula.index + 1}. {aula.nome}
                                </span>
                                <div key={"aula" + index} className="anotacao-content">
                                    <div className="info-anotacao">
                                        <ul>
                                            {aula.anotacoes.map((anotacao, index) => (
                                                <li key={"aulaAnotacao" + index}>
                                                    <span className="texto-anotacao">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase() +
                                                                "/" +
                                                                cursoid +
                                                                "/curso-anotacoes-detalhe/" +
                                                                anotacao.id
                                                            }
                                                            onClick={() =>
                                                                localStorage.setItem(
                                                                    "info",
                                                                    JSON.stringify({
                                                                        moduloIndex: index + 1,
                                                                        modulo: modulo.nome,
                                                                        icon: aula.tipo_Recurso.icon,
                                                                        iconTexto: "Aula com " + aula.tipo_Recurso.nome.toLowerCase(),
                                                                        aulaIndex: aula.index + 1,
                                                                        aula: aula.nome,
                                                                        aulaid: aula.id
                                                                    })
                                                                )
                                                            }
                                                        >
                                                            <HTMLEllipsis
                                                                unsafeHTML={anotacao.texto}
                                                                maxLine="3"
                                                                ellipsis="..."
                                                                basedOn="letters"
                                                            />
                                                        </Link>
                                                        {anotacao.anexo != null && (
                                                            <a
                                                                href={anotacao.anexo}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="anotacao-anexo"
                                                                title="Descarregar anexo"
                                                            >
                                                                <i className="fas fa-arrow-circle-down" />
                                                            </a>
                                                        )}
                                                        {this.props.montarPopover(anotacao)}
                                                        {anotacao.autor && (
                                                            <Dropdown
                                                                overlay={this.props.montarMenu(anotacao.id, anotacao.autor, false)}
                                                                placement="bottomLeft"
                                                                className="botao-icon-configs"
                                                            >
                                                                <i className="fas fa-cog" />
                                                            </Dropdown>
                                                        )}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

export default Anotacao;
