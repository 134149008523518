import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Icon, Tooltip } from "antd";
import noimage from "../../../images/noimage.png";
import moment from "moment";

class Topico extends Component {
    render() {
        const razao = (
            <div>
                <h4>Rejeitado por</h4>
                <p>{this.props.resposta.razao}</p>
            </div>
        );

        return (
            <div className="linha-resposta">
                <div className="info-resposta">
                    <div className="questao questao-desktop">
                        <div className="info-autor">
                            <div className="container-img">
                                <img src={this.props.resposta.utilizador.foto ? this.props.resposta.utilizador.foto : noimage} />
                            </div>
                            <div className="info">
                                <span className="nome">
                                    {this.props.resposta.utilizador.nome_completo.split(" ").length > 1 ? `${this.props.resposta.utilizador.nome_completo.split(" ")[0]} ${this.props.resposta.utilizador.nome_completo.split(" ")[this.props.resposta.utilizador.nome_completo.split(" ").length - 1]}` : this.props.resposta.utilizador.nome_completo}
                                    <span className="separador">-</span>
                                    <span className="data">
                                        {moment(this.props.resposta.dt_criado).format("DD/MM/YYYY - HH:mm")}
                                        {this.props.resposta.estado == 0 ? (
                                            <Icon type="warning" className="aguarda-validacao" title="Aguarda validação" />
                                        ) : (
                                            ""
                                        )}
                                        {this.props.resposta.estado === 2 ? (
                                            <Tooltip placement="bottomLeft" title={razao} overlayClassName="tooltip-razao">
                                                {" "}
                                                <Icon type="stop" className="rejeitado" title="Rejeitado" />{" "}
                                            </Tooltip>
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </span>
                                <p className="numero">{this.props.resposta.utilizador.numero}</p>
                            </div>
                        </div>
                        {(this.props.resposta.proprio && this.props.resposta.estado !== 1) && !this.props.fechado ? (
                            <span className="controlos">
                                <Link to="#" className="botao-icon-excluir" onClick={() => this.props.excluir(this.props.resposta.id)}>
                                    <Icon type="delete" />
                                </Link>
                            </span>
                        ) : null}
                    </div>
                    <div className="questao questao-mobile">
                        <div className="questao-item">
                            <div className="info-autor">
                                <div className="container-img">
                                    <img src={this.props.resposta.utilizador.foto ? this.props.resposta.utilizador.foto : noimage} />
                                </div>
                                <div className="info">
                                    <span className="nome">
                                        {this.props.resposta.utilizador.nome_completo.split(" ").length > 1 ? `${this.props.resposta.utilizador.nome_completo.split(" ")[0]} ${this.props.resposta.utilizador.nome_completo.split(" ")[this.props.resposta.utilizador.nome_completo.split(" ").length - 1]}` : this.props.resposta.utilizador.nome_completo}
                                        {this.props.resposta.estado == 0 ? (
                                            <Icon type="warning" className="aguarda-validacao" title="Aguarda validação" />
                                        ) : (
                                            ""
                                        )}
                                        {this.props.resposta.estado === 2 ? (
                                            <Tooltip placement="bottomLeft" title={razao} overlayClassName="tooltip-razao">
                                                {" "}
                                                <Icon type="stop" className="rejeitado" title="Rejeitado" />{" "}
                                            </Tooltip>
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                    <p className="numero">
                                        {this.props.resposta.utilizador.numero}
                                        <span className="separador">-</span>
                                        <span className="data">
                                            {moment(this.props.resposta.dt_criado).format("DD/MM/YYYY - HH:mm")}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="controlos">
                                <Link to="#" className="botao-icon-excluir" onClick={() => this.props.excluir(this.props.resposta.id)}>
                                    <Icon type="delete" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <span
                        className="resposta"
                        dangerouslySetInnerHTML={{ __html: this.props.resposta.texto.replace(/(?:\r\n|\r|\n)/g, "<br>") }}
                    ></span>
                </div>
            </div>
        );
    }
}

export default Topico;
