import React, { Component } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
// import logo from "../images/logo_branco.png";
import logo from "../images/logos/stepforma-02.png";
import { Input, Icon, notification, Badge, Dropdown } from "antd";
import { GlobalContext } from "../GlobalState";
import axios from "axios";
import DrawerCarrinho from "./_Aux/DrawerCarrinho";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class Navbar extends Component {
    static contextType = GlobalContext;
    state = {
        mobile: false,
        entidades: [],
        //DRAWER CARRINHO
        visibleCart: false,
        semNavbar: ""
    };

    componentDidMount() {
        if (this.props.history.location.pathname.split("/").pop() === "player-videoconferencia"
            || this.props.history.location.pathname.split("/").pop() === "player-video"
            || this.props.history.location.pathname.split("/").pop() === "player-audio"
            || this.props.history.location.pathname.split("/").pop() === "player-pagina"
            || this.props.history.location.pathname.split("/").pop() === "player-pdf"
            || this.props.history.location.pathname.split("/").pop() === "player-download"
            || this.props.history.location.pathname.split("/").pop() === "player-link"
            || this.props.history.location.pathname.split("/").pop() === "player-sumario"
            || this.props.history.location.pathname.split("/").pop() === "player-sessao-presencial")
            this.setState({
                semNavbar: "sem-navbar"
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.history.location.pathname.split("/").pop() === "player-videoconferencia"
                || this.props.history.location.pathname.split("/").pop() === "player-video"
                || this.props.history.location.pathname.split("/").pop() === "player-audio"
                || this.props.history.location.pathname.split("/").pop() === "player-pagina"
                || this.props.history.location.pathname.split("/").pop() === "player-pdf"
                || this.props.history.location.pathname.split("/").pop() === "player-download"
                || this.props.history.location.pathname.split("/").pop() === "player-link"
                || this.props.history.location.pathname.split("/").pop() === "player-sumario"
                || this.props.history.location.pathname.split("/").pop() === "player-sessao-presencial")
                this.setState({
                    semNavbar: "sem-navbar"
                });
            else
                this.setState({
                    semNavbar: ""
                });
        }
    }

    pesquisarEntidades = pesquisa => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/pesquisar-entidade",
            params: {
                pesquisa,
            },
        })
            .then(response => {
                if (response.data)
                    this.setState({
                        entidades: response.data,
                    });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível procurar canal!");
            });
    };

    onKeyDown = event => {
        if (event.keyCode === 27) {
            this.context.atualizarState({
                search_entidade: false,
            });
        }
    };

    voltarCarrinho = () => {
        this.setState({
            visibleCart: false
        }, () => {
            this.context.atualizarState({
                carrinho: []
            })
        })
    }

    abrirLogin = () => {
        this.setState({
            visibleCart: false
        })

        document.getElementsByClassName("btn_login")[0].click();
    }

    render() {
        const { Search } = Input;
        const { semPesquisa, entidade } = this.props;
        const {
            //DRAWER CARRINHO
            visibleCart,
            semNavbar
        } = this.state;

        const menuPaginas = (
            <>
                {entidade?.paginas ?
                    entidade.paginas.map(pagina => (
                        <li className="nav-item">
                            <NavLink to={`${entidade.site_entidade}/${pagina.titulo.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${pagina.id}`} className="nav-link">
                                {pagina.titulo}
                            </NavLink>
                        </li>
                    ))
                    : null}
            </>
        );

        return (
            <>
                <div className={`navbar-nao-logado ${semNavbar}`} style={this.props.style}>
                    <div className="container container-v2">
                        {semPesquisa ? (
                            <div className="brand">
                                <Link to={entidade.site_entidade ? entidade.site_entidade : "#"} className="nav-link">
                                    <div className="container_logo">
                                        <img
                                            src={entidade.logo_entidade && entidade.imagem_propria ? entidade.logo_entidade : logo}
                                            alt="Logo"
                                            className="logo"
                                        />
                                    </div>
                                </Link>
                                {entidade.nome_entidade ?
                                    entidade.paginas.length ?
                                        <Dropdown
                                            overlay={menuPaginas}
                                            overlayClassName="dropdown-menu-navbar"
                                        >
                                            <h2 className="nome-entidade">{entidade.nome_entidade} <Icon type="down" /></h2>
                                        </Dropdown>
                                        :
                                        <h2 className="nome-entidade">{entidade.nome_entidade}</h2>
                                    : null}
                            </div>
                        ) : (
                            <div className="brand">
                                <Link to="/" className="nav-link">
                                    <div className="container_logo">
                                        <img src={logo} alt="Logo" className="logo" />
                                    </div>
                                </Link>
                            </div>
                        )}
                        <ul className={"nav-list" + `${this.state.mobile ? " mobile-ativo" : ""}`}>
                            {!semPesquisa && (
                                <li className="list-item">
                                    <a
                                        className="nav-link btn_procurar"
                                        onClick={() => {
                                            this.context.atualizarState({ search_entidade: true });
                                            this.setState({ mobile: !this.state.mobile });
                                        }}
                                    >
                                        <Icon type="search" />
                                        Canal
                                    </a>
                                </li>
                            )}
                            {semPesquisa && this.context.monetizacao_entidade ?
                                <li className="list-item">
                                    <Badge
                                        count={this.context.carrinho.length}
                                        showZero={false}
                                        style={{ backgroundColor: "#f79071" }}
                                    >
                                        <Link to="#" className="carrinho-compras" onClick={() => this.setState({ visibleCart: true })} style={{ fontSize: 25, color: "rgba(0, 0, 0, 0.6)" }}>
                                            <i className="fas fa-shopping-cart"></i>
                                        </Link>
                                    </Badge>
                                </li>
                                : null}
                            {!this.props.semIniciarSessao ?
                                <li className="list-item">
                                    {semPesquisa ? (
                                        <Link
                                            to={`${entidade.site_entidade}/${entidade.login_entidade}`}
                                            className="nav-link btn_login"
                                            onClick={() => {
                                                this.setState({ mobile: !this.state.mobile });
                                                localStorage.removeItem("nome_curso");
                                            }}
                                        >
                                            Iniciar Sessão
                                        </Link>
                                    )
                                        :
                                        (
                                            <Link
                                                to="/login"
                                                className="nav-link btn_login"
                                                onClick={() => this.setState({ mobile: !this.state.mobile })}
                                            >
                                                Iniciar Sessão
                                            </Link>
                                        )
                                    }
                                </li> : null}
                            {/*{!this.props.semIniciarSessao ?
                                <li className="list-item">
                                    {semPesquisa ? (
                                        <Link
                                            to={`${entidade.site_entidade}/${entidade.login_entidade}`}
                                            className="nav-link btn_login"
                                            onClick={() => {
                                                this.setState({ mobile: !this.state.mobile });
                                                localStorage.removeItem("nome_curso");
                                            }}
                                        >
                                            Iniciar Sessão
                                        </Link>
                                    )
                                        : null
                                    }
                                </li> : null}*/}
                        </ul>
                        <div className="burger" onClick={() => this.setState({ mobile: !this.state.mobile })}>
                            <Icon type={this.state.mobile ? "close" : "menu"} />
                        </div>
                    </div>
                </div>
                {this.context.search_entidade && (
                    <div className="search-fullscreen" onKeyDown={this.onKeyDown}>
                        <div className="container">
                            <Icon
                                type="close"
                                className="close-search"
                                onClick={() => this.context.atualizarState({ search_entidade: false })}
                            />
                            <Search
                                placeholder="Procurar Canal"
                                name="inputPesquisa"
                                autoComplete="off"
                                onSearch={this.pesquisarEntidades}
                            />
                            <div className="listagem-entidades">
                                {this.state.entidades.map((entidade, index) => (
                                    <a
                                        href={entidade.nome_abrev ? (entidade.dominio ? `https://${entidade.dominio}/${entidade.nome_abrev}` : `/${entidade.nome_abrev}`) : `https://${entidade.dominio}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="entidade"
                                        key={index}
                                    >
                                        <div className="container-img">
                                            <img src={entidade.logo ? entidade.logo : require("../images/noimage.png")} />
                                        </div>
                                        <span className="nome">{entidade.nome}</span>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <DrawerCarrinho visibleCart={visibleCart} onClose={() => this.setState({ visibleCart: false })} voltarCarrinho={this.voltarCarrinho} abrirLogin={this.abrirLogin} />
            </>
        );
    }
}

export default withRouter(Navbar);
