import React, { Component } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Drawer, Form, Upload, notification, Icon, Tooltip, Input, Select, Checkbox } from "antd";
import { GlobalContext } from "../../../GlobalState";
import axios from "axios";
import noimage from "../../../images/noimage.png";

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerEnviarMsg extends Component {
    static contextType = GlobalContext;
    state = {
        utilizador: "",
        //INFO
        texto: "",
        anexo: "",
        fileList: [],
        //VALIDACOES
        status: "",
        helper: "",
        totalBytes: 0,
        formatoValido: true
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handlerChangeCheck = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    //ADICIONA UMA NOVA ANOTAÇÃO
    enviarMensagem = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 26214400 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_utilizador", this.state.utilizador.id);
                item.append("id_curso", localStorage.getItem("codigo_curso"));
                item.append("texto", this.state.texto);

                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });
                item.append("responsavel", this.props.responsavel);

                axios({
                    method: "post",
                    url: "/api/chat/enviar-mensagem-participante",
                    data: item
                })
                    .then(() => {
                        this.setState({
                            iconLoading: false,
                            //INFO
                            texto: "",
                            anexo: "",
                            fileList: [],
                            //VALIDACOES
                            status: "",
                            helper: "",
                            totalBytes: 0,
                            formatoValido: true
                        });
                        this.props.form.resetFields();
                        openNotificationWithIcon("success", "Sucesso", "E-mail enviado!");
                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormato = formato => {
        var formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.setState({
                utilizador: JSON.parse(this.props.utilizador),
                //INFO
                texto: "",
                anexo: "",
                fileList: [],
                //VALIDACOES
                status: "",
                helper: "",
                totalBytes: 0,
                formatoValido: true
            })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const Dragger = Upload.Dragger;
        const { Option } = Select;
        const { fileList, validateStatus, help, iconLoading } = this.state;
        
        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: true,
            onChange: info => {
                var tamanhoTotal = 0;
                var ficheirosValidos = true;
                info.fileList.forEach(file => {
                    tamanhoTotal += file.size;

                    if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                        ficheirosValidos = false;
                    }
                });

                if (tamanhoTotal > 26214400)
                    this.setState({
                        totalBytes: tamanhoTotal,
                        validateStatus: "error",
                        help: "Limite de 25 MB por upload."
                    });
                else {
                    if (!ficheirosValidos)
                        this.setState({
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Ficheiro(s) com formato inválido"
                        });
                }
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    var tamanhoTotal = 0;
                    var ficheirosValidos = true;
                    newFileList.forEach(file => {
                        tamanhoTotal += file.size;

                        if (!this.validarFormato(file.name.split(".").pop().toLowerCase())) {
                            ficheirosValidos = false;
                        }
                    });

                    if (tamanhoTotal > 26214400 || !ficheirosValidos)
                        return {
                            totalBytes: tamanhoTotal,
                            fileList: newFileList
                        };
                    else
                        return {
                            totalBytes: tamanhoTotal,
                            formatoValido: true,
                            validateStatus: "",
                            help: "",
                            fileList: newFileList
                        };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            fileList
        };

        return (
            <Drawer
                placement="right"
                className="drawer-envio-mensagem-inscricao"
                maskClosable={false}
                closable={true}
                onClose={this.props.onClose}
                visible={this.props.visibleDrawerEnviarMsg}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-nova-pergunta">
                        {!this.props.responsavel ?
                            <div className="bloco">
                                <h3 className="titulo-separador">Para</h3>
                                <Form.Item>
                                    <div className="info-utilizador">
                                        <div className="container-img">
                                            <img src={this.state.utilizador.foto ? this.state.utilizador.foto : noimage} />
                                        </div>
                                        <div className="info">
                                            <span className="nome">{this.state.utilizador.nome}</span>
                                            <span className="numero">{this.state.utilizador.numero}</span>
                                        </div>
                                    </div>
                                </Form.Item>
                            </div>
                            :
                            <div className="bloco">
                                <h3 className="titulo-separador">Para o responsável de</h3>
                                <Form.Item>
                                    <div className="info-utilizador">
                                        <div className="container-img">
                                            <img src={this.state.utilizador.foto ? this.state.utilizador.foto : noimage} />
                                        </div>
                                        <div className="info">
                                            <span className="nome">{this.state.utilizador.nome}</span>
                                            <span className="numero">{this.state.utilizador.numero}</span>
                                        </div>
                                    </div>
                                </Form.Item>
                                <h3 className="titulo-separador">Responsável</h3>
                                <Form.Item>
                                    <div className="info-responsavel">
                                        <span className="nome">{this.state.utilizador.responsavelNome}</span>
                                        <span className="email">{this.state.utilizador.responsavelEmail}</span>
                                    </div>
                                </Form.Item>
                            </div>
                        }
                        <div className="bloco">
                            <Form.Item label="Mensagem">
                                {getFieldDecorator("texto", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<TextArea rows={15} name="texto" placeholder="Mensagem..." style={{ height: "100%" }} onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        <i className="fas fa-upload" />
                                    </p>
                                    <p className="ant-upload-text">
                                        Adicionar anexos{" "}
                                        <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </p>
                                    <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                </Dragger>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                                </button>
                    <button
                        className="botao-principal"
                        onClick={this.enviarMensagem}
                        disabled={iconLoading}
                    >
                        {iconLoading ? <Icon type="loading" /> : null}
                                    Enviar
                                </button>
                </div>
            </Drawer>
        );
    }
}

const FormDrawerEnviarMsg = Form.create({ name: "form-enviar-mensagem" })(DrawerEnviarMsg);

export default withRouter(FormDrawerEnviarMsg);
