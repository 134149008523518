import React, { Component } from "react";
import { Link, matchPath, Redirect, withRouter } from "react-router-dom";
import { Radio, Checkbox, Row, notification, Upload, Tooltip, Input, Icon, Collapse } from "antd";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ConteudoQuestionario extends Component {
    static contextType = GlobalContext;
    state = {
        questionarioid: 0,
        cursoid: 0,
        questionario_tipo: "",
        curso: "",
        aulaid: 0,
        nrQuestoes: 0,
        questaoAtual: 0,
        questao: {},
        resposta: 0,
        resposta_checkbox: [],
        resposta_livre: "",
        fileList: [],
        fim: false,
        confirmacao: false,
        totalEmBranco: 0,
        //ESTADOS
        apresentacao: true,
        iniciado: false,
        concluido: false,
        //INFO
        nome_curso: "",
        nome_questionario: "",
        total_questoes: 0,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        redirectLinkResolucao: "",
        redirectLinkRepetir: "",
        //
        numPages: null,
        pageNumber: 1,
        scale: 1.5,
        rotate: 0,
        //LOADING
        iconLoadingProximo: false,
        iconLoadingAnterior: false,
        //LOADING
        loading: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = localStorage.getItem("codigo_curso");
        var questionario_tipo = localStorage.getItem("questionario_tipo");

        this.setState({
            cursoid: cursoId,
            questionario_tipo
        });

        this.carregarQuestionarioInfo(cursoId, questionario_tipo);
    }

    //APANHA OS ATRIBUTOS PASSADOS PELO ENDEREÇO
    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    onChangeRadio = e => {
        this.setState({
            resposta: e.target.value
        });
    };

    onChangeCheck = value => {
        var checks = [];

        for (var i = 0; i < value.length; i++) {
            if (value[i] > 0) checks.push(value[i]);
        }

        this.setState({
            resposta_checkbox: checks
        });
    };

    onChangeTextArea = event => {
        this.setState({
            resposta_livre: event.target.value
        });
    };

    //DEVOLVE O TOTAL DE PERGUNTAS
    carregarQuestionarioInfo = (cursoid, questionario_tipo) => {
        axios({
            method: "get",
            url: "/api/questionario-satisfacao/carregar-questionario-info-utilizador",
            params: {
                cursoid,
                questionario_tipo
            }
        })
            .then(response => {
                this.setState({
                    questionarioid: response.data.id,
                    nome_curso: response.data.nomeCurso,
                    nome_questionario: response.data.nomeQuestionario,
                    total_questoes: response.data.totalQuestoes,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    iniciarQuestionario = event => {
        event.preventDefault();

        this.carregaPergunta();
        this.setState({
            apresentacao: false,
            iniciado: true
        });

        //axios({
        //    method: "put",
        //    url: "/api/player/iniciar-aula",
        //    params: {
        //        cursoid: this.state.cursoid
        //    }
        //})
        //    .then(() => {
        //        this.carregaPergunta();
        //        this.setState({
        //            apresentacao: false,
        //            iniciado: true
        //        });
        //    })
        //    .catch(error => {
        //        openNotificationWithIcon("error", "Erro", "Não foi possível iniciar aula!");
        //    });
    };

    carregaPergunta = () => {
        if (this.state.questaoAtual != this.state.total_questoes) {
            this.setState({
                iconLoadingProximo: true
            });
            if (this.state.questionario_tipo != "ALUNO_INSTRUTORES") {
                axios({
                    method: "get",
                    url: "/api/questionario-satisfacao/carrega-pergunta",
                    params: {
                        cursoid: this.state.cursoid,
                        questionarioid: this.state.questionarioid,
                        pergunta: this.state.questaoAtual
                    }
                })
                    .then(response => {
                        this.setState({
                            resposta: 0,
                            resposta_checkbox: [],
                            resposta_livre: "",
                            fileList: []
                        });

                        if (response.data.questionario_Utilizadores2.length > 0) {
                            if (response.data.tipo_pergunta == "Escolha múltipla") {
                                var resposta_checkbox = [];

                                for (var i = 0; i < response.data.questionario_Utilizadores2.length; i++) {
                                    if (response.data.questionario_Utilizadores2[i].id_opcao)
                                        resposta_checkbox.push(response.data.questionario_Utilizadores2[i].id_opcao);
                                }

                                this.setState({
                                    resposta_checkbox
                                });
                            } else if (response.data.tipo_pergunta == "Escala 1 a 5" || response.data.tipo_pergunta == "Escala 1 a 10") {
                                if (response.data.questionario_Utilizadores2[0].id_opcao)
                                    this.setState({
                                        resposta: response.data.questionario_Utilizadores2[0].id_opcao.toString()
                                    });
                                else
                                    this.setState({
                                        resposta: ""
                                    });
                            } else if (response.data.tipo_pergunta == "Resposta livre") {
                                if (response.data.questionario_Utilizadores2[0].resposta_livre) {
                                    var fileList = [];

                                    if (response.data.questionario_Utilizadores2[0].ficheiro)
                                        fileList.push({
                                            uid: "-1",
                                            name: response.data.questionario_Utilizadores2[0].ficheiro_nome,
                                            status: "done",
                                            url: response.data.questionario_Utilizadores2[0].ficheiro
                                        });

                                    this.setState({
                                        resposta_livre: response.data.questionario_Utilizadores2[0].resposta_livre,
                                        fileList
                                    });
                                } else
                                    this.setState({
                                        resposta_livre: "",
                                        fileList: []
                                    });
                            } else {
                                if (response.data.questionario_Utilizadores2[0].id_opcao)
                                    this.setState({
                                        resposta: response.data.questionario_Utilizadores2[0].id_opcao
                                    });
                                else
                                    this.setState({
                                        resposta: ""
                                    });
                            }
                        }

                        this.setState({
                            questao: response.data,
                            questaoAtual: this.state.questaoAtual + 1,
                            iconLoadingProximo: false
                        });
                    })
                    .catch(() => {});
            } else {
                axios({
                    method: "get",
                    url: "/api/questionario-satisfacao/carrega-pergunta2",
                    params: {
                        cursoid: this.state.cursoid,
                        utilizadorid: localStorage.getItem("codigo_utilizador"),
                        questionarioid: this.state.questionarioid,
                        pergunta: this.state.questaoAtual
                    }
                })
                    .then(response => {
                        this.setState({
                            resposta: 0,
                            resposta_checkbox: [],
                            resposta_livre: "",
                            fileList: []
                        });

                        if (response.data.questionario_Utilizadores2.length > 0) {
                            if (response.data.tipo_pergunta == "Escolha múltipla") {
                                var resposta_checkbox = [];

                                for (var i = 0; i < response.data.questionario_Utilizadores2.length; i++) {
                                    if (response.data.questionario_Utilizadores2[i].id_opcao)
                                        resposta_checkbox.push(response.data.questionario_Utilizadores2[i].id_opcao);
                                }

                                this.setState({
                                    resposta_checkbox
                                });
                            } else if (response.data.tipo_pergunta == "Escala 1 a 5" || response.data.tipo_pergunta == "Escala 1 a 10") {
                                if (response.data.questionario_Utilizadores2[0].id_opcao)
                                    this.setState({
                                        resposta: response.data.questionario_Utilizadores2[0].id_opcao.toString()
                                    });
                                else
                                    this.setState({
                                        resposta: ""
                                    });
                            } else if (response.data.tipo_pergunta == "Resposta livre") {
                                if (response.data.questionario_Utilizadores2[0].resposta_livre) {
                                    var fileList = [];

                                    if (response.data.questionario_Utilizadores2[0].ficheiro)
                                        fileList.push({
                                            uid: "-1",
                                            name: response.data.questionario_Utilizadores2[0].ficheiro_nome,
                                            status: "done",
                                            url: response.data.questionario_Utilizadores2[0].ficheiro
                                        });

                                    this.setState({
                                        resposta_livre: response.data.questionario_Utilizadores2[0].resposta_livre,
                                        fileList
                                    });
                                } else
                                    this.setState({
                                        resposta_livre: "",
                                        fileList: []
                                    });
                            } else {
                                if (response.data.questionario_Utilizadores2[0].id_opcao)
                                    this.setState({
                                        resposta: response.data.questionario_Utilizadores2[0].id_opcao
                                    });
                                else
                                    this.setState({
                                        resposta: ""
                                    });
                            }
                        }

                        this.setState({
                            questao: response.data,
                            questaoAtual: this.state.questaoAtual + 1,
                            iconLoadingProximo: false
                        });
                    })
                    .catch(() => {});
            }
        } else this.concluir();
    };

    carregaPerguntaAnterior = () => {
        this.setState({
            iconLoadingAnterior: true
        });

        if (this.state.questionario_tipo != "ALUNO_INSTRUTORES") {
            axios({
                method: "get",
                url: "/api/questionario-satisfacao/carrega-pergunta",
                params: {
                    cursoid: this.state.cursoid,
                    questionarioid: this.state.questionarioid,
                    pergunta: this.state.questaoAtual - 2
                }
            })
                .then(response => {
                    this.setState({
                        resposta: 0,
                        resposta_checkbox: [],
                        resposta_livre: "",
                        fileList: []
                    });

                    if (response.data.questionario_Utilizadores2.length > 0) {
                        if (response.data.tipo_pergunta == "Escolha múltipla") {
                            var resposta_checkbox = [];

                            for (var i = 0; i < response.data.questionario_Utilizadores2.length; i++) {
                                if (response.data.questionario_Utilizadores2[i].id_opcao)
                                    resposta_checkbox.push(response.data.questionario_Utilizadores2[i].id_opcao);
                            }

                            this.setState({
                                resposta_checkbox
                            });
                        } else if (response.data.tipo_pergunta == "Escala 1 a 5" || response.data.tipo_pergunta == "Escala 1 a 10") {
                            if (response.data.questionario_Utilizadores2[0].id_opcao)
                                this.setState({
                                    resposta: response.data.questionario_Utilizadores2[0].id_opcao.toString()
                                });
                            else
                                this.setState({
                                    resposta: ""
                                });
                        } else if (response.data.tipo_pergunta == "Resposta livre") {
                            if (response.data.questionario_Utilizadores2[0].resposta_livre) {
                                var fileList = [];

                                if (response.data.questionario_Utilizadores2[0].ficheiro)
                                    fileList.push({
                                        uid: "-1",
                                        name: response.data.questionario_Utilizadores2[0].ficheiro_nome,
                                        status: "done",
                                        url: response.data.questionario_Utilizadores2[0].ficheiro
                                    });

                                this.setState({
                                    resposta_livre: response.data.questionario_Utilizadores2[0].resposta_livre,
                                    fileList
                                });
                            } else
                                this.setState({
                                    resposta_livre: "",
                                    fileList: []
                                });
                        } else {
                            if (response.data.questionario_Utilizadores2[0].id_opcao)
                                this.setState({
                                    resposta: response.data.questionario_Utilizadores2[0].id_opcao
                                });
                            else
                                this.setState({
                                    resposta: ""
                                });
                        }
                    }

                    this.setState({
                        questao: response.data,
                        questaoAtual: this.state.questaoAtual - 1,
                        iconLoadingAnterior: false
                        //defaultCheckbox: checkboxs,
                        //checkbox: checkboxs
                    });
                })
                .catch(() => {});
        } else {
            axios({
                method: "get",
                url: "/api/questionario-satisfacao/carrega-pergunta2",
                params: {
                    cursoid: this.state.cursoid,
                    utilizadorid: localStorage.getItem("codigo_utilizador"),
                    questionarioid: this.state.questionarioid,
                    pergunta: this.state.questaoAtual - 2
                }
            })
                .then(response => {
                    this.setState({
                        resposta: 0,
                        resposta_checkbox: [],
                        resposta_livre: "",
                        fileList: []
                    });

                    if (response.data.questionario_Utilizadores2.length > 0) {
                        if (response.data.tipo_pergunta == "Escolha múltipla") {
                            var resposta_checkbox = [];

                            for (var i = 0; i < response.data.questionario_Utilizadores2.length; i++) {
                                if (response.data.questionario_Utilizadores2[i].id_opcao)
                                    resposta_checkbox.push(response.data.questionario_Utilizadores2[i].id_opcao);
                            }

                            this.setState({
                                resposta_checkbox
                            });
                        } else if (response.data.tipo_pergunta == "Escala 1 a 5" || response.data.tipo_pergunta == "Escala 1 a 10") {
                            if (response.data.questionario_Utilizadores2[0].id_opcao)
                                this.setState({
                                    resposta: response.data.questionario_Utilizadores2[0].id_opcao.toString()
                                });
                            else
                                this.setState({
                                    resposta: ""
                                });
                        } else if (response.data.tipo_pergunta == "Resposta livre") {
                            if (response.data.questionario_Utilizadores2[0].resposta_livre) {
                                var fileList = [];

                                if (response.data.questionario_Utilizadores2[0].ficheiro)
                                    fileList.push({
                                        uid: "-1",
                                        name: response.data.questionario_Utilizadores2[0].ficheiro_nome,
                                        status: "done",
                                        url: response.data.questionario_Utilizadores2[0].ficheiro
                                    });

                                this.setState({
                                    resposta_livre: response.data.questionario_Utilizadores2[0].resposta_livre,
                                    fileList
                                });
                            } else
                                this.setState({
                                    resposta_livre: "",
                                    fileList: []
                                });
                        } else {
                            if (response.data.questionario_Utilizadores2[0].id_opcao)
                                this.setState({
                                    resposta: response.data.questionario_Utilizadores2[0].id_opcao
                                });
                            else
                                this.setState({
                                    resposta: ""
                                });
                        }
                    }

                    this.setState({
                        questao: response.data,
                        questaoAtual: this.state.questaoAtual - 1,
                        iconLoadingAnterior: false
                        //defaultCheckbox: checkboxs,
                        //checkbox: checkboxs
                    });
                })
                .catch(() => {});
        }
    };

    proximo = event => {
        event.preventDefault();

        if (this.state.questionario_tipo != "ALUNO_INSTRUTORES")
            if (this.state.questao.tipo_pergunta == "Escolha múltipla") {
                axios({
                    method: "post",
                    url: "/api/questionario-satisfacao/add-resposta-checkbox",
                    params: {
                        id_questao: this.state.questao.id,
                        id_curso: this.state.cursoid,
                        opcoes: JSON.stringify(this.state.resposta_checkbox)
                    }
                })
                    .then(() => {
                        this.carregaPergunta();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                    });
            } else if (this.state.questao.tipo_pergunta == "Resposta livre") {
                var item = new FormData();
                item.append("id_questao", this.state.questao.id);
                item.append("id_curso", this.state.cursoid);
                item.append("texto", this.state.resposta_livre);
                item.append("ficheiro", this.state.fileList[0]);

                axios({
                    method: "post",
                    url: "/api/questionario-satisfacao/add-resposta-livre",
                    data: item
                })
                    .then(() => {
                        this.carregaPergunta();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                    });
            } else {
                let item = new FormData();
                item.append("id_questao", this.state.questao.id);
                item.append("id_curso", this.state.cursoid);
                if (this.state.resposta) item.append("id_opcao", this.state.resposta);

                axios({
                    method: "post",
                    url: "/api/questionario-satisfacao/add-resposta-radio",
                    data: item
                })
                    .then(() => {
                        this.carregaPergunta();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                    });
            }
        else if (this.state.questao.tipo_pergunta == "Escolha múltipla") {
            axios({
                method: "post",
                url: "/api/questionario-satisfacao/add-resposta-checkbox2",
                params: {
                    id_questao: this.state.questao.id,
                    id_curso: this.state.cursoid,
                    id_aluno: localStorage.getItem("codigo_utilizador"),
                    opcoes: JSON.stringify(this.state.resposta_checkbox)
                }
            })
                .then(() => {
                    this.carregaPergunta();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        } else if (this.state.questao.tipo_pergunta == "Resposta livre") {
            var item = new FormData();
            item.append("id_questao", this.state.questao.id);
            item.append("id_curso", this.state.cursoid);
            item.append("id_aluno", localStorage.getItem("codigo_utilizador"));
            item.append("texto", this.state.resposta_livre);
            item.append("ficheiro", this.state.fileList[0]);

            axios({
                method: "post",
                url: "/api/questionario-satisfacao/add-resposta-livre2",
                data: item
            })
                .then(() => {
                    this.carregaPergunta();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        } else {
            let item = new FormData();
            item.append("id_questao", this.state.questao.id);
            item.append("id_curso", this.state.cursoid);
            item.append("id_aluno", localStorage.getItem("codigo_utilizador"));
            if (this.state.resposta) item.append("id_opcao", this.state.resposta);

            axios({
                method: "post",
                url: "/api/questionario-satisfacao/add-resposta-radio2",
                data: item
            })
                .then(() => {
                    this.carregaPergunta();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        }
    };

    anterior = event => {
        event.preventDefault();

        if (this.state.questionario_tipo != "ALUNO_INSTRUTORES")
            if (this.state.questao.tipo_pergunta == "Escolha múltipla") {
                axios({
                    method: "post",
                    url: "/api/questionario-satisfacao/add-resposta-checkbox",
                    params: {
                        id_questao: this.state.questao.id,
                        id_curso: this.state.cursoid,
                        opcoes: JSON.stringify(this.state.resposta_checkbox)
                    }
                })
                    .then(() => {
                        this.carregaPerguntaAnterior();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                    });
            } else if (this.state.questao.tipo_pergunta == "Resposta livre") {
                var item = new FormData();
                item.append("id_questao", this.state.questao.id);
                item.append("id_curso", this.state.cursoid);
                item.append("texto", this.state.resposta_livre);
                item.append("ficheiro", this.state.fileList[0]);

                axios({
                    method: "post",
                    url: "/api/questionario-satisfacao/add-resposta-livre",
                    data: item
                })
                    .then(() => {
                        this.carregaPerguntaAnterior();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                    });
            } else {
                let item = new FormData();
                item.append("id_questao", this.state.questao.id);
                item.append("id_curso", this.state.cursoid);
                if (this.state.resposta) item.append("id_opcao", this.state.resposta);

                axios({
                    method: "post",
                    url: "/api/questionario-satisfacao/add-resposta-radio",
                    data: item
                })
                    .then(() => {
                        this.carregaPerguntaAnterior();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                    });
            }
        else if (this.state.questao.tipo_pergunta == "Escolha múltipla") {
            axios({
                method: "post",
                url: "/api/questionario-satisfacao/add-resposta-checkbox2",
                params: {
                    id_questao: this.state.questao.id,
                    id_curso: this.state.cursoid,
                    id_aluno: localStorage.getItem("codigo_utilizador"),
                    opcoes: JSON.stringify(this.state.resposta_checkbox)
                }
            })
                .then(() => {
                    this.carregaPerguntaAnterior();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        } else if (this.state.questao.tipo_pergunta == "Resposta livre") {
            var item = new FormData();
            item.append("id_questao", this.state.questao.id);
            item.append("id_curso", this.state.cursoid);
            item.append("id_aluno", localStorage.getItem("codigo_utilizador"));
            item.append("texto", this.state.resposta_livre);
            item.append("ficheiro", this.state.fileList[0]);

            axios({
                method: "post",
                url: "/api/questionario-satisfacao/add-resposta-livre2",
                data: item
            })
                .then(() => {
                    this.carregaPerguntaAnterior();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        } else {
            let item = new FormData();
            item.append("id_questao", this.state.questao.id);
            item.append("id_curso", this.state.cursoid);
            item.append("id_aluno", localStorage.getItem("codigo_utilizador"));
            if (this.state.resposta) item.append("id_opcao", this.state.resposta);

            axios({
                method: "post",
                url: "/api/questionario-satisfacao/add-resposta-radio2",
                data: item
            })
                .then(() => {
                    this.carregaPerguntaAnterior();
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível guardar resposta!");
                });
        }
    };

    concluir = () => {
        if (this.state.questionario_tipo != "ALUNO_INSTRUTORES")
            axios({
                method: "put",
                url: "/api/questionario-satisfacao/concluir",
                params: {
                    cursoid: this.state.cursoid,
                    questionario_tipo: this.state.questionario_tipo
                }
            })
                .then(response => {
                    this.setState({
                        iniciado: false,
                        concluido: true
                    });
                })
                .catch(() => {});
        else
            axios({
                method: "put",
                url: "/api/questionario-satisfacao/concluir2",
                params: {
                    cursoid: this.state.cursoid,
                    questionario_tipo: this.state.questionario_tipo,
                    utilizadorid: localStorage.getItem("codigo_utilizador")
                }
            })
                .then(response => {
                    this.setState({
                        iniciado: false,
                        concluido: true
                    });
                })
                .catch(() => {});
    };

    render() {
        const { Panel } = Collapse;
        const Dragger = Upload.Dragger;

        const {
            nrQuestoes,
            questaoAtual,
            questao,
            redirectLink,
            redirectLinkResolucao,
            totalEmBranco,
            resposta_checkbox,
            resposta,
            resposta_livre,
            fileList,
            //INFO
            nome_curso,
            nome_questionario,
            total_questoes,
            //LOADING
            iconLoadingProximo,
            iconLoadingAnterior,
            //LOADING
            loading
        } = this.state;
        const RadioGroup = Radio.Group;

        const radioStyle = {
            display: "block",
            height: "30px",
            lineHeight: "30px"
        };

        const montar_perguntar = questao => {
            if (questao.tipo_pergunta === "Escolha única") {
                return (
                    <RadioGroup onChange={this.onChangeRadio} value={resposta}>
                        {questao.opcoes2.map((opcao, index) => (
                            <Radio key={"opcao" + index} style={radioStyle} value={opcao.id}>
                                {opcao.texto.replace(/(?:\r\n|\r|\n)/g, "<br>")}
                            </Radio>
                        ))}
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Escolha múltipla") {
                return (
                    <Checkbox.Group style={{ width: "100%" }} onChange={this.onChangeCheck} defaultValue={resposta_checkbox}>
                        {questao.opcoes2.map((opcao, index) => (
                            <Row key={"opcao" + index}>
                                <Checkbox value={opcao.id}>{opcao.texto.replace(/(?:\r\n|\r|\n)/g, "<br>")}</Checkbox>
                            </Row>
                        ))}
                    </Checkbox.Group>
                );
            } else if (questao.tipo_pergunta === "Escala 1 a 5") {
                return (
                    <RadioGroup className="escala" onChange={this.onChangeRadio} value={resposta}>
                        <span className="minimo">1</span>
                        <Radio style={radioStyle} className="escala" value="1"></Radio>
                        <Radio style={radioStyle} className="escala" value="2"></Radio>
                        <Radio style={radioStyle} className="escala" value="3"></Radio>
                        <Radio style={radioStyle} className="escala" value="4"></Radio>
                        <Radio style={radioStyle} className="escala" value="5"></Radio>
                        <span className="maximo">5</span>
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Escala 1 a 10") {
                return (
                    <RadioGroup className="escala" onChange={this.onChangeRadio} value={resposta}>
                        <span className="minimo">1</span>
                        <Radio style={radioStyle} className="escala" value="1"></Radio>
                        <Radio style={radioStyle} className="escala" value="2"></Radio>
                        <Radio style={radioStyle} className="escala" value="3"></Radio>
                        <Radio style={radioStyle} className="escala" value="4"></Radio>
                        <Radio style={radioStyle} className="escala" value="5"></Radio>
                        <Radio style={radioStyle} className="escala" value="6"></Radio>
                        <Radio style={radioStyle} className="escala" value="7"></Radio>
                        <Radio style={radioStyle} className="escala" value="8"></Radio>
                        <Radio style={radioStyle} className="escala" value="9"></Radio>
                        <Radio style={radioStyle} className="escala" value="10"></Radio>
                        <span className="maximo">10</span>
                    </RadioGroup>
                );
            } else if (questao.tipo_pergunta === "Resposta livre") {
                return <TextArea value={resposta_livre} onChange={this.onChangeTextArea} style={{ height: "100%" }} rows={5} />;
            }
        };

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
            <div className="container">
                <div className="bloco-questionario" style={{ background: "transparent", boxShadow: "none" }}>
                    <div className="bloco-info">
                            {this.state.apresentacao && (
                                <div className="bloco-presencial">
                                    <div className="bloco-download-link">
                                        <div className="bloco-info-tarefa">
                                            <span className="icon-tarefa">
                                                <img src={require("../../images/icons/questionario.png")} />
                                            </span>
                                            <span className="titulo-tarefa">{nome_questionario}<br /><span className="numero-questoes">{this.state.questionario_tipo === "ALUNO_INSTRUTORES" && `${localStorage.getItem("nome_utilizador")} -`}{total_questoes} {total_questoes > 1 ? "Questões" : "Questão"}</span></span>
                                        </div>
                                        <Link className="botao-principal" to="#" onClick={e => this.iniciarQuestionario(e)}>
                                            Iniciar
                                            </Link>
                                    </div>
                                </div>
                            )}
                        {this.state.iniciado && (
                                <div className="prova">
                                    <div className="bloco-questao">
                                        <div className="bloco-enunciado">
                                            <span className="num-pergunta">
                                                Pergunta {questaoAtual} - {total_questoes}
                                            </span>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: questao.pergunta ? questao.pergunta.replace(/(?:\r\n|\r|\n)/g, "<br>") : ""
                                                }}
                                            ></p>
                                            <div className="primeiro-bloco-links">
                                                {questao.ficheiro_pergunta && (
                                                    <Collapse>
                                                        <Panel>
                                                            <Document file={questao.ficheiro_pergunta} onLoadSuccess={this.onDocumentLoadSuccess}>
                                                                <Page
                                                                    pageNumber={this.state.pageNumber}
                                                                    scale={this.state.scale}
                                                                    rotate={this.state.rotate}
                                                                />
                                                            </Document>
                                                            <div className="controlos-pdf">
                                                                <button
                                                                    onClick={() =>
                                                                        this.setState(prevState => ({ pageNumber: prevState.pageNumber - 1 }))
                                                                    }
                                                                >
                                                                    <Icon type="caret-left" />
                                                                </button>
                                                                <span>
                                                                    {this.state.pageNumber}/{this.state.numPages}
                                                                </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.setState(prevState => ({ pageNumber: prevState.pageNumber + 1 }))
                                                                    }
                                                                >
                                                                    <Icon type="caret-right" />
                                                                </button>
                                                                <button onClick={this.scaleUp}>
                                                                    <Icon type="zoom-in" />
                                                                </button>
                                                                <button onClick={this.scaleDown}>
                                                                    <Icon type="zoom-out" />
                                                                </button>
                                                                <button onClick={() => this.setState({ rotate: 90 })}>
                                                                    <Icon type="reload" />
                                                                </button>
                                                                <button onClick={() => this.setState({ rotate: 0 })}>
                                                                    <Icon type="reload" style={{ transform: "scaleX(-1) " }} />
                                                                </button>
                                                                <a href={this.state.pdf} className="botao-icon-download" download>
                                                                    <Icon type="download" />
                                                                </a>
                                                            </div>
                                                        </Panel>
                                                    </Collapse>
                                                )}
                                            </div>
                                        </div>
                                        <div className="bloco-resposta">
                                            <h4 className="bloco-titulo">Responder</h4>
                                            <div className="respostas">{montar_perguntar(questao)}</div>
                                            <div className="controlos-resposta">
                                                {questaoAtual > 1 ? (
                                                    <button
                                                        className="botao-secundario"
                                                        disabled={iconLoadingProximo || iconLoadingAnterior}
                                                        onClick={e => this.anterior(e)}
                                                    >
                                                        {iconLoadingAnterior ? <Icon type="loading" /> : null}
                                                Anterior
                                                    </button>
                                                ) : null}
                                                <button
                                                    className="botao-principal"
                                                    disabled={iconLoadingProximo || iconLoadingAnterior}
                                                    onClick={e => this.proximo(e)}
                                                >
                                                    {iconLoadingProximo ? <Icon type="loading" /> : null}
                                            Próxima
                                        </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        )}
                        {this.state.concluido && (
                                <div className="confirmacao" style={{ background: "white", boxShadow: "0 4px 6px #eee", height: "100vh", width: "100%" }}>
                                    <div className="bloco-confirmacao">
                                        <h2>Questionário Concluído</h2>
                                        <div className="controlos-resposta">
                                            <Link to={`/curso/${localStorage.getItem("nome_curso")}/curso-resultados`} className="botao-principal">
                                                Voltar
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ConteudoQuestionario);
