import React, { Component } from "react";
import { Form, Input, Checkbox, Icon, notification } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Header from "../User/Header";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        logoEntidade: "",
        nomeEntidade: "",
        message: message,
        description: description,
        termos: false,
        politica: false
    });
};

class RegistoPasso2 extends Component {
    state = {
        logoEntidade: "",
        nomeEntidade: "",
        conclusao: false,
        iconLoading: false,
        planoid: 0,
        curso_tecnologico: false,
        categorias: [],
        newsletter: false,
        newsletterStepforma: false,
        notificacao: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.carregarEntidade();
    }

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    //CONCLUI O REGISTO
    concluirRegisto = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.termos && this.state.politica) {
                this.setState({ iconLoading: true });
                axios({
                    method: "put",
                    url: "/api/registo/concluir-registo",
                    params: {
                        termos: this.state.termos,
                        politica: this.state.politica,
                        newsletterEntidade: this.state.newsletter,
                        newsletterStepforma: this.state.newsletterStepforma,
                        notificacao: this.state.notificacao,
                        utilizadoid: localStorage.getItem("codigo_utilizador")
                    }
                })
                    .then(() => {
                        this.setState({
                            iconLoading: true,
                            conclusao: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível finalizar o seu cadastro!");
                        this.setState({
                            iconLoading: false
                        });
                    });
                this.setState({ iconLoading: false });
            } else {
                if (!this.state.termos || !this.state.politica)
                    openNotificationWithIcon(
                        "error",
                        "Erro",
                        <span>
                            Para finalizar o seu cadastro tem de aceitar a <strong>Política de Privacidade</strong> e os <strong>Termos de Uso</strong>
                        </span>
                    );
            }
        });
    };

    carregarEntidade = () => {
        axios({
            method: "get",
            url: "/api/registo/carregar-nome-entidade",
            params: {
                utilizadorId: localStorage.getItem("codigo_utilizador")
            }
        })
            .then(response => {
                this.setState({
                    nomeEntidade: response.data
                });
            })
            .catch(error => {

            });
    };

    erro = () => {
        this.setState({
            redirect: true,
            redirectLink: "/pagina-erro"
        });
    };

    render() {
        const { iconLoading, categorias } = this.state;

        if (this.state.conclusao)
            return <Redirect to={`/${this.props.match.params.entidade}/utilizador-registo-conclusao`} />;

        return (
            <>
                <Header titulo="Cadastro" marginTop0={true} />
                <div className="container">
                    <div className="registar registar-passo2">
                        <div className="header">
                            <div className="passos passos-password">
                                <span>Passo 2</span>
                            </div>
                            <div className="descritivo">
                                <span>Opções exclusivas para si, enquanto utilizador da plataforma.</span>
                            </div>
                            <Link to="/" className="btn-close" title="Fechar">
                                <i className="fas fa-times fechar"></i>
                            </Link>
                        </div>
                        <div className="content">
                            <Form className="registar-passo2">
                                <div className="bloco-checkboxs-passo7">
                                    <h2>Configurações</h2>
                                    <Form.Item>
                                        <Checkbox name="termos" onChange={this.handleChangeCheckbox}>
                                            <span className="agreement-msg" style={{ fontSize: "14px" }}>
                                                Eu aceito os <Link to="/termos-uso">Termos de Uso</Link>
                                            </span>
                                        </Checkbox>
                                        <Checkbox name="politica" onChange={this.handleChangeCheckbox}>
                                            <span className="agreement-msg" style={{ fontSize: "14px" }}>
                                                Eu aceito a <Link to="/politica-privacidade">Política de Privacidade</Link>
                                            </span>
                                        </Checkbox>
                                        <Checkbox name="newsletter" onChange={this.handleChangeCheckbox}>
                                            <span className="agreement-msg" style={{ fontSize: "14px" }}>
                                                Eu aceito a subscrição da newsletter {this.state.nomeEntidade}
                                            </span>
                                        </Checkbox>
                                        <Checkbox name="newsletterStepforma" onChange={this.handleChangeCheckbox}>
                                            <span className="agreement-msg" style={{ fontSize: "14px" }}>
                                                Eu aceito a subscrição da newsletter StepForma
                                            </span>
                                        </Checkbox>
                                        <Checkbox name="notificacao" onChange={this.handleChangeCheckbox}>
                                            <span className="agreement-msg" style={{ fontSize: "14px" }}>
                                                Eu aceito notificações por e-mail
                                            </span>
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                                <Form.Item className="bloco-controlos" style={{ marginTop: "30px" }}>
                                    <div className="controlos">
                                        <Link to="#" onClick={this.concluirRegisto} disabled={iconLoading} className="botao-principal">
                                            {iconLoading ? <Icon type="loading" /> : null}
                                            Concluir
                                        </Link>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const FormRegistoPasso2 = Form.create({ name: "registo-passo2" })(RegistoPasso2);

export default FormRegistoPasso2;
