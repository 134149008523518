import React from "react";
import { Icon } from "antd";

const Info = ({ titulo, texto}) => {
        return (
            <div className="bloco-alerta">
                <span className="icon-alerta">
                    <Icon type="info-circle" />
                </span>
                <span className="mensagem-alerta">
                    <span className="mensagem-alerta-titulo">{titulo}</span>
                    <span className="mensagem-alerta-texto">{texto}</span>
                </span>
            </div>
        );
}

export default Info;
