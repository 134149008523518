import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Icon, Modal, notification } from "antd";
import DrawerEditar from "./DrawerEditar";
import moment from "moment";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Detalhe = ({ voltar, videoConfId, utilizadorId, sessaoInfo, info, registos, atualizarData, atualizarRegistosUtilizador, dataSessao }) => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [data, setData] = useState([]);
    const [registo, setRegisto] = useState({});
    const [estadoForm, setEstadoForm] = useState("");
    const [registosAlterados, setRegistosAlterados] = useState(false);
    const confirm = Modal.confirm;

    const columns = [
        {
            title: "Data/Hora",
            dataIndex: "data"
        },
        {
            title: "Tipo",
            dataIndex: "tipo"
        },
        {
            title: "",
            dataIndex: "atividade"
        },
        {
            title: "",
            dataIndex: "opcoes",
            width: 10
        }
    ];

    const montarTipoAtividade = (atividade) => {
        if (atividade === "ADD")
            return "";
        else
            return "";
    }

    const carregarLista = () => {
        const _registos = registos.filter(x => x.valido)
        const data = _registos.map(item => {
            return {
                data: moment(item.data).format("DD/MM/YYYY HH:mm:ss"),
                tipo: item.tipo,
                atividade: item.atividade ? montarTipoAtividade(item.atividade) : "",
                opcoes: <div className="relatorio-participacoes-opcoes">
                    <Link className="botao-icon-editar" to="#" title="Editar" onClick={() => editar(item)}>
                        <Icon type="edit" />
                    </Link>
                    <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={() => invalidarRegisto(item.id)}>
                        <Icon type="delete" />
                    </Link>
                </div>
            }
        });

        setData(data);
    }

    const invalidarRegisto = (id) => {
        const _registos = registos;
        const registoIndex = _registos.findIndex(x => x.id === id)

        if (registoIndex === 0 || registoIndex === _registos.length - 1)
            confirm({
                title: registoIndex === 0 ? "Se eliminar o primeiro registo retira o formando da sessão. Confirma?" : "Se eliminar o último registo retira o formando da sessão. Confirma?",
                okText: "Confirmar",
                okType: "Cancelar",
                onOk: () => {
                    const registosAtualizados = _registos.map(registo => {
                        registo.valido = false;
                        return registo;
                    });
                    //atualizarData(registosAtualizados);
                    excluir(id, registosAtualizados, true)
                }
            });
        else {
            _registos[registoIndex].valido = false;
            //atualizarData(_registos)
            excluir(id, _registos, false)
        }
    }

    const adicionar = () => {
        setEstadoForm("ADD");
        setRegisto({});
        setDrawerVisible(true);
    }

    const editar = (item) => {
        setEstadoForm("EDIT");
        setRegisto(item);
        setDrawerVisible(true);
    }

    const atualizar = () => {
        atualizarRegistosUtilizador(utilizadorId);
        setRegistosAlterados(false);
    }

    const excluir = (id, registos, excluirFormando) => {
        axios({
            method: "delete",
            url: "/api/gerir-relatorio-participacoes/delete-participacao",
            params: {
                id,
                excluirFormando
            }
        })
            .then(() => {
                atualizarData(registos)
                setRegistosAlterados(true);
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível excluir registo!")
            });
    }

    const adicionarRegisto = (registo) => {
        atualizarData([...registos, registo])
        setDrawerVisible(false);
        setRegistosAlterados(true);
    }

    const atualizarRegisto = (registo) => {
        const _registos = registos.filter(x => x.id !== registo.id);
        atualizarData([..._registos, registo])
        setDrawerVisible(false);
        setRegistosAlterados(true);
    }

    useEffect(() => {
        carregarLista();
    }, [registos]);

    return (
        <>
            <div className="container container-body">
                <div className="curso-mensagens curso-videoconferencias">
                    <div className="controlos controlos-fundo controlos-direita">
                        <div className="controlos-opcoes">
                            <button className="botao-principal" onClick={() => voltar(registosAlterados)}>
                                Voltar
                            </button>
                            {registosAlterados &&
                                <button className="botao-principal" onClick={atualizar}>
                                    Atualizar
                                </button>}
                            <button className="botao-principal" onClick={adicionar}>
                                Adicionar
                            </button>
                        </div>
                    </div>
                    <div className="bloco-relatorio-participacoes">
                        <div className="relatorio-header">
                            <h1 className="relatorio-titulo">
                                {sessaoInfo.titulo} - {sessaoInfo.dataHora}
                            </h1>
                            <span>{info.role}: <strong>{info.nome} ({info.numero})</strong></span>
                        </div>
                        <div className="relatorio-body">
                            <div className="relatorio-lista-total">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    locale={{ emptyText: "Não existem registos!" }}
                                />
                            </div>
                            <div className="relatorio-lista-participantes"></div>
                        </div>
                    </div>
                </div>
            </div>
            <DrawerEditar
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                videoConfId={videoConfId}
                utilizadorId={utilizadorId}
                estadoForm={estadoForm}
                registo={registo}
                adicionarRegisto={adicionarRegisto}
                atualizarRegisto={atualizarRegisto}
                dataSessao={dataSessao}
            />
        </>
    );
}

export default Detalhe;