import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Form, Input, InputNumber, Icon, notification, Modal, Tooltip, Upload, Radio, Progress } from "antd";
import axios from "axios";
import noimage from "../../../images/noimage.png";
import { Upload as UploadVimeo } from "tus-js-client";
import { GlobalContext } from "../../../GlobalState";
import DrawerHTML from "./DrawerHTML"
import { CirclePicker, CirclePickerProps } from 'react-color';

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const accessToken = "c25f3d22c7bcd79d2044ad6eb33ea514"

const headerPost = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    Authorization: `bearer ${accessToken}`,
    "Content-Type": "application/json"
}

const headerPut = {
    Accept: "application/vnd.vimeo.*+json;version=3.4"
}

class DrawerInformacao extends Component {
    static contextType = GlobalContext
    state = {
        titulo: "",
        imagem: "",
        preview: null,
        videoId: "",
        video: [],
        posicao: 1,
        texto: "",
        link: "",
        ordem: 1,
        corFundo: "",
        corFonte: "",
        //UPLOAD VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //PROGRESS
        upload_progress: 0,
        //LOADING
        iconLoading: false,
        //DRAWER HTML
        drawerHTMLVisible: false
    };

    UNSAFE_componentWillMount() {
        
    }

    handlerChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handlerChangeOrdem = ordem => {
        this.setState({
            ordem
        });
    };

    handlerChangeRadio = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleChangeEditor = texto => {
        this.setState({
            html_status: "",
            html_help: "",
            texto
        })
    }

    handleChangeColorPickerFundo = (color, event) => {
        this.setState({
            corFundo: color.hex
        })
    }

    handleChangeColorPickerFonte = (color, event) => {
        this.setState({
            corFonte: color.hex
        })
    }

    carregar = (infoId) => {
        axios({
            method: "get",
            url: "/api/empresa/carregar-pagina-artigo",
            params: {
                infoId
            }
        })
            .then(response => {
                var video = [];

                if (response.data.video)
                    video.push({
                        uid: "-1",
                        name: response.data.video_nome,
                        status: "done"
                    });

                this.setState({
                    titulo: response.data.titulo,
                    imagem: response.data.imagem,
                    preview: response.data.imagem,
                    video,
                    videoId: response.data.video,
                    texto: response.data.texto,
                    ordem: response.data.ordem,
                    posicao: response.data.posicao,
                    corFundo: response.data.corFundo,
                    corFonte: response.data.corFonte,
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar o artigo!");

                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    criarInfo = () => {
        if (this.state.video.length) {
            this.criarVideoVimeo();
        }
        else {
            this.criar();
        }
    }

    criarVideoVimeo = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                const file = this.state.video[0]
                const fileName = file.name
                const fileSize = file.size.toString()

                axios({
                    method: "post",
                    url: `https://api.vimeo.com/me/videos`,
                    transformRequest: [function (data, headers) {
                        delete headers.common['apikey'];

                        return JSON.stringify(data);
                    }],
                    headers: headerPost,
                    data: {
                        upload: {
                            status: "in_progress",
                            approach: "tus",
                            size: fileSize
                        },
                        name: this.state.titulo.trim(),
                        privacy: { view: "disable" }
                    }
                }).then(response => {
                    this.uploadVimeo(file, response)
                })
            }
        })
    }

    uploadVimeo = (file, response) => {
        const upload = new UploadVimeo(file, {
            endPoint: "https://api.vimeo.com/me/videos",
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            headers: {},
            onError: error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível criar o artigo!")
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1)

                this.setState({
                    upload_progress: percentage,
                })
            },
            onSuccess: () => {
                var videoId = response.data.link.split("/").pop()
                this.moverParaPasta(videoId)
                this.adicionarDominioEPreset(videoId)
                this.criar(videoId, file.name)
                //this.setState({
                //    imagem: "",
                //    preview: null,
                //})
            }
        })

        upload.start()
    }

    moverParaPasta = videoId => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/me/projects/${this.context.albumId_entidade}/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    adicionarDominioEPreset = videoId => {
        axios({
            method: "get",
            url: "/api/curso/carregar-vimeo-configs",
        }).then(response => {
            this.adicionarPreset(videoId, response.data.presetId);
            this.adicionarDominio(videoId, response.data.dominio);
        });
    };

    adicionarPreset = (videoId, presetId) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/presets/${presetId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    adicionarDominio = (videoId, dominio) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/privacy/domains/${dominio}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    criar = (videoId, videoNome) => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.texto) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData()
                item.append("id_pagina", this.props.paginaId);
                item.append("titulo", this.state.titulo);
                item.append("texto", this.state.texto);
                item.append("imagem", this.state.imagem);
                if (videoId) {
                    item.append("video", videoId);
                    item.append("video_nome", videoNome);
                }
                item.append("posicao", this.state.posicao);
                item.append("ordem", this.state.ordem);
                item.append("corFundo", this.state.corFundo);
                item.append("corFonte", this.state.corFonte);

                axios({
                    method: "post",
                    url: "/api/empresa/adicionar-pagina-artigo",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionado novo artigo");
                        this.setState({
                            iconLoading: false
                        }, () => this.props.atualizarRegisto());
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar o artigo!");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
            else {
                if (!this.state.texto)
                    this.setState({
                        html_status: "error",
                        html_help: "Campo obrigatório"
                    })
            }
        })
    }

    alterarInfo = () => {
        if (this.state.video.length) {
            this.alterarVideoVimeo();
        }
        else {
            this.alterar();
        }
    }

    alterarVideoVimeo = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                if (this.state.video[0].status == "done") {
                    this.alterar(this.state.videoId, this.state.video[0].name)
                } else {
                    const file = this.state.video[0]
                    const fileName = file.name
                    const fileSize = file.size.toString()

                    axios({
                        method: "post",
                        url: `https://api.vimeo.com/me/videos`,
                        transformRequest: [function (data, headers) {
                            delete headers.common['apikey'];

                            return JSON.stringify(data);
                        }],
                        headers: headerPost,
                        data: {
                            upload: {
                                status: "in_progress",
                                approach: "tus",
                                size: fileSize
                            },
                            name: this.props.tituloAula.trim(),
                            privacy: { view: "disable" }
                        }
                    }).then(response => {
                        this.alterarUploadVimeo(file, response)
                    })
                }
            }
        })
    }

    alterarUploadVimeo = (file, response) => {
        const upload = new UploadVimeo(file, {
            endPoint: `https://api.vimeo.com/videos/${this.props.recurso}/versions`,
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            headers: {},
            onError: error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1)

                this.setState({
                    upload_progress: percentage
                })
            },
            onSuccess: () => {
                this.removerVideoVimeoAnterior(this.state.videoId)
                var videoId = response.data.link.split("/").pop()
                this.moverParaPasta(videoId, file.name)
                this.adicionarDominioEPreset(videoId)
                this.alterar(videoId, file.name)
                //this.setState({
                //    imagem: "",
                //    preview: null,
                //})
            }
        })

        upload.start()
    }

    removerVideoVimeoAnterior = videoId => {
        axios({
            method: "delete",
            url: `https://api.vimeo.com/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    removerVideoVimeoErro = videoId => {
        axios({
            method: "delete",
            url: `https://api.vimeo.com/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    alterar = (videoId, videoNome) => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData()
                item.append("id", this.props.infoId);
                item.append("id_pagina", this.props.paginaId);
                item.append("titulo", this.state.titulo);
                item.append("texto", this.state.texto);
                item.append("imagem", this.state.imagem);
                if (videoId) {
                    item.append("video", videoId);
                    item.append("video_nome", videoNome);
                }
                item.append("posicao", this.state.posicao);
                item.append("ordem", this.state.ordem);
                item.append("corFundo", this.state.corFundo);
                item.append("corFonte", this.state.corFonte);

                axios({
                    method: "post",
                    url: "/api/empresa/alterar-pagina-artigo",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Artigo alterado");
                        this.setState({
                            iconLoading: false
                        }, () => this.props.atualizarRegisto());
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível alterar o artigo!");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        })
    }

    ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    uploadFoto = event => {
        if (event.target.files[0].size < 1024 * 1024)
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                this.setState({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    imagem: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0]),
                    //videoId: "",
                    //video: []
                });
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 1 MB para a imagem.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //REMOVE A IMAGEM
    removerImagem = () => {
        document.getElementById("input-foto").value = "";
        this.setState({
            imagem: "",
            preview: ""
        });
    };

    validarFormatos = (formato, formatos_aceites) => {
        var valido = false

        for (var i = 0; i < formatos_aceites.length; i++) {
            if (formato == formatos_aceites[i]) valido = true
        }

        return valido
    }

    //MP4, MOV, WMV, AVI e FLV - Video
    validar = (rule, value, callback) => {
        if (value.file)
            if (!this.validarFormatos(value.file.name.split(".").pop().toLowerCase(), ["mp4", "mov", "wmv", "avi", "flv"]) || value.file.size > 5368709120)
                callback("Campo obrigatório")

        callback()
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.infoId) {
                this.carregar(this.props.infoId);
            }
            else {
                this.setState({
                    titulo: "",
                    imagem: "",
                    preview: null,
                    videoId: "",
                    video: [],
                    posicao: 1,
                    texto: "",
                    link: "",
                    ordem: this.props.ordem,
                    corFundo: undefined,
                    corFonte: undefined,
                    //UPLOAD VALIDATE
                    totalBytes: 0,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    //PROGRESS
                    upload_progress: 0,
                    //LOADING
                    iconLoading: false,
                    //DRAWER HTML
                    drawerHTMLVisible: false
                })
            }
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const Dragger = Upload.Dragger;
        const {
            titulo,
            imagem,
            preview,
            videoId,
            video,
            posicao,
            texto,
            link,
            ordem,
            corFundo,
            corFonte,
            //UPLOAD VALIDATE
            totalBytes,
            formatoValido,
            validateStatus,
            help,
            //PROGRESS
            upload_progress,
            //LOADING
            iconLoading,
            //DRAWER HTML
            drawerHTMLVisible
        } = this.state;

        const {
            infoId,
            visible
        } = this.props;

        //validarFormatos MP4, MOV, WMV, AVI e FLV
        const propsVideo = {
            accept: ".mp4, .mov, .wmv, .avi, .flv",
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("video")
                this.setState({
                    video: []
                })
            },
            defaultFileList: this.state.video,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["mp4", "mov", "wmv", "avi", "flv"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 5368709120) {
                    openNotificationWithIcon("error", "Erro", "Limite de 5 GB por upload")
                    return false;
                }

                this.setState(state => ({
                    video: [file]
                }))

                return false
            },
            video
        }

        return (
            <Drawer
                className="drawer-pagina-informacoes"
                title={infoId ? "Alterar" : "Adicionar"}
                width={720}
                onClose={this.props.onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Titulo">
                                {getFieldDecorator("titulo", {
                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                    initialValue: titulo
                                })(<Input name="titulo" onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Imagem" validateStatus={validateStatus} help={help}>
                                <div className="bloco-img">
                                    <div className="container-img">
                                        <img src={preview ? preview : noimage} alt="Foto de Utilizador" />
                                    </div>
                                    <input
                                        type="file"
                                        id="input-foto"
                                        accept="image/*"
                                        onChange={this.uploadFoto}
                                        style={{ display: "none" }}
                                    />
                                    {!preview ? (
                                        <>
                                            <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto} disabled={this.state.video.length}>
                                                <span>Adicionar</span>
                                            </Link>{" "}
                                            <Tooltip
                                                className="info-icon"
                                                title={
                                                    <div>
                                                        <p>Tamanho máximo: 100KB</p>
                                                        <p>Tamanho recomendado: 200x200px</p>
                                                        <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                    </div>
                                                }
                                            >
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </>
                                    ) : (
                                            <>
                                                <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                                    <span>Alterar</span>
                                                </Link>
                                                <Tooltip
                                                    className="info-icon"
                                                    title={
                                                        <div>
                                                            <p>Tamanho máximo: 100KB</p>
                                                            <p>Tamanho recomendado: 200x200px</p>
                                                            <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                        </div>
                                                    }
                                                >
                                                    <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                                </Tooltip>
                                                <Link to="#" className="link-principal-border" onClick={this.removerImagem}>
                                                    <span>Remover</span>
                                                </Link>
                                            </>
                                        )}
                                </div>
                            </Form.Item>
                            <Form.Item label="Video (MP4, MOV, WMV, AVI, FLV)">
                                {/*{getFieldDecorator("video", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        },
                                        {
                                            validator: this.validar
                                        }
                                    ],
                                    initialValue: this.state.video
                                })(*/}
                                <Dragger {...propsVideo} fileList={this.state.video} disabled={this.state.imagem}>
                                        <p className="ant-upload-drag-icon">
                                            <i className="fas fa-upload" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Adicionar anexo{" "}
                                            <Tooltip title="Formatos válidos: .mp4, .mov, .wmv, .avi, .flv">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </p>
                                        <p className="ant-upload-hint">Limite de 5GB por upload</p>
                                    </Dragger>
                                {/*)}*/}
                            </Form.Item>
                            <Form.Item label="Posição do Video/Imagem">
                                <Radio.Group name="posicao" onChange={this.handlerChangeRadio} value={posicao}>
                                    <Radio value={1}>
                                        Esquerda
                                    </Radio>
                                    <Radio value={2}>
                                        Direita
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="Texto" validateStatus={this.state.html_status} help={this.state.html_help}>
                                {texto ? (
                                    <Link
                                        to="#"
                                        className="link-principal"
                                        style={{ paddingLeft: 0 }}
                                        onClick={() => this.setState({ drawerHTMLVisible: true })}
                                    >
                                        Editar
                                    </Link>
                                ) : (
                                        <Link
                                            to="#"
                                            className="link-principal"
                                            style={{ paddingLeft: 0 }}
                                            onClick={() => this.setState({ drawerHTMLVisible: true })}
                                        >
                                            Adicionar
                                        </Link>
                                    )}
                            </Form.Item>
                            <Form.Item label="Link">
                                <Input name="link" value={link} onChange={this.handlerChange} />
                            </Form.Item>
                            <Form.Item label="Ordem">
                                {getFieldDecorator("ordem", {
                                    rules: [{
                                        required: true,
                                        message: "Campo obrigatório"
                                    }],
                                    initialValue: ordem
                                })(<InputNumber className="input-25" name="ordem" min={1} onChange={this.handlerChangeOrdem} />)}
                            </Form.Item>
                            <Form.Item label="Cor de fundo">
                                {getFieldDecorator("corFundo", {
                                    rules: [{
                                        required: true,
                                        message: "Campo obrigatório"
                                    }],
                                    initialValue: corFundo
                                })(
                                    <CirclePicker
                                        color={corFundo}
                                        colors={['#FFFFFF', '#29313E', '#696969', '#778899', '#C0C0C0', '#3A75AF', '#6495ED', '#87CEFA', '#5F9EA0', '#D0E5DE', '#2F4F4F', '#006400', '#3CB371', '#9FCD46', '#8B4513', '#A0522D', '#CD853F', '#800000', '#FF6347', '#D2691E', '#FFD700', '#8A2BE2', '#EE82EE', '#FAEBD7']}
                                        onChange={this.handleChangeColorPickerFundo}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Cor de fonte">
                                {getFieldDecorator("corFonte", {
                                    rules: [{
                                        required: true,
                                        message: "Campo obrigatório"
                                    }],
                                    initialValue: corFonte
                                })(
                                    <CirclePicker
                                        color={corFonte}
                                        colors={['#FFFFFF', '#29313E', '#696969', '#778899', '#C0C0C0', '#3A75AF', '#6495ED', '#87CEFA', '#5F9EA0', '#D0E5DE', '#2F4F4F', '#006400', '#3CB371', '#9FCD46', '#8B4513', '#A0522D', '#CD853F', '#800000', '#FF6347', '#D2691E', '#FFD700', '#8A2BE2', '#EE82EE', '#FAEBD7']}
                                        onChange={this.handleChangeColorPickerFonte}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <Link
                        to="#"
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                                </Link>
                    {infoId ? (
                        <button className="botao-principal" disabled={iconLoading} onClick={this.alterarInfo}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                        </button>
                    )
                        :
                        (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.criarInfo}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                            </button>
                        )}
                </div>
                <DrawerHTML
                    texto={texto}
                    visible={drawerHTMLVisible}
                    onClose={() => this.setState({ drawerHTMLVisible: false })}
                    handleChangeEditor={this.handleChangeEditor}
                />
                <Modal
                    visible={iconLoading && video.length}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Progress type="circle" percent={Number(upload_progress)} />
                        </p>
                        <p className="texto">A enviar...</p>
                    </div>
                </Modal>
            </Drawer>
        );
    }
}
const FormDrawerInformacao = Form.create({ name: "form-drawer-informacao" })(DrawerInformacao);

export default FormDrawerInformacao;
