import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, Checkbox, Badge } from "antd";
import BotaoProcurar from "../Geral/_Aux/BotaoProcurar";
import { GlobalContext } from "../GlobalState";

class Opcoes extends Component {
    static contextType = GlobalContext;

    emitEmpty = () => {
        this.setState({ pesquisa: "" });
        this.props.resetCaixaProcura();
    };

    handleChange = e => {
        this.setState({ pesquisa: e.target.value });
        if (e.target.value.length == 0) this.props.resetCaixaProcura();
    };

    render() {
        return (
            <>
                <div className="controlos controlos-fundo controlos-com-blocos controlos-com-blocos-desktop">
                    <div className="bloco-esquerda">
                        <div className="controlos-opcoes">
                            <button className="botao-principal" onClick={this.props.adicionar}><Icon type="plus" /></button>
                        </div>
                        <div className="controlos-opcoes controlo-search">
                            <BotaoProcurar
                                tem_placeholder
                                placeholder="Procurar Grupo"
                                HandleKeyPress={this.props.HandleKeyPress}
                                resetCaixaProcura={this.props.resetCaixaProcura}
                            />
                        </div>
                        {(this.context.responsaveis_entidade && this.context.role !== "UTILIZADOR") &&
                            <div className="controlos-opcoes controlo-checkbox">
                                <Checkbox name="verConversasResponsaveis" value={this.props.verConversasResponsaveis} onChange={this.props.handlerChangeCheckbox}>
                                    Apenas conversas com Responsáveis
                        </Checkbox>
                                <Badge count={this.props.totalPorLerResponsaveis} style={{ backgroundColor: "#f79071" }} />
                            </div>}
                    </div>
                    <div className="bloco-direita">
                        <Link to="#" className="opcao-atualizar" onClick={this.props.recarregar}>
                            <Icon type="reload" spin={this.props.iconLoadingRecarregar} />
                        </Link>
                    </div>
                </div>
                <div className="controlos controlos-fundo controlos-com-blocos controlos-com-blocos-mobile">
                    <div className="controlos-opcoes controlo-search">
                        <BotaoProcurar
                            tem_placeholder
                            placeholder="Procurar Grupo"
                            HandleKeyPress={this.props.HandleKeyPress}
                            resetCaixaProcura={this.props.resetCaixaProcura}
                        />
                    </div>
                    {(this.context.responsaveis_entidade && this.context.role !== "UTILIZADOR") &&
                        <div className="controlos-opcoes controlo-checkbox">
                            <Checkbox name="verConversasResponsaveis" value={this.props.verConversasResponsaveis} onChange={this.props.handlerChangeCheckbox}>
                                Apenas conversas com Responsáveis
                        </Checkbox>
                            <Badge count={this.props.totalPorLerResponsaveis} style={{ backgroundColor: "#f79071" }} />
                        </div>}
                    <div className="bloco-esquerda bloco-direita">
                        <div className="controlos-opcoes">
                            <button className="botao-principal" onClick={this.props.adicionar}><Icon type="plus" /></button>
                            <button className="botao-secundario opcao-atualizar" onClick={this.props.recarregar}>
                                <Icon type="reload" spin={this.props.iconLoadingRecarregar} />
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Opcoes;
