import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Upload, notification, Icon, Tooltip, Input, Drawer } from "antd";
import { GeralContext } from "../ContextProvider";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerNovaAnotacao extends Component {
    static contextType = GeralContext;
    state = {
        iconLoading: false,
        fileList: [],
        descricao: "",
        status: "",
        helper: "",
        validateStatus: "",
        help: "",
    };

    componentDidUpdate() {
        if (this.props.update) {
            this.setState({
                fileList: this.props.fileList
            });
            this.props.stopUpdate();
        }
        if (this.props.novo) {
            this.props.form.resetFields();
            this.setState({
                fileList: []
            });
            this.props.stopUpdate();
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    adicionarAnotacao = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.totalBytes <= 26214400 && this.props.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_curso", this.props.cursoid);
                item.append("texto", this.state.descricao);

                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });

                axios({
                    method: "post",
                    url: "/api/anotacoes/add-anotacao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Nova anotação criada!");
                        this.context.atualizarAnotacoes(this.context.total_anotacoes + 1);
                        this.props.onClose();
                        this.props.CarregarAnotacoesCurso(this.props.cursoid);

                        this.setState({
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        this.setState({
                            iconLoading: false
                        });
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        })
    };

    alterarAnotacao = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.totalBytes <= 26214400 && this.props.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.anotacaoId);
                item.append("id_curso", this.props.cursoid);
                item.append("texto", this.state.descricao);
                if (this.state.fileList.length > 0)
                    item.append("anexo", this.props.anexo);

                this.state.fileList.forEach(file => {
                    item.append("anexo[]", file);
                });

                axios({
                    method: "post",
                    url: "/api/anotacoes/alterar-anotacao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Anotação alterada!");
                        this.props.onClose();
                        this.props.CarregarAnotacoesCurso(this.props.cursoid);

                        this.setState({
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        this.setState({
                            iconLoading: false
                        });
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        })
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormato = (formato, formatos_aceites) => {
        var valido = false;

        for (var i = 0; i < formatos_aceites.length; i++) {
            if (formato == formatos_aceites[i]) valido = true;
        }

        return valido;
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            this.setState({
                iconLoading: false,
                fileList: this.props.fileList,
                descricao: this.props.descricao,
                status: "",
                helper: "",
                validateStatus: "",
                help: "",
            })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const Dragger = Upload.Dragger;
        const {
            descricao,
            fileList,
            status,
            helper,
            validateStatus,
            help,
            iconLoading,
        } = this.state;

        const props = {
            accept: ".zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg",
            multiple: true,
            onChange: info => {
                var tamanhoTotal = 0;
                var ficheirosValidos = true;
                info.fileList.forEach(file => {
                    tamanhoTotal += file.size;

                    if (
                        !this.validarFormato(file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"])
                    ) {
                        ficheirosValidos = false;
                    }
                });

                if (tamanhoTotal > 26214400)
                    this.setState({
                        totalBytes: tamanhoTotal,
                        validateStatus: "error",
                        help: "Limite de 25 MB por upload."
                    });
                else {
                    if (!ficheirosValidos)
                        this.setState({
                            formatoValido: false,
                            validateStatus: "error",
                            help: "Ficheiro(s) com formato inválido"
                        });
                }
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    var tamanhoTotal = 0;
                    var ficheirosValidos = true;
                    newFileList.forEach(file => {
                        tamanhoTotal += file.size;

                        if (
                            !this.validarFormato(file.name.split(".").pop().toLowerCase(), ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"])
                        ) {
                            ficheirosValidos = false;
                        }
                    });

                    if (tamanhoTotal > 26214400 || !ficheirosValidos)
                        return {
                            totalBytes: tamanhoTotal,
                            fileList: newFileList
                        };
                    else
                        return {
                            totalBytes: tamanhoTotal,
                            formatoValido: true,
                            validateStatus: "",
                            help: "",
                            fileList: newFileList
                        };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            fileList
        };

        return (
            <>
                <Drawer
                    className="drawer-add-cursos"
                    title={this.props.anotacaoId > 0 ? "Editar" : "Adicionar"}
                    width={720}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-nova-pergunta">
                            <Form.Item label="Anotação">
                                {getFieldDecorator("descricao", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: this.state.descricao
                                })(<TextArea name="descricao" onChange={this.handleChange} style={{ height: "100%" }} rows={10} />)}
                            </Form.Item>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <div className="anexos">
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <i className="fas fa-upload" />
                                        </p>
                                        <p className="ant-upload-text">
                                            Adicionar anexos{" "}
                                            <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </p>
                                        <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                    </Dragger>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                    </button>
                        <button
                            className="botao-principal"
                            onClick={e => {
                                if (this.props.anotacaoId > 0) this.alterarAnotacao(e);
                                else this.adicionarAnotacao(e);
                            }}
                            disabled={iconLoading}
                        >
                            {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormDrawerNovaAnotacao = Form.create({ name: "drawer-nova-anotacao" })(DrawerNovaAnotacao);

export default FormDrawerNovaAnotacao;
