import React, { Component } from "react";
import { Input, Icon, Dropdown, Skeleton, notification, Menu, Modal } from 'antd';
import { Link } from "react-router-dom";
import BotaoProcurar from "../Geral/_Aux/BotaoProcurar";
import Picker from 'emoji-picker-react';
import noimage from "../images/noimage.png";
import esquilo from "../images/Esquilo_3.jpg";
import InfiniteScroll from 'react-infinite-scroller';
import axios from "axios";
import moment from "moment";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

let dataAnterior = "";

class Conversa extends Component {
    state = {
        texto: "",
        //LOADING
        loadingAnexo: false
    }

    componentDidUpdate() {
        dataAnterior = "";
    }

    handlerChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    enviarMensagem = event => {
        event.preventDefault();

        if (!this.state.texto)
            return false

        var item = new FormData();
        item.append("id_grupo", this.props.grupo.id);
        item.append("texto", this.state.texto);

        axios({
            method: "post",
            url: "/api/chat/enviar-mensagem",
            data: item
        })
            .then(response => {
                var mensagem = {
                    texto: this.state.texto,
                    anexo: "",
                    dt_criado: moment(),
                    utilizador: {
                        nome: ""
                    },
                    proprio: true
                }

                this.props.addMensagem(mensagem);

                this.setState({
                    texto: ""
                })

                var objDiv = document.getElementsByClassName("lista-mensagens")[0];
                objDiv.scrollTop = objDiv.scrollHeight;
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível enviar mensagem!");
            });
    }

    enviarAnexo = anexo => {
        this.setState({
            loadingAnexo: true
        })

        var item = new FormData();
        item.append("id_grupo", this.props.grupo.id);
        item.append("anexo", anexo);

        axios({
            method: "post",
            url: "/api/chat/enviar-anexo",
            data: item
        })
            .then(response => {
                var mensagem = {
                    texto: "",
                    anexo: response.data.anexo,
                    anexo_nome: response.data.nome,
                    dt_criado: moment(),
                    utilizador: {
                        nome: ""
                    },
                    proprio: true
                }

                this.props.addMensagem(mensagem);

                this.setState({
                    texto: "",
                    loadingAnexo: false
                })

                var objDiv = document.getElementsByClassName("lista-mensagens")[0];
                objDiv.scrollTop = objDiv.scrollHeight;
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível enviar mensagem!");
            });
    }

    sairGrupo = grupoId => {
        confirm({
            title: "Pretende sair do grupo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/chat/sair-grupo",
                    params: {
                        grupoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Saiu do grupo!");
                        this.props.atualizarListaGrupos();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível sair do grupo!");
                    });
            }
        });
    }

    validarData = data => {
        var diff = moment().diff(data, 'weeks')
        var _data = moment(data).format("DD/MM/YYYY")

        if (_data === dataAnterior)
            return false

        dataAnterior = _data

        if (diff > 0)
            return <div className="bloco-separador"><span className="separador">{moment(data).format("DD/MM/YYYY")}</span></div>

        diff = moment().isSame(data, 'days')
        if (diff)
            return <div className="bloco-separador"><span className="separador">Hoje</span></div>
        else {
            var _data = moment(data).startOf('day')
            diff = moment().startOf('day').diff(_data, 'days')
            if (diff === 1)
                return <div className="bloco-separador"><span className="separador">Ontem</span></div>
            else
                return <div className="bloco-separador"><span className="separador">{moment(data).format('dddd')}</span></div>
        }
    }

    onEmojiClick = (event, emojiObject) => {
        this.setState({
            texto: this.state.texto + emojiObject.emoji
        })
    }

    ativarUploader = () => {
        document.getElementById("upload-anexo").click();
    };

    //ATUALIZA A FOTO
    uploadAnexo = event => {
        if (event.target.files[0].size < 1024 * 1024)
            if (this.validarFormato(event.target.files[0].name.split(".").pop().toLowerCase())) {
                this.enviarAnexo(event.target.files[0])
            } else {
                document.getElementById("upload-anexo").value = "";
                openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
            }
        else {
            document.getElementById("upload-anexo").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 1 MB por ficheiro.");
        }
    };

    validarFormato = formato => {
        var formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    downloadFicheiro = (mensagemId) => {
        axios({
            method: "post",
            url: "/api/chat/download-ficheiro",
            params: {
                mensagemId
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    render() {
        const { TextArea } = Input;
        const {
            texto,
            //LOADING
            loadingAnexo
        } = this.state;

        const menu = (
            <Picker
                onEmojiClick={this.onEmojiClick}
                groupNames={{
                    smileys_people: 'Smileys e pessoas',
                    animals_nature: 'Animais e natureza',
                    food_drink: 'Comida e bebida',
                    travel_places: 'Viagens e locais',
                    activities: 'Atividades',
                    objects: 'Objetos',
                    symbols: 'Símbolos',
                    flags: 'Bandeiras',
                    recently_used: 'Utilizados recentemente',
                }}
            />
        );

        const opcoesMenu = (
            <Menu>
                {this.props.grupo.administrador ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.props.carregarDetalhe(this.props.grupo.id)}>
                            Editar
                    </Link>
                    </Menu.Item>
                    :
                    <Menu.Item>
                        <Link to="#" onClick={() => this.props.carregarDetalheSimples(this.props.grupo.id)}>
                            Membros
                    </Link>
                    </Menu.Item>
                }
                {(!this.props.grupo.curso) &&
                    <Menu.Item>
                        <Link to="#" onClick={() => this.sairGrupo(this.props.grupo.id)}>Sair do grupo</Link>
                    </Menu.Item>}
            </Menu>
        );

        const {
            grupo,
            mensagens,
            iconLoadingMais,
            iconLoadingGrupo,
            todasMensagens
        } = this.props;

        return (
            <div className="bloco-conversa">
                <div className="bloco-info">
                    {grupo ?
                        grupo.curso ?
                            <div className="info-conversa">
                                <div className="container-img">
                                    <img src={grupo.curso?.foto ? grupo.curso?.foto : noimage} />
                                </div>
                                <div className="info">
                                    <span className="nome">
                                        {grupo.curso.nome}
                                    </span>
                                    <p className="ultima-mensagem">{grupo.curso?.codigo}</p>
                                </div>
                            </div>
                            :
                            (grupo.notificacao || grupo.entidade) ?
                                <div className="info-conversa">
                                    <div className="container-img">
                                        <img src={grupo.notificacao ? esquilo : grupo.capa ? grupo.capa : noimage} />
                                    </div>
                                    <div className="info">
                                        <span className="nome" style={{ margin: 0 }}>
                                            {grupo.nome}
                                        </span>
                                    </div>
                                </div>
                                :
                                <div className="info-conversa">
                                    <div className="container-img">
                                        {grupo.utilizadores.length > 1 ?
                                            <img src={grupo.capa ? grupo.capa : noimage} />
                                            :
                                            <img src={grupo.utilizadores[0]?.foto ? grupo.utilizadores[0]?.foto : noimage} />
                                        }
                                    </div>
                                    <div className="info">
                                        {grupo.utilizadores.length === 1 ?
                                            <>
                                                <span className="nome">
                                                    {(grupo.nome && grupo.utilizadores.length > 1) ? grupo.nome : grupo.utilizadores[0]?.nome.split(" ")[0] + (grupo.utilizadores[0]?.nome.split(" ").length > 1 ? " " + grupo.utilizadores[0]?.nome.split(" ")[grupo.utilizadores[0]?.nome.split(" ").length - 1] : "")}
                                                </span>
                                                {grupo.utilizadores[0]?.responsavel ?
                                                    <p className="info-complementar">{grupo.utilizadores[0]?.responsavel}</p>
                                                    :
                                                    <p className="info-complementar">{grupo.utilizadores[0]?.numero}</p>
                                                }
                                            </>
                                            :
                                            grupo.utilizadores.length > 1 ?
                                                <span className="nome" style={{ marginTop: 0 }}>
                                                    {(grupo.nome && grupo.utilizadores.length > 1) ? grupo.nome : grupo.utilizadores[0]?.nome.split(" ")[0] + (grupo.utilizadores[0]?.nome.split(" ").length > 1 ? " " + grupo.utilizadores[0]?.nome.split(" ")[grupo.utilizadores[0]?.nome.split(" ").length - 1] : "")}
                                                </span>
                                                :
                                                <>
                                                    <span className="nome">
                                                        {grupo.criador.nome.split(" ")[0] + (grupo.criador.nome.split(" ").length > 1 ? " " + grupo.criador.nome.split(" ")[grupo.criador.nome.split(" ").length - 1] : "")}
                                                    </span>
                                                    <p className="info-complementar">{grupo.utilizadores[0]?.numero}</p>
                                                </>
                                        }
                                    </div>
                                </div>
                        :
                        <div className="info-conversa">
                            <div className="container-img">
                                <Skeleton avatar />
                            </div>
                            <div className="info">

                            </div>
                        </div>
                    }
                    {!grupo.curso &&
                        <div className="conversa-opcoes">
                            {/*<div className="controlos-opcoes opcao-search">
                            <BotaoProcurar tem_placeholder placeholder="Procurar mensagem" />
                        </div>*/}
                            {(!grupo.notificacao && grupo.tipo === 0) &&
                                <div className="controlos-opcoes">
                                    <Dropdown overlay={opcoesMenu}>
                                        <Link to="#" className="opcao-mais-opcoes">
                                            <Icon type="ellipsis" />
                                        </Link>
                                    </Dropdown>
                                </div>}
                        </div>}
                </div>
                <div className="lista-mensagens" ref={(ref) => this.scrollParentRef = ref}>
                    {iconLoadingGrupo ?
                        <div className="loading-data" style={{ height: "100%" }}>
                            <div className="loading" />
                        </div>
                        :
                        grupo ?
                            <>
                                {todasMensagens ?
                                    mensagens.length > 0 ?
                                        <div className="bloco-carregar">
                                            <span className="mensagem-carregar">Não existem mais mensagens</span>
                                        </div>
                                        :
                                        <div className="bloco-carregar">
                                            <span className="mensagem-carregar">Não existem mensagens</span>
                                        </div>
                                    : null}
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.props.carregarMensagensAnteriores}
                                    hasMore={!todasMensagens}
                                    loader={
                                        <div className="bloco-carregar">
                                            <span className="mensagem-carregar">A carregar...</span>
                                        </div>
                                    }
                                    useWindow={false}
                                    getScrollParent={() => this.scrollParentRef}
                                    initialLoad={false}
                                    isReverse={true}
                                >
                                    {mensagens.map((mensagem, index) => (
                                        <>
                                            {this.validarData(mensagem.dt_criado)}
                                            {mensagem.proprio ?
                                                <div className="bloco-mensagem-enviada">
                                                    <div className="mensagem-enviada">
                                                        <div className="info-enviada">
                                                            <span className="hora">{moment(mensagem.dt_criado).format("HH:mm")}</span>
                                                        </div>
                                                        {!mensagem.anexo ?
                                                            <p dangerouslySetInnerHTML={{ __html: mensagem.texto.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                                            :
                                                            <Link to="#" className="mensagem-ficheiro" onClick={() => this.downloadFicheiro(mensagem.id)}>
                                                                <div className="start icon-mensagem-ficheiro">
                                                                    <Icon className="paper-clip" type="paper-clip" />
                                                                </div>
                                                                <span className="mensagem-ficheiro-nome">{mensagem.anexo_nome}</span>
                                                            </Link>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className="bloco-mensagem-recebida">
                                                    <div className={`mensagem-recebida ${mensagem.notificacao ? "mensagem-notificacao" : ""}`}>
                                                        <div className={"info-recebida" + (grupo.utilizadores?.length === 1 ? " info-recebida-sem-autor" : "")}>
                                                            {grupo.utilizadores?.length > 2 ?
                                                                <span className="autor">{grupo ? (grupo.utilizadores[0]?.responsavel ? grupo.utilizadores[0]?.responsavel : mensagem.utilizador?.nome) : mensagem.utilizador?.nome}</span>
                                                                : null}
                                                            <span className="hora">{moment(mensagem.dt_criado).format("HH:mm")}</span>
                                                        </div>
                                                        {!mensagem.anexo ?
                                                            <p dangerouslySetInnerHTML={{ __html: mensagem.texto.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                                            :
                                                            <Link to="#" className="mensagem-ficheiro" onClick={() => this.downloadFicheiro(mensagem.id)}>
                                                                <div className="start icon-mensagem-ficheiro">
                                                                    <Icon className="paper-clip" type="paper-clip" />
                                                                </div>
                                                                <span className="mensagem-ficheiro-nome">{mensagem.anexo_nome}</span>
                                                            </Link>
                                                        }
                                                    </div>
                                                </div>}
                                        </>
                                    ))}
                                </InfiniteScroll>
                            </>
                            :
                            <div className="bloco-carregar">
                                <span className="mensagem-carregar">Não existem mensagens</span>
                            </div>
                    }
                </div>
                {/*<InfiniteScroll
                    dataLength={mensagens.length}
                    next={this.props.carregarMensagensAnteriores}
                    hasMore={!todasMensagens}
                    loader={<h4>Loading...</h4>}
                    //hasChildren={true}
                    className="lista-mensagens"
                    height={472}
                    //inverse={true}
                    endMessage={<h4>Não existem mais...</h4>}
                >
                    {mensagens.map((mensagem, index) => (
                        <>
                            {this.validarData(mensagem.dt_criado)}
                            {mensagem.proprio ?
                                <div className="bloco-mensagem-enviada">
                                    <div className="mensagem-enviada">
                                        <div className="info-enviada">
                                            <span className="hora">{moment(mensagem.dt_criado).format("HH:mm")}</span>
                                        </div>
                                        {!mensagem.anexo ?
                                            <p dangerouslySetInnerHTML={{ __html: mensagem.texto.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                            :
                                            <a href={mensagem.anexo} className="mensagem-ficheiro" download={mensagem.anexo_nome}>
                                                <div className="start icon-mensagem-ficheiro">
                                                    <Icon className="paper-clip" type="paper-clip" />
                                                </div>
                                                <span className="mensagem-ficheiro-nome">{mensagem.anexo_nome}</span>
                                            </a>
                                        }
                                    </div>
                                </div>
                                :
                                <div className="bloco-mensagem-recebida">
                                    <div className={`mensagem-recebida ${mensagem.notificacao ? "mensagem-notificacao" : ""}`}>
                                        <div className={"info-recebida" + (grupo.utilizadores?.length === 1 ? " info-recebida-sem-autor" : "")}>
                                            <span className="autor">{grupo ? (grupo.utilizadores[0]?.responsavel ? grupo.utilizadores[0]?.responsavel : mensagem.utilizador?.nome) : mensagem.utilizador?.nome}</span>
                                            <span className="hora">{moment(mensagem.dt_criado).format("HH:mm")}</span>
                                        </div>
                                        {!mensagem.anexo ?
                                            <p dangerouslySetInnerHTML={{ __html: mensagem.texto.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                            :
                                            <a href={mensagem.anexo} className="mensagem-ficheiro" download={mensagem.anexo_nome}>
                                                <div className="start icon-mensagem-ficheiro">
                                                    <Icon className="paper-clip" type="paper-clip" />
                                                </div>
                                                <span className="mensagem-ficheiro-nome">{mensagem.anexo_nome}</span>
                                            </a>
                                        }
                                    </div>
                                </div>}
                        </>
                    ))}
                </InfiniteScroll>*/}
                <div className="nova-mensagem">
                    <Dropdown overlay={menu} disabled={grupo.notificacao || !grupo} placement="topCenter" trigger={['click']}>
                        <Link to="#" className="btn-emoji">
                            <Icon type="smile" />
                        </Link>
                    </Dropdown>
                    <TextArea
                        name="texto"
                        disabled={grupo.notificacao || !grupo}
                        className="caixa-nova-mensagem"
                        value={texto}
                        placeholder="Mensagem"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                        onChange={this.handlerChange}
                    />
                    <Link to="#" className="btn-anexo" disabled={grupo.notificacao || !grupo} onClick={this.ativarUploader}>
                        {loadingAnexo ?
                            <Icon type="loading" />
                            :
                            <Icon type="paper-clip" />
                        }
                    </Link>
                    <Link to="#" className="btn-enviar-mensagem" disabled={grupo.notificacao || !grupo} onClick={this.enviarMensagem}>
                        <i className="fas fa-paper-plane"></i>
                    </Link>
                </div>
                <input
                    type="file"
                    id="upload-anexo"
                    onChange={this.uploadAnexo}
                    style={{ display: "none" }}
                />
            </div>
        );
    }
}

export default Conversa;
