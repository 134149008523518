import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Table } from "antd";
//import { Icon, Table } from "antd";
import axios from "axios";
//import moment from "moment";

export class Historico extends Component {
    state = {
        tempo_medio_sessao: "",
        tempo_total_sessao: "",
        //TABELA
        lista: [],
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //PAGINATION
        pagination: {
            pageSize: 50,
            current: 1,
            total: 0
        },
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        var utilizador = JSON.parse(localStorage.getItem("sis_utilizador"));
        this.setState({
            utilizadorid: utilizador.id
        });
        this.total(utilizador.id);
        this.listar(utilizador.id);
    }

    componentDidUpdate() {
        if (this.props.atualizar) {
            var utilizador = JSON.parse(localStorage.getItem("sis_utilizador"));
            this.setState({
                utilizadorid: utilizador.id
            });
            this.total(utilizador.id);
            this.listar(utilizador.id);
            this.props.paraAtualizar();
        }
    }

    handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = this.state.pagination;
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                this.listar(this.state.utilizadorid)
            }
        );
    };

    total = utilizadorid => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-estatistica/aluno-total-historico",
                    params: {
                        utilizadorid,
                        dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.props.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        var _pagination = this.state.pagination;
                        _pagination.total = response.data;

                        this.setState({
                            pagination: _pagination
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    listar = utilizadorid => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-estatistica/aluno-historico",
                    params: {
                        utilizadorid,
                        dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.props.dt_termino.format("YYYY/MM/DD"),
                        pageSize: this.state.pagination.pageSize,
                        page: this.state.pagination.current,
                    }
                })
                    .then(response => {
                        var lista = [];

                        response.data.forEach((registo, index) => {
                            lista.push({
                                key: index,
                                dataHora: registo.dataHora,
                                pagina: registo.pagina,
                                tempo: registo.tempo,
                            });
                        });

                        this.setState({
                            lista,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    render() {
        const {
            //TABELA
            lista,
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //PAGINATION
            pagination,
        } = this.state;

        const columns = [
            {
                title: "Data/hora",
                dataIndex: "dataHora",
                key: "dataHora",
                className: "td-165",
                render: (text, record) => (record.pagina === "Login" ? <strong className="marcar-registo">{text}</strong> : text)
            },
            {
                title: "Página",
                dataIndex: "pagina",
                key: "pagina",
                render: (text, record) => (record.pagina === "Login" ? <strong className="marcar-registo">{text}</strong> : text)
            }
        ];

        const columnsMobile = [
            {
                title: "Página",
                dataIndex: "pagina",
                key: "pagina",
                render: (text, record) => (<div className="bloco-historico">
                    <div className="info-historico">
                        <span className={`pagina-historico ${(record.pagina === "Login" ? "marcar-registo" : "")}`}>
                            {text}
                        </span>
                        <div className={`data-historico ${(record.pagina === "Login" ? "marcar-registo" : "")}`}>
                            {record.dataHora}
                        </div>
                    </div>
                </div>)
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="dashboard">
                <div className="bloco">
                    <Table
                        className="tabela-historico-desktop"
                        columns={columns}
                        dataSource={lista}
                        pagination={pagination}
                        locale={{ emptyText: "Não existem registos!" }}
                        onChange={this.handleChangeTable}
                        loading={{
                            spinning: loading_table,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading" />
                                </div>
                            )
                        }}
                    />
                    <Table
                        className="tabela-historico-mobile"
                        columns={columnsMobile}
                        dataSource={lista}
                        pagination={pagination}
                        locale={{ emptyText: "Não existem registos!" }}
                        onChange={this.handleChangeTable}
                        loading={{
                            spinning: loading_table,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading" />
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default Historico;
