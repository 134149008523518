import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Drawer, notification, Form, TimePicker, Select } from "antd";
import axios from "axios";
import moment from "moment";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerEditar = ({ drawerVisible, setDrawerVisible, form, videoConfId, utilizadorId, estadoForm, registo, adicionarRegisto, atualizarRegisto, dataSessao }) => {
    const [hora, setHora] = useState("");
    const [tipo, setTipo] = useState("");
    const { getFieldDecorator } = form;

    const confirmar = () => {
        let _registo = registo;
        _registo.data = `${estadoForm === "ADD" ? moment(dataSessao).format("YYYY-MM-DD") : moment(_registo.data).format("YYYY-MM-DD")} ${moment(hora, "HH:mm:ss").format("HH:mm:ss")}`;
        _registo.tipo = tipo;

        if (estadoForm === "ADD")
            adicionar(_registo);
        else
            atualizar(_registo);
    }

    const adicionar = (registo) => {
        let item = new FormData();
        item.append("videoConfId", videoConfId);
        item.append("utilizadorId", utilizadorId);
        item.append("registo", JSON.stringify(registo));

        axios({
            method: "post",
            url: "/api/gerir-relatorio-participacoes/add-participacao",
            data: item
        })
            .then(response => {
                adicionarRegisto(response.data)
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível adicionar registo!")
            });
    }

    const atualizar = (registo) => {
        let item = new FormData();
        item.append("registo", JSON.stringify(registo));

        axios({
            method: "put",
            url: "/api/gerir-relatorio-participacoes/edit-participacao",
            data: item
        })
            .then(response => {
                atualizarRegisto(registo)
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível atualizar registo!")
            });
    }

    useEffect(() => {
        form.resetFields();

        if (estadoForm === "EDIT") {
            setHora(moment(moment(registo.data).format("HH:mm:ss"), "HH:mm:ss"));
            setTipo(registo.tipo);
        }
        else {
            setHora("");
            setTipo(undefined);
        }
    }, [registo]);

    return (
        <Drawer
            className="drawer-inscrever"
            title="Editar registo de participação"
            width={720}
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
            //afterVisibleChange={this.afterVisibleChange}
        >
            <div className="bloco-info">
                <Form className="form-categorias" layout="horizontal">
                    <Form.Item label="Data">
                        {estadoForm === "EDIT" ? moment(registo.data).format("DD/MM/YYYY") : moment(dataSessao).format("DD/MM/YYYY")}
                    </Form.Item>
                    <Form.Item label="Hora">
                        {getFieldDecorator("nome", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: hora
                        })(<TimePicker
                            className="input-25"
                            name="duracao"
                            defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                            format="HH:mm:ss"
                            onChange={duracao => setHora(duracao)}
                            placeholder="Selecionar"
                            suffixIcon={false}
                            clearIcon={false}
                        />)}
                    </Form.Item>
                    <Form.Item label="Tipo">
                        <Select className="input-25" value={tipo} placeholder="Selecionar" allowClear={true} onChange={tipo => setTipo(tipo)}>
                            <Option value="ENTROU">Entrada</Option>
                            <Option value="SAIU">Saida</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button
                    className="botao-secundario"
                    onClick={() => setDrawerVisible(false)}
                    style={{ marginRight: 20, display: "inline-block" }}
                >
                    Voltar
                </button>
                <button className="botao-principal" onClick={confirmar}>
                    confirmar
                </button>
            </div>
        </Drawer>
    );
}
const FormDrawerEditar = Form.create({ name: "form-drawer-editar-participantacao" })(DrawerEditar);

export default FormDrawerEditar;
