import React from "react";
import { Link } from "react-router-dom";
import { Icon, Affix } from "antd";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";

function RedesSociais(props) {
        return (
            <Affix offsetTop={400} className="block-social-networks">
                <div className="block-affix">
                    <div className="list-share">
                        {props.facebook ?
                            <FacebookShareButton
                                url={props.url ? props.url : ""}
                                quote={props.description ? props.description : ""}
                                hashtag={props.hashtag ? props.hashtag : ""}
                                className="share-info"
                            >
                                <span className="share-item item-facebook" title="Facebook">
                                    <Icon type="facebook" />
                                </span>
                            </FacebookShareButton>
                            : null}
                        {props.twitter ?
                            <TwitterShareButton
                                url={props.url ? props.url : ""}
                                title={props.description ? props.description : ""}
                                hashtag={props.hashtag ? props.hashtag : ""}
                                className="share-info"
                            >
                                <span className="share-item item-twitter" title="Twitter">
                                    <Icon type="twitter" />
                                </span>
                            </TwitterShareButton>
                            : null}
                        {props.linkedin ?
                            <LinkedinShareButton
                                url={props.url ? props.url : ""}
                                summary={props.description ? props.description : ""}
                                hashtag={props.hashtag ? props.hashtag : ""}
                                className="share-info"
                            >
                                <span className="share-item item-linkedin" title="LinkedIn">
                                    <Icon type="linkedin" />
                                </span>
                            </LinkedinShareButton>
                            : null}
                        {props.instagram ?
                            <Link to="#" className="share-info share-item item-instagram" title="Instagram">
                                <Icon type="instagram" />
                            </Link>
                            : null}
                        {props.whatsapp ?
                            <a href={`https://api.whatsapp.com/send?phone=${props.numero}&text=${props.mensagem}`} target="_blank" rel="noopener noreferrer" className="share-info share-item item-whatsapp" title={props.title}>
                                <i className="fab fa-whatsapp"></i>
                            </a>
                            : null}
                    </div>
                </div>
            </Affix>
        );
}

export default RedesSociais;
