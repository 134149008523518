import React, { Component } from "react";
import { Input, Icon, Dropdown, Skeleton, notification, Menu, Modal } from 'antd';
import { Link } from "react-router-dom";
import Picker from 'emoji-picker-react';
import noimage from "../../images/noimage.png";
import esquilo from "../../images/Esquilo_3.jpg";
import axios from "axios";
import moment from "moment";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

let dataAnterior = "";

class Conversa extends Component {
    state = {
        texto: "",
        //LOADING
        loadingAnexo: false
    }

    componentDidUpdate() {
        dataAnterior = "";
    }

    handlerChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    enviarMensagem = event => {
        event.preventDefault();

        if (!this.state.texto)
            return false

        var item = new FormData();
        item.append("id_grupo", this.props.grupo.id);
        item.append("texto", this.state.texto);

        axios({
            method: "post",
            url: "/api/pagina-entidade-chat/enviar-mensagem",
            data: item,
            params: {
                codigo: this.props.codigo
            }
        })
            .then(response => {
                var mensagem = {
                    texto: this.state.texto,
                    anexo: "",
                    dt_criado: moment(),
                    utilizador: {
                        nome: ""
                    },
                    proprio: true
                }

                this.props.addMensagem(mensagem);

                this.setState({
                    texto: ""
                })

                var objDiv = document.getElementsByClassName("lista-mensagens")[0];
                objDiv.scrollTop = objDiv.scrollHeight;
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível enviar mensagem!");
            });
    }

    enviarAnexo = anexo => {
        this.setState({
            loadingAnexo: true
        })

        var item = new FormData();
        item.append("id_grupo", this.props.grupo.id);
        item.append("anexo", anexo);

        axios({
            method: "post",
            url: "/api/pagina-entidade-chat/enviar-anexo",
            data: item,
            params: {
                codigo: this.props.codigo
            }
        })
            .then(response => {
                var mensagem = {
                    texto: "",
                    anexo: response.data.anexo,
                    anexo_nome: response.data.nome,
                    dt_criado: moment(),
                    utilizador: {
                        nome: ""
                    },
                    proprio: true
                }

                this.props.addMensagem(mensagem);

                this.setState({
                    texto: "",
                    loadingAnexo: false
                })

                document.getElementById("upload-anexo").value = "";
                var objDiv = document.getElementsByClassName("lista-mensagens")[0];
                objDiv.scrollTop = objDiv.scrollHeight;
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível enviar mensagem!");
            });
    }

    sairGrupo = grupoId => {
        confirm({
            title: "Pretende sair do grupo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/pagina-entidade-chat/sair-grupo",
                    params: {
                        grupoId,
                        codigo: this.props.codigo
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Saiu do grupo!");
                        this.props.atualizarListaGrupos();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível sair do grupo!");
                    });
            }
        });
    }

    validarData = data => {
        var diff = moment().diff(data, 'weeks')
        var _data = moment(data).format("DD/MM/YYYY")

        if (_data === dataAnterior)
            return false

        dataAnterior = _data

        if (diff > 0)
            return <div className="bloco-separador"><span className="separador">{moment(data).format("DD/MM/YYYY")}</span></div>

        diff = moment().isSame(data, 'days')
        if (diff)
            return <div className="bloco-separador"><span className="separador">Hoje</span></div>
        else {
            var _data = moment(data).startOf('day')
            diff = moment().startOf('day').diff(_data, 'days')
            if (diff === 1)
                return <div className="bloco-separador"><span className="separador">Ontem</span></div>
            else
                return <div className="bloco-separador"><span className="separador">{moment(data).format('dddd')}</span></div>
        }
    }

    onEmojiClick = (event, emojiObject) => {
        this.setState({
            texto: this.state.texto + emojiObject.emoji
        })
    }

    ativarUploader = event => {
        event.preventDefault();
        document.getElementById("upload-anexo").click();
    };

    //ATUALIZA A FOTO
    uploadAnexo = event => {
        if (event.target.files[0].size < 26214400)
            if (this.validarFormato(event.target.files[0].name.split(".").pop().toLowerCase())) {
                this.enviarAnexo(event.target.files[0])
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido");
            }
        else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 25 MB por ficheiro.");
        }
    };

    validarFormato = formato => {
        var formatos = ["zip", "rar", "7z", "docx", "doc", "pdf", "odf", "txt", "rtf", "xlsx", "xls", "csv", "ods", "pptx", "ppt", "odp", "bmp", "gif", "png", "jpeg", "jpg"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    render() {
        const { TextArea } = Input;
        const {
            texto,
            //LOADING
            loadingAnexo
        } = this.state;

        const menu = (
            <Picker
                onEmojiClick={this.onEmojiClick}
                groupNames={{
                    smileys_people: 'Smileys e pessoas',
                    animals_nature: 'Animais e natureza',
                    food_drink: 'Comida e bebida',
                    travel_places: 'Viagens e locais',
                    activities: 'Atividades',
                    objects: 'Objetos',
                    symbols: 'Símbolos',
                    flags: 'Bandeiras',
                    recently_used: 'Utilizados recentemente',
                }}
            />
        );

        //const opcoesMenu = (
        //    <Menu>
        //        {(!this.props.grupo.curso && !this.props.grupo.notificacao) &&
        //            <Menu.Item>
        //                <Link to="#" onClick={() => this.sairGrupo(this.props.grupo.id)}>Sair do grupo</Link>
        //            </Menu.Item>}
        //    </Menu>
        //);

        const {
            grupo,
            mensagens,
            iconLoadingMais,
            iconLoadingGrupo
        } = this.props;

        return (
            <div className="bloco-conversa">
                <div className="bloco-info">
                    {grupo ?
                        <div className="info-conversa">
                            <div className="container-img">
                                <img src={grupo.utilizadores[0]?.foto ? grupo.utilizadores[0]?.foto : noimage} />
                            </div>
                            <div className="info">
                                <span className="nome">
                                    {(grupo.nome && grupo.utilizadores.length > 1) ? grupo.nome : grupo.utilizadores[0]?.nome.split(" ")[0] + (grupo.utilizadores[0]?.nome.split(" ").length > 1 ? " " + grupo.utilizadores[0]?.nome.split(" ")[grupo.utilizadores[0]?.nome.split(" ").length - 1] : "")}
                                </span>
                                {grupo.utilizadores[0]?.responsavel ?
                                    <p className="info-complementar">{grupo.utilizadores[0]?.responsavel}</p>
                                    :
                                    <p className="info-complementar">{grupo.utilizadores.length === 1 ? grupo.utilizadores[0]?.numero : "-"}</p>
                                }
                            </div>
                        </div>
                        :
                        <div className="info-conversa">
                            <div className="container-img">
                                <Skeleton avatar />
                            </div>
                            <div className="info">

                            </div>
                        </div>
                    }
                </div>
                <div className="lista-mensagens">
                    {iconLoadingMais &&
                        <div className="bloco-carregar"><span className="mensagem-carregar">A carregar...</span></div>}
                    {iconLoadingGrupo ?
                        <div className="loading-data" style={{ height: "100%" }}>
                            <div className="loading" />
                        </div>
                        :
                        <>
                            {mensagens.map((mensagem, index) => (
                                <>
                                    {this.validarData(mensagem.dt_criado)}
                                    {mensagem.proprio ?
                                        <div className="bloco-mensagem-enviada">
                                            <div className="mensagem-enviada">
                                                <div className="info-enviada">
                                                    <span className="hora">{moment(mensagem.dt_criado).format("HH:mm")}</span>
                                                </div>
                                                {!mensagem.anexo ?
                                                    <p dangerouslySetInnerHTML={{ __html: mensagem.texto.replace(/\n/g, "<br />") }}></p>
                                                    :
                                                    <a href={mensagem.anexo} className="mensagem-ficheiro" download={mensagem.anexo_nome}>
                                                        <div className="start icon-mensagem-ficheiro">
                                                            <Icon className="paper-clip" type="paper-clip" />
                                                        </div>
                                                        <span className="mensagem-ficheiro-nome">{mensagem.anexo_nome}</span>
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="bloco-mensagem-recebida">
                                            <div className={`mensagem-recebida ${mensagem.notificacao ? "mensagem-notificacao" : ""}`}>
                                                <div className={"info-recebida" + (grupo.utilizadores?.length === 1 ? " info-recebida-sem-autor" : "")}>
                                                    <span className="autor">{mensagem.utilizador?.nome}</span>
                                                    <span className="hora">{moment(mensagem.dt_criado).format("HH:mm")}</span>
                                                </div>
                                                {!mensagem.anexo ?
                                                    <p dangerouslySetInnerHTML={{ __html: mensagem.texto.replace(/\n/g, "<br />") }}></p>
                                                    :
                                                    <a href={mensagem.anexo} className="mensagem-ficheiro" download={mensagem.anexo_nome}>
                                                        <div className="start icon-mensagem-ficheiro">
                                                            <Icon className="paper-clip" type="paper-clip" />
                                                        </div>
                                                        <span className="mensagem-ficheiro-nome">{mensagem.anexo_nome}</span>
                                                    </a>
                                                }
                                            </div>
                                        </div>}
                                </>
                            ))
                            }
                        </>
                    }
                </div>
                <div className="nova-mensagem">
                    <Dropdown overlay={menu} disabled={!grupo.membroAtivo} placement="topCenter" trigger={['click']}>
                        <Link to="#" className="btn-emoji">
                            <Icon type="smile" />
                        </Link>
                    </Dropdown>
                    <TextArea
                        name="texto"
                        disabled={!grupo.membroAtivo}
                        className="caixa-nova-mensagem"
                        value={texto}
                        placeholder="Mensagem"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                        onChange={this.handlerChange}
                    />
                    <Link to="#" className="btn-anexo" disabled={!grupo.membroAtivo} onClick={this.ativarUploader}>
                        {loadingAnexo ?
                            <Icon type="loading" />
                            :
                            <Icon type="paper-clip" />
                        }
                    </Link>
                    <Link to="#" className="btn-enviar-mensagem" disabled={!grupo.membroAtivo} onClick={this.enviarMensagem}>
                        <i className="fas fa-paper-plane"></i>
                    </Link>
                </div>
                <input
                    type="file"
                    id="upload-anexo"
                    onChange={this.uploadAnexo}
                    style={{ display: "none" }}
                />
            </div>
        );
    }
}

export default Conversa;
