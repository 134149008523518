import React, { useEffect, useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";

//const PayPalButton = window.paypal.Buttons.driver("react", { React, BrowserRouter });

function Paypal({ entidade, total, concluirPaypal, erro, onClick, onCancel }) {
    //const createOrder = (data, actions) => {
    //    return actions.order.create({
    //        intent: "CAPTURE",
    //        application_context: {
    //            brand_name: "teste",
    //            shipping_preference: 'NO_SHIPPING'
    //        },
    //        purchase_units: [
    //            {
    //                //description: "cenas",
    //                amount: {
    //                    currency: "BRL",
    //                    value: "0.01",
    //                },
    //            },
    //        ],
    //    });
    //}

    const paypal = useRef();

    useEffect(() => {
        window.paypal.Buttons({
            createOrder: (data, actions, err) => {
                return actions.order.create({
                    intent: "CAPTURE",
                    application_context: {
                        brand_name: entidade,
                        shipping_preference: 'NO_SHIPPING'
                    },
                    purchase_units: [
                        {
                            amount: {
                                currency_code: "EUR",
                                value: total,
                            }
                        },
                    ],
                })
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                concluirPaypal(order.id)
            },
            onError: (err) => {
                erro(err)
            },
            onClick: (data, actions) => {
                onClick(data.fundingSource);
            },
            onCancel: (data, actions) => {
                onCancel();
            },
            style: { color: "blue", shape: "pill", label: "pay", height: 40 }
        }).render(paypal.current)
    }, [])

    //const onApprove = (data, actions) => {
    //    //concluirPaypal(data);
    //    return actions.order.capture();
    //}

    return (
        <div className="app">
            <div className="wrapper">
                {/*<PayPalButton
                    createOrder={(data, actions) => createOrder(data, actions)}
                    onApprove={(data, actions) => onApprove(data, actions)}
                    //onError={(err) => erro(err)}
                    style={{ color: "blue", shape: "pill", label: "pay", height: 40 }}
                />*/}
                <div ref={paypal}></div>
            </div>
        </div>
    );
}

export default Paypal;
