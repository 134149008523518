import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../User/Header";
import TabelaAlunos from "./Tabelas/Alunos";
//import TabelaFormadores from "./Tabelas/Formadores"
//import TabelaConteudosConcluidos from "./Tabelas/ConteudosConcluidos"
//import TabelaConteudosAcedidos from "./Tabelas/ConteudosAcedidos"
//import TabelaCursosAcedidos from "./Tabelas/CursosAcedidos"
import TabelaCursos from "./Tabelas/Cursos";
import TabelaInscricoesNovosCursos from "./Tabelas/InscricoesNovosCursos";
import TabelaInteracoesAnotacoes from "./Tabelas/InteracoesAnotacoes";
import TabelaInteracoesForuns from "./Tabelas/InteracoesForuns";
import TabelaMensagensEnviadas from "./Tabelas/MensagensEnviadas";
import TabelaTempoConteudos from "./Tabelas/TempoConteudos";
import TabelaFichsCursos from "./Tabelas/FichsCursos";
import TabelaSessoes from "./Tabelas/Sessoes";
import TabelaStepmeets from "./Tabelas/Stepmeets";
import TabelaStepmeetsGerais from "./Tabelas/StepmeetsGerais";
import TabelaUnidadesOrganizacionais from "./Tabelas/UnidadesOrganizacionais";

import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../GlobalState";
import { DatePicker, Icon, Modal, notification, Tooltip, Alert, Select, Switch } from "antd";
import { Link } from "react-router-dom";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export class Main extends Component {
    static contextType = GlobalContext;
    state = {
        organismoId: "",
        organismos: [],
        dt_inicio: moment().subtract(1, "month"),
        dt_termino: moment(),
        filtroAtivo: "Licenças",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //ATUALIZAR
        atualizar: false,
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //BOTOES
        vistaListagem: "",
        //SWITCH
        vistaMeets: true
    };

    componentDidMount() {
        this.listarOrganismos();
    }

    //componentDidUpdate() {
    //    if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
    //        this.setState({
    //            redirect: true,
    //            redirectLink: "/user"
    //        });
    //}

    onChangeUnidade = organismoId => {
        this.setState({
            organismoId
        });
    };

    onChangeDataInicio = dt_inicio => {
        this.setState({
            dt_inicio
        });
    };

    onChangeDataTermino = dt_termino => {
        this.setState({
            dt_termino
        });
    };

    handleChangeSwitch = vistaMeets => {
        this.setState({
            vistaMeets
        })
    }

    ativarFiltro = (opcao) => {
        this.setState({
            filtroAtivo: opcao,
            dt_inicio: opcao != "Stepmeets/Webinars" ? moment().subtract(1, "month") : moment(),
            dt_termino: moment(),
        });
    }

    exportarDET = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/exportar-det",
                    params: {
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarStepmeets = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/exportar-stepmeets",
                    params: {
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarStepmeetsGerais = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-exportar/exportar-stepmeets-gerais",
                    params: {
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    paraAtualizar = () => {
        this.setState({
            atualizar: false
        });
    };

    pesquisar = () => {
        var diff = this.state.dt_termino.diff(this.state.dt_inicio, "months");
        var diffDays = this.state.dt_termino.diff(this.state.dt_inicio, "days");

        if (diff >= 0 && diff <= 5 && diffDays >= 0)
            this.setState({ atualizar: true });
        else if (diff < 0 || diffDays < 0)
            openNotificationWithIcon("warning", "Atenção", "A data de Inicio deve ser inferior a data de Término");
        else
            openNotificationWithIcon("warning", "Atenção", "O intervalo das duas datas não deve ser superior a 6 meses");
    };

    listarOrganismos = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos/listar-organismos"
        })
            .then(response => {
                this.setState({
                    organismos: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            organismos,
            organismoId,
            dt_inicio,
            dt_termino,
            //REDIRECT
            redirect,
            redirectLink,
            //ATUALIZAR
            atualizar,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //BOTOES
            vistaListagem,
            //SWITCH
            vistaMeets
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const opcoesMenu = () => {
            var opcoes = [
                "Licenças",
                "Cursos",
                "Inscrições",
                "Tarefas",
                "Ficheiros",
                "Anotações",
                "Mensagens",
                "Fóruns",
                "Sessões",
                "Unid. Organizacional",
                "Stepmeets/Webinars"
            ];

            if (!this.context.escolas_entidade)
                opcoes = opcoes.filter(x => x !== "Unid. Organizacional")

            return opcoes;
        }

        return (
            <>
                <Header titulo="Estatísticas" />
                <div className="administracao-estatisticas">
                    <div className="container container-body">
                        <div className="bloco-grid">
                            <div className="filtros">
                                <ul>
                                    {opcoesMenu().map((item, index) => (
                                        <li
                                            key={index}
                                            className={this.state.filtroAtivo === item ? "ativo" : ""}
                                            onClick={() => this.ativarFiltro(item)}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="bloco-cursos" style={{ position: "relative" }}>
                                <div className="combo-datas combo-datas-desktop" style={{ marginBottom: 40 }}>
                                    {this.state.filtroAtivo === "Stepmeets/Webinars" ?
                                        <div className="bloco-switch">
                                            <Switch
                                                checkedChildren="Cursos"
                                                unCheckedChildren="Gerais"
                                                defaultChecked
                                                onChange={this.handleChangeSwitch}
                                            />
                                        </div> : null}
                                    {this.state.filtroAtivo === "Stepmeets/Webinars" ?
                                        <div className="bloco-filtro-botoes">
                                            <button className="botao-anterior" title="Anteriores" onClick={() => this.setState({ atualizar: true, vistaListagem: "ANTERIOR" })}>
                                                <Icon type="double-left" />
                                            </button>
                                            <button className="botao-proxima" title="Próximas" onClick={() => this.setState({ atualizar: true, vistaListagem: "PROXIMO" })}>
                                                <Icon type="double-right" />
                                            </button>
                                        </div>
                                        : null}
                                    {this.state.filtroAtivo === "Unid. Organizacional" ?
                                        <div className="bloco-filtro-unidades">
                                            <Select defaultValue="" onChange={this.onChangeUnidade}>
                                                <Option value="">Todas</Option>
                                                {organismos.map(organismo => (
                                                    <Option value={organismo.id}>{organismo.nome}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        : null}
                                    <div className="inicio">
                                        <label>Inicio:</label>
                                        <DatePicker
                                            value={dt_inicio}
                                            placeholder="Data Inicio"
                                            format="DD/MM/YYYY"
                                            onChange={this.onChangeDataInicio}
                                        />
                                    </div>
                                    <div className="termino">
                                        <label>Término:</label>
                                        <DatePicker
                                            value={dt_termino}
                                            placeholder="Data Término"
                                            format="DD/MM/YYYY"
                                            onChange={this.onChangeDataTermino}
                                        />
                                    </div>
                                    <div className="icon-informacao">
                                        <Tooltip className="info-icon" title="Intervalo máximo de 6 meses">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </div>
                                    <button className="procurar" onClick={this.pesquisar}>
                                        <Icon type="search" />
                                    </button>
                                </div>
                                <div className="combo-datas combo-datas-mobile" style={{ marginBottom: 40 }}>
                                    <Alert message="Intervalo máximo de 6 meses" type="info" showIcon />
                                    {this.state.filtroAtivo === "Stepmeets/Webinars" ?
                                        <div className="bloco-filtro-botoes">
                                            <button className="botao-anterior" title="Anteriores" onClick={() => this.setState({ atualizar: true, vistaListagem: "ANTERIOR" })}>
                                                <Icon type="left" />
                                            </button>
                                            <button className="botao-proxima" title="Próximas" onClick={() => this.setState({ atualizar: true, vistaListagem: "PROXIMO" })}>
                                                <Icon type="right" />
                                            </button>
                                        </div>
                                        : null}
                                    {this.state.filtroAtivo === "Unid. Organizacional" ?
                                        <div className="bloco-filtro-unidades">
                                            <Select defaultValue="" onChange={this.onChangeUnidade}>
                                                <Option value="">Todas</Option>
                                                {organismos.map(organismo => (
                                                    <Option value={organismo.id}>{organismo.nome}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        : null}
                                    <div className="bloco-filtro-datas">
                                        <div className="inicio">
                                            <label>Inicio:</label>
                                            <DatePicker
                                                value={dt_inicio}
                                                placeholder="Data Inicio"
                                                format="DD/MM/YYYY"
                                                onChange={this.onChangeDataInicio}
                                            />
                                        </div>
                                        <div className="termino">
                                            <label>Término:</label>
                                            <DatePicker
                                                value={dt_termino}
                                                placeholder="Data Término"
                                                format="DD/MM/YYYY"
                                                onChange={this.onChangeDataTermino}
                                            />
                                        </div>
                                        <button className="procurar" onClick={this.pesquisar}>
                                            <Icon type="search" />
                                        </button>
                                    </div>
                                </div>
                                <div style={{ marginBottom: 40 }}>
                                    {this.state.filtroAtivo === "Licenças" && (
                                        <TabelaAlunos
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                    )}
                                    {this.state.filtroAtivo === "Cursos" && (
                                        <TabelaCursos
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                    )}
                                    {this.state.filtroAtivo === "Inscrições" && (
                                        <TabelaInscricoesNovosCursos
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                    )}
                                    {this.state.filtroAtivo === "Tarefas" && (
                                        <TabelaTempoConteudos
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                    )}
                                    {this.state.filtroAtivo === "Ficheiros" && (
                                        <TabelaFichsCursos
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                    )}
                                    {this.state.filtroAtivo === "Anotações" && (
                                        <TabelaInteracoesAnotacoes
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                    )}
                                    {this.state.filtroAtivo === "Mensagens" && (
                                        <TabelaMensagensEnviadas
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                    )}
                                    {/*{this.state.filtroAtivo === 3 && <TabelaCursosAcedidos dt_inicio={dt_inicio} dt_termino={dt_termino} atualizar={atualizar} paraAtualizar={this.paraAtualizar} />}*/}
                                    {this.state.filtroAtivo === "Fóruns" && (
                                        <TabelaInteracoesForuns
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                    )}
                                    {/*{this.state.filtroAtivo === 6 && <TabelaConteudosConcluidos />}*/}
                                    {/* {this.state.filtroAtivo === 7 && <TabelaConteudosAcedidos />}*/}
                                    {this.state.filtroAtivo === "Sessões" && (
                                        <TabelaSessoes
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                    )}
                                    {this.state.filtroAtivo === "Unid. Organizacional" && (
                                        <TabelaUnidadesOrganizacionais
                                            organismoId={organismoId}
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                    )}
                                    {this.state.filtroAtivo === "Stepmeets/Webinars" && (
                                        <>
                                            {vistaMeets ?
                                                <TabelaStepmeets
                                                    dt_inicio={dt_inicio}
                                                    dt_termino={dt_termino}
                                                    atualizar={atualizar}
                                                    paraAtualizar={this.paraAtualizar}
                                                    vistaListagem={vistaListagem}
                                                    atualizarDatas={(dti, dtf) => {
                                                        this.setState({
                                                            dt_inicio: moment(dti),
                                                            dt_termino: moment(dtf),
                                                            vistaListagem: ""
                                                        })
                                                    }}
                                                />
                                                :
                                                <TabelaStepmeetsGerais
                                                    dt_inicio={dt_inicio}
                                                    dt_termino={dt_termino}
                                                    atualizar={atualizar}
                                                    paraAtualizar={this.paraAtualizar}
                                                    vistaListagem={vistaListagem}
                                                    atualizarDatas={(dti, dtf) => this.setState({
                                                        dt_inicio: moment(dti),
                                                        dt_termino: moment(dtf),
                                                        vistaListagem: ""
                                                    })}
                                                />
                                            }
                                        </>
                                    )}
                                </div>

                                {this.state.filtroAtivo !== "Stepmeets/Webinars" ?
                                    <div className="botoes-exportar" style={{ position: "absolute", bottom: 0, right: 0, padding: 20 }}>
                                        <Link to="#" onClick={this.exportarDET}>
                                            <Icon type="export" />
                                        Exportar para csv
                                    </Link>
                                    </div>
                                    : null}
                                {this.state.filtroAtivo === "Stepmeets/Webinars" && vistaMeets ?
                                    <div className="botoes-exportar" style={{ position: "absolute", bottom: 0, right: 0, padding: 20 }}>
                                        <Link to="#" onClick={this.exportarStepmeets}>
                                            <Icon type="export" />
                                            Exportar para csv
                                        </Link>
                                    </div>
                                    : null}
                                {this.state.filtroAtivo === "Stepmeets/Webinars" && !vistaMeets ?
                                    <div className="botoes-exportar" style={{ position: "absolute", bottom: 0, right: 0, padding: 20 }}>
                                        <Link to="#" onClick={this.exportarStepmeetsGerais}>
                                            <Icon type="export" />
                                            Exportar para csv
                                        </Link>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A gerar documento...</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                </p>
                                    <p className="texto">Dados exportados com sucesso!</p>
                                    <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

export default Main;
