import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon } from "antd";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";

class Menu extends Component {
    state = {
        pesquisa: ""
    };

    emitEmpty = () => {
        this.setState({ pesquisa: "" });
        this.props.resetCaixaProcura();
    };

    handleChange = e => {
        this.setState({ pesquisa: e.target.value });
        if (e.target.value.length == 0) this.props.resetCaixaProcura();
    };

    render() {
        return (
            <div className="menu">
                <div className="menu-filtros">
                    <div className={"menu-item " + `${this.props.proximas_ativo}`} onClick={this.props.filtro_proximas}>
                        {" "}
                        Agendadas
                    </div>
                    <div className={"menu-item " + `${this.props.anteriores_ativo}`} onClick={this.props.filtro_anteriores}>
                        {" "}
                        Realizadas
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Menu);
