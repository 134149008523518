import React, { Component } from "react";
import Header from "../User/Header";
import { GlobalContext } from "../GlobalState";

class TermosCondicoes extends Component {
    static contextType = GlobalContext;

    state = {
        termos: [
            {
                titulo: "1.",
                texto:
                    "A NextOpinion Lda fornece, no formato software como serviço (SaaS), acesso à plataforma de ensino à distância STEPFORMA, disponível no domínio stepforma.com.br, que permite a criação e gestão de cursos online, que poderão ser disponibilizados a utilizadores (associados a licenças autorizadas por VOCÊ).",
                listagem: [],
                texto2: ""
            },
            {
                // titulo: "2. COMO RECOLHEMOS INFORMAÇÕES",
                titulo: "2.",
                texto:
                    'Este Acordo de Utilizador de Serviço e Termos de Utilização constitui o acordo entre a STEPFORMA e VOCÊ, pessoa jurídica ou física (contratante) como utilizador que estabelece uma conexão com o site da Internet localizado em www.stepforma.com.br ou quaisquer serviços fornecidos em conexão com o site (coletivamente o "Serviço"), que são de propriedade e controlados pela NextOpinion Lda. Se VOCÊ se registrou para ou em nome de uma entidade, VOCÊ é considerado como tendo aceito este Acordo em nome dessa entidade.',
                listagem: [
                    {
                        pontos: "VOCÊ CONCORDA QUE, AO USAR O SERVIÇO QUE VOCÊ REPRESENTA QUE VOCÊ TEM PELO MENOS 18 ANOS DE IDADE E QUE VOCÊ É LEGALMENTE CAPAZ DE ENTRAR NESTE ACORDO."
                    },
                    {
                        pontos:
                            "VOCÊ concorda em cumprir todas as disposições do presente Acordo a fim de permanecer um utilizador autorizado do Serviço, e Seu uso do Serviço constitui o Seu acordo em cumprir estas disposições. VOCÊ é o único responsável por Seu uso do Serviço, por todo o uso do Serviço feito por terceiros usando Seu nome de utilizador e senha, e por garantir que tal uso esteja em total conformidade com as disposições do presente Acordo. "
                    },
                    {
                        pontos:
                            "A NextOpinion Lda reserva tudo fará para fornecer o acesso à PLATAFORMA 24 horas por dia, 7 dias por semana. No entanto, não podemos garantir que os Serviços sejam totalmente imunes a falhas, interrupções, incidentes de segurança, indisponibilidades, entre outros, como qualquer outro serviço similar. "
                    },
                    {
                        pontos:
                            "A NextOpinion Lda respeita a Sua privacidade e permite-lhe controlar o tratamento das Suas informações pessoais. Uma declaração completa da atual política de privacidade da Empresa pode ser encontrada no site www.stepforma.com.br. A política de privacidade da Empresa está expressamente incorporada neste Acordo por esta referência."
                    },
                    {
                        pontos:
                            "A NextOpinion Lda reserva-se o direito, a seu exclusivo critério, de alterar qualquer ou todas as disposições do presente Acordo a qualquer momento. A NextOpinion Lda notificará os utilizadores de quaisquer alterações, colocando-as no Serviço ou através de outros meios razoáveis de notificação. Quaisquer alterações ao presente Acordo entrarão em vigor imediatamente após notificação ao utilizador. A sua utilização do Serviço após a notificação de alterações ao presente Acordo será considerada a Sua aceitação das alterações. "
                    },
                    {
                        pontos:
                            "A subscrição pelo período acordado da plataforma não é reembolsável. Em casos especiais, e apenas aqueles devidos a dificuldades técnicas, os pedidos de reembolso serão considerados. A NextOpinion Lda analisará as questões técnicas e determinará o resultado do pedido de reembolso caso a caso."
                    },
                    {
                        pontos:
                            "A NextOpinion Lda reserva-se o direito, a seu exclusivo critério, de alterar, limitar ou descontinuar qualquer aspeto, conteúdo ou característica do Serviço, bem como qualquer aspeto relativo à utilização do Serviço. A NextOpinion Lda reserva-se ainda o direito, a seu exclusivo critério, de restringir a utilização do Serviço, bem como de suspender ou revogar os Seus direitos de utilização do Serviço com base na convicção da NextOpinion Lda de que a sua utilização do Serviço viola o permitido por este Contrato ou pela lei aplicável."
                    },
                    {
                        pontos:
                            "SE NÃO CONCORDAR COM A DISPOSIÇÃO DESTE ACORDO OU NÃO ESTIVER SATISFEITO COM O SERVIÇO, OCORREREM ERROS OU INDISPONIBILIDADE NOS SERVIÇOS O SEU ÚNICO E EXCLUSIVO RECURSO É INTERROMPER A SUA UTILIZAÇÃO DO SERVIÇO. Independentemente do Seu ciclo de faturação, não há reembolsos ou créditos por meses parciais do Serviço ou reembolsos por tempo não utilizado se fechar a Sua conta antes do final do Seu período de subscrição. Nenhuma exceção será feita para tratar todos igualmente e manter Nossos custos administrativos baixos para o benefício final de Nossa base de clientes. Baixar o nível do Seu plano pode causar a perda de conteúdo, recursos ou capacidade da Sua conta e a NextOpinion não aceita qualquer responsabilidade por tal perda."
                    }
                ],
                texto2: ""
            },
            {
                titulo: "3.",
                texto: "Direitos no Serviço NextOpinion:",
                listagem: [
                    {
                        pontos:
                            "O Serviço está protegido por direitos de autor, patentes, marcas registadas e outras leis de propriedade intelectual e direitos de propriedade aplicáveis e é propriedade, controlado e/ou licenciado pela NextOpinion Lda. O logótipo da STEPFORMA é uma marca comercial da NextOpinion Lda. Todas as outras marcas comerciais que aparecem no Serviço são propriedade dos respetivos proprietários."
                    },
                    {
                        pontos:
                            'A NextOpinion Lda. concede-lhe, pelo presente acordo, uma licença mundial, revogável e não exclusiva para utilizar o Serviço exclusivamente para Seu uso pessoal, organizacional ou interno. VOCÊ não fará nenhum outro uso do conteúdo sem a permissão expressa por escrito da NextOpinion Lda.  do proprietário dos direitos autorais ou de Seu agente autorizado. VOCÊ não irá modificar, publicar, distribuir, transmitir, participar na transferência ou venda, criar trabalhos derivados, ou de qualquer forma explorar, qualquer conteúdo, no todo ou em parte, encontrado no Serviço que não foi expressamente criado por VOCÊ ou alguém em Sua organização com Sua permissão. VOCÊ também não "enquadrará" qualquer conteúdo do Serviço ou do próprio Serviço sem a permissão expressa por escrito da NextOpinion Lda, e do proprietário dos direitos autorais ou de Seu agente autorizado. Ao aceitar este acordo de utilizador VOCÊ concorda com os termos nele expressos.'
                    },
                    {
                        pontos:
                            "Atualizações, melhorias e alterações na plataforma de ensino à distância STEPFORMA e suas funcionalidades poderão ser implementadas, incluindo a sua substituição ou exclusão a qualquer tempo pela NextOpinion Lda."
                    }
                ],
                texto2: ""
            },
            {
                titulo: "4.",
                texto: "Deveres do CONTRATANTE:",
                listagem: [
                    {
                        pontos:
                            "Ao aceitar este Acordo, VOCÊ (CONTRATANTE) se compromete a utilizar a Plataforma para fins lícitos e educacionais, de acordo com as informações constantes no site www.stepforma.com.br e outras recebidas por email. VOCÊ é totalmente responsável pela criação e configuração dos cursos e todos os conteúdos associados a esses cursos que VOCÊ disponibilizar."
                    },
                    {
                        pontos:
                            "VOCÊ pode disponibilizar cursos online para terceiros: incluindo seus colaboradores, alunos ou outros (UTILIZADORES com licenças de utilização). A relação entre VOCÊ e seus utilizadores assim como as ações destes é de sua inteira responsabilidade e VOCÊ deverá garantir que eles aceitem os seus termos de uso e os constantes neste contrato. A NextOpinion Lda. não terá qualquer relação jurídica com os Seus utilizadores, e VOCÊ declara que isentará de quaisquer reclamações, ações judiciais ou indenizações que possam vir a ser exigidas."
                    },
                    {
                        pontos:
                            "Eventuais benefícios e pagamentos entre VOCÊ e os seus utilizadores são da Sua inteira responsabilidade, assim como eventuais falhas de recebimentos provindos de Seus utilizadores."
                    },
                    {
                        pontos: "A utilização do Serviço possui exigências técnicas que deverão ser asseguradas pelos utilizadores do mesmo, nomeadamente a qualidade do acesso à Internet."
                    }
                ],
                texto2: ""
            },
            {
                titulo: "5.",
                texto: "Deveres no Serviço NextOpinion:",
                listagem: [
                    {
                        pontos:
                            "A NextOpinion Lda. utiliza diversos mecanismos para evitar a perda ou a corrupção de dados, porém o acesso à Internet envolve diversos riscos pelos quais não podemos nos responsabilizar, pelo que VOCÊ declara que isentará de quaisquer reclamações, ações judiciais ou indenizações que possam vir a ser exigidas por eventuais incidentes desta natureza."
                    },
                    {
                        pontos:
                            "A NextOpinion Lda. tudo fará para responder rapidamente a todas as questões que lhe cheguem quer por via email, por mensagem interna ou pelo formulário de contato existente no site www.stepforma.com.br. VOCÊ pode ainda consultar a página de suporte técnico com questões frequentes e suas respostas sobre o uso das diversas funcionalidades para cada um dos perfis de utilizadores."
                    }
                ],
                texto2: ""
            }
        ]
    };

    // UNSAFE_componentWillMount() {
    //     this.context.is_authenticated();
    // }

    render() {
        return (
            <div className="suporte">
                <Header titulo="Termos de Uso" marginTop0={true} />
                <div className="container">
                    <div className="content">
                        <div className="termos">
                            {this.state.termos.map((termo, index) => (
                                <div key={index} className="item">
                                    <div className="titulo">{termo.titulo}</div>
                                    <div className="texto">
                                        {termo.texto}
                                        <ul>
                                            {termo.listagem.map((listagem, index2) => (
                                                <li key={index2}>
                                                    <i className="fa fa-check" />
                                                    {listagem.pontos}
                                                </li>
                                            ))}
                                        </ul>
                                        {termo.texto2}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TermosCondicoes;
