import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, Collapse, notification, Icon, Select, Tooltip, Switch } from "antd";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import moment from "moment";
import noimage from "../../images/noimage.png";
import esquilo from "../../images/Esquilo_3.jpg";
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DadosGerais extends Component {
    static contextType = GlobalContext;
    state = {
        avatar: "",
        preview: "",
        //RANKING
        aberto: true,
        medalhasCursos: 0,
        medalhasTarefas: 0,
        pontos: 0,
        cursos: [],
        //LOADING
        loading: false,
        //UPLOAD VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        this.carregarAvatar();
        this.carregarRanking();
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChangeSwitch = value => {
        this.setState({ aberto: value }, () => this.carregarRanking());
    };

    carregarAvatar = () => {
        this.setState({
            loading: true
        })
        axios({
            method: "get",
            url: "/api/minha-conta/carregar-avatar"
        })
            .then(response => {
                this.setState({
                    avatar: response.data,
                    preview: response.data
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    carregarRanking = () => {
        this.setState({
            loading: true
        })
        axios({
            method: "get",
            url: "/api/minha-conta-ranking/carregar-geral",
            params: {
                fechado: !this.state.aberto
            }
        })
            .then(response => {
                this.setState({
                    medalhasCursos: response.data.medalhasCursos,
                    medalhasTarefas: response.data.medalhasTarefas,
                    pontos: response.data.pontos,
                    cursos: response.data.cursos,
                    loading: false
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //ATUALIZA A FOTO
    uploadFoto = event => {
        if (event.target.files[0].size < 100 * 1024)
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop().toLowerCase())) {
                var avatar = URL.createObjectURL(event.target.files[0]);
                var item = new FormData();
                item.append("avatar", event.target.files[0]);

                axios({
                    method: "put",
                    url: "/api/minha-conta/adicionar-avatar",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Avatar atualizado!");
                        this.setState({
                            preview: avatar,
                            validateStatus: "",
                            help: ""
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível atualizar avatar!");
                    });
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 100 KB para a imagem.");
        }
    };

    //REMOVE A FOTO
    removerAvatar = () => {
        axios({
            method: "delete",
            url: "/api/minha-conta/remover-avatar"
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Avatar removido!");
                document.getElementById("input-capa").value = "";
                this.setState({
                    preview: ""
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível remover o avatar!");
            });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() == formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //ABRE O FILE BROWSER
    ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //REMOVE A FOTO
    removerFoto = () => {
        document.getElementById("input-foto").value = "";
        this.setState({
            foto: "",
            preview: ""
        });
    };

    //CARREGA OS DADOS DO UTILIZADOR
    carregarDadosPerfil = () => {
        axios({
            method: "get",
            url: "/api/minha-conta"
        })
            .then(response => {
                this.context.atualizarState({
                    nr_utilizador: response.data.nr_aluno,
                    dt_adesao_utilizador: response.data.dt_criado,
                    login2: response.data.login2,
                    email: response.data.email,
                    nome_apelido: response.data.nome_apelido,
                    organismo: response.data.organismo,
                    medalhasCurso: response.data.medalhasCurso,
                    medalhasTarefa: response.data.medalhasTarefa
                });

                this.setState({
                    nome_completo: response.data.nome_completo,
                    nome_apelido: response.data.nome_apelido,
                    email: response.data.email,
                    emailnotif: response.data.emailnotif,
                    contacto: response.data.contacto ? response.data.contacto : "",
                    dt_nascimento: response.data.dt_nascimento ? moment(response.data.dt_nascimento) : null,
                    naturalidade: response.data.naturalidade ? response.data.naturalidade : "",
                    sexo: response.data.sexo ? response.data.sexo : undefined,
                    cc: response.data.cc ? response.data.cc : "",
                    dt_ccvalidade: response.data.dt_ccvalidade ? moment(response.data.dt_ccvalidade) : null,
                    nif: response.data.nif ? response.data.nif : "",
                    morada: response.data.morada ? response.data.morada : "",
                    cep: response.data.cep ? response.data.cep : "",
                    localidade: response.data.localidade ? response.data.localidade : "",
                    pais: response.data.pais ? response.data.pais : "",
                    habilitacao: response.data.habilitacao ? response.data.habilitacao : "",
                    paginaPessoal: response.data.paginaPessoal ? response.data.paginaPessoal : "",
                    descricao: response.data.descricao ? response.data.descricao : "",
                    paiResponsavel: response.data.responsavel_1 ? response.data.responsavel_1 : "",
                    maeResponsavel: response.data.responsavel_2 ? response.data.responsavel_2 : "",
                    estadoCivil: response.data.estado_civil ? response.data.estado_civil : undefined,
                    foto: response.data.foto,
                    preview: response.data.foto
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    atualizarDados = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.totalBytes <= 100 * 1024 && this.state.formatoValido) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("nome_completo", this.state.nome_completo);
                item.append("nome_apelido", this.state.nome_apelido);

                item.append("foto", this.state.foto);
                item.append("email", this.state.email);
                item.append("emailnotif", this.state.emailnotif);
                item.append("contacto", this.state.contacto);
                if (this.state.dt_nascimento) item.append("dt_nascimento", this.state.dt_nascimento.format("YYYY/MM/DD"));
                item.append("naturalidade", this.state.naturalidade);
                if (this.state.sexo) item.append("sexo", this.state.sexo);
                item.append("cc", this.state.cc);
                if (this.state.dt_ccvalidade) item.append("dt_ccvalidade", this.state.dt_ccvalidade.format("YYYY/MM/DD"));
                item.append("nif", this.state.nif);
                item.append("morada", this.state.morada);
                item.append("cep", this.state.cep);
                item.append("localidade", this.state.localidade);
                item.append("pais", this.state.pais);
                item.append("habilitacao", this.state.habilitacao);
                item.append("paginaPessoal", this.state.paginaPessoal);
                item.append("descricao", this.state.descricao);
                item.append("responsavel_1", this.state.paiResponsavel);
                item.append("responsavel_2", this.state.maeResponsavel);
                if (this.state.estadoCivil) item.append("estado_civil", this.state.estadoCivil);

                axios({
                    method: "put",
                    url: "/api/minha-conta",
                    data: item
                })
                    .then(() => {
                        this.context.atualizarState({ atualizarDadosGerias: true });
                        openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                        this.setState({
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    disabledDate = current => {
        return current && current > moment().endOf("day");
    };

    validarLink = (rule, value, callback) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        if (!pattern.test(value) && value)
            callback("Introduza uma página válida")

        callback();
    }

    render() {
        const {
            preview,
            //RANKING
            medalhasCursos,
            medalhasTarefas,
            pontos,
            cursos,
            //LOADING
            loading,
            //UPLOAD VALIDATE
            validateStatus,
            help,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="bloco-minha-conta">
                <div className="bloco-conquistas">
                    <h3 className="titulo-com-imagem">
                        <div className="container-img">
                            <img src={esquilo} />
                        </div>
                        <span>Ranking</span>
                    </h3>
                    <div className="bloco-img">
                        <h3 className="titulo-separador" style={{ border: "none" }}>Avatar</h3>
                        <Form>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <div className="container-img">
                                    <img src={preview ? preview : noimage} />
                                </div>
                                <input
                                    type="file"
                                    id="input-foto"
                                    accept="image/*"
                                    onChange={this.uploadFoto}
                                    style={{ display: "none" }}
                                />
                                {!preview ? (
                                    <>
                                        <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                            <span>Adicionar</span>
                                        </Link>{" "}
                                        <Tooltip
                                            className="info-icon"
                                            title={
                                                <div>
                                                    <p>Tamanho máximo: 100KB</p>
                                                    <p>Tamanho recomendado: 200x200px</p>
                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                </div>
                                            }
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                ) : (
                                        <>
                                            <Link
                                                to="#"
                                                className="link-principal-border"
                                                onClick={() => {
                                                    this.ativarUploadFoto();
                                                }}
                                            >
                                                <span>Alterar</span>
                                            </Link>
                                            <Tooltip
                                                className="info-icon"
                                                title={
                                                    <>
                                                        <p>Tamanho máximo: 100KB</p>
                                                        <p>Tamanho recomendado: 200x200px</p>
                                                        <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                    </>
                                                }
                                            >
                                                <Icon type="question-circle-o" style={{ marginRight: 20 }} />
                                            </Tooltip>
                                            <Link
                                                to="#"
                                                className="link-principal-border"
                                                onClick={() => {
                                                    this.removerAvatar();
                                                }}
                                            >
                                                <span>Remover</span>
                                            </Link>
                                        </>
                                    )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="bloco-info">
                        <div className="bloco">
                            <h3 className="titulo-separador titulo-com-switch">
                                Cursos
                                <Switch
                                    checkedChildren="Abertos"
                                    unCheckedChildren="Fechados"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                    disabled={loading}
                                />
                            </h3>
                            {loading ?
                                <div className="loading-data">
                                    <div className="loading" />
                                </div>
                                :
                                <>
                                    <div className="info-cursos-totais">
                                        <span className="titulo-curso">Totais: </span>
                                        <span className="icon-medalha">
                                            <i className="fas fa-award icon-medalha-curso"></i>
                                            <span>{medalhasCursos}</span>
                                        </span>
                                        <span className="icon-medalha">
                                            <i className="fas fa-award icon-medalha-aula"></i>
                                            <span>{medalhasTarefas}</span>
                                        </span>
                                        <span className="pontuacao-curso">
                                            {pontos} pts
                                </span>
                                    </div>
                                    <div className="lista-cursos">
                                        {cursos.map((curso, index) => (
                                            <div key={index} className="info-curso">
                                                <span className="titulo-curso">{curso.nome}</span>
                                                {curso.medalhaCurso &&
                                                    <span className="icon-medalha">
                                                            <i className="fas fa-award icon-medalha-curso"></i>
                                                </span>}
                                                <span className="icon-medalha">
                                                    <i className="fas fa-award icon-medalha-aula"></i>
                                                    <span>{curso.medalhasTarefas}</span>
                                                </span>
                                                <span className="pontuacao-curso">
                                                    {curso.pontos} pts (<strong>{curso.posicao > 0 ? `${curso.posicao}º` : "---"}</strong>)
                                    </span>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const FormDadosGerais = Form.create({ name: "form-minha-conta" })(DadosGerais);

export default FormDadosGerais;
