import React, { Component } from "react";
import { Link } from "react-router-dom";
import noimage from "../../../images/noimage.png";
import { Menu, Badge } from "antd";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import axios from "axios";

class Topico extends Component {
    montarMenu = forumid => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.props.excluir(forumid)}>
                        Excluir
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    render() {
        return (
            <div className={this.props.topico.estado == 1 ? "topico" : "topico pendente"}>
                <Link to={this.props.url} onClick={() => localStorage.setItem("codigo_forum", this.props.topico.id)}>
                    <div className="info-autor">
                        <div className="container-img">
                            <img src={this.props.topico.utilizador.foto ? this.props.topico.utilizador.foto : noimage} />
                        </div>
                        <div className="info">
                            <span className="nome">
                                {this.props.topico.utilizador.nome_completo.split(" ").length > 1 ? `${this.props.topico.utilizador.nome_completo.split(" ")[0]} ${this.props.topico.utilizador.nome_completo.split(" ")[this.props.topico.utilizador.nome_completo.split(" ").length - 1]}` : this.props.topico.utilizador.nome_completo}
                            </span>
                            <p className="numero">{this.props.topico.utilizador.numero}</p>
                        </div>
                    </div>
                    <div className="info-topico">
                        <div className="questao">
                            <span className="texto" onClick={this.props.onClick}>
                                {this.props.topico.topico}
                                {this.props.topico.totalRespostas > 0 &&
                                    <Badge count={this.props.topico.totalRespostas} className="badge-total" />}
                            </span>
                        </div>
                        <p className="resposta">{this.props.topico.texto}</p>
                    </div>
                </Link>
            </div>
        );
    }
}

export default Topico;
