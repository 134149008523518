import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Icon, Row, Col, notification } from "antd";
import noimage from "../../images/noimage.png";
import { GlobalContext } from "../../GlobalState";
import { GeralContext } from "../ContextProvider";
import axios from "axios";
import moment from "moment";
import ModalInscricaoPart1 from "../_Aux/ModalInscricaoPart1";
import ModalInscricaoPart2 from "../_Aux/ModalInscricaoPart2";
import ModalInscricaoPart2Generico from "../_Aux/ModalInscricaoPart2Generico";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

//CM
class VisaoGeral extends Component {
    static contextType = GlobalContext;
    state = {
        curso: {},
        retomar: "",
        total_atividades: 0,
        total_tarefas: 0,
        //coordenadores: [],
        formadores: [],
        duracao: "",
        loading: true,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL INSCRICAO
        visiblePart1: true,
        visiblePart2: false,
        nome_completo: "",
        cc: "",
        dt_ccvalidade: null,
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.CarregarInfo();
    }

    //CARREGA A INFORMAÇÃO DO CURSO
    CarregarInfo = () => {
        axios({
            method: "get",
            url: "/api/curso/info-curso",
            params: {
                cursoid: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    curso: response.data,
                    ativo: response.data.ativo,
                    total_atividades: response.data.total_modulos,
                    total_tarefas: response.data.total_aulas,
                    formadores: response.data.instrutores,
                    duracao: this.calcularSemanas(response.data.dt_inicio, response.data.dt_fim),
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    calcularSemanas = (dt_inicio, dt_fim) => {
        if (dt_fim) {
            var semanas = moment(dt_fim).diff(dt_inicio, 'week');
            if (semanas <= 0) {
                var dias = moment(dt_fim).diff(dt_inicio, 'day');
                return `${dias} dias`
            }
            else
                return `${semanas} semanas`
        }
        else
            return "SEM_REGISTO"
    }

    montarTipologia = tipologia => {
        switch (tipologia) {
            case "ONLINE":
                return "Online";
                break;
            case "PRESENCIAL":
                return "Presencial";
                break;
            default:
                return "Misto";
                break;
        }
    }

    montarDedicacao = dedicacao => {
        switch (dedicacao) {
            case "PARCIAL":
                return "Tempo parcial";
                break;
            default:
                return "Tempo inteiro";
                break;
        }
    }

    montarCusto = (valorCurso, valorCertificado) => {
        if (valorCurso > 0 && valorCertificado > 0)
            return `${this.context.moeda_entidade} ${valorCurso} e ${this.context.moeda_entidade} ${valorCertificado}/Certificado`;
        else if (valorCurso > 0)
            return `${this.context.moeda_entidade} ${valorCurso}`;
        else if (valorCertificado > 0)
            return `${this.context.moeda_entidade} ${valorCertificado}/Certificado`;
        else
            return "Gratuito";
    }

    confirmarPart1 = (nome_completo, cc, dt_ccvalidade) => {
        this.setState({
            nome_completo,
            cc,
            dt_ccvalidade,
            visiblePart1: false,
            visiblePart2: true
        })
    }

    finalizar = () => {
        localStorage.setItem("welcome", true)
        window.location.reload();
        //this.setState({
        //    visiblePart2: false
        //})
    }

    downloadFicheiro = (cursoId) => {
        axios({
            method: "post",
            url: "/api/curso/download-ficheiro",
            params: {
                cursoId
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.data === "SEM_PERMISSAO")
                    this.setState({
                        redirect: true,
                        redirectLink: "/user"
                    });
                else if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    render() {
        const {
            curso,
            total_atividades,
            total_tarefas,
            //coordenadores,
            formadores,
            duracao,
            //REDIRECT
            redirect,
            redirectLink,
            //MODAL INSCRICAO
            visiblePart1,
            visiblePart2,
            nome_completo,
            cc,
            dt_ccvalidade,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const lista_linguas = linguas => {
            var _linguas = "";

            linguas.forEach(lingua => {
                _linguas += lingua.nome + ", ";
            });

            return _linguas.slice(0, -2);
        };

        return (
            <div className="container container-body">
                {this.state.loading ? (
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                ) : (
                        <div className="curso-visao-geral">
                            <div className="bloco-indicadores-left">
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <Icon className="item-icon" type="appstore" />
                                                    Categoria
                                                 </h2>
                                                <div className="conteudos">
                                                    <div className="conteudo">
                                                        {curso.categoria.nome}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <Icon className="item-icon" type="calendar" />
                                                    Início
                                                 </h2>
                                                <div className="conteudos">
                                                    <div className="conteudo">
                                                        {moment(curso.dt_inicio).format("DD/MM/YYYY")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {curso.tipologia !== "NAO_APLICAVEL" ?
                                        <Col className="gutter-row" span={8} xs={24} md={8}>
                                            <div className="gutter-box">
                                                <div className="item">
                                                    <h2>
                                                        <Icon className="item-icon" type="build" />
                                                    Tipologia
                                                 </h2>
                                                    <div className="conteudos">
                                                        <div className="conteudo">
                                                            {this.montarTipologia(curso.tipologia)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        : null}
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <Icon className="item-icon" type="global" />
                                                    Idiomas
                                                 </h2>
                                                <div className="conteudos">
                                                    <div className="conteudo">
                                                        {lista_linguas(curso.linguas)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {curso.dt_fecho_inscricoes ?
                                        <Col className="gutter-row" span={8} xs={24} md={8}>
                                            <div className="gutter-box">
                                                <div className="item">
                                                    <h2>
                                                        <Icon className="item-icon" type="carry-out" />
                                                    Fecho das inscrições
                                                 </h2>
                                                    <div className="conteudos">
                                                        <div className="conteudo">
                                                            {moment(curso.dt_fecho_inscricoes).format("DD/MM/YYYY")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        : null}
                                    {curso.dedicacao !== "NAO_APLICAVEL" ?
                                        <Col className="gutter-row" span={8} xs={24} md={8}>
                                            <div className="gutter-box">
                                                <div className="item">
                                                    <h2>
                                                        <Icon className="item-icon" type="history" />
                                                    Dedicação
                                                 </h2>
                                                    <div className="conteudos">
                                                        <div className="conteudo">
                                                            {this.montarDedicacao(curso.dedicacao)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        : null}
                                    {duracao !== "SEM_REGISTO" || curso.carga_horaria ?
                                        <Col className="gutter-row" span={8} xs={24} md={8}>
                                            <div className="gutter-box">
                                                <div className="item">
                                                    <h2>
                                                        <Icon className="item-icon" type="hourglass" />
                                                    Duração
                                                 </h2>
                                                    <div className="conteudos">
                                                        {duracao !== "SEM_REGISTO" &&
                                                            <div className="conteudo">
                                                                {duracao}
                                                            </div>}
                                                        {curso.carga_horaria && (
                                                            <div className="conteudo">
                                                                <span>Carga Horária:</span> {curso.carga_horaria}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        : null}
                                    {this.context.monetizacao_entidade && (this.context.vendaCursos || this.context.vendaCertificados) &&
                                        <Col className="gutter-row" span={8} xs={24} md={8}>
                                            <div className="gutter-box">
                                                <div className="item">
                                                    <h2>
                                                        <Icon className="item-icon" type="shopping" />
                                                    Custo
                                                 </h2>
                                                    <div className="conteudos">
                                                        <div className="conteudo">
                                                            {this.montarCusto(curso.valorCurso, curso.valorCertificado)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>}
                                    {curso.precedencia &&
                                        <Col className="gutter-row" span={8} xs={24} md={8}>
                                            <div className="gutter-box">
                                                <div className="item">
                                                    <h2>
                                                        <Icon className="item-icon" type="branches" />
                                                    Precedência
                                                 </h2>
                                                    <div className="conteudos">
                                                        <div className="conteudo">
                                                            {curso.precedencia.codigo} - {curso.precedencia.nome}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>}
                                    {this.context.gamificacao_stepforma && this.context.gamificacao_entidade && curso.habilitar_gamificacao ?
                                        <Col className="gutter-row" span={8} xs={24} md={8}>
                                            <div className="gutter-box">
                                                <div className="item">
                                                    <h2>
                                                        <i className="fas fa-award item-icon"></i>
                                                    Gamificação
                                                 </h2>
                                                    <div className="conteudos">
                                                        <div className="conteudo">
                                                            {curso.habilitar_gamificacao ? "Sim" : "Não"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        : null}
                                    {curso.habilitar_competencias ?
                                        <Col className="gutter-row" span={8} xs={24} md={8}>
                                            <div className="gutter-box">
                                                <div className="item">
                                                    <h2>
                                                        <Icon className="item-icon" type="file-done" />
                                                    Competências
                                                 </h2>
                                                    <div className="conteudos">
                                                        <div className="conteudo">
                                                            {curso.habilitar_competencias ? "Sim" : "Não"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        : null}
                                    <Col className="gutter-row" span={8} xs={24} md={8}>
                                        <div className="gutter-box">
                                            <div className="item">
                                                <h2>
                                                    <Icon className="item-icon" type="bars" />
                                                    Conteúdos
                                                 </h2>
                                                <div className="conteudos">
                                                    <div className="conteudo">
                                                        <span>Atividades:</span> {total_atividades}
                                                    </div>
                                                    <div className="conteudo">
                                                        <span>Tarefas:</span> {total_tarefas}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {curso.edicao ?
                                        <Col className="gutter-row" span={8} xs={24} md={8}>
                                            <div className="gutter-box">
                                                <div className="item">
                                                    <h2>
                                                        <Icon className="item-icon" type="schedule" />
                                                    Edição
                                                 </h2>
                                                    <div className="conteudos">
                                                        <div className="conteudo">
                                                            {curso.edicao} {curso.ano ? curso.ano : ""} {curso.turma ? curso.turma : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col> : null}
                                </Row>
                            </div>
                            {curso.descricao &&
                                <div className="bloco esquerda">
                                    <div className="item">
                                        <h2><span>Descrição</span></h2>
                                        {curso.descricao && <p className="descricao" dangerouslySetInnerHTML={{ __html: curso.descricao.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>}
                                    </div>
                                </div>}
                            {curso.atividades &&
                                curso.atividades.length > 0 &&
                                <div className="bloco esquerda">
                                <div className="item programa-curso">
                                    <h2 style={{ marginBottom: 30 }}><span>Estrutura do Programa</span></h2>
                                        <div className="conteudos">
                                            {curso.atividades.map((atividade, index) => (
                                                <div key={index} className="conteudo">
                                                    <h3>{atividade.nome}</h3>
                                                    {atividade.objetivos &&
                                                        <p className="texto-objetivo">{atividade.objetivos}</p>}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>}
                            {curso.subcursos &&
                                curso.subcursos.length > 0 &&
                                <div className="bloco esquerda">
                                    <div className="item programa-curso">
                                        <h2>Estrutura do Programa</h2>
                                        <div className="conteudos">
                                            {curso.subcursos.map((subcurso, index) => (
                                                <div key={index} className="conteudo">
                                                    <h3>
                                                        <a
                                                            className="item-link"
                                                            href={`/${this.props.match.params.entidade}/curso/${subcurso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-visao-geral`}
                                                            onClick={() => localStorage.setItem("codigo_curso", subcurso.id)}
                                                        >
                                                            {subcurso.nome}
                                                        </a>
                                                    </h3>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>}
                            {(curso.ficha || curso.pagina_externa) &&
                                <div className="bloco esquerda">
                                <div className="item">
                                    <h2 style={{ display: "flex", alignItems: "center" }}>
                                        Ficha do curso
                                        <Link to="#" className="botao-icon-download" onClick={() => this.downloadFicheiro(localStorage.getItem("codigo_curso"))} style={{ marginLeft: 10 }}>
                                                <Icon type="download" />
                                            </Link>
                                        </h2>
                                        {curso.pagina_externa &&
                                            <a href={curso.pagina_externa} target="_blank" rel="noopener noreferrer" className="botao-secundario btn-visitar">Visitar página do curso</a>}
                                    </div>
                                </div>}
                            {formadores.length > 0 ? (
                                <div className="bloco esquerda">
                                    <div className="item">
                                        <div className="lista-instrutores">
                                            {formadores.map((formador, index) => (
                                                <div key={index} className="instrutor">
                                                    <div className="instrutor-container">
                                                        <div className="container-img">
                                                            <img src={formador.foto ? formador.foto : noimage} alt="Foto do instrutor" />
                                                        </div>
                                                        <div className="info">
                                                            <span className="nome">
                                                                {formador.nome_completo}
                                                            </span>
                                                            <span className="cargo">({formador.instrutor && formador.coordenador ? "Instrutor e Coordenador" : formador.instrutor ? "Instrutor" : "Coordenador"})</span>
                                                        </div>
                                                    </div>
                                                    {formador.descricao ?
                                                        <div className="instrutor-habilitacao">
                                                            {/*<h2>Habilitações</h2>*/}
                                                            <p className="descricao" dangerouslySetInnerHTML={{ __html: formador.habilitacao.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                                        </div>
                                                        : null}
                                                    {formador.descricao ?
                                                        <div className="instrutor-descricao">
                                                            {/*<h2>Descrição</h2>*/}
                                                            <p className="descricao" dangerouslySetInnerHTML={{ __html: formador.descricao.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                                        </div>
                                                        : null}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {curso.curso_Informacoes.length > 0 && (
                                <div className="bloco esquerda">
                                    <h2>Informações</h2>
                                    <div className="lista-informacoes">
                                        {curso.curso_Informacoes.map((info, index) => (
                                            <div key={"info" + index} className="informacao">
                                                <p className="texto" dangerouslySetInnerHTML={{ __html: info.texto.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                                <div className="data">
                                                    <span>{moment(info.dt_criado).format("DD/MM/YYYY")}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <GeralContext.Consumer>
                                {geralContext => {
                                    return <>
                                        {geralContext.tipo_inscricao === "DIGITAL" && !geralContext.inscrito ?
                                            <ModalInscricaoPart1
                                                codigo={curso.codigo.toUpperCase()}
                                                nome={curso.nome.toUpperCase()}
                                                carga_horaria={curso.carga_horaria}
                                                dt_inicio={curso.dt_inicio}
                                                dt_fim={curso.dt_fim}
                                                visiblePart1={visiblePart1}
                                                cancelar={() => this.setState({ visiblePart1: false })}
                                                confirmarPart1={this.confirmarPart1}
                                            />
                                            : null}
                                        {curso.existeContrato ?
                                            <ModalInscricaoPart2
                                                visiblePart2={visiblePart2}
                                                cancelar={() => this.setState({ visiblePart2: false })}
                                                id_curso={localStorage.getItem("codigo_curso")}
                                                nome_completo={nome_completo}
                                                cc={cc}
                                                dt_ccvalidade={dt_ccvalidade}
                                                codigo={curso.codigo.toUpperCase()}
                                                nome={curso.nome.toUpperCase()}
                                                carga_horaria={curso.carga_horaria}
                                                dt_inicio={curso.dt_inicio}
                                                dt_fim={curso.dt_fim}
                                                valor={`${curso.valorCurso} ${curso.moeda}`}
                                                finalizar={this.finalizar}
                                            />
                                            :
                                            <ModalInscricaoPart2Generico
                                                visiblePart2={visiblePart2}
                                                cancelar={() => this.setState({ visiblePart2: false })}
                                                id_curso={localStorage.getItem("codigo_curso")}
                                                nome_completo={nome_completo}
                                                cc={cc}
                                                dt_ccvalidade={dt_ccvalidade}
                                                codigo={curso.codigo.toUpperCase()}
                                                nome={curso.nome.toUpperCase()}
                                                carga_horaria={curso.carga_horaria}
                                                dt_inicio={curso.dt_inicio}
                                                dt_fim={curso.dt_fim}
                                                valor={`${curso.valorCurso} ${curso.moeda}`}
                                                finalizar={this.finalizar}
                                            />
                                        }
                                    </>
                                }}
                            </GeralContext.Consumer>
                        </div>
                    )}
            </div>
        );
    }
}
export default VisaoGeral;
